import { makeStyles, useTheme } from "@material-ui/styles";
import React, { useState, useEffect } from "react";

import CarouselLinear from "../../carousel/carousel";
import DropSelect from "../../common/drop-select";
import Panel from "../../common/panel";

import { useAppSelector } from "../../../store/store";
import { getPhrase } from "../../../utils/language";

import { ReactComponent as SearchIcon } from "../../../static/icons/search.svg";
import languagesStore from "../../../store/languages-store";

interface Props {
  updateSearchCriteria: Function;
}

const ApprovedSellersPanel = ({ updateSearchCriteria }: Props): JSX.Element => {
  const { lang, slides, slidesMobile } = useAppSelector(state => ({
    slides: state.siteConfig.catalogCarousel,
    slidesMobile: state.siteConfig.catalogCarouselMobile,
    lang: state.languages.language,
  }));
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const select = [
    { text: activePhrases && activePhrases["available_regions"], key: "All"},
    { text: activePhrases && activePhrases["center"], key: "Center" },
    { text: activePhrases && activePhrases["north"], key: "North" },
    { text: activePhrases && activePhrases["south"], key: "South" },
    { text: activePhrases && activePhrases["jerusalem"], key: "Jerusalem" },
    { text: activePhrases && activePhrases["sharon"], key: "Sharon" },
  ];

  const [currentDirection, setCurrentDirection] = useState<{ text: string | undefined; key: string }>({
    text: select[0].text ? select[0].text : undefined,
    key: select[0].key,
  });
  const [searchTerm, setSearchTerm] = useState<string>("");

  const classes = useStyles();

  const theme = useTheme();

  useEffect(() => {
    updateSearchCriteria?.({
      searchTerm,
      currentDirection: currentDirection.key,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDirection, searchTerm, currentDirection.key]);

  type Direction = "available_regions" | "north" | "center" | "south" ;

  useEffect(() => {
    const phraseKey: Direction = currentDirection.key?.toLocaleLowerCase() as Direction;

    setCurrentDirection({
      text: activePhrases && activePhrases["available_regions"],
      key: currentDirection.key,
    });
  }, [activePhrases]);

  const isMobile = window.innerWidth < 600;

  return (
    <div className={classes.wrapper}>
      <CarouselLinear
        slides={isMobile ? slidesMobile : slides}
        className={{ root: classes.carousel, dots: classes.dots, dot: classes.dot }}
      />

      <Panel className={{ panel: classes.panel }}>
        <div className={classes.search}>
          <input
            type="text"
            name="seller-search"
            className={classes.searchField}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <SearchIcon className={classes.searchIcon}/>
        </div>

    
        <DropSelect
          borderColor={theme.colors.orange}
          textColor={theme.colors.orange}
          arrowColor={theme.colors.orange}
          borderRadius={25}
          borderWidth={2}
          textSize={22}
          select={select}
          currentText={currentDirection.text}
          setCurrentText={setCurrentDirection}
        />
      </Panel>
    </div>
  );
};

export default ApprovedSellersPanel;

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },

    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      backgroundColor: theme.colors.orange,
      textAlign: "center",
      width: "100%",

      "&>h1": {
        display: "grid",
        placeItems: "center",
        fontWeight: "normal",
        color: "#fff",
        fontSize: 47,
        height: 65,
        padding: 0,
        margin: 0,

        [theme.device.mobile()]: {
          fontSize: 30,
        },
      },
    },
    carousel: {
      [theme.device.mobile()]: {
        minHeight: 100,
      },
    },
    dots: {
      marginBottom: 20,
      [theme.device.mobile()]: {
        gridColumnGap: 15,
      },
    },
    dot: {
      height: 22,
      width: 22,
      [theme.device.mobile()]: {
        height: 17,
        width: 17,
      },
    },

    panel: {
      gridColumnGap: 120,
      padding: "10px 40px",
    },

    search: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      margin: "0 auto",
      backgroundColor: theme.colors.text,
      padding: "10px 12px",
      borderRadius: 25,
      height: 50,
      boxSizing: "border-box",
    },

    searchIcon: {
      cursor: "pointer",
      marginLeft: "auto",
      width: 40,
      height: 40,
      filter: "invert(48%) sepia(79%) saturate(2476%) hue-rotate(11deg) brightness(118%) contrast(119%)",
    },
    searchField: {
      border: "none",
      height: "100%",
      width: "100%",
      fontSize: 20,
    },
  }),
  { name: "contact-panel" }
);
