import { makeStyles } from "@material-ui/styles";
import React from "react";
import FacebookIcon from "react-share/lib/FacebookIcon";
import InstagramIcon from "../../../static/icons/instagram_icon.png"
import { useAppSelector } from "../../../store/store";

const useStyles = makeStyles(
  theme => ({
    socialMediaIcons: {
      width: "100%",
      display: "flex",
      justifyContent: "end",
      gap: 10,
      marginTop: 10,
      [theme.device.mobile()]: {
        justifyContent: "center"
      }
    }
  })
)
function SocialMediaIcons(){
    const classes = useStyles();
    const { socialMedia } = useAppSelector(state => state.siteConfig);

    return (
        <div className={classes.socialMediaIcons}>
            {true ? 
                <a href={socialMedia?.facebook ?? "https://www.facebook.com/JOIEISR/"} target="_blank">
                    <FacebookIcon borderRadius={50} size={40} />
                </a> 
            : null}

            {true ? 
                <a href={socialMedia?.instagram ?? "https://www.instagram.com/joieisrael/"} target="_blank">
                  <img src={InstagramIcon} height={40} width={"auto"} />
                </a>
            : null}
        </div>
    )
}

export default SocialMediaIcons;