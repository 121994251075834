import React, { useCallback, useEffect, useRef, useState } from "react";
import { CreateCSSProperties, makeStyles } from "@material-ui/styles";
import clsx from "clsx";

import { useAppDispatch, useAppSelector } from "../../../store/store";
import UIfiltersStore from "../../../store/ui-filters-store";
import { TTypedStyles } from "../../../theme";
import { isMobile } from "react-device-detect";
import { getPhrase } from "../../../utils/language";
import languagesStore from "../../../store/languages-store";

type StyleProps = {
  left: number;
  right: number;
  circleSize: number;
};

type Circle = "left_circle" | "right_circle" | null;

const RangeSlider = (): JSX.Element => {
  const CIRCLE_SIZE: number = 22;

  const LEFT_CIRCLE: Circle = "left_circle";
  const RIGHT_CIRCLE: Circle = "right_circle";

  const container = useRef<HTMLDivElement>(null);

  const leftCircle = useRef<HTMLDivElement>(null);
  const rightCircle = useRef<HTMLDivElement>(null);

  const [circle, setCircle] = useState<Circle>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<number>(0);

  const [startSlot, setStartSlot] = useState<number>(0);
  const [endSlot, setEndSlot] = useState<number>(0);

  const [startPos, setStartPos] = useState<number>(0);

  const { minAge, maxAge, selectedFrom, selectedTo, lang } = useAppSelector(state => ({
    minAge: state.UIfilters.age.from,
    maxAge: state.UIfilters.age.to,
    selectedFrom: state.UIfilters.age.selectedFrom,
    selectedTo: state.UIfilters.age.selectedTo,
    lang: state.languages.language,
  }));

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const classes = useStyles({ left: end, right: start, circleSize: CIRCLE_SIZE });

  const dispatch = useAppDispatch();

  const getMaxPosition = useCallback(
    (): number => container.current!?.getBoundingClientRect().width - CIRCLE_SIZE,
    []
  );

  const SLOT_CELL = getMaxPosition() / (maxAge - minAge);

  const startDrag = (event: React.MouseEvent | React.TouchEvent): void => {
    setIsDragging(true);
    event.persist();

    const nativeEvent = event.nativeEvent;

    if (event.target === leftCircle.current) {
      if (nativeEvent instanceof MouseEvent) {
        setStartPos(nativeEvent.clientX - end);
      }

      if (nativeEvent instanceof TouchEvent) {
        setStartPos(nativeEvent.touches[0].clientX - end);
      }

      setCircle(LEFT_CIRCLE);
      return;
    }

    if (event.target === rightCircle.current) {
      if (nativeEvent instanceof MouseEvent) {
        setStartPos(nativeEvent.clientX - start);
      }

      if (nativeEvent instanceof TouchEvent) {
        setStartPos(nativeEvent.touches[0].clientX - start);
      }

      setCircle(RIGHT_CIRCLE);
      return;
    }
  };

  const moveCircle = useCallback(
    (clientX: number) => {
      if (isDragging) {
        const calculatePosition = Math.min(Math.max(0, clientX - startPos), getMaxPosition());

        const slot = Math.min(maxAge, maxAge - Math.round(calculatePosition / SLOT_CELL));

        if (circle === LEFT_CIRCLE) {
          setEndSlot(slot);
          setEnd(calculatePosition);
          return;
        }

        if (circle === RIGHT_CIRCLE) {
          setStartSlot(slot);
          setStart(calculatePosition);
          return;
        }
      }
    },
    [isDragging, setEndSlot, setEnd, setStartSlot, setStart, SLOT_CELL, getMaxPosition]
  );

  const onDrag = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (event instanceof TouchEvent) {
        return moveCircle(event.touches[0].clientX);
      }

      if (event instanceof MouseEvent) {
        return moveCircle(event.clientX);
      }
    },
    [moveCircle]
  );

  const stopDrag = useCallback((): void => {
    setIsDragging(false);

    if (isMobile) {
      document.removeEventListener("touchmove", onDrag);
      document.removeEventListener("touchend", stopDrag);
      return;
    }

    document.removeEventListener("mousemove", onDrag);
    document.removeEventListener("mouseup", stopDrag);
  }, [setIsDragging, onDrag]);

  useEffect(() => {
    setStartSlot(minAge);
    setEndSlot(maxAge);
  }, [minAge, maxAge]);

  useEffect(() => {
    if (isMobile) {
      document.addEventListener("touchmove", onDrag);
      document.addEventListener("touchend", stopDrag);
      return;
    }

    document.addEventListener("mousemove", onDrag);
    document.addEventListener("mouseup", stopDrag);
    return () => {
      document.removeEventListener("mousemove", onDrag);
      document.removeEventListener("mouseup", stopDrag);
    }
  }, [circle, onDrag, stopDrag]);

  useEffect(() => {
    if (startSlot > endSlot)
      dispatch(UIfiltersStore.actions.setSelectedAge({ selectedFrom: endSlot, selectedTo: startSlot }));
    else
      dispatch(UIfiltersStore.actions.setSelectedAge({ selectedFrom: startSlot, selectedTo: endSlot }));
  }, [dispatch, endSlot, startSlot]);

  useEffect(() => {
    // eslint-disable-next-line
    let isMounted = true;
    setStart(getMaxPosition());
    dispatch(UIfiltersStore.actions.setSelectedAge({ selectedFrom: startSlot, selectedTo: endSlot }));

    return () => {
      isMounted = false;
    }; // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.border} />
      </div>
      <div className={classes.content} ref={container}>
        <div className={clsx(classes.container, classes.leftCircle)}>
          <span
            className={classes.circle}
            onMouseDown={startDrag}
            onTouchStart={startDrag}
            ref={leftCircle}
          />
          <div className={classes.age}>
            {isFinite(selectedTo!) ? endSlot + '' + (activePhrases && activePhrases["years"]) : "-"}
          </div>
        </div>
        <div className={clsx(classes.container, classes.rightCircle)}>
          <span
            className={classes.circle}
            onMouseDown={startDrag}
            onTouchStart={startDrag}
            ref={rightCircle}
          />
          <div className={classes.age}>
            {isFinite(selectedFrom!) ? startSlot + '' + (activePhrases && activePhrases["years"]) : "-"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;

const useStyles: TTypedStyles<StyleProps> = makeStyles(
  theme => ({
    root: {
      userSelect: "none",
      marginTop: 15,
      width: 250,
      height: 15,
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    wrapper: {
      position: "absolute",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      border: `1px solid ${theme.colors.border}`,
      borderWidth: "0 1px 0 1px",
    },
    border: {
      width: "100%",
      border: `2px solid ${theme.colors.border}`,
    },
    circle: ({ circleSize }: StyleProps): CreateCSSProperties => ({
      zIndex: 1,
      cursor: "pointer",
      width: circleSize,
      height: circleSize,
      borderRadius: "100%",
      border: `1px solid ${theme.colors.border}`,
      backgroundColor: theme.colors.text,
    }),
    content: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      margin: "0 15px",
      boxSizing: "border-box",
    },
    container: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    rightCircle: ({ right }: StyleProps): CreateCSSProperties => ({
      left: right,
    }),
    leftCircle: ({ left }: StyleProps): CreateCSSProperties => ({
      left: left,
    }),
    age: {
      direction: "rtl",
      position: "absolute",
      bottom: -17,
    },
  }),
  { name: "range-slider" }
);
