import exploreJoieService from "../services/explore-joie";
import { TExploreJoie } from "../services/explore-joie/types";

import { createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { TThunk } from './store';


type State = TExploreJoie;

const initialState: State = {
    exploreCarousel: [],
    exploreCarouselMobile: []
}

const fetchExploreJoieConfig: TThunk<TExploreJoie> = createAsyncThunk(
    "explore-joie/fetch",
    async () => await exploreJoieService.fetchExploreJoie()
);

const reducer = createReducer(initialState, {
    [fetchExploreJoieConfig.fulfilled.type]: (state, action: PayloadAction<TExploreJoie>) => {
        state = action.payload;
        return state;
    },
});

const actions = {
    fetchExploreJoieConfig
};

const exploreJoieStore = {
    reducer,
    actions
};

export default exploreJoieStore;

export type { State };