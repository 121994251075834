import { createAction, createAsyncThunk, createReducer } from "@reduxjs/toolkit";

import {
  TOrderedProduct,
  TUpdateCartItemsParams,
  TUpdateCartItemsPayload,
} from "../services/orders/types";
import { buildProduct, countProductPrice, detectUserStatus } from "../utils/helper";
import { Product } from "../services/products/types";
import ordersService from "../services/orders";
import { TThunk } from "./store";

type State = {
  totalDeliveryTime: number;
  totalCount: number;
  totalPrice: number;
  items: any[];
  userId: number | null;
};

const initialState: State = {
  totalDeliveryTime: 0,
  totalCount: 0,
  totalPrice: 0,
  items: [],
  userId: null,
};

const addProduct = createAction("cart/add", (product: any, userId: number) => ({
  payload: { product, userId },
}));

const updateCart = createAction("cart/update", (products: any[]) => ({
  payload: { items: products },
}));

const removeProduct = createAction("cart/remove", (id: number) => ({ payload: id }));

const setProductCount = createAction(
  "cart/product-count",
  (data: { id: number; count: number; weight: number }) => ({
    payload: data,
  })
);

const removeAll = createAction("cart/remove-all");

const setCartTotal = createAction("cart/total");

const updateCartItems: TThunk<TUpdateCartItemsPayload, TUpdateCartItemsParams> = createAsyncThunk(
  "cart/update-all",
  async cartItems => await ordersService.updateCartItems(cartItems)
);

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(addProduct, (state, action) => {
      state.items = state.items
        ?.filter(item => item.id !== action.payload.product.id)
        .concat(action.payload.product);
      state.userId = action.payload.userId;
    })
    .addCase(updateCart, (state, action) => {
      state.items = action.payload.items;
    })
    .addCase(removeProduct, (state, action) => {
      state.items = state.items?.filter(item => item.id !== action.payload);
    })
    .addCase(setProductCount, (state, action) => {
      state.items = state.items?.map(item => {
        if (item.id === action.payload.id) {
          item.count = action.payload.count;
          item.totalPrice = action.payload.count * item.price!;
          item.totalWeight = action.payload.weight * action.payload.count;
        }
        return item;
      });
    })
    .addCase(setCartTotal, state => {
      if (!!state.items?.length) {
        state.totalCount = state.items
          ?.map(item => item.count)
          .reduce((prev, current) => prev + current);
        state.totalPrice = Number(
          state.items
            ?.map(item => item.count * item.price!)
            .reduce((prev, current) => prev + current)
            .toFixed(1)
        );

        state.totalDeliveryTime = Math.max.apply(
          Math,
          state.items?.map(item => item.deliveryTime)
        );
        return;
      }

      state.totalCount = 0;
      state.totalPrice = 0;
      state.totalDeliveryTime = 0;
    })
    .addCase(removeAll, state => {
      state.totalCount = 0;
      state.totalPrice = 0;
      state.items = [];
    })
    .addCase(updateCartItems.fulfilled, (state, action) => {
      const fetchedCartProducts = action.payload.products.map(p => buildProduct(p));
      const user = action.payload.user;

      state.items.forEach(item => {
        const product = fetchedCartProducts.find(p => p.id === item.id);
        if (!product) {
          return;
        }

        if (detectUserStatus(user!, product!)) {
          let { price } = countProductPrice(user?.approvedAgent!, product, Number(user?.discount!));
          const reducedPrice = price;
          item.price = reducedPrice;
          mapProductToCartItem(product, item);
        }
      });
      state.totalPrice = state.items.reduce(
        (totalPrice, currentItem) => totalPrice + currentItem.totalPrice,
        0
      );
    });
});

const mapProductToCartItem = (product: Product, cartItem: TOrderedProduct) => {
  cartItem.name = product.name;
  cartItem.weight = product.weight;
  cartItem.quantity = product.quantity;
  cartItem.availabilityDate = product.availabilityDate;
  cartItem.brand = product.brand!;
  cartItem.picture = product?.mainPicture;
  cartItem.color = product.color;
  cartItem.catalogNumber = product.catalogNumber;
  cartItem.totalPrice = cartItem.price * cartItem.count;
  cartItem.deliveryTime = product.deliveryTime;
  cartItem.totalWeight = product.weight * cartItem.count;
};

const actions = {
  addProduct,
  removeProduct,
  setProductCount,
  setCartTotal,
  removeAll,
  updateCart,
  updateCartItems,
};

const cartStore = {
  reducer,
  actions,
};

export default cartStore;

export type { State };
