/* istanbul ignore file */
import { createBrowserHistory, History, LocationState } from "history";
import { connectRouter, RouterState } from "connected-react-router";
import { combineReducers, Reducer } from "@reduxjs/toolkit";
import approvedSellers, { State as ApprovedSellersState } from "./approved-sellers-store";
import sendMessageStore, { State as SendMessageState } from "./send-message-store";
import siteConfigStore, { State as SiteConfigState } from "./site-config-store";
import categoriesStore, { State as CategoriesState } from "./categories-store";
import languagesStore, { State as LanguagesState } from "./languages-store";
import UIsortingStore, { State as SortingState } from "./ui-sorting-store";
import UIfiltersStore, { State as FiltersState } from "./ui-filters-store";
import authFormStore, { State as AuthFormState } from "./auth-form-store";
import productsStore, { State as ProductsState } from "./products-store";
import brandsStore, { State as BrandsState } from "./brands-store";
import ordersStore, { State as OrdersState } from "./orders-store";
import usersStore, { State as UsersState } from "./users-store";
import cartStore, { State as CartState } from "./cart-store";
import signatureStore, { State as SignatureState } from "./signature-mode";
import signatureModeStore, { State as SignatureModeState } from "./signature-store";
import FitGuideStore, { State as FitGuideState } from "./fit-guide-store";
import ContentPagesStore, { State as ContentPagesState } from "./content-pages-store";
import ThanksPagesStore, { State as ThanksPagesState } from "./thanks-page-store";
import CouponsStore, { State as CouponsState } from "./coupons-store";
import exploreJoieStore, { State as ExploreJoieState } from "./explore-joie-store";
import { SeoSettings } from "../services/seo-settings/types";
import SeoSettingsStore from "./seo-settings-store";

interface ApplicationState {
  router: RouterState<LocationState>;
  approvedSellers: ApprovedSellersState;
  sendMessage: SendMessageState;
  categories: CategoriesState;
  siteConfig: SiteConfigState;
  languages: LanguagesState;
  UIsorting: SortingState;
  UIfilters: FiltersState;
  authForm: AuthFormState;
  products: ProductsState;
  brands: BrandsState;
  orders: OrdersState;
  user: UsersState;
  cart: CartState;
  signature: SignatureState;
  signatureMode: SignatureModeState;
  fitGuide: FitGuideState;
  contentPages: ContentPagesState;
  thanksPages: ThanksPagesState;
  coupons: CouponsState;
  exploreJoie: ExploreJoieState;
  seoSettings: SeoSettings[];
}

const createRootReducer = (history: History<LocationState>) =>
  combineReducers<ApplicationState>({
    user: usersStore.reducer,
    cart: cartStore.reducer,
    orders: ordersStore.reducer,
    brands: brandsStore.reducer,
    products: productsStore.reducer,
    authForm: authFormStore.reducer,
    UIfilters: UIfiltersStore.reducer,
    languages: languagesStore.reducer,
    UIsorting: UIsortingStore.reducer,
    siteConfig: siteConfigStore.reducer,
    categories: categoriesStore?.reducer,
    sendMessage: sendMessageStore.reducer,
    approvedSellers: approvedSellers.reducer,
    signature: signatureStore.reducer,
    signatureMode: signatureModeStore.reducer,
    fitGuide: FitGuideStore.reducer,
    contentPages: ContentPagesStore.reducer,
    thanksPages: ThanksPagesStore.reducer,
    coupons: CouponsStore.reducer,
    exploreJoie: exploreJoieStore.reducer,
    seoSettings: SeoSettingsStore.reducer,
    router: connectRouter(history) as unknown as Reducer<RouterState<LocationState>>,
  });

const history = createBrowserHistory();

const rootReducer = createRootReducer(history);

export { rootReducer, history };

export type { ApplicationState };
