import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useAppSelector } from "../store/store";
import { isMobile } from "react-device-detect";
import languagesStore from "../store/languages-store";

const OverFooterContainer = () => {
  const { ImageOverFooter } = useAppSelector(state => state.signatureMode);
  const classes = useStyles();
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  return (
    <div style={{ background: `url(${ImageOverFooter?.url})` }} className={classes.overFooterContainer}>
      <div className={classes.content}>

        <h3 className={classes.title}><strong>{activePhrases && activePhrases['Section6_title']}</strong></h3>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  overFooterContainer: {
    width: "100%",
    minWidth: "100%",
    height: "250px",
    margin: "0 auto",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    paddingRight: "120px",
    alignSelf: "center",

    [theme.device.superSmall()]: {
      paddingRight: "0",
    },
  },
  title: {
    paddingTop: "20px",
    fontSize: "2.5rem",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "4px",
    color: "rgba(255,255,255, 1)",
    borderBottom: "4px solid" + theme.colors.orange,

    [theme.device.superSmall()]: {
      textAlign: "center",
      border: 'none',
      '& strong': {
        fontWeight: 600,
        borderBottom: "4px solid" + theme.colors.orange,
      }
    },
  },
}));

export default OverFooterContainer;
