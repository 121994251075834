import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import React from "react";

const GoogleMaps = ({ lat, lng }: any): JSX.Element => {
  const mapStyles = {
    width: "100%",
    height: "100%",
  };

  return (
    <a href={`https://google.com/maps/?q=${lat},${lng}`} target="_blank" rel="noopener noreferrer">
      <Map
        google={google}
        zoom={15}
        style={mapStyles}
        initialCenter={{ lat, lng }}
        disableDoubleClickZoom
        gestureHandling={null}
        controlSize={null}
        scrollwheel={false}
      >
        <Marker position={{ lat, lng }} />
      </Map>
    </a>
  );
};

export default GoogleApiWrapper({
  // This apiKey belong to the bambino@appgr8.com account
  apiKey: "AIzaSyD4TpnUfVIUe-7zXZxHnuIcMJ5tjYe3KWk",
})(GoogleMaps);
