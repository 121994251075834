import { makeStyles, useTheme } from "@material-ui/styles";
import React, { useState, useEffect } from "react";
import { push } from "connected-react-router";
import clsx from "clsx";

import EmptyBag from "../../common/empty-bag";
import CartItem from "./cart-item";

import { useAppDispatch, useAppSelector } from "../../../store/store";
import { Product } from "../../../services/products/types";
import displayCurrencySign from "../../../utils/currencies-signs";
import { scrollStyles } from "../../../utils/style-utils";
import { countProductDiscountPrice, countProductPrice } from "../../../utils/helper";
import { getPhrase } from "../../../utils/language";
import Button from "../../common/button";
import { config } from "../../../config";
import languagesStore from "../../../store/languages-store";
import authFormStore from "../../../store/auth-form-store";
import cartStore from "../../../store/cart-store";

type TProps = {
  className?: {
    root?: string;
  };
};

export type UpdatedProduct = Product & {
  count?: number;
  totalPrice?: number;
  totalWeight?: number;
  price?: number;
  shippingPrice?: number;
  isPublished: boolean;
};

const Cart = ({ className }: TProps): JSX.Element => {
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const cartItemsGuest = useAppSelector(state => state.cart.items);

  const { authFormIsVisible, langDropDownIsVisible, cartIsVisible, hamburgerMenuIsVisble } =
    useAppSelector(
      ({
        authForm: {
          authFormIsVisible,
          langDropDownIsVisible,
          cartIsVisible,
          hamburgerMenuIsVisble,
        },
      }) => ({
        authFormIsVisible,
        langDropDownIsVisible,
        cartIsVisible,
        hamburgerMenuIsVisble,
      })
    );

  const { cartProducts, lang } = useAppSelector(state => {
    const cartItems = state.user.data?.cartItems;
    const cartProducts: UpdatedProduct[] = [];

    state.products.productsData.data?.forEach((product: UpdatedProduct) => {
      const updatedProduct = { ...product };

      if (
        cartItems?.find(cartItem => {
          if (cartItem.productId === product.id) {
            let { price } = countProductDiscountPrice(product);
            // let {price} = countProductPrice( state.user.data?.approvedAgent!, product, Number(state.user.data?.discount!));
            updatedProduct.count = cartItem.productCount;
            updatedProduct.totalPrice = price * cartItem.productCount;
            updatedProduct.totalWeight = product.weight * cartItem.productCount;
            updatedProduct.price = price;

            return updatedProduct;
          }

          return;
        })
      ) {
        cartProducts.push(updatedProduct);
      }

      return false;
    });

    return {
      lang: state.languages.language,
      cartProducts,
    };
  });

  const dispatch = useAppDispatch();

  const isLoggedIn = useAppSelector(state => state.user.data);
  const classes = useStyles({});

  const theme = useTheme();

  let items: any = <></>;

  if (isLoggedIn) {
    items = cartProducts?.map(item => <CartItem key={item.id} cartProduct={item} />);
  } else {
    items = cartItemsGuest?.map(item => (
      <CartItem key={item.product.id} cartProduct={{ ...item.product, count: item.count }} />
    ));
  }

  const onProceedBtnClick = () => {
    dispatch(push(config.routes.checkoutPage, true));
    if (cartItemsGuest.length || cartProducts.length) {
      dispatch(authFormStore.actions.toggleVisibility("cartIsVisible", false));
    }
  };

  const onViewBagBtnClick = () => {
    dispatch(push(config.routes.shoppingBagPage, true));
    dispatch(authFormStore.actions.toggleVisibility("cartIsVisible", false));
  };

  const isHe = lang === "he";

  const isAuth = useAppSelector(({ user }) => user.data?.approvedAgent);
  const discount = useAppSelector(({ user }) => user.data?.discount);
  useEffect(() => {
    let totalCartPrice = 0;
    let totalCartCount = 0;
    if (cartProducts?.length > 0) {
      cartProducts?.forEach(cP => {
        let { price } = countProductDiscountPrice(cP);
        totalCartPrice += price * cP.count!;
        totalCartCount += cP.count!;
      });
    } else if (cartItemsGuest?.length > 0) {
      cartItemsGuest?.forEach(cP => {
        let { price } = countProductDiscountPrice(cP.product);
        totalCartPrice += price * cP.count!;
        totalCartCount += cP.count!;
      });
    }
    setTotalCount(totalCartCount);
    setTotalPrice(totalCartPrice);
  }, [cartProducts]);

  return (
    <div className={clsx(classes.root, className?.root)}>
      <div className={classes.title}>{activePhrases && activePhrases["shopping_bag"]}</div>

      {items?.length > 0 ? (
        <>
          <div className={classes.links}>
            <Button
              text={activePhrases && activePhrases["proceed_to_checkout"]}
              outline={theme.colors.blue}
              fill={theme.colors.blue}
              textColor={theme.colors.text}
              textSize={21}
              borderWidth={2}
              borderRadius={10}
              width="100%"
              height={30}
              action={onProceedBtnClick}
            />

            <Button
              text={activePhrases && activePhrases["view_bag"]}
              outline={theme.colors.blue}
              fill={theme.colors.text}
              textColor={theme.colors.blue}
              textSize={21}
              borderWidth={2}
              borderRadius={10}
              width="100%"
              height={30}
              action={onViewBagBtnClick}
            />
          </div>

          <EmptyBag />

          <div className={classes.common}>
            <div className={classes.text}>
              {totalCount === 1
                ? activePhrases && activePhrases["item"]
                : activePhrases && activePhrases["items"]}
              &nbsp;
              <div className={classes.greenText}>{totalCount}</div>
            </div>

            <div className={clsx(classes.text, classes.activeDesktop)}>
              <div className={classes.greenText}>
                {displayCurrencySign(lang)}
                {totalPrice.toFixed(2)}
              </div>

              {isHe && <>&nbsp;:{activePhrases && activePhrases["subtotal_before_delivery"]}</>}
            </div>
          </div>

          <div className={classes.container}>
            {items}
            <div className={clsx(classes.text, classes.activeMobile)}>
              <div className={classes.greenText}>
                {displayCurrencySign(lang)}
                {totalPrice}
              </div>
              &nbsp;:{activePhrases && activePhrases["subtotal"]}
            </div>
          </div>
        </>
      ) : (
        <div className={clsx(classes.text, classes.emptyMsg)}>
          {activePhrases && activePhrases["cart_is_empty"]}
        </div>
      )}
    </div>
  );
};

export default Cart;

const useStyles = makeStyles(
  theme => ({
    root: {
      visibility: "hidden",
      opacity: 0,
      transition: "0.3s",
      position: "absolute",
      top: "100%",
      left: 218.5,
      padding: "25px",
      paddingBottom: "75px",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      width: 620,
      backgroundColor: theme.colors.text,
      border: `1px solid ${theme.colors.border}`,
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: 7,
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "darkgrey",
        outline: `1px solid slategrey`,
      },
      [theme.device.mobile()]: {
        padding: 10,
        width: "100%",
        left: 0,
        right: 0,
      },
    },
    title: {
      alignSelf: "flex-end",
      fontWeight: 700,
      fontSize: 21,
      color: theme.colors.black,
    },
    links: {
      paddingTop: "15px",
      width: "100%",
      display: "grid",
      gridTemplateColumns: "2fr 1fr",
      gridColumnGap: 10,
      [theme.device.mobile()]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: 88,
      },
    },
    common: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingBottom: 25,
      [theme.device.mobile()]: {
        justifyContent: "flex-end",
      },
    },
    text: {
      color: theme.colors.grayText,
      fontSize: 21,
      display: "flex",
    },
    greenText: {
      color: theme.colors.blue,
      fontWeight: 600,
      fontSize: 21,
    },
    smallText: {
      fontSize: 16,
    },
    container: {
      height: 200,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      gridRowGap: 15,
      // overflowY: "scroll",
      ...scrollStyles(theme, false),
    },
    closeIcon: {
      width: 12,
      height: 12,
      cursor: "pointer",
      ...theme.utils.svgStroke(theme.colors.blue),
    },
    product: {
      width: "100%",
      boxSizing: "border-box",
      padding: "0 15px",
      display: "grid",
      gridTemplateColumns: "10px 2fr 1fr",
    },
    productInfo: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      boxSizing: "border-box",
      paddingRight: 15,
    },
    productView: {
      height: "100%",
      width: "100%",
    },
    productName: {
      fontWeight: 700,
      fontSize: 25,
    },
    productPrice: {
      fontSize: 23,
    },
    productColor: {
      padding: "10px 0",
      color: theme.colors.grayText,
      display: "flex",
      alignItems: "center",
      fontSize: 16,
      fontWeight: 600,
    },
    emptyMsg: {
      marginTop: 15,
    },
    activeDesktop: {
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    activeMobile: {
      [theme.device.desktop()]: {
        display: "none",
      },
    },
  }),
  { name: "cart" }
);
