import { Category } from "../../services/categories/types";
import { Product, TAge } from "../../services/products/types";
import { LoggedUser } from "../../services/users/types";
import { TPriceRange } from "../../store/ui-filters-store";

export const filterByCategory = (product: Product, categories: Category[], currentCategoryId: number, subCatIsActive: boolean, subSubCatIsActive: boolean, search: string) => {
  if (subSubCatIsActive) {
    const [, subSubCategoryId] = search.split("=");

    return product.subSubCategories.find(cat => cat.id === Number(subSubCategoryId));
  } else if (subCatIsActive) {
    const [, subCategoryId] = search.split("=");

    return (
      product.subCategories.find(cat => cat.id === Number(subCategoryId)) ||
      product.subSubCategories.find(cat => cat.parent === Number(subCategoryId))
    );
  }

  return (
    product.categories.find(cat => cat.id === currentCategoryId) ||
    product.subCategories.find(subCat => subCat.parent === currentCategoryId) ||
    product.subSubCategories.find(subSubCat => {
      if (subSubCat.parent) {
        const subCategoryId = subSubCat.parent;
        const subCategory = categories.find(c => c?.id === subCategoryId);
        return subCategory?.parent?.id === currentCategoryId;
      }
      return false;
    })
  );
}

export const filterBySearch = (product: Product, search: string) => {
  if (search.startsWith("?search=")) {
    const [, searchString] = search.split("=");
    return (
      product.name.toLowerCase().includes(searchString.toLowerCase()) ||
      product.title.toLowerCase().includes(searchString.toLowerCase()) ||
      product.keywords?.toLowerCase().includes(searchString.toLowerCase())
    );
  }
  return product;
}
    
export const filterByAge = (product: Product, age: TAge) =>
  (product.age?.from! >= age?.selectedFrom! && product.age?.to! <= age?.selectedTo!) ||
  (product.age?.from! <= age?.selectedTo! && product.age?.to! >= age?.selectedFrom!)
  
const checker = (product: Product, user: LoggedUser | null, min: number, max: number) => {
  if (user?.approvedAgent) {
    if (user.discount && +user?.discount > 0) {
      return product?.storePrice - product?.storePrice * (user?.discount / 100) >= +min && product?.storePrice - product?.storePrice * (user?.discount / 100) <= +max
    } else if (user.discount && +user?.discount == 0) {
      return product?.storePrice >= min && product?.storePrice <= max;
    } else {
      return;
    }
  } else {
    return product?.salePrice >= min && product?.salePrice <= max;
  }
};

export const filterByPriceRange = (product: Product, products: Product[], priceRange: TPriceRange, user: LoggedUser | null) => {
  const maxPrice = Math.max(...products.map(p => p.salePrice));
  let { min, max } = priceRange;
  if (min === "") {
    min = 0;
  }
  if (max === "") {
    max = maxPrice;
  }
  return checker(product, user, min, max);
}
  
export const filterByRating = (product: Product, activeRating: number) => {
  if (activeRating === 0) return product;
  const numberOfReviews = product.reviews.length;
  const productRating =
    numberOfReviews <= 4
      ? product.rating
      : Number((product.reviews.reduce((a, b) => (a += b.rating), 0) / numberOfReviews).toFixed(
        2
      ));
  return productRating >= activeRating;
} 

export const filterByBrand = (p: Product, activeBrands: number[]) => !!activeBrands.length ? activeBrands.includes(p.brand?.id!) : p
export const filterByIsoFix = (p: Product, activeIsofix: string) => (activeIsofix.length > 0 ? p.isofix === activeIsofix : p)
export const filterByStandard = (p: Product, activeStandard: string) => activeStandard.length > 0 ? p.productStandart === activeStandard : p
export const filterByFoldType = (p: Product, activeFoldType: string) => activeFoldType.length > 0 ? p.foldTypes === activeFoldType : p
export const applyPagination = (products: Product[], maxProducts: number) => products.filter((_, i) => i < maxProducts)