import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

import { useAppSelector } from "../../store/store";

type Select = "add" | "reduce";

type TProps = {
  setWarningVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setDesiredAmount?: (count: number) => void;
  setCount: (count: number) => void;
  productQuantity: number;
  desiredAmount?: number;
  productId: number;
  className?: {
    root?: string;
  };
  big?: boolean;
};

const ProductCount = ({
  setWarningVisible,
  setCount,
  productQuantity,
  productId,
  className,
  big,
}: TProps) => {
  const updatedProduct = useAppSelector(state => state.cart.items);
  const { count } = useAppSelector(state => {
    const currentProduct = state.user.data
      ? state.user?.data?.cartItems?.find(cartItem => cartItem.productId === productId)
      : state.cart.items.find(cartItem => cartItem.product.id === productId);
    const productCount = state.user.data ? currentProduct?.productCount! : currentProduct.count;

    return { count: productCount || 1 };
  });

  const classes = useStyles();

  const maxQuantityIsReached = count === productQuantity;

  const onSelectClick = (selectType: Select) => () => {
    if (selectType === "add") {
      maxQuantityIsReached && setWarningVisible(true);
      setCount(maxQuantityIsReached ? count! : count! + 1);
    } else {
      setCount(count! > 1 ? count! - 1 : count!);
      setWarningVisible(false);
    }
  };

  useEffect(() => {
    localStorage.setItem("cartProducts", JSON.stringify(updatedProduct));
  }, [count]);

  return (
    <div className={clsx(classes.root, className?.root)}>
      <div className={classes.content}>
        <div
          className={clsx(classes.select, big && classes.selectBig, count <= 1 && classes.inactive)}
          onClick={onSelectClick("reduce")}
        >
          <div className={clsx(count <= 1 && classes.inactive)}>-</div>
        </div>
        <div className={clsx(classes.count, big && classes.countBig)}>
          <div className={classes.countText}>{count}</div>
        </div>
        <div
          className={clsx(classes.select, big && classes.selectBig)}
          onClick={onSelectClick("add")}
        >
          <div>+</div>
        </div>
      </div>
    </div>
  );
};

export default ProductCount;

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
    },
    content: {
      width: 105,
      userSelect: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      position: "relative",
    },
    select: {
      borderRadius: 4,
      cursor: "pointer",
      width: 15,
      height: 15,
      border: `1px solid ${theme.colors.green}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.colors.green,
      fontSize: 14,
      fontWeight: 700,
    },
    count: {
      fontSize: 16,
      fontWeight: 600,
      color: theme.colors.blue,
      height: 20,
      width: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `2px solid ${theme.colors.green}`,
      borderRadius: 5,
    },
    countText: {
      marginBottom: 3,
      textColor: theme.colors.black,
    },
    inactive: {
      cursor: "default",
      borderColor: theme.colors.grayText,
      color: theme.colors.grayText,
    },
    selectBig: {
      height: 23,
      width: 23,
      fontSize: 23,
      paddingBottom: 7,
    },
    countBig: {
      fontSize: 25,
      height: 35,
      width: 30,
      padding: "0 20px",
    },
  }),
  { name: "product-count" }
);
