import React from "react";

import { Trans } from "react-i18next";

const errMessage = (msgs, c) => {
  let message = msgs.find(x => x === c);

  if (message) {
    return message;
  }
  return "";
};

const ErrorMsg = props => {
  const err = props.msg;

  if (!err || err === "") {
    return "";
  }

  const errorMessage = "error_" + err;
  return (
    <div className="cross-validation-error-message alert alert-danger text-right">
      <span>
        <Trans>{errorMessage}</Trans>
      </span>
    </div>
  );
};

const PaymentForm = props => {
  const responseMsgs = props.msg;
  const imageSrc = process.env.PUBLIC_URL + "/images/" + props.img + ".png";

  return (
    <form id="payment_form" style={{ marginTop: 100 }} onSubmit={props.handleSubmit}>
      <div className="row h-100 justify-content-center align-self-center pt-5">
        <div className="card card-outline-secondary">
          <div className="card-body">
            <h3 className="text-right">
              <Trans>header</Trans>
            </h3>
            <div className="row pt-1">
              <div className="col-md-4 w-330 ml-auto">
                <label htmlFor="payment_amount" style={{ height: "auto" }}>
                  <Trans>lbl_amount</Trans>
                </label>
                <div className="d-flex">
                  {/* <div className="input-group">
                    <div className="input-group-append ml-auto">
                      <span className="input-group-text">₪</span>
                    </div>
                  </div> */}
                  <input
                    type="text"
                    className="form-control"
                    name="amount"
                    id="payment_amount"
                    style={{ textAlign: "right" }}
                    value={"₪" + props.totalPrice}
                    disabled
                  />
                </div>
              </div>
              <ErrorMsg msg={errMessage(responseMsgs, "amount")}></ErrorMsg>
            </div>
            <div className="row d-flex">
              <div className="col-md-2 photo ml-auto">
                <img alt="Card" src={imageSrc} />
              </div>
              <div className="col-md-10  w-330">
                <label htmlFor="credit_card_number">
                  <Trans>lbl_card_number</Trans>
                </label>

                <div className="form-control" id="credit_card_number"></div>
                <div id="errors_for_number" className="error_message"></div>
              </div>
            </div>
            <ErrorMsg msg={errMessage(responseMsgs, "credit_card_number")}></ErrorMsg>
            <div className="row pt-1">
              <div className="col-md-12">
                <label htmlFor="card_holder_id_number" style={{ height: "auto" }}>
                  <Trans>lbl_card_holder_id</Trans>
                </label>
                <div className="form-control" id="card_holder_id_number"></div>
                <div id="errors_for_id" className="error_message"></div>
              </div>
              <ErrorMsg msg={errMessage(responseMsgs, "card_holder_id_number")}></ErrorMsg>
            </div>
            <div className="row pt-3">
              <div className="col-md-7">
                <label htmlFor="expiry">
                  <Trans>lbl_expiry</Trans>
                </label>
                <div className="form-control" id="expiry"></div>
                <div id="errors_for_expiry" className="error_message"></div>
                <ErrorMsg msg={errMessage(responseMsgs, "expiry")}></ErrorMsg>
              </div>
              <div className="col-md-5">
                <label htmlFor="cvv">
                  <Trans>lbl_cvv</Trans>
                </label>
                <div className="form-control" id="cvv"></div>
                <div id="errors_for_cvv" className="error_message"></div>
                <ErrorMsg msg={errMessage(responseMsgs, "cvv")}></ErrorMsg>
              </div>
            </div>

            <div className="row d-flex">
              <div className="col-md-7 ml-auto w-325">
                <label htmlFor="installment">
                  <Trans>lbl_number_payments</Trans>
                </label>
                <div className="">
                  <select select="1" className="total_installments_number" id="installment">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                </div>
                <div id="errors_for_installment" className="error_message"></div>
                <ErrorMsg msg={errMessage(responseMsgs, "installment")}></ErrorMsg>
              </div>
            </div>

            <div className="row pt-4">
              <div className="col-md-12">
                <button type="submit" disabled={props.disableButton} className="btn btn-success">
                  <Trans>submit</Trans>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row h-100 d-flex justify-content-right align-self-center">
        <span>
          <Trans i18nKey="copyright_message"> </Trans>
        </span>
      </div>
    </form>
  );
};

export default PaymentForm;
