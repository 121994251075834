import React, { useCallback, useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { escapeRegExp } from "lodash";
import clsx from "clsx";

import ProductItemGrid from "../products-view/product-item-grid";
import ProductItemList from "../products-view/product-item-list";
import CategoryPanel from "../category/category-panel";
import SortPopup from "../popup/sort-popup";
import Button from "../common/button";
import PuffLoader from "react-spinners/ClipLoader";
import UIsortingStore, { Layout } from "../../store/ui-sorting-store";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { Product } from "../../services/products/types";
import UIfiltersStore from "../../store/ui-filters-store";
import usersStore from "../../store/users-store";
import Filters from "../products-view/filters";

import { ReactComponent as GridIcon } from "../../static/icons/grid.svg";
import { ReactComponent as ListIcon } from "../../static/icons/list.svg";
import { Favorites } from "../../services/users/types";
import categoriesStore from "../../store/categories-store";
import { checkImporterDeals, getColors, userLoggedIn } from "../../utils/helper";
import {
  addFavouriteProductToStorage,
  productIsFavourite,
} from "../../services/products/products-service";
import languagesStore from "../../store/languages-store";
import { push } from "connected-react-router";
import { config } from "../../config";
import authFormStore from "../../store/auth-form-store";
import productsStore from "../../store/products-store";

const ImporterDealsPage = () => {
  const PAGE_SIZE = 7;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  let [loadedItems, setLoadedItems] = useState(7);

  const isImporterDeals = checkImporterDeals(
    useAppSelector(state => state.router.location.pathname)
  );

  const search = useAppSelector(state => state.router.location.search);
  const {
    categories,
    activeSubSubCategory,
    activeSubCategory,
    activeCategory,
    activeBrands,
    activeRating,
    activeLayout,
    activeSort,
    products,
    sorting,
    lang,
    user,
    age,
    priceRange,
    activeIsofix,
    activeFoldType,
    activeStandard,
    loaded,
    loading,
    pathname,
  } = useAppSelector(state => ({
    categories: state.categories.categoriesData.data,
    activeSubSubCategory: state.categories.activeSubSubCategory,
    activeSubCategory: state.categories.activeSubCategory,
    activeCategory: state.categories.activeCategory,
    activeBrands: state.UIfilters.activeBrands,
    products: state.products.productsData.data,
    loading: state.products.productsData.loading,
    loaded: state.products.productsData.loaded,
    activeSort: state.UIsorting.activeSort,
    activeRating: state.UIfilters.rating,
    activeLayout: state.UIsorting.layout,
    sorting: state.UIsorting.sorting,
    lang: state.languages.language,
    age: state.UIfilters.age,
    user: state.user.data,
    priceRange: state.UIfilters.price,
    activeIsofix: state.UIfilters.isofix,
    activeFoldType: state.UIfilters.foldType,
    activeStandard: state.UIfilters.standard,
    pathname: state.router.location.pathname,
  }));

  // console.log("PRODUCTS ===>", products);
  const [filters, setFilters] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(PAGE_SIZE);
  // console.log("PAGE_SIZE ===> ", PAGE_SIZE);
  // const [ageRange, setAgeRange] = useState<TAge>(age);

  const favoriteCatalogItems: Favorites[] = user?.favoriteCatalogItems! || [];
  const [showSpinner, setShowSpinner] = useState<boolean>(true);

  const subCatIsActive = search.startsWith("?sub=");
  const subSubCatIsActive = search.startsWith("?sub_sub=");
  const searchIsActive = search.startsWith("?search=");

  const checkDiscountStatus = (startDiscountDate: string, productDiscountDate: string) => {
    let [currYear, currMonth, currDay] = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, "-")
      .split("-");
    const currDate = currYear + "-" + currMonth + "-" + currDay;
    if (startDiscountDate <= currDate && currDate <= productDiscountDate) {
      return true;
    } else {
      return false;
    }
  };

  // const categoriesFilter = useCallback(
  //   (product: Product) => {
  //     if (!activeCategory) {
  //       return product;
  //     }

  //     if (!!activeSubSubCategory) {
  //       return !!product.subSubCategories.find(s => s.id === activeSubSubCategory);
  //     }

  //     if (!!activeSubCategory) {
  //       if (!!product.subCategories.find(s => s.id === activeSubCategory)) {
  //         return true;
  //       }

  //       return !!product.subSubCategories.find(s => s.parent?.id === activeSubCategory);
  //     }

  //     if (!!product.categories.find(c => c.id === activeCategory)) {
  //       return true;
  //     }

  //     const productCategories = [
  //       ...new Set(
  //         product.subSubCategories
  //           .map(sS => categories.find(c => c.id === sS.parent?.id))
  //           .map(s => categories.find(c => c.id === s?.parent?.id))
  //           .concat(product.subCategories.map(s => categories.find(c => c.id === s?.parent?.id)))
  //       ),
  //     ];

  //     return !!productCategories.find(c => c!?.id === activeCategory);
  //   },
  //   [activeCategory, activeSubCategory, activeSubSubCategory, categories]
  // );

  const colorsFilter = useCallback(
    (product: Product) =>
      getColors(products, product).filter(c => c.id !== product?.id).length > 0
        ? product.isMainColor
        : product,
    [products]
  );

  const colorsMapping = useCallback(
    (p: Product) => {
      const productOutOfStock = p.quantity <= 0;
      if (p.isMainColor && productOutOfStock) {
        const alternativeProductColorInStock = getColors(products, p).find(
          color => color.quantity > 0
        );
        if (!!alternativeProductColorInStock) {
          return products.find(product => product.id === alternativeProductColorInStock.id)!;
        }
      }

      return p;
    },
    [products]
  );

  const toProductWithoutQuantity = (all?: boolean) => {
    return searchIsActive
      ? products!?.slice().filter(product => {
          if (search.startsWith("?search=")) {
            const [, searchString] = search.split("=");

            return (
              product?.name?.toLowerCase().includes(searchString.toLowerCase()) ||
              product?.title?.toLowerCase().includes(searchString.toLowerCase()) ||
              product?.keywords?.toLowerCase().includes(searchString.toLowerCase()) ||
              product?.brand?.name?.toLowerCase().includes(searchString.toLowerCase())
            );
          }
          console.log("Here !");
          return product;
          // !!search
          //   ? new RegExp(`(${escapeRegExp(search)})`, "gi").test(product.name) ||
          //     new RegExp(`(${escapeRegExp(search)})`, "gi").test(product.title)
          //   : product;
        })
      : products!
          ?.slice()
          //   .filter(product => {
          //     if (subSubCatIsActive) {
          //       const [, subSubCategoryId] = search.split("=");

          //       return product.subSubCategories.find(cat => cat.id === Number(subSubCategoryId));
          //     } else if (subCatIsActive) {
          //       const [, subCategoryId] = search.split("=");

          //       return (
          //         product.subCategories.find(cat => cat.id === Number(subCategoryId)) ||
          //         product.subSubCategories.find(cat => cat.parent === Number(subCategoryId))
          //       );
          //     }

          //     return product
          //     // const currentCategoryId = pathname.split("/").pop();
          //     // console.log("currentCategoryId ===>", currentCategoryId);
          //     // return (
          //     //   product.categories.find(cat => cat.id === Number(currentCategoryId)) ||
          //     //   product.subCategories.find(subCat => subCat.parent === Number(currentCategoryId)) ||
          //     //   product.subSubCategories.find(subSubCat => {
          //     //     if (subSubCat.parent) {
          //     //       const subCategoryId = subSubCat.parent;
          //     //       const subCategory = categories.find(c => c.id === subCategoryId);
          //     //       return subCategory?.parent.id === Number(currentCategoryId);
          //     //     }

          //     //     return false;
          //     //   })
          //     // );
          //   })
          .filter(product => (isImporterDeals ? product.importerDeal : product))
          .filter(colorsFilter)
          .map(colorsMapping)
          .sort((a, b) => {
            switch (sorting) {
              case "alphabet":
                const aName = a.name.toLowerCase();
                const bName = b.name.toLowerCase();

                return aName > bName ? 1 : -1;
              case "newest":
                return a.published_at < b.published_at ? 1 : -1;
              case "popularity":
                return b.rating - a.rating;
              case "cheapest":
                return user?.approvedAgent
                  ? a.storePrice - b.storePrice
                  : a.salePrice - b.salePrice;
              case "expensive":
                return user?.approvedAgent
                  ? b.storePrice - a.storePrice
                  : b.salePrice - a.salePrice;
              default:
                return 0;
            }
          })
          //     // .filter(categoriesFilter);
          .filter(
            (product: Product) =>
              (product.age?.from! >= age?.selectedFrom! && product.age?.to! <= age?.selectedTo!) ||
              (product.age?.from! <= age?.selectedTo! && product.age?.to! >= age?.selectedFrom!)
          )
          .filter((product: Product) => {
            const maxPrice = Math.max(...products.map(p => p.salePrice));
            let { min, max } = priceRange;

            if (min === "") {
              min = 0;
            }

            if (max === "") {
              max = maxPrice;
            }

            return user?.approvedAgent
              ? product.storePrice >= min && product.storePrice <= max
              : product.salePrice >= min && product.salePrice <= max;
          })
          .filter(product => {
            if (activeRating === 0) return product;

            const numberOfReviews = product.reviews.length;
            const productRating =
              numberOfReviews <= 4
                ? product.rating
                : (product.reviews.reduce((a, b) => (a += b.rating), 0) / numberOfReviews).toFixed(
                    2
                  );

            return productRating >= activeRating;
          })
          .filter(product =>
            !!activeBrands.length ? activeBrands.includes(product.brand?.id!) : product
          )
          .filter(product => (activeIsofix.length > 0 ? product.isofix === activeIsofix : product))
          .filter(product =>
            activeStandard.length > 0 ? product.productStandart === activeStandard : product
          )
          .filter(product =>
            activeFoldType.length > 0 ? product.foldTypes === activeFoldType : product
          )
          .filter(p => p.quantity <= 0)
          .filter((item, pos, a) => a.indexOf(item) === pos);
  };

  const toProduct = (all?: boolean) => {
    return searchIsActive
      ? products!?.slice().filter(product => {
          if (search.startsWith("?search=")) {
            const [, searchString] = search.split("=");

            return (
              product?.name?.toLowerCase().includes(searchString.toLowerCase()) ||
              product?.title?.toLowerCase().includes(searchString.toLowerCase()) ||
              product?.keywords?.toLowerCase().includes(searchString.toLowerCase()) ||
              product?.brand?.name?.toLowerCase().includes(searchString.toLowerCase())
            );
          }

          return product;
          // !!search
          //   ? new RegExp(`(${escapeRegExp(search)})`, "gi").test(product.name) ||
          //     new RegExp(`(${escapeRegExp(search)})`, "gi").test(product.title)
          //   : product;
        })
      : products!
          ?.slice()
          //   .filter(product => {
          //     if (subSubCatIsActive) {
          //       const [, subSubCategoryId] = search.split("=");

          //       return product.subSubCategories.find(cat => cat.id === Number(subSubCategoryId));
          //     } else if (subCatIsActive) {
          //       const [, subCategoryId] = search.split("=");

          //       return (
          //         product.subCategories.find(cat => cat.id === Number(subCategoryId)) ||
          //         product.subSubCategories.find(cat => cat.parent === Number(subCategoryId))
          //       );
          //     }

          //     const currentCategoryId = pathname.split("/").pop();
          //     console.log("currentCategoryId ===>", currentCategoryId);
          //     return (
          //       product.categories.find(cat => cat.id === Number(currentCategoryId)) ||
          //       product.subCategories.find(subCat => subCat.parent === Number(currentCategoryId)) ||
          //       product.subSubCategories.find(subSubCat => {
          //         if (subSubCat.parent) {
          //           const subCategoryId = subSubCat.parent;
          //           const subCategory = categories.find(c => c.id === subCategoryId);
          //           return subCategory?.parent.id === Number(currentCategoryId);
          //         }

          //         return false;
          //       })
          //     );
          //   })
          .filter(product => (isImporterDeals ? product.importerDeal : product))
          .filter(colorsFilter)
          .map(colorsMapping)
          .sort((a, b) => {
            switch (sorting) {
              case "alphabet":
                const aName = a.name.toLowerCase();
                const bName = b.name.toLowerCase();

                return aName > bName ? 1 : -1;
              case "newest":
                return a.published_at < b.published_at ? 1 : -1;
              case "popularity":
                return b.rating - a.rating;
              case "cheapest":
                return user?.approvedAgent
                  ? a.storePrice - b.storePrice
                  : a.salePrice - b.salePrice;
              case "expensive":
                return user?.approvedAgent
                  ? b.storePrice - a.storePrice
                  : b.salePrice - a.salePrice;
              default:
                return 0;
            }
          })
          //     // .filter(categoriesFilter);
          .filter(
            (product: Product) =>
              (product.age?.from! >= age?.selectedFrom! && product.age?.to! <= age?.selectedTo!) ||
              (product.age?.from! <= age?.selectedTo! && product.age?.to! >= age?.selectedFrom!)
          )
          .filter((product: Product) => {
            const maxPrice = Math.max(...products.map(p => p.salePrice));
            let { min, max } = priceRange;

            if (min === "") {
              min = 0;
            }

            if (max === "") {
              max = maxPrice;
            }

            return user?.approvedAgent
              ? product.storePrice >= min && product.storePrice <= max
              : product.salePrice >= min && product.salePrice <= max;
          })
          .filter(product => {
            if (activeRating === 0) return product;

            const numberOfReviews = product.reviews.length;
            const productRating =
              numberOfReviews <= 4
                ? product.rating
                : (product.reviews.reduce((a, b) => (a += b.rating), 0) / numberOfReviews).toFixed(
                    2
                  );

            return productRating >= activeRating;
          })
          .filter(product =>
            !!activeBrands.length ? activeBrands.includes(product.brand?.id!) : product
          )
          .filter(product => (activeIsofix.length > 0 ? product.isofix === activeIsofix : product))
          .filter(product =>
            activeStandard.length > 0 ? product.productStandart === activeStandard : product
          )
          .filter(product =>
            activeFoldType.length > 0 ? product.foldTypes === activeFoldType : product
          )
          .filter(p => p.quantity > 0)
          .filter((product, i) => (all ? product : i < pageNumber))
          .filter((item, pos, a) => a.indexOf(item) === pos);
  };

  const userId = user?.id?.toString()!;
  const toggleFavorite = (p: Favorites) => {
    if (!userLoggedIn(user)) {
      addFavouriteProductToStorage(p, products);

      const isMobile = window.innerWidth < 600;
      if (isMobile) {
        dispatch(push(config.routes.myAccount, true));
      }
      return;
    }

    const updatedList = [...favoriteCatalogItems];

    if (!!updatedList.find(fP => fP.productId === p.productId)) {
      const index = updatedList.indexOf(p);
      updatedList.splice(index, 1);

      dispatch(
        usersStore.actions.toggleFavoriteCatalogItem({ userId, favoriteCatalogItems: updatedList })
      );
      return;
    }
    updatedList.push(p);
    dispatch(
      usersStore.actions.toggleFavoriteCatalogItem({
        userId,
        favoriteCatalogItems: updatedList,
      })
    );
  };

  useEffect(() => {
    if (loaded && products.length > 0 && !search.startsWith("?search=")) {
      setShowSpinner(false);
    } else if (loaded && search.startsWith("?search=")) {
      console.log("in the else if");
      setShowSpinner(true);

      setTimeout(() => setShowSpinner(false), 1500);
    }
  }, [products, search]);

  const readyProducts = toProduct();

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const outOfStockElements = toProductWithoutQuantity();

  // useEffect(() => {
  //   console.log(" ============ Products ===========");
  //   console.log(readyProducts);
  //   console.log(outOfStockElements);
  //   console.log(" ============ Products ===========");
  // });

  const outOfStockProductElements = outOfStockElements.map((product: Product) => {
    const isInFavorites =
      !!favoriteCatalogItems?.find(fP => fP.productId === product?.id) ||
      productIsFavourite(product);

    return activeLayout === "list" ? (
      <ProductItemList
        isImporterDeals={isImporterDeals}
        key={product?.id}
        user={user!}
        product={product!}
        isFavorite={isInFavorites}
        toggleFavorite={toggleFavorite}
      />
    ) : (
      <ProductItemGrid
        isImporterDeals={isImporterDeals}
        key={product?.id}
        user={user!}
        product={product!}
        isFavorite={isInFavorites}
        toggleFavorite={toggleFavorite}
      />
    );
  });

  const productElements = readyProducts

    .filter(p => p.quantity > 0 && checkDiscountStatus(p.startDiscountDate, p.discountDate))
    // .concat(outOfStockElements)
    .map((product: Product) => {
      const isInFavorites =
        !!favoriteCatalogItems?.find(fP => fP.productId === product?.id) ||
        productIsFavourite(product);

      return activeLayout === "list" ? (
        <ProductItemList
          isImporterDeals={isImporterDeals}
          key={product?.id}
          user={user!}
          product={product!}
          isFavorite={isInFavorites}
          toggleFavorite={toggleFavorite}
        />
      ) : (
        <ProductItemGrid
          isImporterDeals={isImporterDeals}
          key={product?.id}
          user={user!}
          product={product!}
          isFavorite={isInFavorites}
          toggleFavorite={toggleFavorite}
        />
      );
    });

  const onFiltersClick = () => setFilters(current => !current);

  const onViewButtonClick = (layout: Layout) => (): void => {
    dispatch(UIsortingStore.actions.changeLayout(layout));
  };

  const { sortDropDownIsVisible } = useAppSelector(({ authForm: { sortDropDownIsVisible } }) => ({
    sortDropDownIsVisible,
  }));

  const onSortByClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    dispatch(
      authFormStore.actions.toggleVisibility("sortDropDownIsVisible", !sortDropDownIsVisible)
    );
  };
  const onLoadMoreProductsClick = (): void => {
    loadedItems += PAGE_SIZE;
    setLoadedItems(loadedItems => loadedItems + 6);
    setPageNumber(current => current + PAGE_SIZE);
  };

  // const onLoadMoreProductsClick = (e: any): void => {
  //   // get current products available, check if pagenum + next batch would be more
  //   // if more hide the load more btn after last possible click
  //   const currentProductCount = toProduct(true).length;
  //   // if (pageNumber + PAGE_SIZE >= currentProductCount) {
  //   //   e.target.style.display = "none";
  //   // } else if (pageNumber < currentProductCount) {
  //     setPageNumber(current => current + PAGE_SIZE);
  //   // }
  // };

  useEffect(() => {
    const filteredProds = products
      ?.slice()
      .filter(product => (isImporterDeals ? product.importerDeal : product))
      //   .filter(product => {
      //     if (subSubCatIsActive) {
      //       const [, subSubCategoryId] = search.split("=");

      //       return product.subSubCategories.find(cat => cat.id === Number(subSubCategoryId));
      //     } else if (subCatIsActive) {
      //       const [, subCategoryId] = search.split("=");

      //       return (
      //         product.subCategories.find(cat => cat.id === Number(subCategoryId)) ||
      //         product.subSubCategories.find(cat => cat.parent === Number(subCategoryId))
      //       );
      //     }

      //     const currentCategoryId = pathname.split("/").pop();
      //     console.log("currentCategoryId ===>", currentCategoryId);
      //     return (
      //       product.categories.find(cat => cat.id === Number(currentCategoryId)) ||
      //       product.subCategories.find(subCat => subCat.parent === Number(currentCategoryId)) ||
      //       product.subSubCategories.find(subSubCat => {
      //         if (subSubCat.parent) {
      //           const subCategoryId = subSubCat.parent;
      //           const subCategory = categories.find(c => c.id === subCategoryId);
      //           return subCategory?.parent.id === Number(currentCategoryId);
      //         }

      //         return false;
      //       })
      //     );
      //   })
      .filter(colorsFilter)
      ?.map(colorsMapping);
    // ?.filter(categoriesFilter);

    // console.log("filteredProducts ===>", filteredProds);

    const availableNums = (n: number) => !!n || n === 0;
    const from = Math.min(...filteredProds?.map(p => p.age?.from!)?.filter(availableNums));
    // console.log("from ===>", from);
    const to = Math.max(...filteredProds?.map(p => p.age?.to!)?.filter(availableNums));
    // console.log("to ===>", to);
    dispatch(UIfiltersStore.actions.setAge({ from, to }));
    dispatch(UIfiltersStore.actions.setSelectedAge({ selectedFrom: from, selectedTo: to }));
  }, [
    dispatch,
    products,
    activeCategory,
    activeSubCategory,
    activeSubSubCategory,
    // categoriesFilter,
    colorsMapping,
    colorsFilter,
  ]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(categoriesStore.actions.setActiveCategory(null));
  //     dispatch(categoriesStore.actions.setActiveSubCategory(null));
  //     dispatch(categoriesStore.actions.setActiveSubSubCategory(null));
  //   };
  // }, [dispatch]);

  return (
    <div className={classes.root}>
      <CategoryPanel />

      <div className={classes.productsView}>
        <div className={classes.content}>
          <Button
            borderRadius={25}
            height={30}
            width={100}
            outline={theme.colors.orange}
            textSize={20}
            text={activePhrases && activePhrases["filters"]}
            textColor={filters ? theme.colors.text : theme.colors.blue}
            fill={filters ? theme.colors.orange : theme.colors.text}
            className={classes.filtersBtn}
            action={onFiltersClick}
          />

          <div className={classes.sort}>
            <div className={classes.sortBy}>
              <Button
                borderRadius={25}
                width={155}
                height={42}
                textSize={23}
                text={activePhrases && activePhrases["sort_by"]!}
                fill={theme.colors.orange}
                textColor={theme.colors.text}
                className={classes.sortButton}
                action={event => onSortByClick(event)}
              />

              <div className={clsx(classes.arrow, activeSort && classes.active)} />

              <SortPopup active={sortDropDownIsVisible} className={classes.sortPopup} />
            </div>

            <div className={classes.view}>
              <GridIcon
                className={clsx(classes.icon, activeLayout === "grid" && classes.iconActive)}
                onClick={onViewButtonClick("grid")}
              />

              <div className={classes.line} />

              <ListIcon
                className={clsx(classes.icon, activeLayout === "list" && classes.iconActive)}
                onClick={onViewButtonClick("list")}
              />
            </div>
          </div>

          <Filters className={{ root: clsx(classes.filtersMobile, filters && classes.active) }} />

          {showSpinner ? (
            <PuffLoader color={theme.colors.orange} size={50} />
          ) : (
            <ul
              className={clsx(
                classes.productsGrid,
                activeLayout === "list" && classes.productsList,
                productElements.length === 0 && classes.notFound
              )}
              dir={activeLayout === "grid" ? "rtl" : ""}
            >
              {productElements.length > 0 ? (
                productElements
              ) : (
                <p className={classes.notFoundText}>
                  {activePhrases && activePhrases["no_results_found_sales"]}
                </p>
              )}
              {toProduct(true).length <= loadedItems &&
                !searchIsActive &&
                outOfStockProductElements}
            </ul>
          )}

          {/* {toProduct(true).length > PAGE_SIZE && !showSpinner && (
            <Button
              borderRadius={25}
              width={180}
              height={40}
              outline={theme.colors.green}
              fill={theme.colors.green}
              fillHover={theme.colors.text}
              textColor={theme.colors.text}
              textColorHover={theme.colors.green}
              text={activePhrases && activePhrases["load_more"]}
              textSize={26}
              className={classes.loadMore}
              action={onLoadMoreProductsClick}
            />
          )} */}

          {toProduct(true).length >= loadedItems && !showSpinner && (
            <Button
              borderRadius={25}
              width={180}
              height={40}
              outline={theme.colors.orange}
              fill={theme.colors.orange}
              fillHover={theme.colors.text}
              textColor={theme.colors.text}
              textColorHover={theme.colors.orange}
              text={activePhrases && activePhrases["load_more"]}
              textSize={26}
              className={classes.loadMore}
              action={onLoadMoreProductsClick}
            />
          )}
        </div>

        <Filters className={{ root: classes.activeDesktop }} />
      </div>
    </div>
  );
};

export default ImporterDealsPage;

export type { Layout };

const useStyles = makeStyles(
  theme => ({
    root: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    productsView: {
      boxSizing: "border-box",
      width: "100%",
      padding: 40,
      display: "grid",
      gridTemplateColumns: "3fr 1fr",
      [theme.device.mobile()]: {
        padding: "10px 15px 0 15px",
        gridTemplateColumns: "100%",
      },
    },
    content: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingRight: 60,
      boxSizing: "border-box",
      [theme.device.mobile()]: {
        paddingRight: 0,
      },
    },
    sort: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      height: 60,
      [theme.device.mobile()]: {
        alignItems: "center",
      },
    },
    sortBy: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    arrow: {
      transition: "0.4s",
      zIndex: 2,
      position: "absolute",
      top: "95%",
      opacity: 0,
      visibility: "hidden",
      borderLeft: "25px solid transparent",
      borderRight: "25px solid transparent",
      borderBottom: `17px solid ${theme.colors.orange}`,
      "&:before": {
        content: '""',
        position: "absolute",
        top: 2,
        left: -25,
        right: 0,
        bottom: 0,
        borderLeft: "25px solid transparent",
        borderRight: "25px solid transparent",
        borderBottom: `17px solid ${theme.colors.text}`,
      },
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    sortPopup: {
      // [theme.device.mobile()]: {
      //   display: "none",
      // },
    },
    sortItem: {
      cursor: "pointer",
      fontWeight: 700,
      fontSize: 17,
      color: theme.colors.orange,
      borderBottom: "2px solid transparent",
      "&:hover": {
        borderBottom: `2px solid ${theme.colors.blue}`,
      },
    },
    view: {
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    line: {
      width: 1,
      height: "100%",
      backgroundColor: theme.colors.orange,
      margin: "0 15px",
    },
    icon: {
      cursor: "pointer",
      height: 40,
      width: 50,
      ...theme.utils.svgChangeColor(theme.colors.blue),
    },
    iconActive: {
      cursor: "default",
      ...theme.utils.svgChangeColor(theme.colors.orange),
    },
    productsGrid: {
      boxSizing: "border-box",
      paddingTop: 15,
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(3, minmax(270px, 1fr))",
      gridGap: "25px 60px",
      [theme.device.mobile()]: {
        paddingRight: 0,
        gridTemplateColumns: "repeat(2, 1fr)",
        gridGap: "15px 20px",
      },
    },
    productsList: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      gridRowGap: 10,
      [theme.device.mobile()]: {
        paddingRight: 0,
      },
    },

    notFound: {
      gridTemplateColumns: "none",
    },

    notFoundText: {
      textAlign: "center",
      color: theme.colors.orange,
      fontSize: 32,
      fontWeight: 700,
    },
    productItemGrid: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: 480,
      border: `2px solid ${theme.colors.orange}`,
      borderRadius: 15,
    },
    productItemList: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: 170,
      border: `2px solid ${theme.colors.orange}`,
      borderRadius: 15,
    },

    filters: {
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    filtersMobile: {
      transition: "0.4s",
      opacity: 0,
      visibility: "hidden",
      height: 0,
      [theme.device.desktop()]: {
        display: "none",
      },
    },
    filtersBtn: {
      marginLeft: "auto",
      marginBottom: 15,
      [theme.device.desktop()]: {
        display: "none",
      },
    },
    sortButton: {
      [theme.device.mobile()]: {
        height: 31,
        width: 120,
        fontSize: 17,
      },
    },
    loadMore: {
      margin: "35px 0",
      transition: "0.4s",
      [theme.device.mobile()]: {
        "&:hover": {
          color: theme.colors.text,
          backgroundColor: theme.colors.orange,
        },
      },
    },
    active: {
      opacity: 1,
      visibility: "visible",
      height: "auto",
    },
    inactive: {
      opacity: 0,
      visibility: "hidden",
      height: 0,
      transition: "0.3s",
      [theme.device.desktop()]: {
        display: "none",
      },
    },
    activeDesktop: {
      [theme.device.mobile()]: {
        display: "none",
      },
    },
  }),
  { name: "products-page" }
);
