export const getFamilies = () => {
    let data = fetch('http://localhost:8000/families')
    .then(resp => resp.json())

    return data
}

export const getCarMakes = () => {
    let data = fetch('http://localhost:8000/carMakes')
    .then(resp => resp.json())

    return data
}

export const getCarModel = (inputObj:{make: string}) => {
    let data = fetch('http://localhost:8000/carModel', {method: "POST", 
    headers: {"Content-Type": "application/json"} ,
    body: JSON.stringify(inputObj)})

    .then(resp => resp.json())

    return data
}
export const getCarYears = (inputObj:{model: string}) => {
    let data = fetch('http://localhost:8000/modelYears', {method: "POST", 
    headers: {"Content-Type": "application/json"} ,
    body: JSON.stringify(inputObj)})

    .then(resp => resp.json())

    return data
}

export const getSeatsByFamilyAndCars = (family: {family: string}, cars: {make: string, model: string, start_year: number}) => {
    let data = fetch('http://localhost:8000/find1', {method: "POST", 
    headers: {"Content-Type": "application/json"} ,
    body: JSON.stringify({family, cars})})

    .then(resp => resp.json())

    return data
}
