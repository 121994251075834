import { buildMediaUrl } from "../../utils/helper";
import strapiService from "../strapi";
import type { Brand } from "./types";

const getBrands = async (): Promise<Brand[]> => {
  const fetchedBrands = await strapiService.fetchBrands();

  return fetchedBrands.map((dto: Brand) => {
    const brand: Brand = {
      id: dto.id,
      name: dto.name,
      image: !!dto.image
        ? {
            id: dto.image.id!,
            url: buildMediaUrl(dto.image.url!),
            caption: dto.image.caption,
          }
        : null,
    };
    return brand;
  });
};

export { getBrands };
