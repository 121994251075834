// import { phrases as phrasesEn } from "../phrases-en";
import { phrases as phrasesHe } from "../phrases-he";
import { Language } from "../store/languages-store";

type Phrase =
  | ""
  | "search"
  | "accessibility"
  | "auth"
  | "shopping_bag"
  | "sign_up"
  | "login"
  | "features"
  | "free_shipping"
  | "qty"
  | "add_to_cart"
  | "view_more"
  | "load_more"
  | "filters"
  | "sort_by"
  | "age"
  | "size"
  | "weight"
  | "color"
  | "warranty"
  | "available_colors"
  | "share"
  | "additional_info"
  | "google_login"
  | "google_sign_up"
  | "empty_bag"
  | "shipping"
  | "subtotal"
  | "main_address"
  | "email"
  | "my_account"
  | "buy_policy"
  | "customer_service"
  | "career"
  | "accumulative_cart"
  | "product_catalog"
  | "authorized_resellers"
  | "importer_deals"
  | "car_suitability"
  | "search_guides"
  | "privacy_policy"
  | "terms_of_use"
  | "join_subscription"
  | "join_newsletter"
  | "submit"
  | "cancel"
  | "newest"
  | "alphabet"
  | "popularity"
  | "cheapest"
  | "additional_info"
  | "expensive"
  | "all_categories"
  | "password"
  | "verify_password"
  | "full_address"
  | "first_name"
  | "last_name"
  | "phone_number"
  | "manufacturies"
  | "rate"
  | "show_more"
  | "related_products"
  | "similar_products"
  | "description"
  | "specification"
  | "kit"
  | "qna"
  | "reviews"
  | "id"
  | "view_bag"
  | "proceed_to_checkout"
  | "item"
  | "items"
  | "order_summary"
  | "subtotal_before_delivery"
  | "order_total"
  | "include_vat"
  | "mobile_phone"
  | "checkout"
  | "shipping_address"
  | "billing_address"
  | "different_address"
  | "full_name"
  | "vat"
  | "notes"
  | "shipping_method"
  | "express_courier"
  | "up_to_business"
  | "delivery_time"
  | "payment"
  | "payment_method"
  | "card_number"
  | "date"
  | "expiry_date"
  | "security_code"
  | "place_order"
  | "january"
  | "february"
  | "march"
  | "april"
  | "may"
  | "june"
  | "july"
  | "august"
  | "september"
  | "october"
  | "november"
  | "december"
  | "personal_details"
  | "order_history"
  | "favorites_list"
  | "sign_out"
  | "catalog_number"
  | "more_details"
  | "add_all_to_cart"
  | "no_items_added_yet"
  | "welcome"
  | "my_id"
  | "consumer_price"
  | "savings"
  | "sale_price"
  | "discount_date"
  | "facebook_login"
  | "facebook_sign_up"
  | "Please provide your username or your e-mail"
  | "Please provide your password"
  | "Please provide your new password"
  | "Please provide your new password confirmation"
  | "New Passwords do not match"
  | "Current password is invalid"
  | "name"
  | "second_phone_number"
  | "address"
  | "change_password"
  | "change_your_password"
  | "current_password"
  | "new_password"
  | "confirm_new_password"
  | "min_pwd_chars"
  | "save_changes"
  | "contact_us"
  | "required_field"
  | "invalid_email"
  | "topic"
  | "message"
  | "phone"
  | "send_message"
  | "send_message_success"
  | "terms_and_conditions"
  | "something_went_wrong"
  | "tc_agree"
  | "out_of_stock"
  | "store_price"
  | "available_on"
  | "approved_sellers"
  | "all"
  | "north"
  | "center"
  | "south"
  | "free"
  | "pick_up"
  | "ship_by_post"
  | "catalog_n"
  | "order_n"
  | "less_details"
  | "order_date"
  | "status"
  | "update_message"
  | "warning_message"
  | "has_subscription"
  | "successful_subscription"
  | "from"
  | "to"
  | "height"
  | "width"
  | "length"
  | "empty_cart"
  | "notFound"
  | "copy_link"
  | "min"
  | "max"
  | "price_range"
  | "years"
  | "product_link"
  | "write_review"
  | "name_username"
  | "close"
  | "review"
  | "joie_israel"
  | "shops"
  | "signature"
  | "pushchairs"
  | "car_seats"
  | "cots_and_cribs"
  | "highchairs"
  | "soothers"
  | "explore_joie"
  | "vehicle_fit_guide"
  | "join_joie_desc";
// | "location"
// | "dob"
// | "hello_joie"
// | "explore_joie_desc"
// | "explore_joie_here"
// | "meet_the_gear_desc"
// | "meet_the_gear"
// | "teamed_up"
// | "partner_desc"
// | "join_joie";

type Phrases = {
  [phrase in Phrase]: string;
};

type NewPhrase =
  | " "
  | "login"
  | "sign_up"
  | "registration"
  | "email"
  | "password"
  | "verify_password"
  | "additional_phone_number"
  | "login_google"
  | "sign_up_google"
  | "login_facebook"
  | "buy_now"
  | "sign_up_facebook"
  | "product_catalog"
  | "authorized_resellers"
  | "importer_deals"
  | "my_account"
  | "contact_us"
  | "buy_policy"
  | "customer_service"
  | "terms_of_use"
  | "privacy_policy"
  | "submit"
  | "careers"
  | "welcome"
  | "order_history"
  | "no_orders_yet"
  | "favourite_list"
  | "sign_out"
  | "personal_details"
  | "phone_number"
  | "second_phone_number"
  | "address"
  | "main_address"
  | "first_name"
  | "last_name"
  | "save_changes"
  | "current_password"
  | "new_password"
  | "confirm_new_password"
  | "additional_info"
  | "sort_by"
  | "newest"
  | "alphabet"
  | "popularity"
  | "cheapest"
  | "expensive"
  | "all_categories"
  | "manufacturies"
  | "age"
  | "rate"
  | "write_review"
  | "share"
  | "product_catalog"
  | "authorized_resellers"
  | "importer_deals"
  | "my_account"
  | "language_code"
  | "languageCode"
  | "username"
  | "change_password"
  | "show_more"
  | "related_products"
  | "similar_products"
  | "description"
  | "specification"
  | "kit"
  | "qna"
  | "reviews"
  | "view_bag"
  | "proceed_to_checkout"
  | "item"
  | "items"
  | "order_summary"
  | "subtotal_before_delivery"
  | "order_total"
  | "include_vat"
  | "phone"
  | "checkout"
  | "shipping_address"
  | "billing_address"
  | "different_address"
  | "full_name"
  | "vat"
  | "notes"
  | "shipping_method"
  | "express_courier"
  | "up_to_business"
  | "delivery_time"
  | "payment"
  | "payment_method"
  | "card_number"
  | "date"
  | "expiry_date"
  | "security_code"
  | "place_order"
  | "january"
  | "february"
  | "march"
  | "april"
  | "may"
  | "june"
  | "july"
  | "august"
  | "september"
  | "october"
  | "november"
  | "december"
  | "catalog_number"
  | "more_details"
  | "add_all_to_cart"
  | "no_items_added_yet"
  | "consumer_price"
  | "savings"
  | "sale_price"
  | "discount_date"
  | "Please_provide_username_or_email"
  | "Please_provide_your_password"
  | "Please_provide_your_new_password"
  | "Please_provide_your_new_password_confirmation"
  | "New_Passwords_do_not_match"
  | "New_Password_must_be_different"
  | "Current_password_is_invalid"
  | "required_field"
  | "invalid_email"
  | "topic"
  | "message"
  | "phone"
  | "send_message"
  | "send_message_success"
  | "terms_and_conditions"
  | "something_went_wrong"
  | "tc_agree"
  | "out_of_stock"
  | "store_price"
  | "available_on"
  | "approved_sellers"
  | "all"
  | "north"
  | "center"
  | "south"
  | "free"
  | "pick_up"
  | "ship_by_post"
  | "order_n"
  | "less_details"
  | "order_date"
  | "status"
  | "update_message"
  | "warning_message"
  | "has_subscription"
  | "successful_subscription"
  | "from"
  | "to"
  | "height"
  | "width"
  | "length"
  | "empty_the_cart"
  | "cart_is_empty"
  | "notFound"
  | "copy_link"
  | "min"
  | "max"
  | "price_range"
  | "years"
  | "product_link"
  | "write_review"
  | "name_username"
  | "close"
  | "review"
  | "min_password_symbols"
  | "join_subscription"
  | "join_newsletter"
  | "load_more"
  | "filters"
  | "shopping_bag"
  | "color"
  | "You_can_not_exceed_the_available_quantity_of_the_product"
  | "features"
  | "free_shipping"
  | "add_to_cart"
  | "quantity"
  | "available_colors"
  | "size"
  | "weight"
  | "warranty"
  | "name_username"
  | "search"
  | "view_more"
  | "open_positions"
  | "location"
  | "roles"
  | "no_file_chosen"
  | "resume_is_required"
  | "error_during_submitting_application"
  | "error_during_resume_uploading"
  | "no_file_uploaded"
  | "resume_cant_be_bigger_than_2MB"
  | "resume"
  | "LinkedIn_Profile_URL"
  | "upload_file"
  | "personal_note"
  | "why_join_our_dream_team"
  | "your_role"
  | "skills_and_experiance"
  | "our_offer"
  | "back_to_job_positions"
  | "checkout"
  | "card_payment"
  | "shipping"
  | "subtotal"
  | "full_name"
  | "you_can_buy_it_from_the_official_website"
  | "central_warehouse"
  | "fax"
  | "and_up"
  | "field_required"
  | "password_should_be_at_least_6_characters_long"
  | "passwords_dont_match"
  | "invalid_first_name"
  | "invalid_last_name"
  | "invalid_phone_number"
  | "invalid_address"
  | "empty_string"
  | "isofix"
  | "standart"
  | "fold_types"
  | "without"
  | "included"
  | "optional"
  | "umbrella"
  | "threeDimensional"
  | "book"
  | "go_home"
  | "ooups"
  | "no_results_found"
  | "no_results_found_sales"
  | "apply"
  | "name"
  | "kg"
  | "centimeter"
  | "shipping_number"
  | "invoice_number"
  | "new"
  | "canceled"
  | "processing"
  | "available_regions"
  | "jerusalem"
  | "sharon"
  | "promotion_text"
  | "done"
  | "your_order_was_submitted"
  | "joie_israel"
  | "shops"
  | "signature"
  | "pushchairs"
  | "car_seats"
  | "cots_and_cribs"
  | "highchairs"
  | "soothers"
  | "explore_joie"
  | "vehicle_fit_guide"
  | "join_joie_desc"
  | "location"
  | "dob"
  | "hello_joie"
  | "explore_joie_desc"
  | "explore_joie_here"
  | "meet_the_gear_desc"
  | "meet_the_gear"
  | "teamed_up"
  | "partner_desc"
  | "customer_service"
  | "no_approved_sellers_message"
  | "join_joie"
  | "Section1_title"
  | "Section1_text"
  | "Section2_title"
  | "Section2_text"
  | "Section3_title"
  | "Section3_text"
  | "Section4_title"
  | "Section4_text"
  | "Section5_title"
  | "Section5_text"
  | "Section6_title"
  | "email_is_already_taken"
  | "identifier_or_password_invalid"
  | "coupon"
  | "view_all"
  | "guest_loggin"
  | "accessibility"
  | "contact_us_success_message";

type NewPhrases = {
  [phrase in NewPhrase]: string;
};

const getPhrase = (phrase: Phrase, language: Language): string => {
  switch (language) {
    case "en":
      return phrasesHe[phrase];
    case "he":
      return phrasesHe[phrase];
    case "ar":
      return phrasesHe[phrase];

    default:
      return "en";
  }
};

export { getPhrase };

export type { Phrase, Phrases, NewPhrase, NewPhrases };
