import { makeStyles } from "@material-ui/styles";
import { isMobile } from "react-device-detect";
import React from "react";

import Presentation from "../presentation/presentation";
import CategoryPanel from "../category/category-panel";
import ProductsCatalog from "../catalog/catalog";

import { TCatalogItem } from "../../services/site-config-service/types";
import { useAppSelector } from "../../store/store";
import { config } from "../../config";
import { checkImporterDeals } from "../../utils/helper";

const CatalogPage = (): JSX.Element => {
  const classes = useStyles();

  const { categories, product } = useAppSelector(state => ({
    categories: state.siteConfig.categories.map(c => c.category),
    product: state.siteConfig.product!,
  }));

  const url = useAppSelector(state => state.router.location.pathname);

  const isImporterDeals = checkImporterDeals(url);

  const catalogItems: TCatalogItem[] = categories.map(category => ({
    id: category.id,
    name: category.name,
    backgroundImg: category.backgroundImg?.url!,
    path: `${isImporterDeals ? config.routes.importerDealsPage : config.routes.productsPage}/${
      category.id
    }`,
  }));

  return (
    <div className={classes.root}>
      <CategoryPanel />

      <ProductsCatalog
        catalogItems={catalogItems}
        big={!isMobile}
        className={{
          root: classes.productsCatalog,
          itemsContainer: classes.productsCatalogContainer,
        }}
        equalItemsSize
      />
    </div>
  );
};

export default CatalogPage;

const useStyles = makeStyles(
  theme => ({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    productsCatalog: {
      paddingTop: 5,
    },
    productsCatalogContainer: {
      width: "100%",
      boxSizing: "border-box",
      padding: "20px 15px",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      // gridTemplateRows: "450px 450px",
      gridGap: "10px 12px",
      [theme.device.mobile()]: {
        gridGap: 5,
        padding: "0 5px",
        gridTemplateRows: "repeat(2, 200px)",
        gridTemplateColumns: "repeat(2, 1fr)",
        gridAutoRows: 200,
      },
    },
    product: {
      width: "100%",
      height: "100%",
    },
    presentation: {
      [theme.device.mobile()]: {
        paddingBottom: 20,
      },
    },
    active: {
      opacity: 1,
      visibility: "visible",
    },
  }),
  { name: "catalog-page" }
);
