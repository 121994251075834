import strapiService from "../strapi";
import type { SeoSettings } from "./types";

const getSeoSettings = async (): Promise<SeoSettings[]> => {
  const fetchedSeoSettings = await strapiService.fetchSeoSettings();

  return fetchedSeoSettings.map((dto: SeoSettings) => {
    const seo: SeoSettings = {
      id: dto.id,
      enteredURL: dto.enteredURL,
      forwardToURL: dto.forwardToURL,
      timesClicked: dto.timesClicked,
      
    };
    return seo;
  });
};
// const updateSeoSettings = async (): Promise<SeoSettings[]> => {
//   const updateSeoSettings = await strapiService.updateSeoSettings();

//   return updateSeoSettings.map((dto: SeoSettings) => {
//     const seo: SeoSettings = {
//       id: dto.id,
//       enteredURL: dto.enteredURL,
//       forwardToURL: dto.forwardToURL,
//       timesClicked: dto.timesClicked,
//     };
//     return seo;
//   });
// };

export { getSeoSettings };
