import { makeStyles } from "@material-ui/styles";
import React from "react";
import languagesStore from "../../store/languages-store";
import { useAppSelector } from "../../store/store";
import { getPhrase } from "../../utils/language";
import CarouselLinear from "../carousel/carousel";

const MyAccountPanel = (): JSX.Element => {
  // The empty object is intentional because otherwise the app crashes
  const classes = useStyles({});
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const { lang, slides, slidesMobile, firstName } = useAppSelector(state => ({
    lang: state.languages.language,
    slides: state.siteConfig.catalogCarousel,
    slidesMobile: state.siteConfig.catalogCarouselMobile,
    firstName: state.user.data?.firstName,
  }));

  const isHebrew = lang === "he";
  const hasFirstName = firstName && firstName.length > 0;

  const displayWelcomeMessage = () => {
    if (hasFirstName) {
      if (isHebrew) return `!${firstName} ,${activePhrases && activePhrases["welcome"]}`;
      return `${activePhrases && activePhrases["welcome"]}, ${firstName}!`;
    }
  };

  const isMobile = window.innerWidth < 600;

  return (
    <div className={classes.root}>
      <CarouselLinear
        slides={isMobile ? slidesMobile : slides}
        className={{ root: classes.carousel, dots: classes.dots, dot: classes.dot }}
      />
      <div className={classes.welcome}>
        {hasFirstName ? displayWelcomeMessage() : activePhrases && activePhrases["welcome"]}
      </div>
    </div>
  );
};

export default MyAccountPanel;

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    welcome: {
      display: "grid",
      placeItems: "center",
      color: "#fff",
      width: "100%",
      height: "65px",
      fontSize: 40,
      textAlign: "center",
      backgroundColor: theme.colors.blue,
      [theme.device.mobile()]: {
        fontSize: 30,
      },
    },
    carousel: {
      [theme.device.mobile()]: {
        minHeight: 100,
      },
    },
    dots: {
      marginBottom: 20,
      [theme.device.mobile()]: {
        gridColumnGap: 15,
      },
    },
    dot: {
      height: 22,
      width: 22,
      [theme.device.mobile()]: {
        height: 17,
        width: 17,
      },
    },
  }),
  { name: "my-account-panel" }
);
