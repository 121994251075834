export const hebrew = {
  header: "תשלום בכרטיס אשראי",
  lbl_card_number: "מספר כרטיס אשראי",
  lbl_expiry: "תאריך תפוגה",
  lbl_cvv: "CVV",
  lbl_card_holder_id: "ת”ז של בעל הכרטיס",
  lbl_amount: "סכום לתשלום",
  lbl_number_payments: "מספר תשלומים",
  error_credit_card_number: "פרטי כרטיס אינם נכונים",
  error_expiry: "תאריך תפוגה לא תקין",
  error_cvv: "לא תקין CVV",
  error_card_holder_id_number: "מספר ת”ז לא תקין",
  placeholder_card_holder_id: "ID",
  placeholder_card_number: "מספר כרטיס אשראי",
  placeholder_expiry: "שששש/חח",
  placeholder_cvv: "CVV",
  copyright_message: 'כל הזכויות שמורות ל "טרנזילה" סליקה © 1999-2020',
  submit: "שלם",
};
