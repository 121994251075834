import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { ReactComponent as CloseIcon } from "../../static/icons/close.svg";
import React from "react";
import { TMediaData } from "../../services/site-config-service/types";

const useStyles = makeStyles(
  theme => ({
    root: {
      userSelect: "none",
      opacity: 0,
      visibility: "hidden",
      transition: "0.3s",
      cursor: "pointer",
      height: window.innerWidth * 0.12,
      zIndex: 2,
      position: "fixed",
      bottom: 0,
      width: "100%",
      marginTop: "auto",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-start",
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    image: {
      height: "100%",
      width: "100%",
    },
    closeBtn: {
      position: "absolute",
      padding: 7,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      zIndex: 1,
      width: 20,
      filter: "invert(50%) sepia(20%) saturate(1321%) hue-rotate(173deg) brightness(89%) contrast(96%)",
      height: 20,
    },
    active: {
      opacity: 1,
      visibility: "visible",
    },
  }),
  { name: "app-banner" }
);

type TProps = {
  banner: TMediaData;
  bannerIsVisible: boolean;
  setBannerIsVisible: (value: React.SetStateAction<boolean>) => void;
  className?: {
    root?: string;
    closeBtn?: string;
  };
};

const AppBanner = ({
  banner,
  className,
  bannerIsVisible,
  setBannerIsVisible,
}: TProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className?.root, bannerIsVisible && classes.active)}>
      <img className={classes.image} src={banner.url} alt="bottom banner" />
      <div className={classes.closeBtn} onClick={() => setBannerIsVisible(false)}>
        <CloseIcon className={classes.icon} />
      </div>
    </div>
  );
};

export default AppBanner;
