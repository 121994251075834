import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Picture, Product } from "../../../services/products/types";
import Popup from "reactjs-popup";
import { ReactComponent as CloseIcon } from "../../../static/icons/close.svg";
import { useAppSelector } from "../../../store/store";
import CarouselLinear from "../../carousel/carousel";
import { TMediaData } from "../../../services/site-config-service/types";

type TProps = {
  product: Product;
  className?: {
    root?: string;
  };
};

const SingleProductView = ({ className, product }: TProps): JSX.Element => {
  const classes = useStyles();

  const mainPicture = product?.mainPicture;

  const [activePicture, setActivePicture] = useState<Picture | null>(mainPicture);
  const [open, setOpen] = useState<boolean>(false);

  const [clickedPicture, setClickedPicture] = useState(0)
  const onPictureClick = (pic: Picture, i: number) => { 
    setActivePicture(pic) 
    setClickedPicture(i)
  };

  const isPictureActive = (pic: Picture) => activePicture?.id === pic.id;

  const pictures = product?.pictures.map((picture, i) => (
    <div
      key={picture?.id}
      className={clsx(
        classes.smallPicture,
        isPictureActive(picture) && classes.imageActive,
        product?.pictures?.length <= 3 && classes.smallPictureCount
      )}
      onClick={() => onPictureClick(picture, i)}
    >
      <img className={classes.smallPictureImg} src={picture?.url!} alt="small" />
    </div>
  ));

  useEffect(() => setActivePicture(product?.mainPicture), [product]);

  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);
  const signatureMode = useAppSelector(state => state.signature.signatureMode);

  const slides: TMediaData[] = product.pictures.map(p => { return { ...p, caption: p.alternativeText } })

  const buildYoutubeUrl = (url: string): string => {
    if (!url) {
      return "";
    }
    const URL_TEMPLATE = "https://www.youtube.com/embed/";
    return URL_TEMPLATE + new URL(url).searchParams.get("v");
  };
  return (
    <div className={clsx(classes.root, className?.root)}>
      <Popup
        open={open}
        closeOnDocumentClick
        onClose={closeModal}
        overlayStyle={{ backgroundColor: "rgba(0, 0, 0 , 0.8)" }}
        contentStyle={{ height: "100%", display: "flex", position: "unset" }}
      >
        <div className={classes.picturePopup} onClick={closeModal}>
          <img src={activePicture?.url} className={classes.modalImage} alt="main" />
        </div>
        <CloseIcon className={classes.closeButton} onClick={closeModal} />
      </Popup>
      <div className={classes.product} onClick={openModal}>
        <CarouselLinear slides={slides} chosenSlide={clickedPicture} className={{ root: classes.carouselRoot, slideImage: classes.carouselSlideImage }}/>
      </div>

      {!!product?.color! && <div className={(signatureMode ? classes.currentColorSignature : classes.currentColor)}>{product?.color!}</div>}

      <div
        className={clsx(classes.smallPictures, pictures.length <= 3 && classes.smallPicturesCount)}
      >
        {pictures}
      </div>

      {!!product?.video && (
        <iframe
          className={classes.video}
          src={buildYoutubeUrl(product?.video!)}
          title="product video"
          frameBorder="0"
        />
      )}
    </div>
  );
};

export default SingleProductView;

const useStyles = makeStyles(
  theme => ({
    root: {
      paddingLeft: 130,
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      [theme.device.laptop()]: {
        paddingLeft: 40,
      },
      [theme.device.mobile()]: {
        padding: "10px 0",
      },
    },
    product: {
      width: "100%",
      boxSizing: "border-box",
      padding: "8px 0",
      display: "flex",
      justifyContent: "center",
    },
    modalImage: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },

    closeButton: {
      cursor: "pointer",
      position: "absolute",
      right: "2%",
      top: "2%",
      height: 25,
      width: 25,
      ...theme.utils.svgChangeColor(theme.colors.yellow),
    },
    carouselRoot: {
      height: 600,
      [theme.device.mobile()]: {
        height: 400
      }
    },
    carouselSlideImage: {
      objectFit: "scale-down"
    },
    image: {
      height: 400,
      width: 400,
      transition: "0.3s",
      objectFit: "contain",
      cursor: "pointer",
      [theme.device.mobile()]: {
        objectFit: "scale-down",
        height: 350,
        width: "100%",
      },
    },
    imageActive: {
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.colors.black,
    },
    currentColor: {
      transition: "0.3s",
      width: "100%",
      padding: "5px 0",
      backgroundColor: theme.colors.blue,
      textAlign: "center",
      fontWeight: 700,
      fontSize: 31,
      borderRadius: 35,
      color: theme.colors.text,
      [theme.device.mobile()]: {
        width: "calc(100% - 30px)",
        margin: "auto",
      },
      [theme.device.smallMobile()]: {
        fontSize: 27,
      },
    },
    currentColorSignature: {
      transition: "0.3s",
      width: "100%",
      padding: "5px 0",
      backgroundColor: theme.colors.brown,
      textAlign: "center",
      fontWeight: 700,
      fontSize: 31,
      borderRadius: 35,
      color: theme.colors.text,
      [theme.device.smallMobile()]: {
        fontSize: 27,
      },
    },
    smallPictures: {
      direction: "rtl",
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      gridColumnGap: 5,
      width: "100%",
      padding: "10px 0",
      [theme.device.mobile()]: {
        paddingTop: 25,
        gridTemplateColumns: "repeat(3, 1fr)",
        gridColumnGap: 15,
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    smallPicturesCount: {
      display: "flex",
      justifyContent: "center",
    },
    smallPicture: {
      margin: "0 auto",
      cursor: "pointer",
      padding: 5,
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: 100,
      maxWidth: 100,
      [theme.device.mobile()]: {
        height: 70,
      },
    },
    smallPictureCount: {
      margin: 0,
    },
    smallPictureImg: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    video: {
      width: "100%",
      height: 260,
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    picturePopup: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }),
  { name: "single-product-view" }
);
