import { useHistory, useLocation } from "react-router";
import React, { useEffect } from "react";
import Cookies from "js-cookie";

import { useAppDispatch, useAppSelector } from "../../store/store";
import { ApplicationState } from "../../store/root-reducer";
import authFormStore from "../../store/auth-form-store";
import userStore from "../../store/users-store";
import { config } from "../../config";
import { push } from "connected-react-router";
import PuffLoader from "react-spinners/ClipLoader";
import { theme } from "../../theme";
import { makeStyles } from "@material-ui/styles";

type Provider = {
  search: string;
  match: {
    params: {
      providerName: string;
    };
  };
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 70,
  },
}));

const ProviderAuth = (props: Provider): JSX.Element => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const location = useLocation();

  const lastLocation = localStorage.getItem("lastLocation");

  const { jwt, data, providerSignin } = useAppSelector(({ user }: ApplicationState) => ({
    data: user.data,
    jwt: user.data?.jwt,
    providerSignin: user.providerSignin,
  }));

  useEffect(() => {
    const { providerName } = props.match.params;
    const search = `?access_token=${new URLSearchParams(location.search).get("access_token")}`;

    dispatch(userStore.actions.signUserWithProvider({ providerName, search }));
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(providerSignin)
    if (providerSignin) {
      if (jwt) {
        // cookies should be set here in order the browser's cookie api to be updated properly
        Cookies.set("jwt", jwt, { path: "/", expires: 1 });
        console.log("provider");
        setTimeout(() => {
          dispatch(push(lastLocation || config.routes.homePage, true));
          localStorage.removeItem("lastLocation");
        }, 1500);
      }

      console.log("provider1");
      dispatch(authFormStore.actions.toggleVisibility("authFormIsVisible", false));
      // dispatch(push(config.routes.homePage));
    }
  }, [dispatch, jwt, data, providerSignin]);

  return (
    <div className={classes.root}>
      <PuffLoader color={theme.colors.green} size={50} />
    </div>
  );
};

export default ProviderAuth;
