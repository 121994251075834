import { makeStyles } from "@material-ui/styles";
import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { config } from "../../config";
import { useAppDispatch, useAppSelector } from "../../store/store";
import Auth from "../authentication/auth";

const AuthPage = (): JSX.Element => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const jwt = useAppSelector(({ user }) => user.data?.jwt);

  useEffect(() => {
    jwt && dispatch(push(config.routes.homePage, true));
  }, [dispatch, jwt]);

  return (
    <div className={classes.root}>
      <Auth />
    </div>
  );
};

export default AuthPage;

const useStyles = makeStyles(
  theme => ({
    root: {
      display: theme.device.isMobile ? "flex" : "none",
      flexDirection: "column",
      alignItems: "center",
      overflow: "hidden",
      height: "100%",
      width: "100%",
    },
  }),
  { name: "auth-page" }
);
