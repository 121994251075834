import React, {useState, useEffect} from 'react';

import fitGuideStore from '../../store/fit-guide-store';

import { useAppDispatch, useAppSelector } from "../../store/store";

import CarsFit from "./cars-fit";

import { makeStyles, useTheme } from "@material-ui/styles";



const SelectChildDimensions = () => {

  const dispatch = useAppDispatch();

  const classes = useStyles();

  const { setChildDimensions } = useAppSelector(state => state.fitGuide);

  const maxChildAge = 36;

  const minChildHeight = 40;

  const maxChildHeight = 70;

  const maxChildWeight = 16;

    const [data, setData] = useState(['']);

    const [childW, setChildW] = useState(0);

    const [childH, setChildH] = useState(0);

    const [childAge, setChildAge] = useState(0);

    

    const loopFunction = (maxChildAge: number) => {

      let result : any = [];

      for(let i = 0 ; i < maxChildAge ; i++) {

        result.push(i+1)

      }

      console.log(result);

      return result

    }


    useEffect(() => {

      let asd = loopFunction(maxChildAge);

      setData(asd)

    }, [])



    const handleOnWidthChange = (e: any) => {

        console.log(e.target.value);

        setChildW(e.target.value)

    }


    const handleOnAgeChange = (e: any) => {

      setChildAge(e.target.value);

    }


    const handleOnHeightChange = (e: any) => {

      setChildH(e.target.value);

    }


    const handleSubmit = () => {

      let data = {

        childHeight: childH,

        childWidth: childW,

        childAge: childAge

      }


      dispatch(fitGuideStore.actions.setChildDimensions("setChildDimensions", data))

    }


    console.log(maxChildAge)

  return (

    <div>

      {setChildDimensions ? <CarsFit/>

      :

      <div className={classes.selectForm}>


      <select className={classes.selectElement} placeholder='Select child age' onChange={handleOnWidthChange} name="childDimensions">

      <option disabled selected value="Select">Select Child Months</option>

        {data ? data.map(el => {

          return <option value={el}>{el} month</option>

        }): null}


      </select>


      <select className={classes.selectElement} placeholder='Select child kg' onChange={handleOnAgeChange} name="childDimensions">

      <option disabled selected value="Select">Select Child Kg</option>

        {data ? data.map(el => {

          return <option value={el}>{el} kg</option>

        }): null}


      </select>


      <select className={classes.selectElement} placeholder='Select child height' onChange={handleOnHeightChange} name="childDimensions">

      <option disabled selected value="Select">Select Child Height</option>

        {data ? data.map(el => {

          return <option value={el}>{+el * 2} cm</option>

        }): null}

      </select>


        <button className={classes.buttons} onClick={handleSubmit} >Submit</button>

      </div>

      }

    </div>

  );

};


const useStyles = makeStyles(theme => ({ 

  carsNumberContainer: {

    display: "flex",

    justifyContent: "center",

    alignItems: "center",

    marginBottom: "20px",

  }, 

  numberElement: {

    cursor: "pointer",

    padding: "8px 20px",

    border: "1px solid " + theme.colors.blue,

    borderRadius: "11px",

    margin: "0 20px",

    color: theme.colors.blue,

    fontWeight: 600,

    "&:hover": {

      backgroundColor: theme.colors.blue,

      color: "white",

    },

  },

  blue: {

    backgroundColor: theme.colors.blue,

    color: "white",

  },

  buttons: {

    border: "1px solid " + theme.colors.blue,

    padding: "7px 23px",

    borderRadius: "24px",

    fontWeight: 700,

    margin: "12px",

    color: theme.colors.blue,

    "&:hover": {

      backgroundColor: theme.colors.blue,

      color: "white",

    },

  },

  selectForm: {

    width: "50%",

    margin: "0 auto",

  },

  selectElement: {

    margin: "0 auto",

    border: "1px solid " + theme.colors.blue,

    display:"block",

    width:"40%",

    padding: "6px 14px",

    borderRadius: "11px",

    marginBottom: "8px",

    fontSize: "1.5rem",

    color: theme.colors.blue,

  },

}))


export default SelectChildDimensions;