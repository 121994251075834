import { makeStyles } from "@material-ui/styles";
import { push } from "connected-react-router";
import React from "react";
import { useAppSelector, useAppDispatch } from "../../../store/store";
import { config } from "../../../config";

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      [theme.device.mobile()]: {
        alignItems: "center",
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      [theme.device.mobile()]: {
        paddingBottom: 10,
        width: "70%",
        alignItems: "center",
        borderBottom: `1px solid ${theme.colors.yellow}`,
      },
    },
    item: {
      userSelect: "none",
      color: theme.colors.darkBlue,
      fontSize: 20,
      fontWeight: 600,
      cursor: "pointer",
      lineHeight: 1.7,
      textDecoration: "none",
      "&:hover": {
        transition: "0.3s",
        fontWeight: "bold"
      },
      [theme.device.mobile()]: {
        fontWeight: 600,
        fontSize: 22,
      },
      [theme.device.mobile480()]: {
        fontSize: 18,
      },
    },
    itemBlack: {
      userSelect: "none",
      color: theme.colors.black,
      fontSize: 20,
      fontWeight: 600,
      cursor: "pointer",
      lineHeight: 1.7,
      borderBottom: "2px solid transparent",
      textDecoration: "none",
      "&:hover": {
        transition: "0.3s",
        color: theme.colors.orange,
        borderBottom: `2px solid ${theme.colors.black}`,
      },
      [theme.device.mobile()]: {
        fontWeight: 600,
        fontSize: 22,
      },
      [theme.device.mobile480()]: {
        fontSize: 18,
      },
    },
  }),
  { name: "info-links" }
);

type TProps = {
};

const FooterCategoriesLinks = ({ }: TProps): JSX.Element => {
  const signatureMode = useAppSelector(state => state.signature.signatureMode)
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const siteCategories = useAppSelector(state => state.siteConfig.categories);

  const onCategoryLinkClick = (path: string) => {
    dispatch(push(path, true));
  };

  const categoryLinks = siteCategories.map(c => (
    <a key={c.category.id} href={`${config.routes.productsPage}/${c.category.id}`} className={(signatureMode ? classes.itemBlack : classes.item)} onClick={() => onCategoryLinkClick(`${config.routes.productsPage}/${c.category.id}`)}>
      {c.name}
    </a>
  ))

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {categoryLinks}
      </div>
    </div>
  );
};

export default FooterCategoriesLinks;
