import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(
  theme => ({
    root: {
      position: "relative",
      display: "flex",
      alignItems: "end",
      flexDirection: "column",
      width: "100%",
      paddingRight: 10,
      [theme.device.mobile()]: {
        padding: "0 15px",
        boxSizing: "border-box",
      },
    },
    reviewField: {
      width: "100%",
      display: "flex",
      alignItems: "flex-end",
      [theme.device.mobile()]: {
        justifyContent: "end",
      },
    },
    id: {
      width: 200,
      minWidth: 200,
      wordBreak: "break-word",
      textAlign: "end",
      marginLeft: "auto",
      color: theme.colors.blue,
      fontSize: 18,
      [theme.device.mediumMobile()]: {
        minWidth: "unset",
      },
      [theme.device.mobile()]: {
        alignSelf: "flex-end",
        margin: "auto 0",
      },
    },
    signatureId: {
      color: theme.colors.black,
    },
    title: {
      paddingLeft: 40,
      boxSizing: "border-box",
      position: "relative",
      userSelect: "none",
      width: "100%",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      [theme.device.mobile()]: {
        justifyContent: "space-between",
        gridColumnGap: 10,
        padding: 0,
      },
    },
    name: {
      userSelect: "text",
      wordBreak: "break-word",
      fontWeight: 700,
      fontSize: 32,
      color: theme.colors.black,
      textAlign: "right",
      boxSizing: "border-box",
      paddingLeft: 50,
      direction: "rtl",
      [theme.device.mobile()]: {
        fontSize: 26,
        paddingLeft: 0,
      },

      [theme.device.superSmall()]: {
        fontSize: 20,
        paddingLeft: 0,
      },
    },

    writeReviewButton: {
      minWidth: 160,
    },

    badge: {
      padding: "25px 0 25px 40px",
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      [theme.device.mobile()]: {
        paddingLeft: 0,
      },
    },
    badgeImg: {
      height: 35,
      width: 90,
      objectFit: "contain",
      margin: '20px 0'
    },
    button: {
      display: "flex",
      justifyContent: "space-between",
      boxSizing: "border-box",
      padding: "0 10px 3px 34px",
    },
    star: {
      [theme.device.mobile()]: {
        height: 25,
        width: 25,
      },
      [theme.device.smallMobile()]: {},
    },
    flag: {
      position: "relative",
      margin: "auto",
      minHeight: 60,
      minWidth: 40,
      transition: "0.4s",
      [theme.device.mobile()]: {
        top: 10,
        minHeight: 40,
        left: 0,
      },

      [theme.device.superSmall()]: {
        marginBottom: 20,
      },
    },
    sharePopup: {
      left: 185,
      position: "absolute",
      boxShadow: `0px 0px 20px ${theme.colors.shadow}`,
      backgroundColor: theme.colors.text,
      zIndex: -1,
      opacity: 0,
      visibility: "hidden",
      transition: "0.3s",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      boxSizing: "border-box",
      padding: "15px 20px 30px 20px",
      [theme.device.mobile()]: {
        position: "fixed",
        top: "30%",
        width: "90%",
        left: 0,
        right: 0,
        margin: "0 auto",
        paddingBottom: 10,
      },
    },
    sharePopupTitle: {
      color: theme.colors.blue,
      fontSize: 30,
      fontWeight: 700,
      padding: "5px 0 20px 0",
      whiteSpace: "nowrap",
    },

    sharePopupSignatureTitle: {
      color: theme.colors.brown,
    },
    sharePopupSocial: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      gridColumnGap: 40,
      paddingBottom: 40,
      [theme.device.mobile()]: {
        gridColumnGap: 0,
        justifyContent: "space-between",
      },
    },

    shareIcon: {
      borderRadius: "100%",
      cursor: "pointer",
      height: 55,
      width: 55,
      [theme.device.mobile()]: {
        height: 45,
        width: 45,
      },
    },
    facebookIcon: {
      filter:
        "invert(34%) sepia(61%) saturate(4330%) hue-rotate(198deg) brightness(95%) contrast(96%)",
    },
    twitterIcon: {
      filter:
        "invert(52%) sepia(37%) saturate(3021%) hue-rotate(168deg) brightness(91%) contrast(110%)",
    },
    linkedInIcon: {
      filter:
        "invert(32%) sepia(80%) saturate(5861%) hue-rotate(185deg) brightness(95%) contrast(101%)",
    },
    pinterestIcon: {
      filter:
        "invert(16%) sepia(77%) saturate(7494%) hue-rotate(352deg) brightness(93%) contrast(88%)",
    },
    closeIcon: {
      ...theme.utils.svgStroke(theme.colors.grayText),
      cursor: "pointer",
      position: "absolute",
      top: 15,
      left: 20,
      width: 25,
      height: 25,
    },
    copyLink: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "0.8fr 3.2fr",
      gridColumnGap: 15,
      alignItems: "center",
      [theme.device.mobile()]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gridRowGap: 5,
      },
    },
    copyLinkBtn: {
      padding: 0,
      cursor: "pointer",
      userSelect: "none",
      [theme.device.mobile()]: {
        marginTop: 5,
      },
    },
    copyLinkText: {
      color: theme.colors.grayText,
      fontWeight: 600,
      fontSize: 19,
      [theme.device.desktop()]: {
        display: "none",
      },
    },
    copyLinkInput: {
      boxSizing: "border-box",
      color: theme.colors.black,
      fontSize: 24,
      cursor: "text",
      height: 55,
      borderRadius: 15,
      padding: "0 8px",
      border: `1px solid ${theme.colors.grayText}`,
      [theme.device.mobile()]: {
        width: "100%",
        borderWidth: 2,
        padding: 0,
      },
    },

    reviewPopUp: {
      top: 60,
      right: 20,
      position: "absolute",
      width: "100%",
      boxShadow: `0px 0px 20px ${theme.colors.shadow}`,
      backgroundColor: theme.colors.text,
      zIndex: 7,
      opacity: 0,
      visibility: "hidden",
      transition: "0.3s",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      boxSizing: "border-box",
      padding: "15px 20px 30px 20px",
      [theme.device.mobile()]: {
        position: "fixed",
        top: "15%",
        width: "90%",
        left: 0,
        right: 0,
        margin: "0 auto",
        paddingBottom: 20,
      },
      [theme.device.smallMobile()]: {
        top: "20%",
      },
    },

    popupRow: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      margin: "10px 0",

      "&>label": {
        marginBottom: 5,
        fontWeight: 700,
        fontSize: 18,
        color: theme.colors.blue,
      },
      [theme.device.mobile()]: {
        width: "100%",
        margin: 0,
      },
      [theme.device.smallMobile()]: {
        fontSize: 15,
      },
    },

    reviewTitle: {
      fontSize: 24,
      fontWeight: 600,
      color: theme.colors.orange,
      marginBottom: 20,
    },
    reviewTitleSignature: {
      fontSize: 24,
      fontWeight: 600,
      color: theme.colors.black,
      marginBottom: 20,
    },
    reviewInput: {
      fontFamily: "Helvetica, sans-serif",
      border: `1px solid ${theme.colors.orange}`,
      borderRadius: 6,
      paddingRight: 10,
      maxWidth: 500,
      fontSize: 20,
      height: 58,

      [theme.device.desktop()]: {
        width: 500,
      },

      "@media (min-width: 1200px)": {
        borderRadius: 16,
        paddingLeft: 20,
        fontSize: 24,
        height: 48,
      },
      [theme.device.mobile()]: {
        width: "100%",
        height: 45,
        borderRadius: 5,
        borderWidth: 2,
      },
      [theme.device.smallMobile()]: {
        height: 35,
      },
    },

    reviewText: {
      border: `1px solid ${theme.colors.orange}`,
      fontFamily: "Helvetica, sans-serif",
      resize: "vertical",
      borderRadius: 6,
      minHeight: 40,
      fontSize: 20,
      padding: 10,

      [theme.device.desktop()]: {
        width: 500,
        height: 200,
      },

      "@media (min-width: 1200px)": {
        borderRadius: 16,
        fontSize: 24,
      },
      [theme.device.mobile()]: {
        width: "100%",
        borderRadius: 5,
        resize: "none",
        borderWidth: 2,
      },
    },

    error: {
      color: theme.colors.red,
      textAlign: "end",
      fontSize: 16,
      opacity: 0,
      transition: "0.3s",
    },

    errorActive: {
      opacity: 1,
      transition: "0.3s",
    },

    invalidField: {
      borderColor: theme.colors.red,
    },

    reviewButtonContainer: {
      display: "flex",
      width: "60%",
      justifyContent: "space-between",
      alignSelf: "center",
      marginTop: 30,
      [theme.device.mobile()]: {
        width: "100%",
      },
      [theme.device.smallMobile()]: {
        gridColumnGap: 15,
      },
    },
    flagIcon: {
      opacity: 0,
    },
    heartIcon: {
      width: 50,
      height: 50,
      transition: "0.4s",
      [theme.device.mobile()]: {
        width: 40,
        height: 40,
      }
    },
    shareBtnIcon: {
      marginRight: 3,
      height: 20,
      width: 20,
      ...theme.utils.svgStroke(theme.colors.text),
    },
    price: {
      width: "100%",
      boxSizing: "border-box",
      paddingLeft: 180,
      [theme.device.mobile()]: {
        padding: 0,
      },
    },
    priceInfo: {
      position: "relative",
      width: "100%",
      backgroundColor: theme.colors.whiteBackground,
      boxShadow: `2px 3px 3px ${theme.colors.shadow}`,
      borderRadius: 20,
      padding: "10px 15px",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      gridRowGap: 10,
      [theme.device.smallMobile()]: {
        gridRowGap: 5,
      },
    },
    priceInfoText: {
      display: "flex",
      color: theme.colors.black,
      fontSize: 27,
      [theme.device.mobile()]: {
        fontSize: 23,
      },
      [theme.device.smallMobile()]: {
        fontSize: 17,
      },
    },
    retailPrice: {
      textDecoration: "line-through",
    },
    salePrice: {
      fontWeight: 700,
      color: theme.colors.green,
    },
    discountPercentage: {
      position: "absolute",
      left: 15,
      top: -7,
      [theme.device.mobile()]: {
        left: 7,
      },
    },
    discountIcon: {
      width: 100,
      height: 100,
      [theme.device.mobile()]: {
        height: 70,
        width: 70,
      },
    },
    discountText: {
      userSelect: "none",
      cursor: "default",
      top: 39,
      left: 39,
      position: "absolute",
      color: theme.colors.text,
      fontWeight: 700,
      fontSize: 20,
      transform: "rotate(45deg)",
      [theme.device.mobile()]: {
        top: 27,
        left: 27,
        fontSize: 15,
      },
    },
    discountTextOneDigit: {
      top: 38,
      left: 45,
      [theme.device.mobile()]: {
        top: 26,
        left: 30,
      },
    },
    discountDate: {
      fontSize: 17,
      fontWeight: 700,
    },
    missingPrice: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gridColumnGap: 15,
      padding: "30px 0",
    },

    brandLinkWrapper: {
      width: 350,
      height: 75,
      alignSelf: "center",
      display: "grid",
      gridTemplateColumns: "1fr  2fr",
      gridColumnGap: 20,
      padding: "0 7px",
      borderRadius: 24,
      backgroundColor: "#ffffff",
      border: "3px solid #FAA21B",

      "&:hover": {
        cursor: "pointer",
      },

      "&>div:nth-child(2)": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        fontSize: 22,
        fontWeight: 700,
        color: "#FAA21B",
      },
    },

    logoWrapper: { width: 100 },

    joie: {
      backgroundColor: "#ffffff",
      border: "3px solid #FAA21B",

      "&>div:nth-child(2)": {
        color: "#FAA21B",
      },
    },
    infanti: {
      backgroundColor: "#005CB8",
      border: "3px solid #005CB8",

      "&>div:nth-child(2)": {
        color: "#ffffff",
      },

      "&>div:nth-child(1)": {
        backgroundColor: "#ffffff",
        height: "55%",
        borderRadius: 7,
        alignSelf: "center",
        padding: 4,
      },
    },
    graco: {
      backgroundColor: "#ffffff",
      border: "3px solid #00539F",

      "&>div:nth-child(2)": {
        color: "#00539F",
      },
    },
    bumprider: {
      backgroundColor: "#ffffff",
      border: "3px solid #000000",

      "&>div:nth-child(2)": {
        color: "#000000",
      },
    },
    nuna: {
      backgroundColor: "#ffffff",
      border: "3px solid #161846",

      "&>div:nth-child(2)": {
        color: "#161846",
      },
    },
    brandLink: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    active: {
      display: "flex",
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    activeDesktop: {
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    activeMobile: {
      [theme.device.desktop()]: {
        display: "none",
      },
    },
    popupActive: {
      visibility: "visible",
      opacity: 1,
      zIndex: 1,
      transition: "0.3s",
    },
  }),
  { name: "single-product-info" }
);
