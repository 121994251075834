import { CreateCSSProperties, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import { TTypedStyles } from "../../theme";

type StyleProps = {
  color: React.CSSProperties["color"];
  borderWidth?: number;
};

const useStyles: TTypedStyles<StyleProps> = makeStyles(
  theme => ({
    arrow: ({ color, borderWidth }: StyleProps): CreateCSSProperties => ({
      width: 0,
      height: 0,
      borderLeft: `${!!borderWidth ? borderWidth - 2 : 8}px solid transparent`,
      borderRight: `${!!borderWidth ? borderWidth - 2 : 8}px solid transparent`,
      borderTop: `${!!borderWidth ? borderWidth : 10}px solid ${color}`,
    }),
    active: ({ color, borderWidth }: StyleProps): CreateCSSProperties => ({
      borderBottom: `${!!borderWidth ? borderWidth : 10}px solid ${color}`,
      borderTop: 0,
    }),
  }),
  { name: "arrow" }
);

type TProps = {
  active: boolean;
  color: React.CSSProperties["color"];
  className?: string;
  borderWidth?: number;
};

const Arrow = ({ active, color, className, borderWidth }: TProps): JSX.Element => {
  const classes = useStyles({ color, borderWidth });
  return <div className={clsx(classes.arrow, className, active && classes.active)} />;
};

export default Arrow;
