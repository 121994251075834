//@ts-nocheck
import { useTheme } from "@material-ui/styles";
import React, { useEffect, useRef, useState } from "react";
import { push } from "connected-react-router";
import clsx from "clsx";

import SearchWindow from "./search-window";
import Arrow from "../common/arrow";
import Cart from "./cart/cart";

import languagesStore, { Language } from "../../store/languages-store";
import { useAppDispatch, useAppSelector } from "../../store/store";
import authFormStore from "../../store/auth-form-store";
import signature from "../../store/signature-mode";
import { NewPhrase, NewPhrases } from "../../utils/language";
import useAuth from "../../hooks/useAuth";
import { config } from "../../config";
import categoriesStore from "../../store/categories-store";

import { ReactComponent as CartIcon } from "../../static/icons/bag.svg";
import { ReactComponent as CareerIcon } from "../../static/icons/carrier_icon.svg";
import { ReactComponent as SearchIcon } from "../../static/icons/search.svg";
import { ReactComponent as SignatureIcon } from "../../static/icons/signature.svg";
// import { ReactComponent as PushChairsIcon } from "../../static/icons/nav-pushchairs.svg";

// import { ReactComponent as CarSeatsIcon } from "../../static/icons/nav-carseats.svg";

import PushChairsIcon from "../../static/icons/nav-pushchairs.png";
import CarSeatsIcon from "../../static/icons/nav-carseats.png";

import SoothersIcon from "../../static/icons/nav-soothers.png";

import { ReactComponent as CotsCribsIcon } from "../../static/icons/nav-travelcots.svg";
import { ReactComponent as HighChairsIcon } from "../../static/icons/nav-highchairs.svg";
// import { ReactComponent as SoothersIcon } from "../../static/icons/nav-soothers.svg";

import { ReactComponent as ExploreJoieIcon } from "../../static/icons/nav-explorejoie.svg";

import { ReactComponent as IsraelFlag } from "../../static/icons/israel-flag.svg";
import { ReactComponent as MenuIcon } from "../../static/icons/drop-menu.svg";
import { ReactComponent as ArabicFlag } from "../../static/icons/arabic.svg";
// import { ReactComponent as SearchIcon } from "../../static/icons/search.svg";
// import { ReactComponent as CartIcon } from "../../static/icons/bag.svg";

// Signature Mode Icons

import { ReactComponent as SignatureBrownIcon } from "../../static/icons/signature-brown.svg";
import { ReactComponent as SignatureWhiteIcon } from "../../static/icons/signature-white.svg";
// SignatureWhiteIcon

import { ReactComponent as CartBrownIcon } from "../../static/icons/bag-brown.svg";
import { ReactComponent as SearchBrownIcon } from "../../static/icons/search-brown.svg";
import { ReactComponent as MenuBrownIcon } from "../../static/icons/drop-menu-brown.svg";

import { ReactComponent as CotsCribsBrownIcon } from "../../static/icons/nav-travelcots-brown.svg";

import { ReactComponent as SoothersBrownIcon } from "../../static/icons/nav-soothers-brown.svg";

import logo from "../../static/images/logo.png";
import logoMobile from "../../static/images/joie.png";
import logoBlack from "../../static/images/logoBlack.png";
import { useStyles } from "./header-styles";
import useViewport from "../../hooks/useViewport";
import Auth from "../authentication/auth";
import { getPhrase } from "../../utils/language";
import { isMobile } from "react-device-detect";
import signatureMode from "../../store/signature-mode";
import { useHistory } from "react-router";
import HeaderCategories from "./header-categories";

const getLanguageItems = (iconClass: string): { name: Language; icon: JSX.Element }[] => [
  {
    name: "he",
    icon: <IsraelFlag />,
  },
  {
    name: "ar",
    icon: <ArabicFlag />,
  },
];

const Header = (): JSX.Element => {
  const { navigationLinks } = useAppSelector(state => ({
    navigationLinks: state.siteConfig.navigationLinks,
  }));
  const [isMoving, setIsMoving] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const textRef = useRef<HTMLSpanElement>(null);

  const signatureMode = useAppSelector(state => state.signature.signatureMode);
  const history = useHistory();

  const {
    authFormIsVisible,
    langDropDownIsVisible,
    cartIsVisible,
    hamburgerMenuIsVisble,
    searchIsVisible,
  } = useAppSelector(
    ({
      authForm: {
        authFormIsVisible,
        langDropDownIsVisible,
        cartIsVisible,
        hamburgerMenuIsVisble,
        searchIsVisible,
      },
    }) => ({
      authFormIsVisible,
      langDropDownIsVisible,
      cartIsVisible,
      hamburgerMenuIsVisble,
      searchIsVisible,
    })
  );

  const { isAuthFormVisible, totalCartItemsCount, userId, lang, phrases, cart } = useAppSelector(
    state => {
      let totalCartItemsCount = 0;

      if (state?.user?.data) {
        state.user.data?.cartItems
          ?.filter(item => state.products.productsData.data.find(p => p.id === item.productId))
          ?.forEach(cartItem => (totalCartItemsCount += cartItem.productCount));
      } else {
        state.cart.items
          ?.filter(item => state.products.productsData.data.find(p => p.id === item.product.id))
          ?.forEach(cartItem => (totalCartItemsCount += cartItem.count));
      }

      return {
        totalCartItemsCount,
        isAuthFormVisible: state.authForm.authFormIsVisible,
        lang: state.languages.language,
        phrases: state.languages.phrases,
        userId: state.user.data?.id,
      };
    }
  );

  const { runningText } = useAppSelector(state => state.siteConfig);

  const activePhrases: NewPhrases | undefined = useAppSelector(
    languagesStore.selectors.getActivePhrases()
  );

  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  const handleScroll = () => {
    const distannceY = window.pageYOffset || document.documentElement.scrollTop;
    const shrinkOn = 150;

    if (distannceY > shrinkOn) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { isAuth } = useAuth(userId);
  const { width } = useViewport();

  const dispatch = useAppDispatch();

  const classes = useStyles();

  const theme = useTheme();

  const toggleMenu = () =>
    dispatch(
      authFormStore.actions.toggleVisibility("hamburgerMenuIsVisble", !hamburgerMenuIsVisble)
    );

  const onLogoClick = () => {
    dispatch(push(config.routes.homePage, true));
    dispatch(authFormStore.actions.toggleVisibility("hamburgerMenuIsVisble", false));
  };

  const onMenuItemClick = (path: string, id: number) => {
    dispatch(push(path, true));
    dispatch(categoriesStore.actions.setActiveCategory(id));
    dispatch(authFormStore.actions.toggleVisibility("hamburgerMenuIsVisble", false));
  };


  const navLink = [...navigationLinks];
  if (isMobile) {
    navLink.push({
      id: -2,
      name: "explore",
      url: "/explore",
    });
  }
  if (isMobile && !signatureMode) {
    navLink.unshift({
      id: -1,
      name: "signature",
      url: "/signature",
    });
  }

  const menuItems = navLink.map((item, i) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const label = activePhrases && activePhrases[item.name];
    // if(isMobile) {}
    return (
      <div key={item.id}>
        {item.name === "signature" ? (
          <div key={item.id}>
            <div
              style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
              className={signatureMode ? classes.menuItemBlack : classes.menuItemSlide}
              onClick={() => onMenuItemClick(item.url)}
            >
              <SignatureWhiteIcon className={clsx(classes.menuIcon)} style={{ marginRight: 10 }} />
              <span> {label}</span>
            </div>
          </div>
        ) : (
          <div
            key={item.id}
            className={signatureMode ? classes.menuItemBlack : classes.menuItemSlide}
            onClick={() => onMenuItemClick(item.url)}
          >
            {label}
          </div>
        )}
      </div>
    );
  });

  const onSearchClick = () =>
    dispatch(authFormStore.actions.toggleVisibility("searchIsVisible", !searchIsVisible));

  const onCartClick = () => {
    dispatch(authFormStore.actions.toggleVisibility("cartIsVisible", !cartIsVisible));
  };

  const onSignatureClick = () => {
    if (signatureMode && window.location.pathname === "/signature") {
      return;
    }
    dispatch(signature.actions.toggleMode(!signatureMode));
    dispatch(authFormStore.actions.toggleVisibility("hamburgerMenuIsVisble", false));

    history.push("/signature");
  };

  const onExploreClick = () => {
    history.push("/explore");
  };

  const onAuthClick = () => {
    dispatch(authFormStore.actions.toggleVisibility("authFormIsVisible", !authFormIsVisible));
  };

  let windowWidth = window.innerWidth;

  useEffect(() => {
    if (window.innerWidth < 900) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    const textWidth = textRef.current?.clientWidth;

    if (textWidth && width && textWidth > width - 20) {
      setIsMoving(true);
    } else if (textWidth && width && textWidth < width) {
      setIsMoving(false);
    }
  }, [width, runningText, textRef]);

  return (
    <div
      className={clsx(
        classes.root,
        signatureMode && classes.rootBlack,
        isAuthFormVisible && classes.unfixed
      )}
    >
      <Auth className={clsx(classes.authPopup, authFormIsVisible && classes.active)} />
      <div className={signatureMode ? classes.titleBlack : classes.title}>
        <div className={classes.text}>{runningText}</div>
      </div>
      <div className={classes.content}>
        {!isMobile && (
          <img
            className={classes.logo}
            src={signatureMode ? logoBlack : logo}
            alt="joie logo"
            onClick={onLogoClick}
          />
        )}
        {/* logoMobileSignature */}
        {isMobile && (
          <img
            className={
              signatureMode
                ? clsx(classes.logoMobileSignature, classes.logoMobile)
                : classes.logoMobile
            }
            // src={logoMobile}
            src={signatureMode ? logoBlack : logoMobile}
            alt="joie logo"
            onClick={onLogoClick}
          />
        )}

        <div className={classes.navigation}>
          {isMobile && (
            <div className={classes.navigationItem} onClick={onSearchClick}>
              {signatureMode ? (
                <SearchBrownIcon />
              ) : (
                <SearchIcon className={clsx(classes.icon, classes.navigationIcon)} />
              )}
            </div>
          )}
          {!isMobile && (
            <div style={{ display: "flex" }}>
              <div
                className={classes.cart}
                style={{ padding: "4px 10px 0 0" }}
                onClick={onSearchClick}
              >
                {signatureMode ? (
                  <SearchBrownIcon className={clsx(classes.iconBrown)} />
                ) : (
                  <SearchIcon className={clsx(classes.icon, classes.navigationIcon)} />
                )}
                {/* {getPhrase("search", lang)} */}
              </div>
              <div className={classes.cart} onClick={onCartClick}>
                {signatureMode ? (
                  <CartBrownIcon className={classes.menuIconBlack} />
                ) : (
                  <CartIcon className={classes.menuIcon} />
                )}

                {totalCartItemsCount > 0 && (
                  <div className={classes.cartCount}>{totalCartItemsCount}</div>
                )}
              </div>
            </div>
          )}
          {/* {isMobile && (
            <div
              className={clsx(
                signatureMode ? classes.menuItemBlack : classes.menuItem,
                classes.signatureIconMobile
              )}
              onClick={onMenuItemClick}
            >
              <SignatureIcon className={classes.menuIcon} />
            </div>
          )} */}
          {!isMobile && (
            <div className={classes.authWrapper}>
              {!userId && (
                <div
                  className={signatureMode ? classes.authBlack : classes.auth}
                  onClick={onAuthClick}
                >
                  {activePhrases && activePhrases["login"]}/
                  {activePhrases && activePhrases["registration"]}
                </div>
              )}
            </div>
          )}
          <div className={classes.navigationContainer}>
            {/* <div className={classes.navigationItem}>
            <IzraelFlagIcon className={clsx(classes.icon, classes.navigationIcon)} />
            {getPhrase("joie_israel", lang)}
          </div> */}
            {/* <div className={classes.navigationItem}>
            <PlaceIcon className={clsx(classes.icon, classes.placeIcon)} />
            {getPhrase("shops", lang)}
          </div> */}
            {/* {!isMobile && (
              <div className={classes.navigationItem} onClick={onSearchClick}>
                <SearchIcon className={clsx(classes.icon, classes.navigationIcon)} />
                {getPhrase("search", lang)}
              </div>
            )} */}
            {isMobile && (
              <>
                <div className={classes.cart} onClick={onCartClick}>
                  {signatureMode ? (
                    <CartBrownIcon className={classes.menuIconBlack} />
                  ) : (
                    <CartIcon className={classes.menuIcon} />
                  )}
                  {totalCartItemsCount > 0 && (
                    <div className={classes.cartCount}>{totalCartItemsCount}</div>
                  )}
                </div>
              </>
            )}

            {signatureMode && (
              <div
                className={
                  hamburgerMenuIsVisble
                    ? classes.menuItemHamburgerBlackActive
                    : classes.menuItemHamburger
                }
              >
                <MenuBrownIcon
                  className={clsx(classes.menuIconBlack, hamburgerMenuIsVisble)}
                  onClick={toggleMenu}
                />
              </div>
            )}

            {!signatureMode && (
              <div
                className={
                  hamburgerMenuIsVisble
                    ? classes.menuItemHamburgerActive
                    : classes.menuItemHamburger
                }
              >
                <MenuIcon
                  className={clsx(
                    classes.menuIcon,
                    classes.menuHamburgerIcon,
                    hamburgerMenuIsVisble
                  )}
                  onClick={toggleMenu}
                />
              </div>
            )}
          </div>
        </div>

        {/* <div
              className={
                signatureMode && hamburgerMenuIsVisble ?
                  classes.navigationItemBlack :
                  !signatureMode && hamburgerMenuIsVisble ?
                    classes.menuItemHamburgerActive :
                    classes.menuItemHamburger
              }
            > */}
        <div className={clsx(classes.menu)}>
          {!isMobile && (
            <div
              className={signatureMode ? classes.menuItemBlack : classes.menuItem}
              onClick={onSignatureClick}
            >
              {signatureMode && <SignatureBrownIcon className={classes.menuIconBlack} />}
              {!signatureMode && <SignatureIcon className={classes.menuIcon} />}

              <span>{activePhrases && activePhrases["signature"]}</span>
            </div>
          )}

          {!isMobile && <HeaderCategories onMenuItemClick={onMenuItemClick} isSignature={signatureMode} />}

          {!isMobile && (
            <div
              className={signatureMode ? classes.menuItemBlack : classes.menuItem}
              onClick={onExploreClick}
            >
              <ExploreJoieIcon
                className={signatureMode ? classes.menuIconBlack : classes.menuIcon}
              />
              <span>{activePhrases && activePhrases["explore"]}</span>
            </div>
          )}
          {/* {!isMobile && (
            <MenuIcon
              className={clsx(classes.menuIcon, hamburgerMenuIsVisble)}
              onClick={toggleMenu}
            />
          )} */}
          {isMobile && (
            <div
              className={clsx(
                classes.dropMenu,
                !signatureMode && hamburgerMenuIsVisble
                  ? classes.dropMenuActive
                  : signatureMode && hamburgerMenuIsVisble
                  ? clsx(classes.dropMenuActive, classes.dropMenuBlackActive)
                  : ""
              )}
            >
              <div className={clsx(classes.dropdownItemsComponent, classes.mobileMenuItemSlide)}>
                {menuItems}
              </div>
              {!userId && (
                <div className={clsx(classes.menuItemSlide)} onClick={onAuthClick}>
                  {activePhrases && activePhrases["login"]}/
                  {activePhrases && activePhrases["registration"]}
                </div>
              )}
            </div>
          )}
        </div>

        {!isMobile && (
          <div
            className={clsx(
              classes.dropMenu,
              !signatureMode && hamburgerMenuIsVisble
                ? classes.dropMenuActive
                : signatureMode && hamburgerMenuIsVisble
                ? clsx(classes.dropMenuActive, classes.dropMenuBlackActive)
                : ""
            )}
          >
            <div className={classes.dropdownItemsComponent}>{menuItems}</div>
          </div>
        )}
        <SearchWindow className={{ root: clsx(searchIsVisible && classes.active) }} />
        <Cart className={{ root: clsx(cartIsVisible && classes.active) }} />
      </div>

      {/* <div className={classes.root}> */}
      {/* <Auth
        className={clsx(
          classes.authPopup,
          authFormIsVisible && classes.active,
          classes.activeDesktop
        )}
      /> */}
      {/* <div className={classes.title}>
        <div className={clsx(classes.logo, isScrolled && classes.logoScrolled)}>
          <img className={classes.logoImg} src={logo} alt="logo" onClick={onLogoClick} />
        </div>
        <span className={clsx(classes.text, isMoving && classes.textIsMoving)} ref={textRef}>
          {runningText}
        </span>
      </div>

      <div className={classes.content}>
        <div className={classes.panel}>
          <SearchIcon className={clsx(classes.icon, classes.searchIcon)} onClick={onSearchClick} />

          <div className={classes.cart} onClick={onCartClick}>
            <CartIcon className={classes.icon} />
            {isAuth && totalCartItemsCount > 0 && (
              <div className={classes.cartCount}>{totalCartItemsCount}</div>
            )}
          </div>
        </div>
        <div className={classes.authWrapper}>
          {!userId && (
            <div className={classes.auth} onClick={onAuthClick}>
              {activePhrases && activePhrases["login"]}/
              {activePhrases && activePhrases["registration"]}
            </div>
          )}
        </div>*/}

      {/* <div className={classes.menu}>
          <MenuIcon
            className={clsx(classes.menuIcon, hamburgerMenuIsVisble && classes.menuIconActive)}
            onClick={toggleMenu}
          />
        </div> */}

      {/* <div className={clsx(classes.dropMenu, hamburgerMenuIsVisble && classes.dropMenuActive)}>
        {menuItems}
      </div> */}
      {/*
        <SearchWindow className={{ root: clsx(searchIsVisible && classes.active) }} />

        <Cart className={{ root: clsx(cartIsVisible && classes.active) }} />
      </div>
    </div> */}
    </div>
  );
};

export default Header;
