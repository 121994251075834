import React, { Component } from "react";

import PaymentForm from "./PaymentForm";
import PaymentResult from "./PaymentResult";

import "./Payment.css";
import tranzillaSecurity from "../../../static/images/tranzilla-security.png";

class Payment extends Component {
  constructor() {
    super();
  }

  fields = null;
  state = {
    imageSrc: "cardExample",
    messages: [],
    sucess: false,
    resMessage: "",
    isDisable: false,
  };

  componentDidMount = () => {
    if (this.fields == null) {
      this.tzlHostedFields();
    }
  };

  chargeCcData = e => {
    let a = document.getElementById("installment");

    let paymentsNo = Number(a.value);
    const customerFullName = this.props.customerFullName;
    const currentOrderId = localStorage.getItem("currentOrderId");
    // console.log("this.props.orderId", this.props.orderId);

    let data = {
      pid: currentOrderId,
      currency_code: "ILS",
      contact: customerFullName,
      terminal_name: "bambinoj",
      response_language: "hebrew",
      amount: this.props.totalOrderPrice,
      pdesc: this.props.productDescriptions.join(", "),
      OrderID: this.props.orderId,
    };

    if (paymentsNo > 1) {
      data.payment_plan = 8;
      const installment = (Number(+this.props.totalOrderPrice) / paymentsNo).toFixed(2);
      const firstInstallment = (
        Number(+this.props.totalOrderPrice) -
        +installment * (paymentsNo - 1)
      ).toFixed(2);
      data.first_installment_amount = firstInstallment;
      data.other_installments_amount = installment;
      data.total_installments_number = paymentsNo;
    }

    this.fields.charge(data, (error, response) => {
      if (response) {
        this.parseResponse(response);
      }
      if (error) {
        if (error.messages !== null) {
          this.handleError(error);
          this.setState(prevState => {
            return {
              ...prevState,
              isDisable: !prevState.isDisable,
            };
          });
        }
      }
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState(prevState => {
      return {
        ...prevState,
        isDisable: !prevState.isDisable,
      };
    });
    await this.props.onPlaceOrder(event, true, "card");
    if (this.props.isValid) {
      await this.chargeCcData(event);
    }
  };

  handleError = err => {
    let tmpArray = [];
    err.messages.forEach(message => {
      tmpArray.push(message.param);
    });
    this.setState({ messages: tmpArray, sucess: false });
  };

  parseResponse = response => {
    if (response.errors != null) {
      let tmpArray = [];
      response.errors.forEach(message => {
        tmpArray.push(message.message);
      });
      const str = tmpArray.join();
      this.setState({ resMessage: str, sucess: false });
      this.props.onPayment(false);
    } else {
      if (response.transaction_response.success === false) {
        this.setState({
          resMessage: response.transaction_response.error,
          sucess: false,
        });
        this.props.onPayment(false);
      } else {
        this.setState({ sucess: true });
        this.props.onPayment(true);
      }
    }
  };

  onCardNumberChanged = result => {
    if (result && result.cardType !== "unknown") {
      this.setState({ imageSrc: result.cardType });
    }
  };

  validityChange = result => {
    const tmpArray = this.state.messages.slice();
    if (result && result.field) {
      tmpArray.splice(result.field.index, 1);
    }
    this.setState({ messages: tmpArray });
  };

  tzlHostedFields = () => {
    const tzlFields = window.TzlaHostedFields.create({
      sandbox: false,
      fields: {
        card_holder_id_number: {
          selector: "#card_holder_id_number",
          placeholder: "ת”ז",
          tabindex: 1,
        },
        credit_card_number: {
          selector: "#credit_card_number",
          placeholder: "מספר כרטיס אשראי",
          version: 1,
        },
        cvv: {
          selector: "#cvv",
          placeholder: "CVV",
          tabindex: 2,
        },
        expiry: {
          selector: "#expiry",
          placeholder: "שששש/חח",
          tabindex: 3,
          version: "1",
        },
      },
      styles: {
        input: {
          "text-align": "right",
        },
      },
    });
    tzlFields.onEvent("cardTypeChange", this.onCardNumberChanged);
    tzlFields.onEvent("blur", this.validityChange);
    // tzlFields.onEvent("change", this.change);
    this.fields = tzlFields;
  };

  render() {
    const { resMessage, imageSrc, sucess, messages } = this.state;
    if (resMessage !== "" || sucess) {
      return <PaymentResult msg={resMessage} success={sucess}></PaymentResult>;
    }

    return (
      <>
        <PaymentForm
          msg={messages}
          img={imageSrc}
          handleSubmit={this.handleSubmit}
          disableButton={this.state.isDisable}
          totalPrice={this.props.totalOrderPrice}
        />
        <img style={{ pointerEvents: "none" }} src={tranzillaSecurity} alt="payment-security" />
      </>
    );
  }
}

export default Payment;
