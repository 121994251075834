import { buildCategory, buildMediaUrl, buildProduct } from "../../utils/helper";
import strapiService from "../strapi";
import { TMediaData, TSiteConfig } from "./types";

const getSiteConfig = async (): Promise<TSiteConfig> => {
  const fetchedData = await strapiService.fetchSiteConfig();

  const siteConfig: TSiteConfig = {
    products: fetchedData.products?.map(p => ({
      product: buildProduct(p.product),
    })),
    runningText: fetchedData.runningText,
    carousel: fetchedData.carousel?.map((dto: TMediaData) => ({
      id: dto.id,
      url: buildMediaUrl(dto.url),
      caption: dto.caption,
    })),
    carouselMobile: fetchedData.carouselMobile?.map((dto: TMediaData) => ({
      id: dto.id,
      url: buildMediaUrl(dto.url),
      caption: dto.caption,
    })),
    catalogCarousel: fetchedData.catalogCarousel?.map((dto: TMediaData) => ({
      id: dto.id,
      url: buildMediaUrl(dto.url),
      caption: dto.caption,
    })),
    catalogCarouselMobile: fetchedData.catalogCarouselMobile?.map((dto: TMediaData) => ({
      id: dto.id,
      url: buildMediaUrl(dto.url),
      caption: dto.caption,
    })),
    middleBanner: !!fetchedData.middleBanner
      ? {
          id: fetchedData.middleBanner.id,
          url: buildMediaUrl(fetchedData.middleBanner.url),
          caption: fetchedData.middleBanner.caption,
        }
      : null,
    middleBannerMobile: !!fetchedData.middleBannerMobile
      ? {
          id: fetchedData.middleBannerMobile.id,
          url: buildMediaUrl(fetchedData.middleBannerMobile.url),
          caption: fetchedData.middleBannerMobile.caption,
        }
      : null,
    bottomBanner: !!fetchedData.bottomBanner
      ? {
          id: fetchedData.bottomBanner.id,
          url: buildMediaUrl(fetchedData.bottomBanner.url),
          caption: fetchedData.bottomBanner.caption,
        }
      : null,
    contactUs: !!fetchedData.contactUs
      ? {
          address: fetchedData.contactUs.address,
          fax: fetchedData.contactUs.fax,
          phone: fetchedData.contactUs.phone,
          email: fetchedData.contactUs.email,
        }
      : null,
    posts: fetchedData.posts?.map((post: TMediaData) => ({
      id: post.id!,
      url: post.url!,
      caption: post.caption,
    })),
    product: !!fetchedData.product ? buildProduct(fetchedData.product) : null,
    socialMedia: !!fetchedData.socialMedia
      ? {
          facebook: fetchedData.socialMedia?.facebook!,
          twitter: fetchedData.socialMedia?.twitter!,
          pinterest: fetchedData.socialMedia?.pinterest!,
          linkedin: fetchedData.socialMedia?.linkedin!,
          whatsapp: fetchedData.socialMedia.whatsapp,
          instagram: fetchedData.socialMedia.instagram
        }
      : null,
    categories: fetchedData.categories?.map(c => ({
      category: buildCategory(
        c.category,
        fetchedData?.categories?.map(c => c.category)
      )!,
      name: c?.name!,
    })),
    HomePageExploreBanner: !!fetchedData.HomePageExploreBanner
      ? {
          id: fetchedData.HomePageExploreBanner.id,
          url: buildMediaUrl(fetchedData.HomePageExploreBanner.url),
          caption: fetchedData.HomePageExploreBanner.caption,
        }
      : null,
    HomePageExploreBannerMobile: !!fetchedData.HomePageExploreBannerMobile
      ? {
          id: fetchedData.HomePageExploreBannerMobile.id,
          url: buildMediaUrl(fetchedData.HomePageExploreBannerMobile.url),
          caption: fetchedData.HomePageExploreBannerMobile.caption,
        }
      : null,
    HomePageJoinBanner: !!fetchedData.HomePageJoinBanner
      ? {
          id: fetchedData.HomePageJoinBanner.id,
          url: buildMediaUrl(fetchedData.HomePageJoinBanner.url),
          caption: fetchedData.HomePageJoinBanner.caption,
        }
      : null,
    HomePageJoinBannerMobile: !!fetchedData.HomePageJoinBannerMobile
      ? {
          id: fetchedData.HomePageJoinBannerMobile.id,
          url: buildMediaUrl(fetchedData.HomePageJoinBannerMobile.url),
          caption: fetchedData.HomePageJoinBannerMobile.caption,
        }
      : null,
    maxOrderPrice: fetchedData?.maxOrderPrice!,
    maxOrderWeight: fetchedData?.maxOrderWeight!,
    TermsAndConditions: fetchedData?.TermsAndConditions!,
    TermsAndConditionsHebrew: fetchedData?.TermsAndConditionsHebrew!,
    BuyPolicy: fetchedData?.BuyPolicy!,
    BuyPolicyHebrew: fetchedData?.BuyPolicyHebrew!,
    privacyPolicy: fetchedData.privacyPolicy!,
    privacyPolicyHebrew: fetchedData.privacyPolicyHebrew!,
    accessibility: fetchedData.accessibility!,
    accessibilityHebrew: fetchedData.accessibilityHebrew!,
    CustomerService: fetchedData?.CustomerService,
    CustomerServiceHebrew: fetchedData?.CustomerServiceHebrew,
    vat: fetchedData?.vat!,
    shipByPostPrice: fetchedData?.shipByPostPrice!,
    courierPrice: fetchedData?.courierPrice!,
    careers: fetchedData?.careers,
    navigationLinks: fetchedData?.navigationLinks,
    MeetTheGearComponent: fetchedData?.MeetTheGearComponent,
    UnderCrouselElements: fetchedData?.UnderCrouselElements,
    MeetTheGearTitle: fetchedData?.MeetTheGearTitle,
    MeetTheGearDescription: fetchedData?.MeetTheGearDescription,
    partners: fetchedData?.partners,
    description: fetchedData.description,
    keywords: fetchedData.keywords,
    title: fetchedData.title,
  };

  return siteConfig;
};

export { getSiteConfig };
