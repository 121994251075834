import { makeStyles } from "@material-ui/styles";
import Loader from "react-loader-spinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import React ,{ useEffect, useState } from "react";
import clsx from "clsx";

import { useAppDispatch, useAppSelector } from "../../../store/store";
import { ResetPasswordErrors } from "../../../services/users/types";
import useViewport from "../../../hooks/useViewport";
import usersStore from "../../../store/users-store";
import languagesStore from "../../../store/languages-store";

interface FormValues {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const initialValues: FormValues = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const ChangePasswordForm = (): JSX.Element => {
  const { user } = useAppSelector(state => ({
    user: state.user,
  }));

  const classes = useStyles();

  const { width } = useViewport();

  const dispatch = useAppDispatch();
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const userError = user.errors.password;
  const displayErrorMessage = (): ResetPasswordErrors | Error | string | undefined => {
    if (typeof userError === "string") {
      if (userError.length > 0) {
        console.log(userError, "userError");
        switch (userError) {
          case "Please provide your new password confirmation":
            return activePhrases && activePhrases["Please_provide_your_new_password_confirmation"];

          case "Please provide your username or your e-mail":
            return activePhrases && activePhrases["Please_provide_username_or_email"];

          case "Please provide your new password":
            return activePhrases && activePhrases["Please_provide_your_new_password"];

          case "Please provide your password":
            return activePhrases && activePhrases["Please_provide_your_password"];

          case "Current password is invalid":
            return activePhrases && activePhrases["Current_password_is_invalid"];

          case "New Passwords do not match":
            return activePhrases && activePhrases["New_Passwords_do_not_match"];

          case "New Password must be different from current!":
          return activePhrases && activePhrases["New_Password_must_be_different"];

          default:
            return userError;
        }
      }
    }

    return userError;
  };

  const ChangePasswordSchema: any = Yup.object().shape({
    currentPassword: Yup.string().required(activePhrases && activePhrases["required_field"]),

    newPassword: Yup.string()
      .min(6, "Password should be at least 6 characters long, and without spaces!")
      .matches(/^[/\S*$/]+$/, "No spaces allowed")
      .notOneOf([Yup.ref("currentPassword"), null], activePhrases && activePhrases["New_Password_must_be_different"])
      .required(activePhrases && activePhrases["required_field"]),

    confirmPassword: Yup.string()
      .min(6, "Password should be at least 6 characters long, and without spaces!")
      .oneOf([Yup.ref("newPassword"), null], "Password doesn't match newPassword")
      .required(activePhrases && activePhrases["required_field"]),
  });
  

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: (values: any) => {
      formik.setSubmitting(true);

      dispatch(
        usersStore.actions.resetPassword({
          identifier: user.data!.username,
          password: values.currentPassword,
          newPassword: values.newPassword.replace(/ /g, ""),
          confirmPassword: values.confirmPassword.replace(/ /g, ""),
        })
      );
    },
  });



   //check for new password field
   if(formik.errors.newPassword) {
    console.log(formik.errors.newPassword)
   
    switch(formik.errors.newPassword){
      //New Password must be different from current!
      case"Password should be at least 6 characters long, and without spaces!":  formik.errors.newPassword = activePhrases && activePhrases["New_Passwords_do_not_match"];
      case"New Password must be different from current !":  formik.errors.newPassword = activePhrases && activePhrases["New_Password_must_be_different"];
      // case"New Password must be different from current":  formik.errors.newPassword = 'activePhrases && activePhrases["New_Password_must_be_different"]'
      // case"New password does not match":  formik.errors.newPassword = activePhrases && activePhrases["New_Passwords_do_not_match"]
      default:  formik.errors.newPassword = formik.errors.newPassword
    }

  }

    //check for confirm new password field
    if(formik.errors.confirmPassword) {
      if(formik.errors.confirmPassword == "Password doesn't match newPassword") formik.errors.confirmPassword = activePhrases && activePhrases["New_Passwords_do_not_match"]
      else if(formik.errors.confirmPassword == "New password does not match") formik.errors.confirmPassword = activePhrases && activePhrases["New_Passwords_do_not_match"]
    }
    // const [newPasswordError, setNewPasswordError] = useState<string>("");
    // useEffect(() => {
    //   if(formik.errors.newPassword == "New Password must be different from current!") {
    //     setNewPasswordError(formik.errors.newPassword)
    //   }
    // }, [formik.errors.newPassword]);

  return (
    <div className={clsx(classes.wrapper, width && width < 600 && "separate")}>
      <h1 className={classes.tabTitle}>{activePhrases && activePhrases["change_password"]}</h1>

      <form  onSubmit={formik.handleSubmit}>
        <div className="form-row">
          <label htmlFor="currentPassword">
            {activePhrases && activePhrases["current_password"]}
          </label>
          <input
            id="currentPassword"
            className={clsx(
              formik.errors.currentPassword && formik.touched.currentPassword && "invalid-field"
            )}
            name="currentPassword"
            type="password"
            onChange={formik.handleChange}
          />
          {formik.errors.currentPassword && formik.touched.currentPassword && (
            <span className="error">{formik.errors.currentPassword}</span>
          )}
        </div>

        <div className="form-row">
          <label htmlFor="newPassword">{activePhrases && activePhrases["new_password"]}</label>
          <input
            id="newPassword"
            className={clsx(
              formik.errors.newPassword && formik.touched.newPassword && "invalid-field"
            )}
            name="newPassword"
            type="password"
            onChange={formik.handleChange}
          />
          <span className="pwd-hint">{activePhrases && activePhrases["min_password_symbols"]}</span>
          {formik.errors.newPassword && formik.touched.newPassword && (
            <span className="error">{formik.errors.newPassword}</span>
          )}
         
        </div>

        <div className="form-row">
          <label htmlFor="confirmPassword">
            {activePhrases && activePhrases["confirm_new_password"]}
          </label>
          <input
            id="confirmPassword"
            className={clsx(
              formik.errors.confirmPassword && formik.touched.confirmPassword && "invalid-field"
            )}
            name="confirmPassword"
            type="password"
            onChange={formik.handleChange}
          />
          {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            <span className="error">{formik.errors.confirmPassword}</span>
          )}
        </div>

        {user.errors.password && (
          <div className="error">
            <span>{displayErrorMessage()}</span>
          </div>
        )}

        <div className={classes.saveBtnWrapper}>
          <button className={classes.saveBtn} type="submit">
            {user.loading && formik.isSubmitting ? (
              <Loader type="ThreeDots" color="#FFF" width={45} />
            ) : (
              activePhrases && activePhrases["save_changes"]
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordForm;

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      marginTop: 50,

      "&.separate": {
        borderTop: `1px solid ${theme.colors.blue}`,
      },

      "&>form": {
        padding: "0 10px",

        "&>div.form-row": {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",

          marginBottom: 20,

          "&>label": {
            fontSize: 24,
            marginBottom: 6,
            textAlign: "end",
            fontWeight: "bold",
            color: `${theme.colors.grayBorder}`,

            "@media (min-width: 1200px)": {
              fontSize: 35,
              marginBottom: 16,
            },
          },

          "&>input": {
            boxSizing: "border-box",
            padding: "0 5px",
            textAlign: "end",
            height: 50,
            fontSize: 24,
            width: "100%",
            maxWidth: 500,
            paddingLeft: 10,
            borderRadius: 10,
            border: `1px solid ${theme.colors.blue}`,

            "&.invalid-field": {
              borderColor: theme.colors.red,
            },

            "@media (min-width: 1200px)": {
              height: 55,
            },
          },

          "&>span": {
            "&.error": {
              fontSize: 20,
              color: theme.colors.red,

              "@media (min-width: 1200px)": {},
            },

            "&.pwd-hint": {
              fontSize: 18,
              color: theme.colors.grayText,

              "@media (min-width: 800px)": {
                fontSize: 20,
              },

              "@media (min-width: 1000px)": {
                fontSize: 25,
              },
            },
          },
        },

        "&>div": {
          "&.error": {
            fontSize: 20,
            textAlign: "end",
            color: theme.colors.red,
          },
        },
      },
    },

    tabTitle: {
      fontSize: 34,
      fontWeight: "bold",
      textAlign: "center",
      margin: "10px 0 30px 0",

      [theme.device.desktop()]: {
        textAlign: "end",
      },
      "@media (min-width: 800px)": {
        fontSize: 40,
      },
    },

    saveBtnWrapper: {
      display: "flex",
      justifyContent: "flex-end",

      "&>button": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 200,
        height: 50,
      },
    },
    saveBtn: {
      display: "grid",
      placeItems: "center",

      fontSize: 28,
      marginTop: 40,
      minWidth: 100,
      maxHeight: 80,
      outline: "none",
      color: "#fff",
      cursor: "pointer",
      borderRadius: 30,
      padding: "6px 10px",
      textAlign: "center",
      backgroundColor: theme.colors.blue,

      [theme.device.desktop()]: {
        fontSize: 24,
      },
      "@media (min-width: 700px)": {
        fontSize: 30,
      },
      "@media (min-width: 800px)": {
        textAlign: "end",
      },
      "@media (min-width: 1200px)": {
        fontSize: 35,
        maxHeight: 100,
        textAlign: "center",
      },
    },
  }),
  { name: "my-account-overview-change-password" }
);
