import { makeStyles, useTheme } from "@material-ui/styles";
import clsx from "clsx";
import { push } from "connected-react-router";
import React, { Fragment, useState } from "react";
import { config } from "../../config";
import type { Category, ChidlCategory } from "../../services/categories/types";
import categoriesStore from "../../store/categories-store";
import { useAppDispatch, useAppSelector } from "../../store/store";
import Arrow from "../common/arrow";

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      position: "relative",
      [theme.device.desktop()]: {
        display: "none",
      },
    },
    dropItem: {
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.colors.text,
      border: `solid ${theme.colors.orange}`,
      borderWidth: "0 1px 2px 1px",
    },

    dropItemBlack: {
      backgroundColor: theme.colors.text,
      border: `solid ${theme.colors.brown}`,
    },

    dropItemMain: {
      backgroundColor: theme.colors.orange,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "15px 20px",
    },


    dropItemContent: {
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "15px 20px",
    },

    dropItemBlackMain: {
      backgroundColor: theme.colors.brown,
      border: `1px solid ${theme.colors.brown}`,
    },
    dropBlackItem: {
      border: `1px solid ${theme.colors.brown}`,
    },


    categoryTitle: {
      color: theme.colors.blue,
      fontWeight: 700,
      fontSize: 18,
    },
    categoryTitleBlack: {
      color: theme.colors.brown,
    },
    categoriesTitle: {
      color: theme.colors.text,
      fontWeight: 700,
      fontSize: 20,
    },
    categories: {
      position: "absolute",
      width: "100%",
      top: "100%",
      backgroundColor: "white",
      zIndex: 3,
      display: "flex",
      flexDirection: "column",
      visibility: "hidden",
      opacity: 0,
      height: 0,
      transition: "0.3s",
    },
    subcategories: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      visibility: "hidden",
      opacity: 0,
      height: 0,
      transition: "0.3s",
    },
    subSubCategories: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    subSubCategory: {
      width: "100%",
      boxSizing: "border-box",
      padding: "5px 20px",
      backgroundColor: theme.colors.orange,
      textAlign: "end",
      borderBottom: `2px solid ${theme.colors.text}`,
      color: theme.colors.text,
      fontSize: 18,
    },
    subcategoryTitle: {
      backgroundColor: theme.colors.blue,
    },
    subcategoryTitleBrown: {
      backgroundColor: theme.colors.brown,
    },
    active: {
      visibility: "visible",
      opacity: 1,
      height: "auto",
    },
  }),
  { name: "drop-categories" }
);

type TProps = {
  currentCategory: number | null;
  setCurrentCategory: (id: number | null) => void;
  isImporterDeals?: boolean;
};

const DropCategories = ({
  currentCategory,
  isImporterDeals,
  setCurrentCategory,
}: TProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [activeMenu, setActiveMenu] = useState<boolean>(false);

  const categories = useAppSelector(state => state.siteConfig.categories.map(c => c.category));

  const categoriesData = useAppSelector(state => state.categories.categoriesData.data);

  const signatureMode = useAppSelector(state => state.signature.signatureMode);

  const toggleMenu = () => setActiveMenu(current => !current);

  const onCategoryClick = (id: number) => () => {
    if (id === currentCategory) {
      setCurrentCategory(null);
      return;
    }

    setCurrentCategory(id);
  };

  const onSubCategoryClick = (subcategory: Category | undefined) => () => {
    dispatch(push(`${config.routes.productsPage}/${subcategory?.parent.id}?sub=${subcategory?.id}`, true));
    dispatch(categoriesStore.actions.setActiveCategory(subcategory?.parent));
    dispatch(categoriesStore.actions.setActiveSubSubCategory(null));
    setCurrentCategory(null);
    setActiveMenu(false);
  };

  const onSubSubCategoryClick =
    (subcategory: Category | undefined, subSubcategory: Category | undefined) => () => {
      dispatch(push(`${config.routes.productsPage}/${subcategory?.parent.id}?sub_sub=${subSubcategory?.id}`, true));
      if (subSubcategory) {
        dispatch(categoriesStore.actions.setActiveSubSubCategory(subSubcategory?.id));
      }
      setCurrentCategory(null);
      setActiveMenu(false);
    };

  const items = categories.map(category => (
    <div key={category.id} className={signatureMode ? clsx(classes.dropItem, classes.dropBlackItem, classes.dropItemBlack) : classes.dropItem}>
      <div className={classes.dropItemContent} onClick={onCategoryClick(category.id)}>
        <Arrow color={theme.colors.orange} active={category.id === currentCategory} />
        {/* signatureMode ? clsx(classes.categoryTitle, classes.categoryTitleBlack) : classes.categoryTitle */}
        <div className={signatureMode ? clsx(classes.categoryTitle, classes.categoryTitleBlack) : classes.categoryTitle}>{category.name}</div>
      </div>
      <div
        className={clsx(classes.subcategories, category.id === currentCategory && classes.active)}
      >
        {categoriesData
          ?.find(c => c.id === category.id)
          ?.childrenCategories.map((chidlCategory: ChidlCategory) => {
            if (chidlCategory) {
              const subcategory = categoriesData.find(
                cat => cat.id === chidlCategory.childCategory.id
              );

              return (
                <Fragment key={subcategory?.id}>
                  {/* subcategoryTitleBrown */}
                  <div
                    className={signatureMode ? clsx(classes.subSubCategory, classes.subcategoryTitle, classes.subcategoryTitleBrown) : clsx(classes.subSubCategory, classes.subcategoryTitle)}
                    onClick={onSubCategoryClick(subcategory)}
                  >
                    {subcategory?.name}
                  </div>
                  <div className={classes.subSubCategories}>
                    {subcategory?.childrenCategories.map((childCat: ChidlCategory) => {
                      const subSubCategory = categoriesData.find(
                        sSubCat => sSubCat.id === childCat.childCategory.id
                      );
                      return (
                        <div
                          key={subSubCategory?.id}
                          className={classes.subSubCategory}
                          onClick={onSubSubCategoryClick(subcategory, subSubCategory)}
                        >
                          {subSubCategory?.name}
                        </div>
                      );
                    })}
                  </div>
                </Fragment>
              );
            }

            return null;
          })}
      </div>
    </div>
  ));
  return (
    <div className={classes.root}>
      <div className={signatureMode ? clsx(classes.dropItem, classes.dropItemMain, classes.dropItemBlackMain) : clsx(classes.dropItem, classes.dropItemMain)} onClick={toggleMenu}>
        <Arrow color={theme.colors.text} active={activeMenu} />
        <div className={classes.categoriesTitle}>קטגוריות</div>
      </div>
      <div className={clsx(classes.categories, activeMenu && classes.active)}>{items}</div>
    </div>
  );
};

export default DropCategories;
