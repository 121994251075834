import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import useViewport from "../../../hooks/useViewport";
import { useAppSelector } from "../../../store/store";
import { TOrder, TOrderStatus } from "../../../services/orders/types";
import displayCurrencySign from "../../../utils/currencies-signs";
import { getPhrase, NewPhrase } from "../../../utils/language";
import languagesStore from "../../../store/languages-store";

type TProps = {
  order: TOrder;
};

const Order = ({ order }: TProps): JSX.Element => {
  const [showMoreDetails, setShowMoreDetails] = useState<boolean>(false);

  const { orderedProducts } = order;

  const { products, lang } = useAppSelector(state => ({
    products: state.products.productsData.data,
    lang: state.languages.language,
  }));

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const orderedItems = products.filter(product =>
    orderedProducts.find(oP => product.catalogNumber === oP.catalogNumber)
  );

  const classes = useStyles();

  const { device } = useViewport();

  const getOrderStatus = (orderStatus: TOrderStatus): NewPhrase => {
    switch (orderStatus) {
      case "New":
        return "new";
      case "Canceled":
        return "canceled";
      case "Done":
        return "done";
      case "Processing":
        return "processing";
    }
  };

  const expandedOrderInfo = () => (
    <>
      <div className={classes.orderD}>
        <span>{activePhrases && activePhrases["shipping_number"]}:</span>
        {/* TODO: Remove hard-coded value after it's added to server resnpose */}
        <span>{!!order.shippingNumber ? order.shippingNumber : activePhrases && activePhrases["shipping_number"]}</span>
      </div>

      <div className={classes.orderD}>
        <span>{activePhrases && activePhrases["invoice_number"]}: </span>
        {/* TODO: Swap hard-coded value with real data after it's added to server resnpose */}
        <span>{!!order.invoiceNumber ? order.invoiceNumber : activePhrases && activePhrases["invoice_number"]}</span>
      </div>

      <div className={classes.items}>
        <span>
          {getPhrase("items", lang).charAt(0).toUpperCase() + getPhrase("items", lang).slice(1)}:{" "}
        </span>
        <div>
          <ul>
            {orderedItems?.map((oI, idx) => (
              <li key={oI?.id}>
                <img
                  src={oI?.mainPicture?.url!}
                  alt={oI?.mainPicture?.alternativeText!}
                  width={device === "mobile" ? "50px" : device === "tablet" ? "75px" : "100px"}
                  height={device === "mobile" ? "50px" : device === "tablet" ? "75px" : "100px"}
                  style={{ objectFit: "contain" }}
                />
                <p>{oI?.name}</p>

                <div className={classes.catalogNumber}>
                  <span>
                    {getPhrase("catalog_n", lang)} {oI.catalogNumber}
                  </span>
                </div>

                <div className={classes.qty}>
                  <span>
                    {getPhrase("qty", lang)}: <span>{orderedProducts[idx].count}</span>
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className={classes.orderTotal}>
        <span className="total">{getPhrase("order_total", lang)}</span>
        <span className="vat">({getPhrase("include_vat", lang)})</span>
        <span className="price">
          {displayCurrencySign(lang)}
          {order.totalPrice}
        </span>
      </div>
    </>
  );

  return (
    <div key={order.id} className={classes.wrapper}>
      <div className={classes.orderInfo}>
        <div className={classes.orderN}>
          {/* TODO: Should implement orderNumber in client and cms */}
          <span>
            {getPhrase("order_n", lang)} {order.id}
          </span>
        </div>

        <div className={classes.orderD}>
          <span>
            ({getPhrase("order_date", lang)}: {order.orderDate})
          </span>
        </div>

        <div className={classes.orderS}>
          <span>
            {activePhrases && activePhrases["status"]}:{" "}
            {activePhrases && activePhrases[getOrderStatus(order.orderStatus)]}
          </span>
        </div>

        {showMoreDetails && expandedOrderInfo()}
        <div className={classes.detailsBtn}>
          <button
            className={clsx(showMoreDetails && "less")}
            onClick={() => setShowMoreDetails(!showMoreDetails)}
          >
            {showMoreDetails ? getPhrase("less_details", lang) : getPhrase("more_details", lang)}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Order;

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      display: "flex",
      flexDirection: "column",

      padding: 10,
      marginTop: 20,
      borderRadius: 10,
      border: `3px solid ${theme.colors.blue}`,

      "@media (min-width: 800px)": {
        flexDirection: "row",
        justifyContent: "space-between",
      },
    },
    detailsBtn: {
      display: "flex",
      textAlign: "end",
      marginTop: 20,
      marginBottom: 20,
      //justifyContent: "flex-end",
      [theme.device.mobile()]: {
        marginBottom: 0,
      },

      "&>button": {
        display: "grid",
        placeItems: "center",

        minWidth: 100,
        maxHeight: 40,
        color: "#fff",
        cursor: "pointer",
        borderRadius: 30,
        padding: "4px 8px",
        backgroundColor: theme.colors.blue,

        "&.less": {
          backgroundColor: theme.colors.orange,
        },

        "@media (min-width: 1200px)": {
          position: "relative",

          fontSize: 28,
          minWidth: 200,
          maxHeight: 47,
        },
      },

      "@media (min-width: 800px)": {
        flex: 1,
        marginBottom: 0,
      },
    },
    orderInfo: {
      flex: 1,
      height: "100%",
      width: "100%",
      direction: 'rtl',
      //textAlign: "end",
    },
    orderN: {
      color: theme.colors.blue,
      marginBottom: 10,

      "&>span": {
        fontSize: 24,
        fontWeight: "bold",

        "@media (min-width: 1200px)": {
          fontSize: 35,
        },
      },
    },
    orderD: {
      marginBottom: 10,

      "&>span": {
        display: "block",
        fontSize: 16,
        color: theme.colors.grayBorder,

        "@media (min-width: 1200px)": {
          fontSize: 22,
        },

        "&:last-child": {
          fontWeight: "bold",
          color: theme.colors.blue,
        },
      },
    },
    orderS: {
      marginBottom: 10,

      "&>span": {
        fontSize: 16,
        fontWeight: "bold",
        color: theme.colors.blue,

        "@media (min-width: 1200px)": {
          fontSize: 26,
        },
      },
    },
    items: {
      paddingBottom: 20,
      borderBottom: `1px solid ${theme.colors.grayBorder}`,

      "&>span": {
        fontSize: 26,
        fontWeight: "bold",
        color: theme.colors.blue,

        "@media (min-width: 1200px)": {
          fontSize: 26,
        },
      },

      "&>div": {
        display: "flex",
        justifyContent: "space-around",
        marginTop: 10,

        "&>ul": {
          display: "flex",
          flexDirection: "column",
          //alignItems: "flex-end",
          width: "100%",
          margin: 0,
          padding: 0,
          listStyle: "none",

          "&>li": {
            margin: 0,
            padding: 0,
            overflowX: "auto",

            "&>p": {
              fontSize: 26,
              fontWeight: "bold",
              color: theme.colors.blue,
              wordBreak: "break-word",

              "@media (min-width: 1200px)": {
                fontSize: 26,
              },
            },
          },
        },
      },
    },
    catalogNumber: {
      "&>span": {
        fontSize: 20,
        color: theme.colors.grayBorder,

        "@media (min-width: 1200px)": {
          fontSize: 25,
        },
      },
    },
    qty: {
      marginTop: 6,

      "&>span": {
        fontSize: 16,
        color: theme.colors.grayBorder,

        "&>span": {
          fontWeight: "bold",
          color: theme.colors.blue,
        },

        "@media (min-width: 1200px)": {
          fontSize: 25,
        },
      },
    },
    orderTotal: {
      display: "flex",
      flexDirection: "column",

      marginTop: 10,
      paddingTop: 10,

      "&>span": {
        fontWeight: "bold",
        fontSize: 16,
        color: theme.colors.blue,

        "&.total": {
          "@media (min-width: 1200px)": {
            fontSize: 22,
          },
        },
        "&.vat": {
          color: theme.colors.grayBorder,

          "@media (min-width: 1200px)": {
            fontSize: 26,
          },
        },
        "&.price": {
          fontSize: 30,
          marginTop: 20,

          "@media (min-width: 1200px)": {
            fontSize: 36,
          },
        },
      },
    },
  }),
  { name: "my-account-order" }
);
