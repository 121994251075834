import { makeStyles, CreateCSSProperties } from "@material-ui/styles";
import clsx from "clsx";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import UIfiltersStore from "../../store/ui-filters-store";
import { ReactComponent as StarIcon } from "../../static/icons/star.svg";
import { ReactComponent as EmptyStarIcon } from "../../static/icons/emptyStar.svg";
import { ReactComponent as FullStarIcon } from "../../static/icons/fullStar.svg";
import { ReactComponent as QuarterStarIcon } from "../../static/icons/1-4-star.svg";
import { ReactComponent as HalfStarIcon } from "../../static/icons/1-2-star.svg";
import { ReactComponent as ThreeQuatStarIcon } from "../../static/icons/3-4-star.svg";

type StyleProps = {
  size: "small" | "medium" | "big";
  isClickable: boolean;
};

const sizeParser = {
  small: 25,
  medium: 32,
  big: 40,
};

const useStyles = makeStyles(
  theme => ({
    stars: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    icon: ({ size, isClickable }: StyleProps): CreateCSSProperties => ({
      cursor: isClickable ? "pointer" : "default",
      height: sizeParser[size],
      width: sizeParser[size],
    }),
    iconCursor: {
      cursor: "default",
    },
    iconActive: {
      ...theme.utils.svgChangeColor(theme.colors.orange),
    },
  }),
  { name: "rating" }
);

type TProps = {
  staticCount?: number;
  className?: {
    stars?: string;
    icon?: string;
  };
  rating: string;
  isClickable: boolean;
  size: "big" | "medium" | "small";
  reviewPopUp?: boolean;
  reviewPopUpRating?: number;
  setReviewRating?: (num: number) => void;
};

type StarIcons = {
  [key: number]: JSX.Element;
};

const Rating = ({
  className,
  staticCount,
  rating,
  isClickable,
  size,
  reviewPopUp,
  reviewPopUpRating,
  setReviewRating,
}: TProps): JSX.Element => {
  const classes = useStyles({ size, isClickable });
  const dispatch = useAppDispatch();
  const MAX_RATING_SCORE = 5;

  const activeRating = useAppSelector(state => state.UIfilters.rating);
  const initialActiveStars =
    Number(activeRating) === 0 || reviewPopUp
      ? []
      : [1, 2, 3, 4, 5].map((_, i) => i).slice(Number(5 - activeRating));
  const [activeStars, setActiveStars] = useState<number[]>(initialActiveStars);

  const r: number = useAppSelector(state => state.UIfilters.rating);

  const starIconsParser = (decimalPart: number, keyValue: number) => {
    const starsObj: StarIcons = {
      25: <QuarterStarIcon key={keyValue} className={classes.icon} />,
      50: <HalfStarIcon key={keyValue} className={classes.icon} />,
      75: <ThreeQuatStarIcon key={keyValue} className={classes.icon} />,
    };

    return starsObj[decimalPart];
  };

  const [integerPart, decimalPart]: number[] = rating!.split(".").map(string => Number(string));

  const onStarClick = (
    star: number,
    stars: number[],
    reviewPopUp: boolean | undefined
  ) => (): void => {
    const activeStars = stars.map((_, i) => i).slice(star);

    if (activeStars.length <= 1 && !!r && activeStars.includes(5 - r)) {
      setActiveStars([]);

      reviewPopUp
        ? setReviewRating && setReviewRating(0)
        : dispatch(UIfiltersStore.actions.setRating(0));

      return;
    }

    setActiveStars(activeStars);

    reviewPopUp
      ? setReviewRating && setReviewRating(5 - star)
      : dispatch(UIfiltersStore.actions.setRating(MAX_RATING_SCORE - star));
    //Because of the RTL design the first star has to be a rating of 5. Ex: 5(max rating value) - 0(first star index) = 5(rating)
  };

  const notClickableStars: JSX.Element[] = [1, 2, 3, 4, 5]
    .map((num, i, current) => {
      if (num <= integerPart) {
        return <FullStarIcon key={i} className={classes.icon} />;
      } else if (num - integerPart === 1 && decimalPart > 0) {
        return starIconsParser(decimalPart, i);
      }

      return <EmptyStarIcon key={i} className={classes.icon} />;
    })
    .reverse();

  const clickableStars: JSX.Element[] = [1, 2, 3, 4, 5].map((num, i, current) => (
    <StarIcon
      key={i}
      className={clsx(
        classes.icon,
        activeStars.includes(i) && classes.iconActive,
        r === i && !staticCount && activeStars.length > 1 && classes.iconCursor,
        !!staticCount && clsx(classes.iconActive, classes.iconCursor),
        className?.icon
      )}
      onClick={onStarClick(i, current, reviewPopUp)}
    />
  ));

  return (
    <div className={clsx(classes.stars, className?.stars)}>
      {isClickable ? clickableStars : notClickableStars}
    </div>
  );
};

export default Rating;
