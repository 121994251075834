import * as React from 'react'
import ProductItemGrid from './products-view/product-item-grid';
import { CreateCSSProperties, makeStyles, StylesContext, useTheme } from "@material-ui/styles";

import { useAppDispatch, useAppSelector } from "../store/store";
import clsx from 'clsx';

import signature from "../store/signature-mode";
import languagesStore from '../store/languages-store';

const { useState, useEffect } = React;

export const SignatureProducts = () => {
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const theme = useTheme();
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const [signatureCategory, setSignatureCategory] = useState<any>(null);
  const [filteredProducts, setFilteredProducts] = useState<any>(null);
  const [categoryId, setCategoryId] = useState<any>(null);

  const {
    user,
    products,
    categories,
    signatureMode
  } = useAppSelector(state => ({
    user: state.user.data,
    products: state.products.productsData.data,
    categories: state.categories.categoriesData.data,
    signatureMode: state.signature.signatureMode,
  }));

  useEffect(() => {
    if (categories) {
      categories.map(cat => {
        if (cat.name === "signature") {
          setSignatureCategory(cat);
        }
      })
    }
  }, [categories])

  // Filter categories and get the one with the name signature

  // Filter all of the categories and get those wgich has parent the "signatyure" category

  // Setter for filter if empty show all signatures if not filter by id

  // useEffect(() => {
  //   if (products) {
  //     let filtered = products.filter((product, i) => {
  //       if (product?.categories?.length > 0) {
  //         let isSignatureCategories = product?.categories?.filter(c => c.id === 107);
  //         if (isSignatureCategories.length > 0) {
  //           return true;
  //         }
  //         return false;
  //       }
  //     });

  //     setFilteredProducts(filtered)

  //     console.log(filteredSub)
  //     console.log(filtered.map(prod => prod.categories.map(a => console.log(a))))
  //     console.log(filtered.categories.map(cat => console.log(cat)))

  //   }
  // }, [products, signatureCategory])



  useEffect(() => {

    if (categoryId) {
      let filtered = products.filter((product, i) => {
        if (product?.categories?.length > 0) {
          let isSignatureCategories = product?.categories?.filter(c => c.id === 107);
          if (isSignatureCategories.length > 0) {
            return true;
          }
          return false;
        }
      });

      const signatureSubCat = filtered?.filter((product: any) => {
        let isMatch = false
        product?.subCategories?.forEach((c: any) => {
          //console.log('categoryId', categoryId)
          if (c.id === categoryId) {
            isMatch = true
          }
        })
        return isMatch
      })
      setFilteredProducts(signatureSubCat)
    } else {
      let filtered = products.filter((product, i) => {
        if (product?.categories?.length > 0) {
          let isSignatureCategories = product?.categories?.filter(c => c.id === 107);
          if (isSignatureCategories.length > 0) {
            return true;
          }
          return false;
        }
      });
      setFilteredProducts(filtered)
    }
  }, [categoryId, products]);


  //console.log(filteredProducts, 'O', categoryId)

  return (
    <div className={classes.wrapper}>
      <div className={classes.signatureWrapper}>
        <h3 className={classes.signatureTitle}>מהדורת <strong>Signature</strong></h3>
        <div className={classes.subCategories}>
          <div onClick={() => setCategoryId(null)} className={clsx(classes.subItem, !categoryId && classes.active)}>{activePhrases && activePhrases["view_all"]}</div>
          {signatureCategory?.subcategories?.map((category: any) =>
            <div key={category.id} onClick={() => setCategoryId(category.id)} className={clsx(classes.subItem, categoryId === category.id && classes.active)}>{category.name}</div>)}
        </div>
      </div>

      <div className={classes.productItems}>
        {filteredProducts?.sort((a: { quantity: number; }, b: { quantity: number; }) => b.quantity - a.quantity).map((product: any) => {
          return (<ProductItemGrid
            //isImporterDeals={false}
            key={product?.id}
            user={user!}
            product={product!}
            isFavorite={false}
            toggleFavorite={() => { console.log("xaxax") }}
          />)
        })}
      </div>
    </div >
  )
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "1200px",

  },
  signatureWrapper: {
    width: "100%",
    textAlign: "center"
  },
  signatureTitle: {
    fontSize: "44px",
    marginBottom: "10px",
    marginTop: "10px",
    margin: '0px 60px',
    textTransform: "uppercase",
    borderBottom: "4px solid #FFA400",
    display: "inline-block",
    color: theme.colors.brown,
    fontWeight: 500,
    direction: 'rtl',
    "& strong": {
      fontWeight: 600
    },
    [theme.device.smallTablet()]: {
      fontSize: "40px",
      border: "none",
      direction: 'ltr',
      "& strong": {
        borderBottom: "4px solid #FFA400",
      }

    },

    // [theme.device.mobile()]: {
    //   fontSize: "26px",
    // },
  },
  subItem: {
    display: "inline-block",
    fontSize: 26,
    margin: 0,
    cursor: "pointer",
    textTransform: 'uppercase',
    marginBottom: "30px",
    marginTop: "30px",
    paddingRight: "10px",
    paddingLeft: "10px",
    color: theme.colors.brown,
    opacity: .7,
    "&:not(:last-child)": {
      borderRight: "2px solid #707070",

    },
    [theme.device.smallTablet()]: {
      fontSize: "18px",
    },

    [theme.device.superSmall()]: {
      fontSize: "16px",
    },
  },
  active: {
    fontWeight: 400,
    // color: theme.colors.brown,
    opacity: 1,
  },
  subCategories: {
    // display: "flex",
    // width: "70%",
    width: "auto",

    // justifyContent: "space-evenly",
    margin: "0 auto"
  },
  productItems: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: "20px",
    [theme.device.smallTablet()]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    // mobile
    [theme.device.mobile()]: {
      display: "flex",
      flexDirection: 'column',
    },
  }
}))