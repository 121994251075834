import {
  createAction,
  createAsyncThunk,
  createReducer,
  createSelector,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { castDraft } from "immer";
import categoriesService from "../services/categories";
import { Category, CCategory } from "../services/categories/types";
import { getPhrase } from "../utils/language";
import { getDefaultResourceState, Resource } from "./resource-state";
import { ApplicationState } from "./root-reducer";
import { TThunk, useAppSelector } from "./store";

type State = {
  categoriesData: Resource<Category>;
  activeCategory: number | null;
  activeSubCategory: number | null;
  activeSubSubCategory: number | null;
};

const initialState: State = {
  categoriesData: getDefaultResourceState(),
  activeCategory: null,
  activeSubCategory: null,
  activeSubSubCategory: null,
};

const fetchCategories: TThunk<Category[]> = createAsyncThunk(
  "categories/fetch",
  async () => await categoriesService.getCategories(),
  {
    condition: (_, { getState }) => {
      const state = getState();
      return !state.categories.categoriesData.loaded;
    },
  }
);

const setActiveCategory = createAction(
  "categories/active-category",
  (id: number | null | undefined | CCategory) => ({
    payload: id,
  })
);

const setActiveSubCategory = createAction(
  "categories/active-subcategory",
  (id: number | null | undefined | CCategory) => ({
    payload: id,
  })
);

const setActiveSubSubCategory = createAction(
  "categories/active-subsubcategory",
  (id: number | null) => ({
    payload: id,
  })
);

const reducer = createReducer(initialState, {
  [fetchCategories.pending.type]: state => {
    state.categoriesData.loading = true;
  },
  [fetchCategories.fulfilled.type]: (state, action: PayloadAction<Category[]>) => {
    state.categoriesData.data = castDraft(action.payload);
    state.categoriesData.loading = false;
    state.categoriesData.loaded = true;
  },
  [fetchCategories.rejected.type]: (
    state,
    action: PayloadAction<null, string, unknown, SerializedError>
  ) => {
    state.categoriesData.error = action.error.message || "General Error";
    state.categoriesData.loading = false;
  },
  [setActiveCategory.type]: (state, action: PayloadAction<number>) => {
    state.activeCategory = action.payload;
  },
  [setActiveSubCategory.type]: (state, action: PayloadAction<number>) => {
    state.activeSubCategory = action.payload;
  },
  [setActiveSubSubCategory.type]: (state, action: PayloadAction<number>) => {
    state.activeSubSubCategory = action.payload;
  },
});

// Selectors
const categoriesPageSelector = () =>
  createSelector(
    (state: ApplicationState) => state.siteConfig.categories.map(c => c.category),
    categories => {
      categories = [...categories].reverse();
      return categories;
    }
  );

const activeCategorySelector = () =>
  createSelector(
    (state: ApplicationState) => state.categories,
    categories =>
      categories.categoriesData.data.find(category =>
        !!categories.activeSubSubCategory
          ? category.id === categories.activeSubSubCategory
          : category.id === categories.activeCategory
      )!
  );

const selectors = {
  categoriesPageSelector,
  activeCategorySelector,
};

const actions = {
  fetchCategories,
  setActiveCategory,
  setActiveSubCategory,
  setActiveSubSubCategory,
};

const categoriesStore = {
  actions,
  reducer,
  selectors,
  initialState,
};

export default categoriesStore;
export type { State };
