import { config } from "../../config";
import { TMediaData } from "../site-config-service/types";
import { cmsClient } from "../strapi/strapi-service";

type ValidateProps = {
  value: string;
  setValueEmpty?: (value: React.SetStateAction<boolean | null>) => void;
};

const isNullOrEmpty = (value: any) => {
  if (!value || value === "") {
    return true;
  }

  return false;
};

type UploadResumeProps = {
  applicationId: number;
  resume: File;
};

export type CreateJobApplicationProps = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  linkedIn: string;
  resume: File;
  note: string;
  hearAboutUs: string;
};
interface JobApplication extends CreateJobApplicationProps {
  id: number;
}

const validate = (props: ValidateProps): boolean => {
  if (isNullOrEmpty(props.value)) {
    !!props.setValueEmpty && props.setValueEmpty(true);
    return false;
  }

  !!props.setValueEmpty && props.setValueEmpty(false);
  return true;
};

const createJobApplication = async (
  props: CreateJobApplicationProps
): Promise<JobApplication | null> => {
  const requestData = {
    ...props,
    resume: null,
  };

  try {
    // Job Application Creation
    const { data }: { data: JobApplication } = await cmsClient.post(
      config.endpoints.jobApplications,
      requestData
    );
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const uploadResume = async (props: UploadResumeProps): Promise<TMediaData | null> => {
  const resumeFormData = new FormData();
  resumeFormData.append("ref", "job-application");
  resumeFormData.append("refId", props.applicationId.toString());
  resumeFormData.append("field", "resume");
  resumeFormData.append("files", props.resume);

  try {
    const { data }: { data: TMediaData } = await cmsClient.post(
      config.endpoints.upload,
      resumeFormData
    );
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export { validate, createJobApplication, uploadResume };
