import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles(
	theme => ({
		item: {
			display: "flex",
			flexDirection: "column",
			minHeight: 300,
			maxWidth: 300,
			justifyContent: "space-between",
			position: "relative",
			["@media (max-width: 1024px)"]: {
				"& > img": {
					width: "70%",
					margin: "0 auto",
					marginBottom: "20px",
					height: "auto"
				},
				maxWidth: "unset",
				justifyContent: "center",
			},
		},

		itemContentWrapper: {
			display: "flex",
			justifyContent: "center",
		},

		itemTransparentImg: {
			transition: "0.4s",
			opacity: 0.4
		},

		title: {
			color: "#155AAB",
			textAlign: 'center'
		},

		itemText: {
			alignSelf: "center",
			position: "absolute",
			color: "#155AAB",
			bottom: 70,
			width: "70%",
			textAlign: "center",
			transition: "0.4s",
			opacity: 0,
			fontWeight: 700,
			direction: 'rtl',

			["@media (max-width: 640px)"]: {
				width: '50%',
				bottom: 74,
				fontSize: "14px"
			},
		},

		itemTextActive: {
			opacity: 1
		}

	})
)

type TProps = {
	title: string,
	img: string,
	text: string
}

const Item = ({ title, img, text }: TProps) => {

	const classes = useStyles();

	const [textIsVisible, setTextIsVisible] = useState<boolean>(false);

	const showText = () => {
		setTextIsVisible(true);
	};

	const hideText = () => {
		setTextIsVisible(false)
	}

	return (
		<div className={classes.item}>
			<h2 className={classes.title}>{title}</h2>
			<div className={classes.itemContentWrapper} onMouseOver={showText} onMouseOut={hideText}>
				<img className={clsx(textIsVisible && classes.itemTransparentImg)} src={img} alt="" />
				<div className={clsx(classes.itemText, textIsVisible && classes.itemTextActive)}>
					{text}
				</div>
			</div>
		</div>
	)
};

export default Item;
