import Cookies from "js-cookie";
import {
  createAsyncThunk,
  SerializedError,
  createSelector,
  createReducer,
  PayloadAction,
  createAction,
} from "@reduxjs/toolkit";

import { CartItem, UserErrors } from "../services/users/types";
import { ErrorMessagePayload } from "../utils/formatError";
import createCmsClient from "../services/common/cmsClient";
import { ApprovedSeller } from "./approved-sellers-store";
import { Product } from "../services/products/types";
import { ApplicationState } from "./root-reducer";
import strapiService from "../services/strapi";
import { Language } from "./languages-store";
import { config } from "../config";
import { TThunk } from "./store";
import {
  UpdateUserInfo,
  ResetPassword,
  ProviderSign,
  LoggedUser,
  Favorites,
  User,
} from "../services/users/types";

type State = {
  data: LoggedUser | null;
  providerSignin: boolean;
  subscribed: boolean;
  updated: boolean;
  loading: boolean;
  loaded: boolean;
  errors: UserErrors;
};

const initialState: State = {
  data: null,
  providerSignin: false,
  subscribed: false,
  updated: false,
  loading: false,
  loaded: false,
  errors: {
    cart: "",
    general: "",
    password: "",
    subscription: "",
    authentication: "",
  },
};

const cmsClient = createCmsClient();

const signUser: TThunk<User, User> = createAsyncThunk("user/sign", async (data: User) => {
  const res = await strapiService.signUser(data);
  if (res.message) return res as { message: string };
  return res;
});

const fetchUser: TThunk<LoggedUser | undefined> = createAsyncThunk(
  "user/fetched-by-id",
  async () => {
    if (Cookies.get("jwt")) return await strapiService.fetchUser();
  }
);

const signUserWithProvider: TThunk<User, ProviderSign> = createAsyncThunk(
  "user/sign-with-provider",
  async (authData: ProviderSign) => await strapiService.signUserWithProvider(authData)
);

const clearServerSignErrors = createAction("user/clear-server-sign-errors");

const toggleFavoriteCatalogItem: TThunk<
  LoggedUser,
  { userId: string; favoriteCatalogItems: Favorites[] }
> = createAsyncThunk("user/toggle-favorite-catalog-item", async args => {
  return await strapiService.toggleFavoriteCatalogItem(args.userId, args.favoriteCatalogItems);
});

const resetPassword: TThunk<
  LoggedUser | ErrorMessagePayload | undefined,
  ResetPassword
> = createAsyncThunk("user/reset-password", async (pwds: ResetPassword) => {
  try {
    const res = await strapiService.resetPassword(pwds);

    if (res?.errorMessage) {
      throw Error(res?.errorMessage);
    }

    return res;
  } catch (error) {
    throw new Error(error.message);
  }
});

const updateUserInfo: TThunk<LoggedUser | undefined, UpdateUserInfo> = createAsyncThunk(
  "user/update",
  async userData => {
    try {
      return await strapiService.updateUserInfo(userData);
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

const resetUserUpdateFlag = createAction("user/update-flag-reset");

const addSubscriptionEmail: TThunk<void, { email: string; lang: Language }> = createAsyncThunk(
  "user/add-subscription-email",
  async ({ email, lang }: { email: string; lang: Language }) => {
    try {
      // TODO: Check if you have to put return statement
      await strapiService.addSubscriptionEmail({ email, lang });
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

const updateUserCart: TThunk<
  LoggedUser | undefined,
  { userId: number; cartItems: CartItem[] }
> = createAsyncThunk(
  "cart/update-products",
  async ({ userId, cartItems }: { userId: number; cartItems: CartItem[] }) => {
    try {
      return await strapiService.updateUserCart({ userId, cartItems });
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

const reducer = createReducer(initialState, {
  // SIGNING USER \\
  [signUser.pending.type]: state => {
    state.loading = true;
    state.loaded = false;
  },
  [signUser.fulfilled.type]: (state, action: PayloadAction<LoggedUser>) => {
    state.data = action.payload;
    state.loading = false;
    state.loaded = true;
    state.errors.authentication = "";
  },
  [signUser.rejected.type]: (
    state,
    action: PayloadAction<null, string, unknown, SerializedError>
  ) => {
    state.errors.authentication = action.error.message || "General Error";
    state.loading = false;
    state.loaded = true;
  },
  [signUserWithProvider.pending.type]: state => {
    state.loading = true;
    state.loaded = false;
  },
  [signUserWithProvider.fulfilled.type]: (state, action: PayloadAction<LoggedUser>) => {
    state.data = action.payload;
    state.providerSignin = true;
    state.loading = false;
    state.loaded = true;
    state.errors.authentication = "";
  },
  [signUserWithProvider.rejected.type]: (
    state,
    action: PayloadAction<null, string, unknown, SerializedError>
  ) => {
    state.errors.authentication = action.error.message || "General Error";
    state.loading = false;
    state.loaded = true;
  },

  // CLEAR SERVER SIGN ERRORS \\
  [clearServerSignErrors.toString()]: state => {
    delete state.data?.errorMessage;
    state.errors.authentication = "";
  },

  // FETCH USER \\
  [fetchUser.pending.type]: state => {
    state.loading = true;
    state.loaded = false;
  },
  [fetchUser.fulfilled.type]: (state, action: PayloadAction<LoggedUser>) => {
    state.data = action.payload;
    state.loading = false;
    state.loaded = true;
    state.errors.general = "";
  },
  [fetchUser.rejected.type]: (state, action) => {
    state.errors.general = action.error.response || "General Error";
    state.loading = false;
    state.loaded = true;
  },

  // ADDING AND REMOVING FAVORITE ITEMS \\
  [toggleFavoriteCatalogItem.pending.type]: state => {
    state.loading = true;
    state.loaded = false;
  },
  [toggleFavoriteCatalogItem.fulfilled.type]: (state, action: PayloadAction<LoggedUser>) => {
    state.data = {
      ...state.data,
      favoriteCatalogItems: action.payload.favoriteCatalogItems,
    } as LoggedUser;
    state.loading = false;
    state.loaded = true;
    state.errors.general = "";
  },
  [toggleFavoriteCatalogItem.rejected.type]: (
    state,
    action: PayloadAction<null, string, unknown, SerializedError>
  ) => {
    state.errors.general = action.error.message || "General Error";
    state.loading = false;
    state.loaded = true;
  },

  // PASSWORD RESET \\
  [resetPassword.pending.type]: state => {
    state.loading = true;
    state.loaded = false;
  },
  [resetPassword.fulfilled.type]: (state, action: PayloadAction<LoggedUser>) => {
    state.data = action.payload;
    state.loading = false;
    state.loaded = true;
    state.errors.password = "";
    // This reload is needed to prevent error if user decide to change password again immediately after the first change
    window.location.reload();
  },
  [resetPassword.rejected.type]: (state, action) => {
    state.errors.password = action.error.message || "General error";
    state.loading = false;
    state.loaded = true;
  },

  // UPDATE USER INFO \\
  [updateUserInfo.pending.type]: state => {
    state.loading = true;
    state.loaded = false;
  },
  [updateUserInfo.fulfilled.type]: (state, action: PayloadAction<any>) => {
    state.data = action.payload;
    state.updated = true;
    state.loading = false;
    state.loaded = true;
    state.errors.authentication = "";
  },
  [updateUserInfo.rejected.type]: (
    state,
    action: PayloadAction<null, string, unknown, SerializedError>
  ) => {
    state.errors.authentication = action.error.message || "General Error";
    state.loading = false;
    state.loaded = true;
  },
  [resetUserUpdateFlag.toString()]: state => {
    state.updated = false;
  },

  // EMAIL SUBSCRIPTION \\
  [addSubscriptionEmail.pending.type]: state => {
    state.errors.subscription = "";
    state.loading = true;
    state.loaded = false;
  },
  [addSubscriptionEmail.fulfilled.type]: state => {
    state.errors.subscription = "";
    state.subscribed = true;
    state.loading = true;
    state.loaded = false;
  },
  [addSubscriptionEmail.rejected.type]: (
    state,
    action: PayloadAction<null, string, unknown, SerializedError>
  ) => {
    state.errors.subscription = action.error.message || "General Error";
    state.subscribed = false;
    state.loading = true;
    state.loaded = false;
  },

  // UPDATE CART \\
  [updateUserCart.pending.type]: state => {
    state.errors.cart = "";
    state.loading = true;
    state.loaded = false;
  },
  [updateUserCart.fulfilled.type]: (state, action) => {
    const cartItems = action.payload.cartItems;
    state.data = { ...action.payload, cartItems };
    state.loading = false;
    state.loaded = true;
  },
  [updateUserCart.rejected.type]: (state, action) => {
    state.errors.cart = action.error.message || "General Error";
    state.loading = false;
    state.loaded = true;
  },
});

type ProductsAndFavorites = {
  favoriteCatalogItems: Favorites[];
  products: Product[];
};

const productsAndFavorites = ({ user, products }: ApplicationState): ProductsAndFavorites => ({
  favoriteCatalogItems: user.data?.favoriteCatalogItems || [],
  products: products.productsData.data,
});

const favoriteProductsSelector = () =>
  createSelector(productsAndFavorites, (productsAndFavorites): Product[] =>
    productsAndFavorites.products.filter(
      product =>
        !!productsAndFavorites.favoriteCatalogItems.find(
          fP => product.isPublished && fP.productId === product.id
        )
    )
  );

const selectors = { favoriteProductsSelector };

const actions = {
  toggleFavoriteCatalogItem,
  clearServerSignErrors,
  signUserWithProvider,
  addSubscriptionEmail,
  resetUserUpdateFlag,
  updateUserCart,
  updateUserInfo,
  resetPassword,
  fetchUser,
  signUser,
};

const usersStore = {
  actions,
  reducer,
  selectors,
};

export default usersStore;

export type { State };
