import axios from "axios";

import React, { useState } from "react";

import { json2xml } from "xml-js";
import { google } from "googleapis";
import { log } from "console";
const fs = require("fs");
const convert = require("xml-js");
const jsonexport = require("jsonexport");
const FileSaver = require("file-saver");

const TestPage = () => {
  const [isReady, setIsReady] = useState("test");

  const updateJoieImporterDealStatus = async () => {
    try {
      const host = "https://joie.bambinok.com/";
      const checkDiscountStatus = (startDiscountDate: any, endDiscountDate: any) => {
        let [currYear, currMonth, currDay] = new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, "-")
          .split("-");
        const currDate = currYear + "-" + currMonth + "-" + currDay;
        if (startDiscountDate <= currDate && currDate <= endDiscountDate) {
          return true;
        } else {
          return false;
        }
      };

      const createUpdate = async (catalogNumber: any, body: any) => {
        await axios.put(`${host}products/${catalogNumber}`, body);
      };

      const products = await axios.get(
        "https://joie.bambinok.com/products?_limit=-1"
      );

      for (let i = 0; i < products.data.length; i++) {
        let currProduct = products.data[i];

        const discountStatus = await checkDiscountStatus(
          currProduct.startDiscountDate,
          currProduct.discountDate
        );
        if (discountStatus) {
          if (!currProduct.importerDeal) {
            const updateObj = { importerDeal: true };

            await createUpdate(currProduct.catalogNumber, updateObj);
          }
        } else {
          if (currProduct.importerDeal) {
            const updateObj = { importerDeal: false };

            await createUpdate(currProduct.catalogNumber, updateObj);
          }
        }
      }
    } catch (error) {
      console.log("Error occured ===", error);
    }
  };

  const sendShippingNumer = async () => {
    let orders: any = [];
    await axios
      .get("https://joie.bambinok.com/allOrders")
      .then(response => (orders = response.data));
    console.log("orders", orders);
    orders.forEach((o: any) => {
      if (o.orderStatus == "Processing") {
        console.log(o, "Processing");
        if (o.shippingNumber && o.shippingNumber != 0) {
          console.log(o, "trackingNumber");
          // trackNumberEmail(o.user.email, o.user.firstName, o.shippingNumber);
          updateOrder(o);
        }
      } else {
        console.log(o);
      }
    });
  };

  const updateOrder = async (order: any) => {
    const host = "https://joie.bambinok.com/";
    const orderStatus = "Done";

    const body = {
      ...order,
      orderStatus: orderStatus,
    };

    await axios.put(`${host}orders/${order.id}`, body);
  };

  const productsSync = async () => {
    const host = "http://localhost:1337/";

    const createProduct = async (body: any, requestOptions: any) => {
      await axios.post(`${host}products`, body, requestOptions);
    };

    const createUpdate = async (catalogNumber: any, body: any, requestOptions: any) => {
      await axios.put(`${host}products/${catalogNumber}`, body, requestOptions);
    };

    let requestOptions = {
      "Content-type": "application/json",
    };
    axios.get("https://bambino-3.ew.r.appspot.com/allProducts?_limit=-1").then(async response => {
      let allProducts = response.data;
      for (let i = 0; i < response.data.length; i++) {
        await axios
          .get(`${host}products/${allProducts[i].catalogNumber}`)
          .then(async data => {
            delete data.data.id;
            if (
              !allProducts[i].brand ||
              (allProducts[i].brand && !allProducts[i].brand.name.toLowerCase().includes("joie"))
            ) {
            } else {
              const setParentColor = async (catalogNumber: number) => {
                const getParentColorCatalogNumber = await fetch(
                  `http://localhost:1337/products/${catalogNumber}`
                );
                const data = await getParentColorCatalogNumber.json();

                return data.id;
              };

              const setRelatedProducts = async (product: any) => {
                let outputArr = [];

                for (let index = 0; index < product.length; index++) {
                  const element = product[index];
                  const getRelatedProductsCatalogNumber = await fetch(
                    `http://localhost:1337/products/${element.product.catalogNumber}`
                  );
                  const data = await getRelatedProductsCatalogNumber.json();
                  console.log("Related Bau", data);

                  outputArr.push({
                    product: data.id,
                  });
                }

                return outputArr;
              };

              const setSimilarProducts = async (product: any) => {
                let outputArr = [];

                for (let index = 0; index < product.length; index++) {
                  const element = product[index];
                  const getSimilarProductsCatalogNumber = await fetch(
                    `http://localhost:1337/products/${element.product.catalogNumber}`
                  );
                  const data = await getSimilarProductsCatalogNumber.json();
                  console.log("Similar Bau", data);

                  outputArr.push({
                    product: data.id,
                  });
                }

                return outputArr;
              };

              let parentColor = allProducts[i].parentColor
                ? await setParentColor(allProducts[i].parentColor.catalogNumber)
                : null;
              let relatedProducts = await setRelatedProducts(allProducts[i].relatedProducts);
              let similarProducts = await setSimilarProducts(allProducts[i].similarProducts);

              let updateObj = {
                parentColor: parentColor,
                relatedProducts: relatedProducts,
                similarProducts: similarProducts,
              };

              console.log("CreateUpdate", allProducts[i].catalogNumber, updateObj);

              createUpdate(allProducts[i].catalogNumber, updateObj, requestOptions);
            }
          })
          .catch(err => {
            console.log("create", err);
          });

        // console.log(isExist);
        // if(isExist) {
        //   //TODO UPDATE
        //   createUpdate(allProducts[i].catalogNumber, allProducts[i+1], requestOptions)
        // } else {
        //   //TODO CREATE
        //   createArray.push(allProducts[i])
        //   axios.post(`${host}products`, allProducts[i], requestOptions)
        // }
        setIsReady(Math.ceil((i / allProducts.length) * 100) + "%");
        // }
      }
      setIsReady("Success !!!");
    });
  };

  const productsCSV = () => {
    console.log("V CSV-to sum !");
    axios
      .get("https://bambino-3.ew.r.appspot.com/products?_limit=-1")
      .then(data => {
        console.log(data.data);
        let json = data.data;
        let fields = Object.keys(json[0]);
        let replacer = function (key: any, value: any) {
          return value === null ? "" : value;
        };
        let csv = json.map(function (row: any) {
          return fields
            .map(function (fieldName) {
              return JSON.stringify(row[fieldName], replacer);
            })
            .join("|");
        });
        csv.unshift(fields.join("|")); // add header column
        csv = csv.join("\r\n");
        console.log(csv);
        let blob = new Blob([csv], { type: "text/plain;charset=utf-8" });
        FileSaver.saveAs(blob, "bambino_products_csv.txt");
      })
      .catch(err => {
        console.error(err);
      });
  };

  const updateCategories = async () => {
    const host = "https://joie-315821.ew.r.appspot.com/";

    let requestOptions = {
      "Content-type": "application/json",
    };

    const createUpdate = async (catId: any, body: any, requestOptions: any) => {
      return await axios.put(`${host}categories/${catId}`, body, requestOptions);
    };
    await axios
      .get("https://bambino-syncs-dot-joie-315821.ew.r.appspot.com/categories-sync")
      .then(async data => {
        console.log("OK !");
        axios.get("https://bambino-3.ew.r.appspot.com/categories?_limit=-1").then(data => {
          let categories = data.data;

          let filteredCategories = categories.filter((c: any) => c.childrenCategories.length > 0);

          filteredCategories.map((cat: any) => {
            let outputArr: any = [];
            cat.childrenCategories.map((child: any) => {
              console.log(child);
              outputArr.push({ childCategory: child?.childCategory?.id, name: child?.name });
            });

            let backgroundImg = cat?.backgroundImg?.id ? cat?.backgroundImg?.id : null;

            console.log("category", outputArr);

            createUpdate(
              cat.id,
              { childrenCategories: outputArr, backgroundImg: backgroundImg },
              requestOptions
            )
              .then(data => {})
              .catch(err => {
                console.log("fetch err", err.message);
              });
            return;
          });
        });
      })
      .catch(err => {
        console.log("error", err);
      });
  };

  const updateWarranties = async () => {
    const host = "http://localhost:1337/";
    let requestOptions = {
      "Content-type": "application/json",
    };

    const createUpdate = async (catId: any, body: any, requestOptions: any) => {
      return await axios.put(`${host}warranties/${catId}`, body, requestOptions);
    };

    await axios
      .get("http://localhost:8080/warranties-sync")
      .then(async data => {
        console.log("OK !");
        await axios
          .get("https://bambino-3.ew.r.appspot.com/warranties")
          .then(data => {
            data.data.map((el: any) => {
              let elementId = el.id;
              delete el.id;
              let output = {
                ...el,
                file: null,
              };
              if (el.file) {
                output.file = el.file.id;
              }

              console.log("Id===", elementId);
              console.log("Body===", output);
              createUpdate(elementId, output, requestOptions);
            });
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        console.log("NOT OK !");

        console.log(err);
      });
  };

  const brandsSync = async () => {
    const host = "https://joie-315821.ew.r.appspot.com/";
    let requestOptions = {
      "Content-type": "application/json",
    };

    const createUpdate = async (catId: any, body: any, requestOptions: any) => {
      return await axios.put(`${host}brands/${catId}`, body, requestOptions);
    };

    await axios
      .get("https://bambino-syncs-dot-joie-315821.ew.r.appspot.com/brands-sync")
      .then(async data => {
        console.log("OK !");
        await axios
          .get("https://bambino-48082.ew.r.appspot.com/brands")
          .then(data => {
            data.data.map((el: any) => {
              let elementId = el.id;
              delete el.id;
              let output = {
                ...el,
                image: null,
              };
              if (el.image) {
                output.image = el.image.id;
              }

              console.log("Id===", elementId);
              console.log("Body===", output);
              createUpdate(elementId, output, requestOptions);
            });
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        console.log("NOT OK !");

        console.log(err);
      });
  };

  const uploadsSync = async () => {
    await axios
      .get("http://localhost:8080/uploads-sync")
      .then(async data => {
        console.log("Success");
      })
      .catch(err => {
        console.log("Error", err);
      });
  };
  function removeHTML(text: string) {
    let tmp = document.createElement("p");
    tmp.innerHTML = text;

    return tmp.textContent || "";
  }

  const XML = async () => {
    let joieProducts: any = [];
    await axios.get("https://bambino-3.ew.r.appspot.com/allProducts?_limit=-1").then(response => {
      let products = response.data;
      console.log(products);
      for (let i = 0; i < products.length; i++) {
        if (products[i].brand?.name.toLowerCase().includes("joie")) {
          let {
            id,
            brand,
            title,
            description,
            link,
            image_link,
            availability,
            price,
            condition,
            shipping,
          } = {
            id: products[i].id,
            brand: products[i].brand.name,
            title: products[i]?.name || null,
            description: removeHTML(products[i]?.description || null),
            link: `https://joiebaby.co.il/products/product/${products[i].catalogNumber}`,
            image_link: products[i].pictures[0]?.url || null,
            availability: "in stock",
            price: products[i]?.storePrice || null,
            condition: "new",
            shipping: { country: null, price: null, service: null },
          };
          joieProducts.push({
            id,
            brand,
            title,
            description,
            link,
            image_link,
            availability,
            price,
            condition,
            shipping,
          });
        }
      }
    });

    const XMLproducts = await convert.json2xml(
      { item: joieProducts },
      { compact: true, spaces: 4 }
    );
    let blob = new Blob([`<rss version="2.0"><channel>${XMLproducts}</channel></rss>`], {
      type: "text/xml;charset=utf-8",
    });
    FileSaver.saveAs(blob, "joie-products-facebook");
  };

  return (
    <div style={{ backgroundColor: "red", marginTop: 100, fontSize: 20 }}>
      {/* <button onClick={sendShippingNumer}>Click me for handleTest</button> */}
      {/* <button onClick={productsSync}>Click me for ProductsSync</button>
      <br />
      <button onClick={brandsSync}>Click me for Brands Update</button>
      {/* <button onClick={productsCSV}>Click me for ProductsCSV</button> */}
      {/* <br />
      <button onClick={updateCategories}>Click me for Categories Update</button>
      <br />
      <button onClick={updateWarranties}>Click me for Warranties Update</button>
      <br /> */}
      {/* <button onClick={uploadsSync}>Click me for Images Sync</button> */}
      {/* <button onClick={XML}>Click me for XML</button> */}
    </div>
  );
};

export default TestPage;
