import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useAppSelector } from "../../store/store";
import clsx from "clsx";

function SpinxlAutoplay(){
    const classes = useStyles()

    const products = useAppSelector(({ products }) => products.productsData.data)
    // Until product data loads display placeholder
    if(!products.length) return <div className={clsx(classes.iframe, classes.placeholder)}></div>

    const spinXL = products.find(p => {
      const lowerCaseName = p.name.toLocaleLowerCase()
      const isSpinXL = lowerCaseName.includes("spin") && lowerCaseName.includes("xl")
      return isSpinXL
    }) ?? products[0]
    const ytVideoId = new URL(spinXL.video).searchParams.get("v")

    return (
      <iframe 
          className={classes.iframe} 
          src={`https://www.youtube.com/embed/${ytVideoId}?autoplay=1&mute=1`} 
          allow="autoplay">
      </iframe>
    )
}

const useStyles = makeStyles(theme => ({
  iframe: {
    width: "600px",
    height: "400px",
    [theme.device.mobile()]: {
      width: "100%",
      height: "200px"
    },
  },
  placeholder: {
    backgroundColor: theme.colors.gray
  }
}));

export default SpinxlAutoplay