import React, { useEffect, useState } from 'react';

import { useAppSelector } from '../../store/store';

import { getSeatsByFamilyAndCars } from "../../services/fit-guide-service";


const DisplayAllProducts = () => {

    const { family,cars } = useAppSelector(state => state.fitGuide);

    const [familyRes, setFamilyRes] = useState('');

    const {

        products

      } = useAppSelector(state => ({

        products: state.products.productsData.data,

      }));

    useEffect(() => {

        getSeatsByFamilyAndCars(family, cars)

        .then(data => {

            if(data) {

                setFamilyRes(data[0].family)

            }

        })

    })

    return (

        <div>


            <h1>{familyRes ? familyRes : "Smeni kolata"}</h1>

        </div>

    )




}



export default DisplayAllProducts;