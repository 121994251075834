import { createAsyncThunk, createReducer, createSelector, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { castDraft } from "immer";
import brandsService from "../services/brands";
import { Brand } from "../services/brands/types";
import { getDefaultResourceState, Resource } from "./resource-state";
import { ApplicationState } from "./root-reducer";
import { TThunk } from "./store";

type State = Resource<Brand>;

const initialState: State = getDefaultResourceState();

const fetchBrands: TThunk<Brand[]> = createAsyncThunk("brand/fetch", async () => await brandsService.getBrands(), {
  condition: (_, { getState }) => {
    const state = getState();
    return !state.brands.loaded;
  },
});

const reducer = createReducer(initialState, {
  [fetchBrands.pending.type]: state => {
    state.loading = true;
  },
  [fetchBrands.fulfilled.type]: (state, action: PayloadAction<Brand[]>) => {
    state.data = castDraft(action.payload);
    state.loading = false;
    state.loaded = true;
  },
  [fetchBrands.rejected.type]: (state, action: PayloadAction<null, string, unknown, SerializedError>) => {
    state.error = action.error.message || "General Error";
    state.loading = false;
  },
});

// Selectors

// Returns all brands
const brandsSelector = () =>
  createSelector(
    (state: ApplicationState) => state.brands.data,
    (brands: Brand[]) => brands
  );

const selectors = {
  brandsSelector,
};

const actions = {
  fetchBrands,
};

const brandsStore = {
  actions,
  reducer,
  selectors,
  initialState,
};

export default brandsStore;
export type { State };
