import {
  createAction,
  createAsyncThunk,
  createSelector,
  createReducer,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { config } from "../config";
import { cmsClient } from "../services/strapi/strapi-service";
import { NewPhrases } from "../utils/language";
import { ApplicationState } from "./root-reducer";
import { useAppSelector } from "./store";

type Language = "en" | "he" | "ar";

type State = {
  language: Language;
  phrases: NewPhrases[] | undefined;
  activePhrases: NewPhrases | undefined;
  loading: boolean;
  loaded: boolean;
  errors: string;
};

const initialState: State = {
  language: "he",
  phrases: undefined,
  activePhrases: undefined,
  loading: false,
  loaded: false,
  errors: "",
};

const changeLanguage = createAction("language/change-language", (language: Language) => ({
  payload: language,
}));

const fetchPhrases = createAsyncThunk("phrases/fetch", async () => {
  try {
    const { data } = await cmsClient.get(config.endpoints.getLanguages);
    return data;
  } catch (e: any) {
    throw new Error(e.message);
  }
});

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(changeLanguage, (state, action) => {
      state.language = action.payload;
    })
    .addCase(fetchPhrases.pending.type, state => {
      state.loading = true;
    })
    .addCase(fetchPhrases.fulfilled.type, (state, action: PayloadAction<Array<NewPhrases>>) => {
      state.loading = false;
      state.loaded = true;
      state.phrases = action.payload;
    })
    .addCase(
      fetchPhrases.rejected.type,
      (state, action: PayloadAction<null, string, unknown, SerializedError>) => {
        state.errors = action.error.message || "General Error";
      }
    );
});

const getActivePhrases = () =>
  createSelector(
    (state: ApplicationState) => state.languages.activePhrases,
    (phrases: NewPhrases | undefined) => {
      const activeLanguage = useAppSelector(state => state.languages.language);
      phrases = useAppSelector(state =>
        state.languages.phrases?.find(p => p.languageCode === activeLanguage)
      );

      return phrases;
    }
  );

const selectors = {
  getActivePhrases,
};

const actions = {
  changeLanguage,
  fetchPhrases,
};

const languagesStore = {
  reducer,
  actions,
  selectors,
  initialState,
};

export default languagesStore;

export type { Language, State };
