import { makeStyles, useTheme } from "@material-ui/styles";
import clsx from "clsx";
import { push } from "connected-react-router";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { config } from "../../config";
import type { Product } from "../../services/products/types";
import { Favorites, LoggedUser } from "../../services/users/types";
import languagesStore from "../../store/languages-store";
import { useAppDispatch, useAppSelector } from "../../store/store";
import displayCurrencySign from "../../utils/currencies-signs";
import { countProductDiscountPrice, countProductPrice, getColors, getProductUrl } from "../../utils/helper";
import Button from "../common/button";
import Flag from "../common/flag";

type TProps = {
  product: Product;
  user: LoggedUser;
  isImporterDeals: boolean;
  isFavorite: boolean;
  toggleFavorite: (p: Favorites) => void;
};

const ProductItemList = ({
  product,
  user,
  isImporterDeals,
  isFavorite,
  toggleFavorite,
}: TProps): JSX.Element => {
  const theme = useTheme();

  const classes = useStyles();


  const productName = `${product.name} ${product.title}`;

  const title: string = productName;

  const dispatch = useAppDispatch();
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const lang = useAppSelector(state => state.languages.language);

  const { activeCategory, activeSubCategory, activeSubSubCategory, products } = useAppSelector(
    state => {
      const { activeCategory, activeSubCategory, activeSubSubCategory } = state.categories;
      const products = state.products.productsData.data;

      return {
        activeCategory,
        activeSubCategory,
        activeSubSubCategory,
        products,
      };
    }
  );

  const showPrice = true;

  const colors = getColors(products, product);

  const onColorItemClick = (color: Product) => () => {
    const url = `${isImporterDeals ? config.routes.importerDealsProduct : config.routes.product}/${color.catalogNumber
      }`;
    dispatch(
      push(getProductUrl(url, activeCategory, activeSubCategory, activeSubSubCategory), true)
    );
  };

  const colorItems = colors
    ?.filter((_, i) => i < 3)
    .map(cP => {
      return (
        <img
          key={cP.id}
          className={classes.smallImg}
          src={cP?.mainPicture?.url!}
          onClick={onColorItemClick(cP!)}
          alt={`product color ${cP?.color}`}
        />
      );
    });

  const goToSpProduct = () => {
    const url = `${isImporterDeals ? config.routes.importerDealsProduct : config.routes.product}/${product.catalogNumber}`;
    dispatch(push(url));
  };

  const updateFavorite = () => {
    toggleFavorite({ productId: product.id, name: product.name });
  };
  // let {price} = countProductPrice(user?.approvedAgent!, product!, user?.discount!);
  const { price } = user?.approvedAgent ? countProductPrice(user?.approvedAgent!, product!, user?.discount!) : countProductDiscountPrice(product);
  const signatureMode = useAppSelector(state => state.signature.signatureMode);

  return (
    <li className={classes.root}>
      <div className={classes.buy}>
        <div className={clsx((signatureMode ? classes.priceBlack : classes.price), showPrice && classes.active)} onClick={goToSpProduct}>
          {displayCurrencySign(lang)}{" "}
          {price?.toFixed(2)}
        </div>

        <Button
          width="100%"
          height={40}
          borderRadius={10}
          className={classes.button}
          text={activePhrases && activePhrases["additional_info"]}
          fill={(signatureMode ? theme.colors.brown : theme.colors.blue)}
          fillHover={theme.colors.text}
          outline={(signatureMode ? theme.colors.brown : theme.colors.blue)}
          textColor={theme.colors.text}
          textColorHover={(signatureMode ? theme.colors.brown : theme.colors.blue)}
          action={goToSpProduct}
        />
      </div>

      <div className={classes.smallImgs}>{colorItems.length > 0 && colorItems}</div>

      <div className={classes.info}>
        {/* <img className={classes.brand} src={product.brand?.image?.url!} alt="brand" /> */}

        <div className={classes.title} onClick={goToSpProduct}>
          {title}
        </div>

        <div className={classes.id} onClick={goToSpProduct}>
          {product.catalogNumber} :{activePhrases && activePhrases["catalog_number"]}
        </div>
      </div>

      <div className={classes.outOfStockWrapper}>
        <div className={classes.largeImageWrapper}>
          <img
            className={classes.largeImg}
            src={product?.mainPicture?.url}
            alt="main"
            onClick={goToSpProduct}
          />
        </div>
        {product.awards ?
          product.awards?.slice(0, 3).map(a =>
            <img className={classes.award} src={a.image?.url} alt='awards' />)
          : null}

        {product?.quantity === 0 && (
          <div className={classes.outOfStock} onClick={goToSpProduct}>
            {activePhrases && activePhrases["out_of_stock"]}
          </div>
        )}
      </div>

      <Flag
        className={{ root: classes.flag, flagIcon: classes.flagIcon, heartIcon: classes.heartIcon }}
        isFavorite={isFavorite}
        toggleFavorite={updateFavorite}
      />
    </li>
  );
};

export default ProductItemList;

const useStyles = makeStyles(
  theme => ({
    root: {
      position: "relative",
      minHeight: 140,
      display: "grid",
      gridTemplateColumns: "repeat(5, 1fr) 0.3fr",
      alignItems: "center",
      boxSizing: "border-box",
      padding: "5px 20px",
      width: "100%",
      borderRadius: 15,
      [theme.device.mobile()]: {
        height: 80,
        padding: 0,
        gridColumnGap: 5,
      },
    },
    buy: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gridRowGap: 5,
    },
    price: {
      opacity: 0,
      visibility: "hidden",
      color: theme.colors.blue,
      fontWeight: 600,
      fontSize: 23,
      cursor: "pointer",

      [theme.device.mobile()]: {
        wordWrap: "break-word",
        fontSize: 18,
      },
    },
    priceBlack: {
      opacity: 0,
      visibility: "hidden",
      color: theme.colors.black,
      fontWeight: 600,
      fontSize: 23,
      cursor: "pointer",

      [theme.device.mobile()]: {
        wordWrap: "break-word",
        fontSize: 18,
      },
    },
    button: {
      transition: "0.3s",
      marginTop: "auto",
      [theme.device.mobile()]: {
        borderRadius: 25,
        fontSize: 10,
        width: 80,
        height: 20,
      },
    },
    award: {
      width: 50,
      height: 60,
      objectFit: "contain",
      [theme.device.mobile()]: {
        width: 40,
        height: 50,
      },
    },
    smallImgs: {
      margin: "0 auto",
      boxSizing: "border-box",
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      direction: "rtl",
      gridColumnGap: 4,
      [theme.device.desktop()]: {
        gridColumnGap: 10,
      },
    },
    smallImg: {
      width: 40,
      height: 40,
      objectFit: "contain",
      cursor: "pointer",
      [theme.device.mobile()]: {
        width: 20,
        height: 20,
      },
    },
    info: {
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gridColumn: "3/span 2",
      [theme.device.mobile()]: {
        boxSizing: "border-box",
        padding: "0 7px",
      },
    },
    brand: {
      fontSize: 13,
      height: 20,
      width: 55,
      objectFit: "contain",
    },
    title: {
      color: theme.colors.black,
      fontWeight: 700,
      fontSize: "1.5rem",
      cursor: "pointer",
      textAlign: "center",
      [theme.device.mobile()]: {
        fontSize: 16,
      },
    },
    id: {
      whiteSpace: "nowrap",
      color: theme.colors.grayText,
      fontSize: 16,
      cursor: "pointer",

      [theme.device.mobile()]: {
        fontSize: 12,
      },
    },

    largeImageWrapper: {
      height: 100,
      width: 100,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.device.mobile()]: {
        height: 50,
        width: 50,
      },
    },

    largeImg: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      cursor: "pointer",

      [theme.device.mobile()]: {
        width: 50,
      },
    },
    flag: {
      position: "relative",
      cursor: "default",
      alignSelf: "center",
      marginLeft: "auto",
      right: 0,
      [theme.device.mobile()]: {
        top: 0,
      },
    },
    flagIcon: {
      opacity: 0,
    },
    heartIcon: {
      height: 35,
      width: 35,
      cursor: "pointer",
      [theme.device.mobile()]: {
        height: 25,
        width: 25,
      },
    },
    active: {
      visibility: "visible",
      opacity: 1,
    },
    outOfStockWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
    },
    outOfStock: {
      cursor: "pointer",
      position: "absolute",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      textAlign: "center",
      borderRadius: 20,
      color: theme.colors.text,
      padding: 10,
    },
  }),
  { name: "product-item-list" }
);
