import signatureService from "../services/signature";
import {SignatureConfig} from "../services/signature/types";
import { createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit";
import {TThunk} from './store';

type State = SignatureConfig;

const initialState: State = {
    ImageOverFooter: null,
    WhyToChooseUsImages: [],
    VideoImage: null,
    SimplyTimeless: null,
    youtube_video_url: ''
}

const fetchSignatureConfig: TThunk<SignatureConfig> = createAsyncThunk(
    "signature-config/fetch",
    async () => await signatureService.getSignatureConfig()
);

const reducer = createReducer(initialState, {
    [fetchSignatureConfig.fulfilled.type]: (state, action: PayloadAction<SignatureConfig>) => {
        state = action.payload;
        return state;
    },
});

const actions = {
    fetchSignatureConfig
};

const signatureModeStore = {
    reducer,
    actions
};

export default signatureModeStore;

export type { State };