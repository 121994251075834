import React from "react"

type TProps = {
  strokeColor: string  
  size?: number
}

function MinusIcon({ strokeColor, size = 20 }: TProps){
  return (
    <svg height={size} width={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 12L18 12" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
  )
}

export default MinusIcon