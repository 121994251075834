import React, { useCallback, useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import clsx from "clsx";

import ProductItemGrid from "../products-view/product-item-grid";
import ProductItemList from "../products-view/product-item-list";
import CategoryPanel from "../category/category-panel";
import SortPopup from "../popup/sort-popup";
import Button from "../common/button";
import PuffLoader from "react-spinners/ClipLoader";
import UIsortingStore, { Layout } from "../../store/ui-sorting-store";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { Product } from "../../services/products/types";
import UIfiltersStore from "../../store/ui-filters-store";
import usersStore from "../../store/users-store";
import Filters from "../products-view/filters";

import { ReactComponent as GridIcon } from "../../static/icons/grid.svg";
import { ReactComponent as ListIcon } from "../../static/icons/list.svg";
import { Favorites } from "../../services/users/types";
import {
  checkImporterDeals,
  checkProductsPage,
  getColors,
  getMostSpecificCategoryId,
  removeHTML,
  userLoggedIn,
} from "../../utils/helper";
import {
  addFavouriteProductToStorage,
  productIsFavourite,
} from "../../services/products/products-service";
import languagesStore from "../../store/languages-store";
import { push } from "connected-react-router";
import { config } from "../../config";
import authFormStore from "../../store/auth-form-store";
import { Helmet } from "react-helmet";
import useViewport from "../../hooks/useViewport";
import { filterBySearch, filterByCategory, filterByAge, filterByPriceRange, filterByRating, filterByBrand, filterByIsoFix, filterByStandard, filterByFoldType, applyPagination } from "./products-page-filters";
import productsStore from "../../store/products-store";
import { Category } from "../../services/categories/types";
import { CategoryFreeText } from "../products-view/category-free-text";

const ProductsPage = () => {
  const PAGE_SIZE = 6;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  let [loadedItems, setLoadedItems] = useState(6);

  const isImporterDeals = checkImporterDeals( useAppSelector(state => state.router.location.pathname));
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const search = useAppSelector(state => state.router.location.search);
  const {
    categories,
    activeSubSubCategory,
    activeSubCategory,
    activeCategory,
    activeBrands,
    activeRating,
    activeLayout,
    activeSort,
    products,
    productsFullyLoaded,
    sorting,
    lang,
    user,
    age,
    priceRange,
    activeIsofix,
    activeFoldType,
    activeStandard,
    loaded,
    loading,
    pathname,
    location
  } = useAppSelector(state => ({
    activeCategory: state.categories.activeCategory,
    activeBrands: state.UIfilters.activeBrands,
    products: state.products.productsData.data,
    productsFullyLoaded: state.products.isFullyLoaded,
    loading: state.products.productsData.loading,
    loaded: state.products.productsData.loaded,
    activeSort: state.UIsorting.activeSort,
    activeRating: state.UIfilters.rating,
    activeLayout: state.UIsorting.layout,
    sorting: state.UIsorting.sorting,
    lang: state.languages.language,
    age: state.UIfilters.age,
    user: state.user.data,
    priceRange: state.UIfilters.price,
    activeIsofix: state.UIfilters.isofix,
    activeFoldType: state.UIfilters.foldType,
    activeStandard: state.UIfilters.standard,
    pathname: state.router.location.pathname,
    location: state.router.location,
    categories: state.categories.categoriesData.data,
    activeSubCategory: state.categories.activeSubCategory,
    activeSubSubCategory: state.categories.activeSubSubCategory,
  }));
  const isProductsPage = checkProductsPage(
    useAppSelector(state => state.router.location.pathname)
  );

  const [filters, setFilters] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(PAGE_SIZE);
  const { device } = useViewport();

  const favoriteCatalogItems: Favorites[] = user?.favoriteCatalogItems! || [];
  const [showSpinner, setShowSpinner] = useState<boolean>(true);
  const subCatIsActive = search.startsWith("?sub=");
  const subSubCatIsActive = search.startsWith("?sub_sub=");
  const searchIsActive = search.startsWith("?search=");
  const currentCategoryId = Number(getMostSpecificCategoryId(location));
  const currentCategory = categories.find(c => c.id === currentCategoryId)

  const colorsFilter = useCallback(
    (product: Product) =>
      getColors(products, product).filter(c => c.id !== product?.id).length > 0
        ? product.isMainColor
        : product,
    [products]
  );

  const colorsMapping = useCallback(
    (p: Product) => {
      const productOutOfStock = p.quantity <= 0;
      if (p.isMainColor && productOutOfStock) {
        const alternativeProductColorInStock = getColors(products, p).find(
          color => color.quantity > 0
        );
        if (!!alternativeProductColorInStock) {
          return products.find(
            product => product.id === alternativeProductColorInStock.id
          )!;
        }
      }

      return p;
    },
    [products]
  );

  useEffect(() => {
    const availableNums = (n: number) => !!n || n === 0;
    const categoryProducts = products.filter(p => filterByCategory(p, categories, currentCategoryId, subCatIsActive, subSubCatIsActive, search))
    const fromAges = categoryProducts.map(p => p.age?.from ?? 0).filter(availableNums)
    const toAges = categoryProducts.map(p => p.age?.to ?? Infinity).filter(availableNums)
    const minFromAge = fromAges.length <= 0 ? 0 : Math.min(...fromAges)
    const maxToAge = toAges.length <= 0 ? Infinity : Math.max(...toAges)
    
    dispatch(UIfiltersStore.actions.setSelectedAge({ selectedFrom: minFromAge, selectedTo: maxToAge }));
    dispatch(UIfiltersStore.actions.setAge({ from: minFromAge, to: maxToAge }));

    if(!products.length || !productsFullyLoaded){
      let hasDispatched = false

      if(activeSubSubCategory){
        dispatch(productsStore.actions.fetchBrandProductsByCategory({ categoryLevel: "subSubCategoryId", categoryId: currentCategoryId }))
        hasDispatched = true
      }else if(activeSubCategory){
        dispatch(productsStore.actions.fetchBrandProductsByCategory({ categoryLevel: "subCategoryId", categoryId: currentCategoryId }))
        hasDispatched = true
      }else if(activeCategory){
        dispatch(productsStore.actions.fetchBrandProductsByCategory({ categoryLevel: "categoryId", categoryId: currentCategoryId }))
        hasDispatched = true
      }
      
      if(hasDispatched){
        dispatch(productsStore.actions.addRequestedCategory(currentCategoryId))
      }
    }
  }, [products, activeCategory, activeSubCategory, activeSubSubCategory])
  
  const applySorting = (a: Product, b: Product) => {
    switch (sorting) {
      case "alphabet":
        return a.name.localeCompare(b.name)
      case "newest":
        return a.published_at < b.published_at ? 1 : -1;
      case "popularity":
        return b.rating - a.rating;
      case "cheapest":
        return user?.approvedAgent
          ? a.storePrice - b.storePrice
          : a.salePrice - b.salePrice;
      case "expensive":
        return user?.approvedAgent
          ? b.storePrice - a.storePrice
          : b.salePrice - a.salePrice;
      default:
        return 0;
    }
  }
  
  const availableFirstSort = (a: Product, _: Product) => a.quantity > 0 ? -1 : 1

  const applyFilters = (products: Product[]) => {
    if(searchIsActive){
      return products.filter(p => filterBySearch(p, search))
    }
    
    return products
      .filter(p => filterByCategory(p, categories, currentCategoryId, subCatIsActive, subSubCatIsActive, search))
      .filter(product => (isImporterDeals ? product.importerDeal : product))
      .filter(colorsFilter)
      .map(colorsMapping)
      .sort(applySorting)
      .filter(p => filterByAge(p, age))
      .filter(p => filterByPriceRange(p, products, priceRange, user))
      .filter(p => filterByRating(p, activeRating))
      .filter(p => filterByBrand(p, activeBrands))
      .filter(p => filterByIsoFix(p, activeIsofix))
      .filter(p => filterByStandard(p, activeStandard))
      .filter(p => filterByFoldType(p, activeFoldType))
      .filter((item, pos, a) => a.indexOf(item) === pos)
      .sort(availableFirstSort)
  };

  const userId = user?.id?.toString()!;
  const toggleFavorite = (p: Favorites) => {
    if (!userLoggedIn(user)) {
      addFavouriteProductToStorage(p, products);

      const isMobile = window.innerWidth < 600;
      if (isMobile) {
        dispatch(push(config.routes.myAccount, true));
      }
      return;
    }

    const updatedList = [...favoriteCatalogItems];

    if (!!updatedList.find(fP => fP.productId === p.productId)) {
      const index = updatedList.indexOf(p);
      updatedList.splice(index, 1);

      dispatch(
        usersStore.actions.toggleFavoriteCatalogItem({ userId, favoriteCatalogItems: updatedList })
      );
      return;
    }
    updatedList.push(p);
    dispatch(
      usersStore.actions.toggleFavoriteCatalogItem({
        userId,
        favoriteCatalogItems: updatedList,
      })
    );
  };
  
  const productsToElements = (products: Product[]) => {
    return products
      .map((product: Product) => {
        const isInFavorites =
          !!favoriteCatalogItems?.find(fP => fP.productId === product?.id) ||
          productIsFavourite(product);
        return activeLayout === "list" ? (
          <ProductItemList
            isImporterDeals={isImporterDeals}
            key={product?.id}
            user={user!}
            product={product!}
            isFavorite={isInFavorites}
            toggleFavorite={toggleFavorite}
          />
        ) : (
          <ProductItemGrid
            isImporterDeals={isImporterDeals}
            key={product?.id}
            user={user!}
            product={product!}
            isFavorite={isInFavorites}
            toggleFavorite={toggleFavorite}
          />
        );
      });
  }

  useEffect(() => {
    if (loaded && products.length > 0 && !search.startsWith("?search=")) {
      setShowSpinner(false);
    } else if (loaded && search.startsWith("?search=")) {
      setShowSpinner(true);
      setTimeout(() => setShowSpinner(false), 1000);
    }
  }, [products, search]);

  const filteredProducts = applyFilters(products);
  const paginatedFilteredProducts = applyPagination(filteredProducts, pageNumber)
  const filteredProductsElements = productsToElements(paginatedFilteredProducts)

  const onFiltersClick = () => setFilters(current => !current);

  const onViewButtonClick = (layout: Layout) => (): void => {
    dispatch(UIsortingStore.actions.changeLayout(layout));
  };

  const { sortDropDownIsVisible } = useAppSelector(({ authForm: { sortDropDownIsVisible } }) => ({
    sortDropDownIsVisible,
  }));

  const onSortByClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    dispatch(
      authFormStore.actions.toggleVisibility("sortDropDownIsVisible", !sortDropDownIsVisible)
    );
  };
  const onLoadMoreProductsClick = (): void => {
    loadedItems += PAGE_SIZE;
    setLoadedItems(loadedItems);
    setPageNumber(current => current + PAGE_SIZE);
  };
  
  const getPageTitle = (category: Category) => {
    let title = category.name
    
    if(category.description || category.keywords){
      title += " - "
    }
    
    if(category.description){
      title += category.description + " "
    }
    if(category.keywords){
      title += category.keywords
    }
    
    return title
  }

  return (
    <div className={classes.root}>
      {currentCategory &&
        <Helmet>
          <title>{getPageTitle(currentCategory)}</title>
          <meta name="keywords" content={currentCategory.keywords} />
          <meta name="description" content={removeHTML(currentCategory.freeText ?? "") ?? currentCategory.description ?? null} />
        </Helmet>
      }
      <CategoryPanel />

      <div className={classes.productsView}>
        {isProductsPage && device == 'mobile' ? 
          <CategoryFreeText /> : 
          null }

        <div className={classes.content}>
          <Button
            borderRadius={25}
            height={30}
            width={100}
            outline={theme.colors.blue}
            textSize={20}
            text={activePhrases && activePhrases["filters"]}
            textColor={filters ? theme.colors.text : theme.colors.yellow}
            fill={filters ? theme.colors.orange : theme.colors.text}
            className={classes.filtersBtn}
            action={onFiltersClick}
          />

          <div className={classes.sort}>
            <div className={classes.sortBy}>
              <Button
                borderRadius={25}
                width={155}
                height={42}
                textSize={23}
                text={activePhrases && activePhrases["sort_by"]!}
                fill={theme.colors.blue}
                textColor={theme.colors.text}
                className={classes.sortButton}
                action={event => onSortByClick(event)}
              />

              <div className={clsx(classes.arrow, activeSort && classes.active)} />

              <SortPopup active={sortDropDownIsVisible} className={classes.sortPopup} />
            </div>

            {device != "mobile" ? 
              <div className={classes.view}>
                <GridIcon
                  className={clsx(classes.icon, activeLayout === "grid" && classes.iconActive)}
                  onClick={onViewButtonClick("grid")}
                />

                <div className={classes.line} />

                <ListIcon
                  className={clsx(classes.icon, activeLayout === "list" && classes.iconActive)}
                  onClick={onViewButtonClick("list")}
                />
              </div>:
              null
            }
          </div>

          <Filters className={{ root: clsx(classes.filtersMobile, filters && classes.active) }} />

          {showSpinner ? (
            <PuffLoader color={theme.colors.blue} size={50} />
          ) : (
            <ul
              className={clsx(
                classes.productsGrid,
                activeLayout === "list" && classes.productsList,
                filteredProductsElements.length === 0 && classes.notFound
              )}
              dir={activeLayout === "grid" ? "rtl" : ""}
            >
              {
                filteredProducts.length ? 

                filteredProductsElements :

                <p className={classes.notFoundText}>
                  {activePhrases && activePhrases["no_results_found"]}
                </p>
              }
            </ul>
          )}
          {filteredProducts.length > loadedItems && !showSpinner && (
            <Button
              borderRadius={25}
              width={180}
              height={40}
              outline={theme.colors.orange}
              fill={theme.colors.blue}
              fillHover={theme.colors.text}
              textColor={theme.colors.text}
              textColorHover={theme.colors.orange}
              text={activePhrases && activePhrases["load_more"]}
              textSize={26}
              className={classes.loadMore}
              action={onLoadMoreProductsClick}
            />
          )}
        </div>
        
        <div>
          {isProductsPage && device != 'mobile' ? 
            <CategoryFreeText /> : 
            null }
          <Filters className={{ root: classes.activeDesktop }} />
        </div>
      </div>
    </div>
  );
};

export default ProductsPage;

export type { Layout };

const useStyles = makeStyles(
  theme => ({
    root: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    productsView: {
      boxSizing: "border-box",
      width: "100%",
      padding: 40,
      display: "grid",
      gridTemplateColumns: "3fr 1fr",
      [theme.device.tablet()]: {
        padding: "10px 15px 0 15px",
        gridTemplateColumns: "100%",
      },
    },
    content: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingRight: 60,
      paddingTop: 20,
      boxSizing: "border-box",
      [theme.device.tablet()]: {
        paddingRight: 0
      },
    },
    sort: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      height: 60,
      [theme.device.tablet()]: {
        alignItems: "center",
      },
    },
    sortBy: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    arrow: {
      transition: "0.4s",
      zIndex: 2,
      position: "absolute",
      top: "95%",
      opacity: 0,
      visibility: "hidden",
      borderLeft: "25px solid transparent",
      borderRight: "25px solid transparent",
      borderBottom: `17px solid ${theme.colors.green}`,
      "&:before": {
        content: '""',
        position: "absolute",
        top: 2,
        left: -25,
        right: 0,
        bottom: 0,
        borderLeft: "25px solid transparent",
        borderRight: "25px solid transparent",
        borderBottom: `17px solid ${theme.colors.text}`,
      },
      [theme.device.tablet()]: {
        display: "none",
      },
    },
    sortPopup: {

    },
    sortItem: {
      cursor: "pointer",
      fontWeight: 700,
      fontSize: 17,
      color: theme.colors.green,
      borderBottom: "2px solid transparent",
      "&:hover": {
        borderBottom: `2px solid ${theme.colors.yellow}`,
      },
    },
    view: {
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    line: {
      width: 1,
      height: "100%",
      backgroundColor: theme.colors.grayText,
      margin: "0 15px",
    },
    icon: {
      cursor: "pointer",
      height: 40,
      width: 50,
      ...theme.utils.svgChangeColor(theme.colors.orange),
    },
    iconActive: {
      cursor: "default",
      ...theme.utils.svgChangeColor(theme.colors.blue),
    },
    productsGrid: {
      boxSizing: "border-box",
      paddingTop: 15,
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(3, minmax(270px, 1fr))",
      gridGap: "25px 60px",
      [theme.device.tablet()]: {
        paddingRight: 0,
        gridTemplateColumns: "1fr",
        gridGap: "15px 20px",
      },
    },
    productsList: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      gridRowGap: 10,
      [theme.device.tablet()]: {
        paddingRight: 0,
      },
    },

    notFound: {
      gridTemplateColumns: "none",
    },

    notFoundText: {
      textAlign: "center",
      color: theme.colors.orange,
      fontSize: 32,
      fontWeight: 700,
    },
    productItemGrid: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: 480,
      border: `2px solid ${theme.colors.blue}`,
      borderRadius: 15,
    },
    productItemList: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: 170,
      border: `2px solid ${theme.colors.blue}`,
      borderRadius: 15,
    },

    filters: {
      [theme.device.tablet()]: {
        display: "none",
      },
    },
    filtersMobile: {
      transition: "0.4s",
      opacity: 0,
      visibility: "hidden",
      height: 0,
      [theme.device.desktop()]: {
        display: "none",
      },
      [theme.device.tablet()]: {
        display: "flex",
      },
    },
    filtersBtn: {
      marginLeft: "auto",
      marginBottom: 15,
      [theme.device.desktop()]: {
        display: "none",
      },
      [theme.device.tablet()]: {
        display: "flex",
      },
    },
    sortButton: {
      [theme.device.tablet()]: {
        height: 31,
        width: 120,
        fontSize: 17,
      },
    },
    loadMore: {
      margin: "35px 0",
      transition: "0.4s",
      [theme.device.tablet()]: {
        "&:hover": {
          color: theme.colors.text,
          backgroundColor: theme.colors.blue,
        },
      },
    },
    active: {
      opacity: 1,
      visibility: "visible",
      height: "auto",
    },
    inactive: {
      opacity: 0,
      visibility: "hidden",
      height: 0,
      transition: "0.3s",
      [theme.device.desktop()]: {
        display: "none",
      },
    },
    activeDesktop: {
      [theme.device.tablet()]: {
        display: "none",
      },
    },
  }),
  { name: "products-page" }
);
