import { makeStyles, useTheme } from "@material-ui/styles";

import React, { useEffect, useState } from "react";

import { getFamilies } from "../../services/fit-guide-service";

import fitGuideStore from "../../store/fit-guide-store";

import languagesStore from "../../store/languages-store";

import { useAppDispatch, useAppSelector } from "../../store/store";


const SelectFamily = () => {

  const theme = useTheme();

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const classes = useStyles();

  const [option, setOption] = useState("");

  const dispatch = useAppDispatch();

  const [currData, setCurrData] = useState([]);

  //   const families = getFamilies().then(data => setCurrData(data));

  const handleChange = (e: any) => {

    // console.log(e.target.parentNode.lastChild.innerHTML);

    setOption(e.target.parentNode.lastChild.innerHTML);

  };


  const familyContainer = (families: any) => {

    return (

      <div>

        {/* {families.map((family : any) =>{

          <div>

            <input onChange={handleChange} type="radio" id="option1" name="option" />

            <label htmlFor="option1">Joie</label>

          </div>

        })} */}

      </div>

    );

  };


  useEffect(() => {
    //@ts-ignore
    getFamilies().then(data => setCurrData(data));

    console.log(currData);

  }, []);


  const handleSumbit = (e: any) => {

    e.preventDefault();

    dispatch(fitGuideStore.actions.setFamily("family", option));

  };


  const handleGoBack = (e: any) => {

    e.preventDefault();

    dispatch(fitGuideStore.actions.toggleState("case1", null));

  };


  return (

    <div className={classes.familyContainer}>

      <h3 className={classes.familyTitle}>Select your chosen family</h3>

      <h5>{option}</h5>

      <form className={classes.familyForm}>

        <div className={classes.elementsContainer}>


        {currData?.map((family: any) => {

          return (

            <div className={classes.optionElement}>

              <input

                className={classes.circle}

                onChange={handleChange}

                type="radio"

                id="option1"

                name="option"

                />

              <label className={classes.familyText} htmlFor="option1">

                {family.family}

              </label>

            </div>

          );

        })}

        </div>

        <button className={classes.buttons} onClick={handleGoBack}> Go Back </button>

        <button className={classes.buttons} onClick={handleSumbit}>Submit</button>

      </form>

    </div>

  );

};


const useStyles = makeStyles(theme => ({

  familyContainer: {

    width: "100%",

    margin: "0 auto",

  },

  familyTitle: {

    color: theme.colors.blue,

    fontWeight: 700,

    letterSpacing: "3px",

    fontSize: "2rem",

  },

  familyForm: {

    margin: "0 auto",

  },

  elementsContainer: {

    width:"54%",

    [theme.device.smallDesktop()]: {width:"56%"},

    [theme.device.tablet()]: {width:"58%"},

    [theme.device.smallTablet()]: {width:"59%"},

    [theme.device.mobile()]: {width:"63%"},

  },

  optionElement: {

    direction: "rtl",

    textAlign: "right",

  },

  familyText: {

    color: theme.colors.blue,

    fontSize: "1.3rem",

  },

  circle: {

    transform: "scale(1.5)",

    marginLeft: "15px",

    marginBottom: "6px",

  },

  buttons: {

    border: "1px solid " + theme.colors.blue,

    padding: "7px 23px",

    borderRadius: "24px",

    fontWeight: 700,

    margin: "12px",

    color: theme.colors.blue,

    "&:hover": {

      backgroundColor: theme.colors.blue,

      color: "white",

    },

  },

}));

export default SelectFamily;