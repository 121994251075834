import { createAction, createReducer } from "@reduxjs/toolkit";
import { TAge } from "../services/products/types";

type TPriceRange = {
  min: number | "";
  max: number | "";
};

type State = {
  age: TAge;
  price: TPriceRange;
  activeBrands: number[];
  rating: number;
  isofix: string;
  standard: string;
  foldType: string;
};

const initialState: State = {
  age: {
    selectedFrom: 0,
    selectedTo: Infinity,
    from: 0,
    to: Infinity,
  },
  price: {
    min: 0,
    max: 0,
  },
  activeBrands: [],
  rating: 0,
  isofix: "",
  standard: "",
  foldType: "",
};

const setAge = createAction("filters/age", (data: { from: number; to: number }) => ({
  payload: data,
}));

const setSelectedAge = createAction(
  "filters/select-age",
  (payload: { selectedFrom: number; selectedTo: number }) => ({
    payload,
  })
);

const setPriceRange = createAction("filters/price", (data: TPriceRange) => ({ payload: data }));

const setRating = createAction("filters/rating", rating => ({ payload: rating }));

const setActiveBrands = createAction("filters/brands", (brandId: number) => ({ payload: brandId }));

const setIsofix = createAction("filters/isofix", isofix => ({ payload: isofix }));

const setStandard = createAction("filters/standard", standard => ({ payload: standard }));

const setFoldType = createAction("filters/foldType", foldType => ({ payload: foldType }));

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(setAge, (state, action) => {
      state.age.from = action.payload.from;
      state.age.to = action.payload.to;
    })
    .addCase(setSelectedAge, ({ age }, { payload }) => {
      age.selectedFrom = payload.selectedFrom;
      age.selectedTo = payload.selectedTo;
    })
    .addCase(setRating, (state, action) => {
      state.rating = action.payload;
    })
    .addCase(setPriceRange, (state, action) => {
      state.price = action.payload;
    })
    .addCase(setActiveBrands, (state, action) => {
      state.activeBrands = state.activeBrands.includes(action.payload)
        ? state.activeBrands.filter(brandId => brandId !== action.payload)
        : state.activeBrands.concat(action.payload);
    })
    .addCase(setIsofix, (state, action) => {
      state.isofix = action.payload;
    })
    .addCase(setStandard, (state, action) => {
      state.standard = action.payload;
    })
    .addCase(setFoldType, (state, action) => {
      state.foldType = action.payload;
    });
});

const actions = {
  setActiveBrands,
  setSelectedAge,
  setRating,
  setAge,
  setPriceRange,
  setIsofix,
  setStandard,
  setFoldType,
};

const UIfiltersStore = {
  reducer,
  actions,
};

export default UIfiltersStore;

export type { State, TPriceRange };
