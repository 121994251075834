import { isMobile } from "react-device-detect";
import { Route, Redirect } from "react-router";
import Cookies from "js-cookie";
import React from "react";

import { useAppDispatch, useAppSelector } from "../../store/store";
import authFormStore from "../../store/auth-form-store";
import { config } from "../../config";

type Props = {
  children: React.ReactNode;
  exact?: boolean;
  path: string;
};

const PrivateRoute = ({ path, exact, children }: Props) => {
  const userId = useAppSelector(({ user }) => user.data?.id);
  const isAuth = Cookies.get("jwt") && (userId !== undefined || userId !== null);

  const dispatch = useAppDispatch();

  const getPathname = (): string | undefined => {
    if (isMobile) return config.routes.authPage;
    else {
      dispatch(authFormStore.actions.toggleVisibility("authFormIsVisible", true));
      return;
    }
  };

  return (
    <Route
      path={path}
      exact={exact}
      render={({ location }) =>
        isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: getPathname(),
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
