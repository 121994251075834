import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import CatalogItem from "./catalog-item";
import Button from "../common/button";
import clsx from "clsx";
import { isMobile } from "react-device-detect";
import { TCatalogItem } from "../../services/site-config-service/types";
import { theme } from "../../theme";
import { useAppSelector } from "../../store/store";
import languagesStore from "../../store/languages-store";

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      padding: "20px 0",
      [theme.device.mobile()]: {
        paddingBottom: 0,
      },
    },
    container: {
      listStyleType: "none",
      direction: "rtl",
      boxSizing: "border-box",
      padding: "0 20px",
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gridGap: "7px 10px",
      [theme.device.mobile()]: {
        gridGap: 5,
        padding: "0 10px",
        gridTemplateColumns: "repeat(2, 1fr)",
        textAlign: "center",
      },
    },
    item: {
      height: 761,
      [theme.device.mobile()]: {
        height: "100%",
      },
    },
    button: {
      margin: "20px 0",
      transition: "0.5s",
      paddingBottom: 5,
      borderRadius: 25,
      [theme.device.mobile()]: {
        border: "none",
        backgroundColor: theme.colors.orange,
        color: theme.colors.text,
      },
    },
    bigProduct: {
      [theme.device.desktop()]: {
        height: "100%",
        gridRowEnd: "span 2",
        gridColumnEnd: "span 2",
      },
    },
    wideProduct: {
      [theme.device.desktop()]: {
        gridColumnEnd: "span 2",
      },
    },
  }),
  { name: "catalog" }
);

type TProps = {
  catalogItems: TCatalogItem[];
  big?: boolean;
  equalItemsSize?: boolean;
  pathname?: string;
  className?: {
    root?: string;
    itemsContainer?: string;
    orderItem?: string;
  };
};

const Catalog = ({
  catalogItems,
  big,
  equalItemsSize,
  className,
  pathname,
}: TProps): JSX.Element => {
  const WIDE_PRODUCT_POS_INITIAL: number = 0;
  const BIG_PRODUCT_POS_INITIAL: number = 3;

  const INITIAL_LAYOUT = isMobile ? 5 : big ? 5 : 5;
  const [layout, setLayout] = useState<number>(INITIAL_LAYOUT);

  const classes = useStyles();

  const catalogItemsLength = catalogItems?.length;
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const items = catalogItems
    .filter((_, i) => i < layout)
    .map((catalogItem, index) => (
      <li
        key={catalogItem.id}
        className={clsx(
          classes.item,
          !equalItemsSize &&
          clsx(
            ((index === WIDE_PRODUCT_POS_INITIAL ||
              index % INITIAL_LAYOUT === WIDE_PRODUCT_POS_INITIAL) &&
              classes.wideProduct) ||
            ((index === BIG_PRODUCT_POS_INITIAL ||
              index % INITIAL_LAYOUT === BIG_PRODUCT_POS_INITIAL) &&
              classes.bigProduct)
          )
        )}
      >
        <CatalogItem catalogItem={catalogItem} big={big} />
      </li>
    ));

  const homePageItems = catalogItems.map((catalogItem, index) => (
    <li
      key={catalogItem.id}
      className={clsx(
        classes.item,
        !equalItemsSize &&
        clsx(
          (index === WIDE_PRODUCT_POS_INITIAL && classes.wideProduct) ||
          ((index === BIG_PRODUCT_POS_INITIAL || index % 3 === 0) && classes.bigProduct)
        )
      )}
    >
      <CatalogItem catalogItem={catalogItem} big={big} />
    </li>
  ));
  const itemsLength = items?.length;
  const onLoadMoreClick = () => {
    if (big) {
      setLayout(current => (current <= catalogItems.length ? current + 2 : current));
      return;
    }
    setLayout(current => (current <= catalogItems.length ? current + INITIAL_LAYOUT : current));
  };

  return (
    <div className={clsx(classes.root, className?.root)}>
      <ul dir="rtl" className={clsx(classes.container, className?.itemsContainer)}>
        {pathname === "/" ? homePageItems : items}
      </ul>

      {pathname !== "/" && itemsLength !== catalogItemsLength && (
        <Button
          text={activePhrases && activePhrases["load_more"]}
          fill={theme.colors.blue}
          fillHover={theme.colors.text}
          borderRadius={10}
          width={120}
          height={30}
          borderWidth={2}
          textSize={20}
          textWeight={600}
          textColor={theme.colors.text}
          textColorHover={theme.colors.orange}
          outline={theme.colors.blue}
          outlineHover={theme.colors.orange}
          action={onLoadMoreClick}
          className={classes.button}
        />
      )}
    </div>
  );
};

export default Catalog;
