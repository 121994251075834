import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import UIsortingStore, { Sort } from "../../store/ui-sorting-store";
import { ReactComponent as CloseIcon } from "../../static/icons/close.svg";
import { getPhrase } from "../../utils/language";
import languagesStore from "../../store/languages-store";

const useStyles = makeStyles(
  theme => ({
    root: {
      transition: "0.4s",
      opacity: 0,
      visibility: "hidden",
      padding: "10px 0",
      borderRadius: 15,
      backgroundColor: theme.colors.text,
      zIndex: 1,
      position: "absolute",
      width: "100%",

      left: 0.1,
      display: "flex",
      flexDirection: "column",
      gridRowGap: 2,
      alignItems: "center",
      border: `2px solid ${theme.colors.green}`,
      [theme.device.desktop()]: {
        top: 56,
      },
      [theme.device.mobile()]: {
        zIndex: 6,
        boxSizing: "border-box",
        bottom: 0,
        padding: 15,
        position: "fixed",
        gridRowGap: 15,
        border: "none",
        borderRadius: 0,
        alignSelf: "flex-end",
        transform: "translateY(100%)",
        opacity: 1,
      },
    },
    sortItem: {
      userSelect: "none",
      cursor: "pointer",
      fontWeight: 700,
      fontSize: 17,
      color: theme.colors.blue,
      borderBottom: "2px solid transparent",
      "&:hover": {
        borderBottom: `2px solid ${theme.colors.orange}`,
      },
      [theme.device.mobile()]: {
        fontSize: 32,
        borderWidth: 3,
        borderBottom: "5px solid transparent",
        "&:hover": {
          borderBottom: `5px solid ${theme.colors.orange}`,
        },
      },
    },
    icon: {
      cursor: "pointer",
      alignSelf: "flex-end",
      width: 35,
      height: 35,
      marginBottom: 40,
      ...theme.utils.svgStroke(theme.colors.green),
      [theme.device.desktop()]: {
        display: "none",
      },
    },
    active: {
      transition: "0.4s",
      opacity: 1,
      visibility: "visible",

      [theme.device.mobile()]: {
        transform: "translateY(0)",
      },
    },
  }),
  { name: "sort-popup" }
);

type TProps = {
  active: boolean;
  className?: string;
};

const SortPopup = ({ active, className }: TProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const lang = useAppSelector(state => state.languages.language);
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const onSortItemClick = (sorting: Sort) => () => {
    dispatch(UIsortingStore.actions.changeSorting(sorting));
    closePopup();
  };

  const closePopup = () => {
    dispatch(UIsortingStore.actions.toggleSorting(false));
  };

  return (
    <div className={clsx(classes.root, className, active && classes.active)}>
      <CloseIcon className={classes.icon} onClick={closePopup} />
      <div className={classes.sortItem} onClick={onSortItemClick("newest")}>
        {activePhrases && activePhrases["newest"]}
      </div>
      <div className={classes.sortItem} onClick={onSortItemClick("alphabet")}>
        {activePhrases && activePhrases["alphabet"]}
      </div>
      <div className={classes.sortItem} onClick={onSortItemClick("popularity")}>
        {activePhrases && activePhrases["popularity"]}
      </div>
      <div className={classes.sortItem} onClick={onSortItemClick("cheapest")}>
        {activePhrases && activePhrases["cheapest"]}
      </div>
      <div className={classes.sortItem} onClick={onSortItemClick("expensive")}>
        {activePhrases && activePhrases["expensive"]}
      </div>
    </div>
  );
};

export default SortPopup;

export type { Sort };
