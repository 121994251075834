import { makeStyles } from "@material-ui/styles";
import React from "react";
import PrivacyPolicyPanel from "./privacy-policy-panel";
import ReactMarkdown from "react-markdown";
import { useAppSelector } from "../../../store/store";
import { parseRichText } from "../../../utils/helper";

export const PrivacyPolicy = (): JSX.Element => {
  const classes = useStyles();

  const { privacyPolicy } = useAppSelector(state => ({
    privacyPolicy:
      state.languages.language === "he"
        ? state.siteConfig.privacyPolicyHebrew
        : state.siteConfig.privacyPolicy,
  }));
  return (
    <div className={classes.wrapper} style={{ textAlign: "right" }}>
      <PrivacyPolicyPanel />

      <div className={classes.markdownWrapper}>
        {parseRichText(privacyPolicy)}
      </div>
    </div>
  );
};

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      "&>h1": {
        color: theme.colors.green,
        textAlign: "center",
        fontSize: 45,

        [theme.device.desktop()]: {
          fontSize: 65,
        },
      },
    },

    spinnerWrapper: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },

    markdownWrapper: {
      padding: "2rem",
      marginBottom: "55px",
      textAlign: 'right',
      "& > h1, h2, h3, h4, h5, h6": {
        textAlign: "center",
        color: theme.colors.blue,
        marginTop: 0,
      },

      "& > p": {
        fontWeight: 700,
        color: theme.colors.grayText,
        fontSize: 20,
      },
      "& > ol": {
        direction: "rtl",
      },
      "& > ol > li > ol": {
        direction: "rtl",
      },
    },
  }),
  { name: "privacy-policy" }
);
