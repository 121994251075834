import { useTheme } from "@material-ui/styles";
import React, { useEffect, useRef, useState } from "react";

import Button from "../../../common/button";
import Rating from "../../../common/rating";
import Flag from "../../../common/flag";
import * as Yup from "yup";
import displayCurrencySign from "../../../../utils/currencies-signs";
import { Product, Review, TReview } from "../../../../services/products/types";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  buildClientUrl,
  countDiscount,
  countProductPrice,
  detectUserStatus,
  getColors,
} from "../../../../utils/helper";
import { getPhrase } from "../../../../utils/language";

import { ReactComponent as DiscountIcon } from "../../../../static/icons/discount.svg";
import { ReactComponent as ShareIcon } from "../../../../static/icons/share.svg";
import { ReactComponent as CloseIcon } from "../../../../static/icons/close.svg";
import { ReactComponent as PinterestIcon } from "../../../../static/icons/pinterest.svg";
import { ReactComponent as LinkedinIcon } from "../../../../static/icons/linkedin.svg";
import { ReactComponent as TwitterIcon } from "../../../../static/icons/twitter.svg";
import { ReactComponent as FacebookIcon } from "../../../../static/icons/fb.svg";
import infantiLink from "../../../../static/images/infanti.png";
import bumpriderLink from "../../../../static/images/bumprider.png";
import gracoLink from "../../../../static/images/graco.png";
import joieLink from "../../../../static/images/joie.png";
import nunaLink from "../../../../static/images/nuna.png";
import { Favorites } from "../../../../services/users/types";
import { useStyles } from "./single-product-styles";
import clsx from "clsx";
import productsStore from "../../../../store/products-store";
import authFormStore from "../../../../store/auth-form-store";
import { useFormik } from "formik";
import { productIsFavourite } from "../../../../services/products/products-service";
import { isMobile } from "react-device-detect";
import SingleProductPrice from "./single-product-price";
import languagesStore from "../../../../store/languages-store";

type TProps = {
  product: Product;
  open: boolean;
  toggleFavorite: (p: Favorites) => void;
  setOpen: (isOpen: boolean) => void;
  onReviewButtonClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

interface FormValues {
  title: string;
  text: string;
}

type BrandsObject = {
  [key: string]: string;
};

const SingleProductInfo = ({
  product,
  open,
  toggleFavorite,
  setOpen,
  onReviewButtonClick,
}: TProps): JSX.Element => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [ratingError, setRatingError] = useState<boolean>(false);
  const [writeReviewRating, setWriteReviewRating] = useState<number>(0);
  const [brandName, setBrandName] = useState<string>("joie");

  useEffect(() => {
    setBrandName(product!?.brand!?.name!?.toLocaleLowerCase());
  }, []);

  const isMobile = theme.device.isMobile;

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const { products, favoriteProducts, user, discount } = useAppSelector(state => ({
    products: state.products.productsData.data,
    favoriteProducts: state.user?.data?.favoriteCatalogItems!,
    user: state.user?.data!,
    discount: state.user?.data?.discount!,
  }));
  const productFamily = getColors(products, product);
  const initialValues: FormValues = {
    title: "",
    text: "",
  };

  const errorMsg = activePhrases && activePhrases["required_field"];

  const validationSchema: any = Yup.object().shape({
    title: Yup.string().required(errorMsg),
    text: Yup.string().required(errorMsg),
  });

  const closeWriteReview = () => {
    formik.resetForm();
    setRatingError(false);
    setWriteReviewRating(0);
    setOpen(false);
  };

  const onSubmit = (values: FormValues) => {
    if (writeReviewRating === 0) {
      return;
    }

    const productsToRate = productFamily.map(product => {
      const productReviews = product.reviews.map((obj: TReview) => {
        const { id, ...rest } = obj;
        return rest;
      }, {});

      const date: Date = new Date();
      const newReview = {
        ...values,
        rating: writeReviewRating,
        userId: user.id,
        date,
      };

      const data: Review[] = [...productReviews, newReview];

      return {
        catalogNumber: product.catalogNumber,
        reviews: data,
      };
    });

    dispatch(productsStore.actions.updateProduct(productsToRate));
    closeWriteReview();
  };

  const formik = useFormik<FormValues>({ initialValues, validationSchema, onSubmit });

  const shareInputRef = useRef<HTMLInputElement>(null);

  const isFavorite =
    !!favoriteProducts?.find(fP => fP.productId === product?.id) || productIsFavourite(product);

  const updateFavorite = () => {
    toggleFavorite({ productId: product.id, name: product.name });
  };

  const signatureMode = useAppSelector(state => state.signature.signatureMode);

  const setReviewRating = (num: number) => {
    setWriteReviewRating(num);
    setRatingError(false);
  };

  const handleSubmitClick = () => {
    if (writeReviewRating === 0) {
      setRatingError(true);
    }
  };

  const title = `${product.title} ${product.name}`;

  const getBrandLink = (brandName: string): string => {
    const obj: BrandsObject = {
      joie: joieLink,
      graco: gracoLink,
      bumprider: bumpriderLink,
      nuna: nunaLink,
      infanti: infantiLink,
    };

    return obj[brandName] || joieLink;
  };

  const handleLinkClick = (url: string) => {
    if (product.productLink !== '' && product.quantity > 0) { window.open(url, "_blank") }

  };

  return (
    <div className={classes.root}>
      <div className={classes.reviewField}>
        {open && (
          <form
            noValidate
            className={clsx(classes.reviewPopUp, open && classes.popupActive)}
            onSubmit={formik.handleSubmit}
          >
            <CloseIcon className={classes.closeIcon} onClick={closeWriteReview} />
            <label className={(signatureMode ? classes.reviewTitleSignature : classes.reviewTitle)}>
              {activePhrases && activePhrases["write_review"]}
            </label>
            <div className={classes.popupRow}>
              <label>{activePhrases && activePhrases["name_username"]}</label>
              <input
                dir="rtl"
                id="title"
                className={clsx(
                  classes.reviewInput,
                  formik.errors.title && formik.touched.title && classes.invalidField
                )}
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                autoFocus
              />

              <span
                className={clsx(
                  classes.error,
                  open && formik.errors.title && formik.touched.title && classes.errorActive
                )}
              >
                {activePhrases && activePhrases["required_field"]}
              </span>
            </div>
            <div className={classes.popupRow}>
              <label>{activePhrases && activePhrases["rate"]}</label>
              {open && (
                <Rating
                  rating=""
                  size="medium"
                  isClickable={true}
                  reviewPopUp={true}
                  setReviewRating={setReviewRating}
                />
              )}

              <span className={clsx(classes.error, ratingError && classes.errorActive)}>
                {activePhrases && activePhrases["required_field"]}
              </span>
            </div>
            <div className={classes.popupRow}>
              <label>{activePhrases && activePhrases["review"]}</label>
              <textarea
                dir="rtl"
                id="text"
                name="text"
                className={clsx(
                  classes.reviewText,
                  formik.errors.text && formik.touched.text && classes.invalidField
                )}
                value={formik.values.text}
                onChange={formik.handleChange}
              />

              <span
                className={clsx(
                  classes.error,
                  open && formik.errors.text && formik.touched.text && classes.errorActive
                )}
              >
                {activePhrases && activePhrases["required_field"]}
              </span>
            </div>
            <div className={classes.reviewButtonContainer}>
              <Button
                text={activePhrases && activePhrases["close"]}
                height={31}
                width={125}
                textSize={19}
                textWeight={600}
                borderRadius={25}
                fill={theme.colors.white}
                textColor={theme.colors.orange}
                outline={theme.colors.orange}
                action={closeWriteReview}
              />
              <Button
                type="submit"
                text={activePhrases && activePhrases["submit"]}
                height={35}
                width={130}
                textSize={19}
                textWeight={600}
                borderRadius={25}
                fill={theme.colors.orange}
                textColor={theme.colors.text}
                action={handleSubmitClick}
              />
            </div>
          </form>
        )}

        <div className={clsx(classes.id, product.isSignature ? classes.signatureId : '')}>
          {" "}
          {product?.catalogNumber!} :{activePhrases && activePhrases["catalog_number"]}
        </div>
      </div>

      <div className={classes.title}>
        <Flag
          className={{
            root: classes.flag,
            flagIcon: classes.flagIcon,
            heartIcon: classes.heartIcon,
          }}
          isFavorite={isFavorite}
          toggleFavorite={updateFavorite}
        />

        <div className={classes.name}>{title}</div>
      </div>

      {/* {!detectUserStatus(user!, product!) && (
        <div
          className={clsx(
            classes.brandLinkWrapper,
            brandName === "joie" && classes[brandName],
            brandName === "graco" && classes.graco,
            brandName === "infanti" && classes.infanti,
            brandName === "nuna" && classes.nuna,
            brandName === "bumprider" && classes.bumprider
          )}
          onClick={() => handleLinkClick(product.productLink)}
        >
          <div className={classes.logoWrapper}>
            <img src={getBrandLink(brandName)} alt="brandLink" className={classes.brandLink} />
          </div>
          <div>{activePhrases && activePhrases["you_can_buy_it_from_the_official_website"]}</div>
        </div>
      )} */}
      {detectUserStatus(user!, product!) && (
        <img className={classes.badgeImg} src={product?.brand?.image?.url!} alt="product badge" />
      )}

      {detectUserStatus(user, product!) && !isMobile && (
        <SingleProductPrice user={user!} product={product!} />
      )}
    </div>
  );
};

export default SingleProductInfo;
