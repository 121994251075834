import { createAction, createReducer } from "@reduxjs/toolkit";

type Layout = "grid" | "list";

type Sort = "newest" | "alphabet" | "popularity" | "cheapest" | "expensive";

type State = {
  layout: Layout;
  sorting: Sort;
  activeSort: boolean;
};

const initialState: State = {
  layout: "grid",
  sorting: "newest",
  activeSort: false,
};

const changeLayout = createAction("sorting/change-layout", (layout: Layout) => ({ payload: layout }));

const changeSorting = createAction("sorting/change-sorting", (sorting: Sort) => ({ payload: sorting }));

const toggleSorting = createAction("sorting/toggle-sorting", (active: boolean) => ({ payload: active }));

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(changeLayout, (state, action) => {
      state.layout = action.payload;
    })
    .addCase(changeSorting, (state, action) => {
      state.sorting = action.payload;
    })
    .addCase(toggleSorting, (state, action) => {
      state.activeSort = action.payload;
    });
});

const actions = {
  changeLayout,
  changeSorting,
  toggleSorting,
};

const UIsortingStore = {
  reducer,
  actions,
};

export default UIsortingStore;

export type { State, Layout, Sort };
