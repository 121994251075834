import { makeStyles } from "@material-ui/styles";
import React from "react";

import GoogleMaps from "./google-maps";

import useViewport from "../../../hooks/useViewport";

import { ReactComponent as WebSiteIcon } from "../../../static/icons/website.svg";
import { ReactComponent as PlaceIcon } from "../../../static/icons/place.svg";
import { ReactComponent as ClockIcon } from "../../../static/icons/clock.svg";
import { ReactComponent as PhoneIcon } from "../../../static/icons/phone.svg";

interface Props {
  name: string;
  address: string;
  website: string;
  workingHours: string;
  phoneNumber: string;
  mapsCoordinates: {
    lat: number;
    lng: number;
  };
}

const ApprovedSellerBox = ({
  name,
  address,
  website,
  workingHours,
  phoneNumber,
  mapsCoordinates,
}: Props): JSX.Element => {
  const { width } = useViewport();

  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.infoPart}>
        <div className={classes.boxRow}>
          <span className="company-name">{name}</span>
        </div>

        <div className={classes.boxRow}>
          <span>{address}</span>
          <span>
            <PlaceIcon style={{ width: width! < 1200 ? 30 : 40 }} />
          </span>
        </div>

        <div className={classes.boxRow}>
          <a href={website} target="_blank" rel="noopener noreferrer">
            {website !== null && website !== "" && website?.startsWith("http://")
              ? website?.replace("http://", "")
              : website?.replace("https://", "")}
          </a>

          {website && (
            <span>
              <WebSiteIcon style={{ width: width! < 1200 ? 30 : 40 }} />
            </span>
          )}
        </div>

        <div className={classes.boxRow}>
          <span>{workingHours}</span>
          <span>
            <ClockIcon style={{ width: width! < 1200 ? 30 : 40 }} />
          </span>
        </div>

        <div className={classes.boxRow}>
          <span>{phoneNumber}</span>
          <span>
            <PhoneIcon style={{ width: width! < 1200 ? 30 : 40 }} />
          </span>
        </div>
      </div>

      <div className={classes.mapPart}>
        <div className={classes.boxRow}>
          <div id="map" className={classes.mapWrap}>
            <GoogleMaps lat={mapsCoordinates.lat} lng={mapsCoordinates.lng} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovedSellerBox;

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      border: `1px solid ${theme.colors.grayBorder}`,
      marginBottom: 20,
      borderRadius: 6,
      padding: 6,

      [theme.device.desktop()]: {
        flexDirection: "row-reverse",
        marginBottom: 60,
        border: "none",
      },
    },

    boxRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",

      "&>a": {
        [theme.device.desktop()]: {
          fontSize: 22,
        },

        "@media (min-width: 1200px)": {
          fontSize: 26,
        },
      },

      "&>span": {
        "&:first-child": {
          display: "block",
          textAlign: "end",
          marginRight: 4,

          "&.company-name": {
            fontWeight: "bold",
            fontSize: 22,

            [theme.device.desktop()]: {
              marginRight: 30,
              fontSize: 34,
            },

            "@media (min-width: 1200px)": {
              marginRight: 40,
              fontSize: 55,
            },
          },

          [theme.device.desktop()]: {
            fontSize: 26,
          },

          "@media (min-width: 1200px)": {
            fontSize: 34,
          },
        },

        "&:last-child": {
          display: "block",
          textAlign: "end",
          filter: "invert(48%) sepia(79%) saturate(2476%) hue-rotate(11deg) brightness(118%) contrast(119%)",
        },
      },
    },

    mapWrap: {
      position: "relative",
      marginTop: 10,
      width: "100%",
      height: 200,

      [theme.device.desktop()]: {
        marginTop: 0,
        height: 250,
      },

      "@media (min-width: 1200px)": {
        height: 300,
      },
    },

    infoPart: {
      [theme.device.desktop()]: {
        flex: 1,
        height: 250,
      },
    },

    mapPart: {
      [theme.device.desktop()]: {
        flex: 1,
        height: 250,
      },

      "@media (min-width: 1200px)": {
        height: 300,
      },
    },
  }),
  { name: "approved-seller-box" }
);
