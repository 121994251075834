    import {
    createAction,
    createAsyncThunk,
    createReducer,
    createSelector,
    PayloadAction,
    SerializedError,
  } from "@reduxjs/toolkit";

  type State = {
      case1: any,
      family: any,
      totalCars: number,
      cars: any,
      setChildDimensions: any,
  }

  type StateType =
  | "case1"
  | "family"
  | "totalCars"
  | "cars"
  | "setChildDimensions";

  const initialState: State = {
    case1: null,
    family: '',
    totalCars: 0,
    cars: [],
    setChildDimensions: null,
  };


  const toggleState = createAction('fit/toggleState', (type: StateType, newState: any) => ({
    payload: {
        type,
        newState
    }
}));


const setFamily = createAction('fit/setFamily', (type: StateType, newState: string) => ({
    payload: {
        type,
        newState
    }
}));

const setCarsCount = createAction('fit/setCarsCount', (type: StateType, newState: number) => ({
    payload: {
        type,
        newState
    }
}));

const setCars = createAction('fit/setCars', (type: StateType, newState: string[]) => ({
    payload: {
        type,
        newState
    }
}));

const setChildDimensions = createAction('fit/setChildDimensions', (type: StateType, newState: any) => ({
    payload: {
        type,
        newState
    }
}));

  const reducer = createReducer(initialState, builder => {
      builder
      .addCase(toggleState, (state, action) => {
          const {type, newState} = action.payload;

          state.case1 = newState;
      })
      .addCase(setFamily, (state, action) => {
        const {type, newState} = action.payload;

        state.family = newState;
    })
    .addCase(setCarsCount, (state, action) => {
        const {type, newState} = action.payload;

        state.totalCars = newState;
    })
    .addCase(setCars, (state, action) => {
        const {type, newState} = action.payload;

        state.cars = newState;
    })
    .addCase(setChildDimensions, (state, action) => {
        const {type, newState} = action.payload;

        state.setChildDimensions = newState;
    });
  })

  const actions = { toggleState, setFamily, setCarsCount, setCars, setChildDimensions };

  const fitGuideStore = { reducer, actions}

  export default fitGuideStore;

  export type { State };