import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      height: "100%",

      "&>div": {
        "&:nth-child(2)": {
          width: "100%",
          direction: "rtl",

          [theme.device.desktop()]: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          },

          "@media (min-width: 1000px)": {
            display: "grid",
            grid: "repeat(auto-fit, minmax(200px, 260px))",
            gridTemplateColumns: "repeat(2, 1fr)",
          },
          "@media (min-width: 1200px)": {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
          [theme.device.mobile()]: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridColumnGap: 10,
          },
        },
      },
    },

    title: {
      "&>div": {
        margin: "15px 0 20px 0",
        textAlign: "center",
        [theme.device.mobile()]: {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        },

        [theme.device.desktop()]: {
          margin: 0,
        },

        "&:nth-child(1)": {
          "@media (min-width: 1000px)": {
            textAlign: "end",
          },
        },
      },
    },

    favListTitle: {
      fontSize: 40,
      fontWeight: "bold",
      color: theme.colors.blue,
      [theme.device.mobile()]: {
        fontSize: 30,
      },
    },

    addAll: {
      display: "grid",
      placeItems: "center",
      fontSize: 20,

      "&>button": {
        [theme.device.desktop()]: {
          margin: "20px 0",
        },
        "@media (min-width: 1200px)": {
          width: 254,
          height: 47,
          fontSize: 28,
        },
      },

      "@media (min-width: 1000px)": {
        placeItems: "end",
      },
    },

    favoriteItemBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: 15,
      margin: 10,
      borderRadius: 6,

      "&>div": {
        "&:nth-child(1)": {
          display: "flex",
          justifyContent: "space-between",

          "&>div": {
            marginLeft: 10,
          },
        },

        "&:nth-child(2)": {
          display: "flex",
          flexDirection: "column",

          "&>p": {
            margin: 0,
            textAlign: "center",
            cursor: "pointer",

            "&:nth-child(1)": {
              wordBreak: "break-word",
              fontSize: 26,
              fontWeight: "bold",

              "@media (min-width: 1000px)": {
                fontSize: 33,
              },
            },
            "&:nth-child(2)": {
              fontSize: 18,
              color: "#6B6969",

              "@media (min-width: 1000px)": {
                fontSize: 24,
              },
            },
          },

          [theme.device.desktop()]: {
            display: "flex",
            flexDirection: "column",
          },
        },
      },

      "&:hover": {
        "&>div:nth-child(1)>div": {
          visibility: "visible",
          transition: "0.2s",
        },

        "&>div:nth-child(2)>button": {
          visibility: "visible",
          transition: "0.2s",
        },
      },

      "@media (min-width: 1000px)": {
        border: "none",
      },
    },

    binIconWrapper: {
      position: "relative",
      right: 0,
      bottom: 5,
      visibility: theme.device.isMobile ? "visible" : "hidden",
      transition: "0.2s",
    },

    bin: {
      width: 30,
      position: "relative",
      right: 10,
      top: -8,
      cursor: "pointer",
    },

    colors: {
      display: "flex",
      justifyContent: "space-evenly",
      margin: "10px 0",

      "&>img": {
        cursor: "pointer",
      },
    },

    currency: {
      height: 33,
      fontSize: 27,
    },

    details: {
      display: "grid",
      placeItems: "center",

      "&>button": {
        display: "grid",
        placeItems: "center",
        width: 200,
        height: 60,
        fontSize: 25,
        borderRadius: 16,
        color: theme.colors.text,
        boxShadow: "0px 3px 3px #000000AB",
        backgroundColor: theme.colors.blue,
      },
    },

    favoriteItemBtn: {
      alignSelf: "center",
      marginTop: 20,
      boxShadow: theme.utils.boxShadow(theme.colors.shadow),
      visibility: theme.device.isMobile ? "visible" : "hidden",
      transition: "0.2s",
      width: theme.device.isMobile ? "100%" : 220,
    },
    addToCartDisabled: {
      backgroundColor: theme.colors.grayText,
      cursor: "default",
      color: theme.colors.text,
      borderColor: theme.colors.grayText,
    },
    mainPicWrapper: {
      position: "relative",

      "&>img": {
        cursor: "pointer",
      },
    },
    itemsContainer: {
      // width: "100%",
      // display:
    },
    outOfStock: {
      position: "absolute",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      textAlign: "center",
      borderRadius: 20,
      color: "#fff",
      padding: 10,
    },
  }),
  { name: "my-account-favorites-list" }
);

export default useStyles;
