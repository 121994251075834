import { makeStyles, useTheme } from "@material-ui/styles";
import clsx from "clsx";
import React, { useState } from "react";
import {
  createJobApplication,
  CreateJobApplicationProps,
  uploadResume,
  validate,
} from "../../services/careers/careers-service";
import { ReactComponent as BackArrow } from "../../static/icons/back-arrow.svg";
import { useAppDispatch, useAppSelector } from "../../store/store";
import CarouselLinear from "../carousel/carousel";
import Button from "../common/button";
import Panel from "../common/panel";
import checkIcon from "../../static/images/check.png";
import loadingIcon from "../../static/images/loading.png";
import { push } from "connected-react-router";
import { config } from "../../config";
import languagesStore from "../../store/languages-store";

const PositionPage = (): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const MAX_RESUME_SIZE = 2097152; //2MB

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const [uploadedFileName, setUploadedFileName] = useState<string>("No file chosen");
  const [userAgreed, setUserAgreed] = useState<boolean>(false);

  //Form input errors
  const [fileError, setFileError] = useState<string | null>(null);
  const [firstNameEmpty, setFirstNameEmpty] = useState<boolean | null>(null);
  const [lastNameEmpty, setLastNameEmpty] = useState<boolean | null>(null);
  const [emailEmpty, setEmailEmpty] = useState<boolean | null>(null);
  const [phoneEmpty, setPhoneEmpty] = useState<boolean | null>(null);
  //Form submit feedback
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { slides, slidesMobile, careers } = useAppSelector(state => ({
    slides: state.siteConfig.catalogCarousel,
    slidesMobile: state.siteConfig.catalogCarouselMobile,
    lang: state.languages.language,
    careers: state.siteConfig.careers,
  }));

  const splitedURL = useAppSelector(state => state.router.location.pathname).split("/");

  const activePosition = careers.find(pos => {
    return pos.id === Number(splitedURL[splitedURL.length - 1]);
  })!;

  const onAgreeChange = () => {
    setUserAgreed(!userAgreed);
  };

  const onFormSubmit = async (event: any) => {
    event.preventDefault();
    setError(null);
    setLoading(true);
    const form = event.target;

    // Form values
    const firstName = form?.firstName?.value;
    const lastName = form?.lastName?.value;
    const email = form.email?.value;
    const phone = form.phone?.value;
    const profileUrl = form.profileUrl?.value;
    const resume = form.resume?.files[0];
    const note = form.note?.value;
    const hearAboutUs = form.hearAboutUs?.value;

    // Validation
    const firstNameValid = validate({ value: firstName, setValueEmpty: setFirstNameEmpty });
    const lastNameValid = validate({ value: lastName, setValueEmpty: setLastNameEmpty });
    const emailValid = validate({ value: email, setValueEmpty: setEmailEmpty });
    const phoneValid = validate({ value: phone, setValueEmpty: setPhoneEmpty });

    if (!resume) {
      setFileError(activePhrases!["resume_is_required"]);
      return;
    }

    if (!firstNameValid || !lastNameValid || !emailValid || !phoneValid) {
      return;
    }

    // Job Application creation
    const data: CreateJobApplicationProps = {
      firstName,
      lastName,
      email,
      phone,
      resume,
      note,
      hearAboutUs,
      linkedIn: profileUrl,
    };

    const jobApplication = await createJobApplication(data);
    if (!jobApplication) {
      setError(activePhrases!["error_during_submitting_application"]);
      setLoading(false);
      return;
    }

    // Resume Upload
    const uploadedResume = await uploadResume({
      applicationId: jobApplication!.id,
      resume: resume,
    });
    if (!uploadedResume) {
      setError(activePhrases!["error_during_resume_uploading"]);
      setLoading(false);
      return;
    }

    setLoading(false);
    setSuccess(true);

    setTimeout(() => {
      setUploadedFileName(activePhrases!["no_file_uploaded"]);
      setSuccess(false);
      form.reset();
    }, 2000);
  };

  const onUploadClick = (e: any) => {
    const uploadedFile = e.target.files[0];

    if (!uploadedFile) {
      return;
    }

    //Check if uploaded file is bigger than the limit
    if (uploadedFile.size > MAX_RESUME_SIZE) {
      setFileError(activePhrases!["resume_cant_be_bigger_than_2MB"]);
      return;
    }

    //Check if file name is longer than 25 characters and if it is cut the name to the 25th character else set the full name
    const fileName =
      uploadedFile.name.length > 25 ? `${uploadedFile.name.substr(0, 25)}...` : uploadedFile.name;
    setFileError(null);
    setUploadedFileName(fileName);
  };

  const onBackClick = () => {
    dispatch(push(config.routes.careers));
  };

  const buttonContent = loading ? (
    <img
      src={loadingIcon}
      className={clsx(classes.submitButtonIcon, classes.loadingIcon)}
      alt="loading"
    />
  ) : success ? (
    <img src={checkIcon} className={clsx(classes.submitButtonIcon)} alt="success" />
  ) : undefined;

  const isMobile = window.innerWidth < 600;

  return (
    <div className={classes.root}>
      <CarouselLinear
        slides={isMobile ? slidesMobile : slides}
        className={{ root: classes.carousel, dots: classes.dots, dot: classes.dot }}
      />
      <Panel className={{ panel: classes.panel }}>
        <div className={classes.title}>{activePhrases && activePhrases["careers"]}</div>
      </Panel>
      <div className={classes.arrowWrapper} onClick={onBackClick}>
        <div className={classes.arrowText}>
          {activePhrases && activePhrases["back_to_job_positions"]}
        </div>
        <BackArrow className={classes.arrowIcon} />
      </div>
      <div className={classes.content}>
        <form className={classes.positionForm} autoComplete="off" onSubmit={onFormSubmit}>
          <div className={clsx(classes.positionTitle, classes.formTitle)}>
            {activePhrases && activePhrases["apply"]}
          </div>
          <div className={classes.positionInputs}>
            <div className={classes.positionInputItem}>
              <label className={classes.positionInputText} htmlFor="firstName">
                {activePhrases && activePhrases["first_name"]}
                <span className={classes.formFieldRequired}>*</span>
              </label>
              <input
                className={classes.positionInput}
                type="text"
                id="firstName"
                name="firstName"
              />
              <span
                className={clsx(
                  classes.formFieldError,
                  firstNameEmpty && classes.formFieldErrorEnabled
                )}
              >
                {activePhrases && activePhrases["required_field"]}
              </span>
            </div>
            <div className={classes.positionInputItem}>
              <label className={classes.positionInputText} htmlFor="lastName">
                {activePhrases && activePhrases["last_name"]}
                <span className={classes.formFieldRequired}>*</span>
              </label>
              <input className={classes.positionInput} type="text" id="lastName" name="lastName" />
              <span
                className={clsx(
                  classes.formFieldError,
                  lastNameEmpty && classes.formFieldErrorEnabled
                )}
              >
                {activePhrases && activePhrases["required_field"]}
              </span>
            </div>
            <div className={classes.positionInputItem}>
              <label className={classes.positionInputText} htmlFor="email">
                {activePhrases && activePhrases["email"]}
                <span className={classes.formFieldRequired}>*</span>
              </label>
              <input className={classes.positionInput} type="text" id="email" name="email" />
              <span
                className={clsx(
                  classes.formFieldError,
                  emailEmpty && classes.formFieldErrorEnabled
                )}
              >
                {activePhrases && activePhrases["required_field"]}
              </span>
            </div>
            <div className={classes.positionInputItem}>
              <label className={classes.positionInputText} htmlFor="phone">
                {activePhrases && activePhrases["phone_number"]}
                <span className={classes.formFieldRequired}>*</span>
              </label>
              <input className={classes.positionInput} type="text" id="phone" name="phone" />
              <span
                className={clsx(
                  classes.formFieldError,
                  phoneEmpty && classes.formFieldErrorEnabled
                )}
              >
                {activePhrases && activePhrases["required_field"]}
              </span>
            </div>
            <div className={classes.positionInputItem}>
              <label className={classes.positionInputText} htmlFor="linkedin">
                {activePhrases && activePhrases["LinkedIn_Profile_URL"]}&nbsp;
              </label>
              <input className={classes.positionInput} type="text" id="linkedin" name="linkedin" />
            </div>
          </div>
          <div className={classes.positionInputText}>
            {activePhrases && activePhrases["resume"]}
            <span className={classes.formFieldRequired}>*</span>
          </div>
          <div className={classes.upload}>
            <span
              className={clsx(classes.uploadedFileName, fileError && classes.uploadedFileError)}
            >
              {fileError ? fileError : uploadedFileName}
            </span>
            <label className={classes.formUploadLabel} htmlFor="resume">
              {activePhrases && activePhrases["upload_file"]}
            </label>
            <input
              id="resume"
              name="resume"
              onChange={onUploadClick}
              className={classes.formUploadInput}
              type="file"
            />
          </div>
          <div className={classes.positionInputItem}>
            <label className={classes.positionInputText} htmlFor="note">
              {activePhrases && activePhrases["personal_note"]}
            </label>
            <textarea
              className={clsx(classes.positionInput, classes.textarea)}
              rows={8}
              cols={54}
              id="note"
              name="note"
            />
          </div>
          <p className={classes.agreeTermsConditionsText}>
            By clicking the "Submit" button, you agree and consent to Bambino Terms and Conditions
          </p>
          <label htmlFor="IAgree" className={classes.checkboxLabel}>
            I agree that you can keep my data for an extended time period so that it will be easier
            for you to contact me about job opportunities.
            <input
              className={classes.checkBoxInput}
              onChange={onAgreeChange}
              type="checkbox"
              id="IAgree"
              name="IAgree"
            />
            <span className={classes.checkBoxSpan}></span>
          </label>
          <Button
            disabled={!userAgreed}
            className={clsx(classes.submitBtn, userAgreed && classes.submitBtnEnabled)}
            text={!buttonContent ? activePhrases && activePhrases["submit"] : ""}
            icon={buttonContent}
            textSize={19}
            borderRadius={5}
            textColor={theme.colors.text}
            width={200}
            height={45}
          />
          <span className={clsx(classes.submitError, error && classes.submitErrorEnabled)}>
            {error}
          </span>
        </form>
        <div className={classes.positionInfo}>
          <div className={classes.positionTitle}>{activePosition?.name!}</div>
          <div className={classes.positionText}>{activePosition?.jobDescription!}</div>
          <div className={classes.positionSubTitle}>
            {activePhrases && activePhrases["why_join_our_dream_team"]}
          </div>
          <div className={classes.positionText}>{activePosition?.teamDescription!}</div>
          <div className={classes.positionSubTitle}>
            {activePhrases && activePhrases["your_role"]}
          </div>
          <div className={classes.positionText}>{activePosition?.role!}</div>
          <div className={classes.positionSubTitle}>
            {activePhrases && activePhrases["skills_and_experiance"]}
          </div>
          <div className={classes.positionList}>
            {activePosition?.skills?.map((s, i) => (
              <div key={s.id} className={classes.positionListItem}>
                <div className={classes.positionText}>
                  {i < activePosition?.skills?.length - 1 ? ";" : "."}
                  {s.name}
                </div>
                <div className={classes.circle} />
              </div>
            ))}
          </div>
          <div className={classes.positionSubTitle}>
            {activePhrases && activePhrases["our_offer"]}
          </div>
          <div className={classes.positionList}>
            {activePosition?.offers?.map((o, i) => (
              <div key={o.id} className={classes.positionListItem}>
                <div className={classes.positionText}>
                  {i < activePosition?.offers?.length - 1 ? ";" : "."}
                  {o.name}
                </div>
                <div className={classes.circle} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PositionPage;

const useStyles = makeStyles(
  theme => ({
    "@keyframes rotation": {
      from: {
        transform: "rotate(0deg)",
      },
      to: {
        transform: "rotate(359deg)",
      },
    },
    "@keyframes pop": {
      "50%": {
        transform: "scale(1.2)",
      },
    },
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    carousel: {
      [theme.device.mobile()]: {
        minHeight: 100,
      },
    },
    dots: {
      marginBottom: 20,
      [theme.device.mobile()]: {
        gridColumnGap: 15,
      },
    },
    dot: {
      height: 22,
      width: 22,
      [theme.device.mobile()]: {
        height: 17,
        width: 17,
      },
    },
    panel: {
      justifyContent: "center",
      padding: 0,
    },
    title: {
      color: theme.colors.text,
      fontSize: 40,
      height: 65,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    content: {
      fontFamily: "Poppins, sans-serif",
      width: "100%",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      boxSizing: "border-box",
      padding: "0 60px",
      [theme.device.smallDesktop()]: {
        display: "flex",
        flexFlow: "column-reverse wrap",
      },
      [theme.device.mobile()]: {
        padding: 20,
        paddingTop: 0,
      },
    },
    arrowWrapper: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gridColumnGap: 7,
      margin: "80px 60px 40px auto",
      [theme.device.mobile()]: {
        margin: 20,
        marginLeft: "auto",
      },
    },
    arrowIcon: {
      marginTop: 5,
      transform: "scaleX(-1)",
      ...theme.utils.svgChangeColor(theme.colors.green),
    },
    arrowText: {
      fontWeight: 600,
      color: theme.colors.green,
      fontSize: 24,
    },
    positionInfo: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "flex-end",
      [theme.device.smallDesktop()]: {
        gridRowStart: 1,
      },
    },
    positionForm: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      boxSizing: "border-box",
      paddingRight: 80,
      [theme.device.smallDesktop()]: {
        padding: 0,
        marginTop: "5rem",
      },
      [theme.device.mobile()]: {
        marginTop: "3rem",
      },
    },
    positionTitle: {
      textAlign: "end",
      fontSize: 45,
      color: theme.colors.black,
      fontWeight: 600,
      paddingBottom: 10,
    },
    positionSubTitle: {
      textAlign: "end",
      padding: "35px 0 15px 0",
      textTransform: "uppercase",
      fontSize: 35,
      color: theme.colors.black,
      fontWeight: 700,
    },
    positionText: {
      fontSize: 18,
    },
    positionList: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    positionListItem: {
      display: "flex",
      gridColumnGap: 8,
      alignItems: "center",
    },
    positionInputs: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gridRowGap: 10,
      marginBottom: 40,
    },
    positionInputItem: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      gridRowGap: 5,
    },
    positionInputText: {
      cursor: "text",
      fontSize: 21,
    },
    positionInput: {
      textAlign: "end",
      boxSizing: "border-box",
      fontSize: 21,
      padding: "10px 15px",
      width: "100%",
      borderRadius: 7,
      border: `2px solid ${theme.colors.green}`,
    },
    upload: {
      display: "flex",
      gridColumnGap: 5,
      alignItems: "center",
      marginBottom: 15,
    },
    formUploadLabel: {
      cursor: "pointer",
      color: theme.colors.text,
      backgroundColor: theme.colors.green,
      padding: "7px 20px",
      borderRadius: 7,
      fontSize: "1.2rem",
      whiteSpace: "nowrap",
    },
    formUploadInput: {
      width: 0.1,
      height: 0.1,
      opacity: 0,
      overflow: "hidden",
      position: "absolute",

      zIndex: -1,
    },
    uploadedFileName: {
      marginLeft: 5,
      wordBreak: "break-word",
    },
    uploadedFileError: {
      color: theme.colors.red,
      fontSize: "0.9rem",
    },
    circle: {
      width: 7,
      height: 7,
      borderRadius: "100%",
      backgroundColor: theme.colors.green,
    },
    socialIcon: {
      ...theme.utils.svgFill(theme.colors.green),
    },
    textarea: {
      resize: "vertical",
      minHeight: 100,
    },
    formFieldRequired: {
      color: theme.colors.green,
    },
    checkboxLabel: {
      textAlign: "end",
      position: "relative",
      paddingRight: 30,
      cursor: "pointer",
    },
    checkBoxInput: {
      position: "absolute",
      opacity: 0,
      width: 0,
      height: 0,
      "&:checked ~ span": {
        backgroundColor: theme.colors.green,
      },
    },
    agreeTermsConditionsText: {
      textAlign: "end",
      padding: "50px 0 10px 0",
    },
    checkBoxSpan: {
      position: "absolute",
      top: 2,
      right: 0,
      width: 20,
      height: 20,
      backgroundColor: theme.colors.text,
      borderColor: theme.colors.green,
      borderWidth: 3,
      borderStyle: "solid",
      borderRadius: "50%",
      "&:after": {
        content: "",
        position: "absolute",
        display: "none",

        /* check icon */
        left: 6,
        top: 2,
        width: 4,
        height: 10,
        borderColor: theme.colors.text,
        borderStyle: "solid",
        borderWidth: "0 3px 3px 0",
      },
    },
    submitBtn: {
      cursor: "default",
      backgroundColor: theme.colors.grayText,
      margin: "15px auto 0 auto",
    },
    submitBtnEnabled: {
      cursor: "pointer",
      backgroundColor: theme.colors.green,
    },
    submitError: {
      display: "none",
      color: theme.colors.red,
    },
    submitErrorEnabled: {
      display: "inline",
      margin: "5px auto 0 auto",
    },
    formFieldError: {
      display: "none",
      color: theme.colors.red,
    },
    formFieldErrorEnabled: {
      display: "inline",
    },
    submitButtonIcon: {
      width: 30,
      height: 30,
      animation: "$pop 0.3s linear 1",
    },
    loadingIcon: {
      animation: "$rotation 2s infinite linear",
    },
    formTitle: {
      fontSize: 30,
    },
    active: {
      transition: "0.3s",
      opacity: 1,
      visibility: "visible",
    },
  }),
  { name: "position-page" }
);
