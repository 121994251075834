import { makeStyles, useTheme, CreateCSSProperties } from "@material-ui/styles";
import { useSelector, shallowEqual } from "react-redux";
import React, { useState, useEffect } from "react";
import { push } from "connected-react-router";
import { isMobile } from "react-device-detect";
import clsx from "clsx";

import ProviderAuthButton from "./providerAuthButton";
import Button from "../common/button";
import Input from "../common/input";

import { useAppDispatch, useAppSelector } from "../../store/store";
import { Authentication, Favorites, User } from "../../services/users/types";
import { ApplicationState } from "../../store/root-reducer";
import { validateAuth } from "../../utils/validateForm";
import authFormStore from "../../store/auth-form-store";
import languagesStore, { Language } from "../../store/languages-store";
import { getPhrase, NewPhrase } from "../../utils/language";
import userStore from "../../store/users-store";
import { config } from "../../config";

import { ReactComponent as CloseIcon } from "../../static/icons/close.svg";
import { removeDuplicateProducts } from "../../services/products/products-service";

type TAuthField = {
  additional: boolean;
  login: boolean;
  label: NewPhrase;
  type?: string;
  name: string;
};

const googleLogo = require("../../static/images/google-logo-1200px.png");
const facebookLogo = require("../../static/images/facebook-logo.png");

const authFields: TAuthField[] = [
  {
    label: "email",
    login: true,
    additional: false,
    name: "email",
  },
  {
    label: "password",
    login: true,
    additional: false,
    name: "password",
    type: "password",
  },
  {
    label: "verify_password",
    login: false,
    additional: false,
    name: "verifyPassword",
    type: "password",
  },
  {
    label: "address",
    login: false,
    additional: false,
    name: "address",
  },
  {
    label: "first_name",
    login: false,
    additional: false,
    name: "firstName",
  },
  {
    label: "last_name",
    login: false,
    additional: false,
    name: "lastName",
  },
  {
    label: "phone_number",
    login: false,
    additional: true,
    name: "phoneNumber",
  },
  {
    label: "additional_phone_number",
    login: false,
    additional: true,
    name: "additionalPhoneNumber",
  },
];

type TProps = {
  // setActiveAuth?: (activeAuth: boolean) => void;
  className?: string;
};

type FormState = {
  [stateProps: string]: string;
};

// type InputErrors = FormState;

type InputErrors = {
  [stateProps: string]: string;
};

type ServerSignError = string | Error | null;

const Auth = ({ className }: TProps): JSX.Element => {
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const initialFormState = {
    id: "",
    email: "",
    password: "",
    verifyPassword: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    additionalPhoneNumber: "",
    address: "",
  };

  const [inputErrors, setInputErrors] = useState<InputErrors>({});
  const [formState, setFormState] = useState<FormState>(initialFormState);
  const [serverSignError, setServerSignError] = useState<ServerSignError>(null);
  const [authentication, setAuthentication] = useState<Authentication>("login");

  const [additional, setAdditional] = useState<boolean>(false);

  const user = useSelector((state: ApplicationState) => state.user.data, shallowEqual);
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const onAuthItemClick = (type: Authentication): void => {
    setInputErrors({});
    setAuthentication(type);
    setFormState(initialFormState);
  };

  const isAuthLogin = authentication === "login";

  const {
    email,
    password,
    verifyPassword,
    firstName,
    lastName,
    phoneNumber,
    additionalPhoneNumber,
    address,
  } = formState;

  const handleFormSubmit = (): void => {
    setInputErrors({});
    setServerSignError(null);
    const lastLogin = new Date().toString();

    const credentials: User = isAuthLogin
      ? {
          identifier: email,
          password,
          signType: authentication,
          lastLogin,
        }
      : {
          email,
          username: email,
          firstName,
          lastName,
          password,
          phoneNumber,
          additionalPhoneNumber,
          address,
          signType: authentication,
          lastLogin,
          registrationDate: lastLogin,
        };

    const validationObject = validateAuth(
      { ...credentials, verifyPassword },
      activePhrases,
      isAuthLogin
    );

    if (!validationObject.isValid) {
      setInputErrors(validationObject.errors);
    } else {
      setInputErrors({});
      setServerSignError("");
      dispatch(userStore.actions.clearServerSignErrors());
    }

    if (validateAuth({ ...credentials, verifyPassword }, activePhrases, isAuthLogin).isValid) {
      dispatch(userStore.actions.signUser(credentials)).then(response => {
        const responsePayload: any = response.payload;
        if (responsePayload.errorMessage && !isAuthLogin) {
          const takenEmailError: string = activePhrases
            ? activePhrases["email_is_already_taken"]
            : "אימייל שגוי";

          setInputErrors({ email: takenEmailError });

          return;
        } else if (responsePayload.errorMessage && isAuthLogin) {
          const errorMessage: string = activePhrases
            ? activePhrases["identifier_or_password_invalid"] || "המזהה או הסיסמה לא תקפים"
            : "המזהה או הסיסמה לא תקפים";

          setInputErrors({ email: errorMessage, password: errorMessage });

          return;
        }
        dispatch(authFormStore.actions.toggleVisibility("authFormIsVisible", false));
      });
    }
  };

  useEffect(() => {
    if (!!user?.errorMessage) {
      setServerSignError(user?.errorMessage);
      dispatch(authFormStore.actions.toggleVisibility("authFormIsVisible", true));
      return;
    } else if (!!user?.error) {
      setServerSignError(user?.error);
      dispatch(authFormStore.actions.toggleVisibility("authFormIsVisible", true));
      return;
    }

    if (!!user && !user?.errorMessage) {
      dispatch(authFormStore.actions.toggleVisibility("authFormIsVisible", false));
    } else if (!!user && !user?.error) {
      dispatch(authFormStore.actions.toggleVisibility("authFormIsVisible", false));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    const userId = user!.id.toString();

    const favouriteItemsFromStorage = sessionStorage.getItem("favourites");
    if (favouriteItemsFromStorage) {
      const favouriteItemsToAdd: Favorites[] = JSON.parse(favouriteItemsFromStorage);
      const updatedFavouritesList = [...user.favoriteCatalogItems, ...favouriteItemsToAdd];
      const favouritesList = removeDuplicateProducts(updatedFavouritesList);
      dispatch(
        userStore.actions.toggleFavoriteCatalogItem({
          userId,
          favoriteCatalogItems: favouritesList,
        })
      );

      sessionStorage.removeItem("favourites");
    }
  }, [dispatch, user]);

  useEffect(() => {
    setServerSignError("");
  }, [authentication]);
  const [leftPadding, setLeftPadding] = useState(0);
  const classes = useStyles({ leftPadding });

  useEffect(() => {
    let windoWidth = (window.innerWidth - 580) / 2;

    setLeftPadding(windoWidth);
  });

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.header}>
        <div className={classes.navigation}>
          <div
            className={clsx(classes.navigationItem, !isAuthLogin && classes.navigationItemActive)}
            onClick={(): void => onAuthItemClick("sign_up")}
          >
            {activePhrases && activePhrases["sign_up"]}
          </div>

          <span className={classes.separator} />

          <div
            className={clsx(classes.navigationItem, isAuthLogin && classes.navigationItemActive)}
            onClick={(): void => onAuthItemClick("login")}
          >
            {activePhrases && activePhrases["login"]}
          </div>
        </div>

        <CloseIcon
          className={classes.close}
          onClick={(): void => {
            setInputErrors({});
            setServerSignError("");
            setFormState(initialFormState);
            dispatch(authFormStore.actions.toggleVisibility("authFormIsVisible", false));
            dispatch(userStore.actions.clearServerSignErrors());
          }}
        />
      </div>

      <div className={classes.content}>
        {isAuthLogin ?
      <Button
          borderRadius={25}
          width={156}
          height={30}
          fill={theme.colors.blue}
          textSize={16}
          className={classes.authBtn}
          text={activePhrases && activePhrases["guest_loggin"]}
          textColor={theme.colors.white}
          action={() => {
            if (isMobile) {
              dispatch(push(config.routes.homePage, true));
            } else {
              dispatch(authFormStore.actions.toggleVisibility("authFormIsVisible", false));
            }
            dispatch(authFormStore.actions.toggleVisibility("cartIsVisible", true));
          }}
        />
: null}
{isAuthLogin ? (
          <p style={{textAlign: 'center', color: theme.colors.blue}}>הרשמו לרשימת הדיוור שלנו ותיהנו ממגוון הטבות בלעדיות קופונים ותוכן שווה לכל הורה</p>
        ) : null}
        <form
          noValidate
          autoComplete="off"
          className={clsx(!isAuthLogin && classes.signUp, isAuthLogin && classes.login)}
        >
          {authFields
            .filter(field => (isAuthLogin ? field.login : field))
            .map((field, i) => {
              const isAdditionalForm = field.name === "additionalPhoneNumber";

              const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
                const value =
                  e.currentTarget.name === "password" || e.currentTarget.name === "verifyPassword"
                    ? e.currentTarget.value.replace(/ /g, "")
                    : e.currentTarget.value;

                setFormState({
                  ...formState,
                  [e.currentTarget.name]: value,
                });
              };

              const onCircleClick = (): void => setAdditional(current => !current);
              const label = activePhrases && activePhrases[field.label];
              return (
                <Input
                  key={field.name}
                  label={`${isAdditionalForm ? "" : "*"} ${label}`}
                  name={field.name}
                  type={field.type!}
                  inputValue={formState[field.name]}
                  additional={field.additional}
                  isAdditionalForm={isAdditionalForm}
                  errorMsg={inputErrors[field.name]!}
                  className={{
                    root: clsx(
                      isAuthLogin && classes.loginItem,
                      isAdditionalForm && classes.additional,
                      isAdditionalForm && additional && classes.additionalActive
                    ),
                    input: clsx(!!inputErrors[field.name] && classes.error),
                    circle: clsx(!isAdditionalForm && additional && classes.inactive),
                  }}
                  onCircleClick={onCircleClick}
                  onInputChange={onInputChange}
                />
              );
            })}
        </form>

        <Button
          borderRadius={25}
          width={156}
          height={30}
          textSize={16}
          className={classes.authBtn}
          text={
            isAuthLogin
              ? activePhrases && activePhrases["login"]
              : activePhrases && activePhrases["sign_up"]
          }
          fill={theme.colors.blue}
          textColor={theme.colors.text}
          action={handleFormSubmit}
        />

        <ProviderAuthButton
          providerName="google"
          text={
            isAuthLogin
              ? activePhrases && activePhrases["login_google"]
              : activePhrases && activePhrases["sign_up_google"]
          }
          logoUrl={googleLogo}
          imgSize={{
            width: 20,
            height: 20,
          }}
        />

        <ProviderAuthButton
          className="fb-btn"
          providerName="facebook"
          text={
            isAuthLogin
              ? activePhrases && activePhrases["login_facebook"]
              : activePhrases && activePhrases["sign_up_facebook"]
          }
          logoUrl={facebookLogo}
          imgSize={{
            width: 20,
            height: 20,
          }}
        />

        <span className={classes.errorMsg}>{serverSignError}</span>
      </div>
    </div>
  );
};

export default Auth;

type CSSProps = {
  leftPadding: number;
};

const useStyles = makeStyles(
  theme => ({
    root: ({ leftPadding }: CSSProps): CreateCSSProperties => ({
      display: "flex",
      flexDirection: "column",
      width: "580px",
      position: "relative",
      zIndex: 7,
      top: 0,
      left: leftPadding,
      backgroundColor: theme.colors.text,
      [theme.device.smallTablet()]: {
        paddingBottom: 40,
        width: "80%",
        border: "none",
        left: "10%",
      },
      [theme.device.mobile()]: {
        paddingBottom: 40,
        width: "100%",
        border: "none",
        left: 0,
      },
    }),
    header: {
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
      backgroundColor: theme.colors.blue,
      position: "relative",
      padding: 10,
      width: "100%",
    },
    content: {
      border: `2px solid ${theme.colors.blue}`,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxSizing: "border-box",
      padding: 10,
      [theme.device.mobile()]: {
        border: "none",
      },

      "&>a.fb-btn": {
        color: theme.colors.blue,
      },
    },
    signUp: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "repeat(4, 1fr)",
      gridAutoFlow: "column",
      justifyItems: "center",
      marginBottom: 25,
      [theme.device.mobile()]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
        paddingRight: 25,
        paddingBottom: 25,
      },
    },
    login: {
      width: "100%",
      display: "-webkit-inline-box",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: 25,
      [theme.device.mobile()]: {
        boxSizing: "border-box",
        paddingRight: 25,
        display: 'flex'
      },
    },
    close: {
      cursor: "pointer",
      position: "absolute",
      right: 0,
      marginRight: 10,
      ...theme.utils.svgStroke(theme.colors.text),
      height: 22,
      width: 22,
    },
    navigation: {
      display: "flex",
    },
    separator: {
      height: "100%",
      width: 2,
      backgroundColor: theme.colors.text,
      margin: "0 35px",
    },
    navigationItem: {
      cursor: "pointer",
      color: theme.colors.orange,
      fontWeight: 600,
      fontSize: 17,
      transition: "0.2s",
      userSelect: "none",
      [theme.device.mobile()]: {
        fontSize: 25,
      },
    },
    navigationItemActive: {
      cursor: "default",
      color: theme.colors.text,
      textDecoration: "underline",
    },
    authBtn: {
      margin: "3px 0",
      [theme.device.mobile()]: {
        padding: "10px 35px",
        fontSize: 20,
      },
    },
    loginItem: {
      [theme.device.desktop()]: {
        paddingLeft: 0,
      },
    },
    formInput: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      [theme.device.mobile()]: {
        width: "100%",
      },
    },
    formInputLabel: {
      cursor: "default",
      userSelect: "none",
      color: theme.colors.orange,
      fontSize: 15,
      fontWeight: 600,
      padding: "8px 0",
      [theme.device.mobile()]: {
        fontSize: 18,
      },
    },
    formInputContent: {
      transition: "0.3s",
      width: 200,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      position: "relative",
      [theme.device.mobile()]: {
        width: "100%",
        paddingLeft: 35,
        boxSizing: "border-box",
      },
    },
    input: {
      boxSizing: "border-box",
      padding: "5px 10px",
      fontSize: 16,
      width: "100%",
      borderRadius: 25,
      border: `1px solid ${theme.colors.green}`,
      outline: "none",
      textAlign: "end",
      color: theme.colors.blue,
      [theme.device.mobile()]: {
        padding: "12px 15px",
        fontSize: 19,
      },
      "&.error": {
        border: `1px solid ${theme.colors.red}`,
        boxShadow: `0 0 2px 0 ${theme.colors.red}`,
      },
    },
    circle: {
      transition: "0.3s",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: "60%",
      left: "-25px",
      outline: "none",
      border: "none",
      cursor: "pointer",
      width: 18,
      height: 18,
      borderRadius: "100%",
      backgroundColor: theme.colors.green,
      [theme.device.mobile()]: {
        left: "0px",
        width: 25,
        height: 25,
      },
    },
    circleText: {
      userSelect: "none",
      marginBottom: 3,
      fontWeight: 700,
      fontSize: 18,
      color: "#fff",
      [theme.device.mobile()]: {
        fontSize: 21,
      },
    },
    additional: {
      transform: "translateY(-100%)",
      opacity: 0,
      visibility: "hidden",
      [theme.device.mobile()]: {
        height: 0,
      },
    },
    additionalActive: {
      transform: "translateY(0)",
      visibility: "visible",
      opacity: 1,
      height: "auto",
    },
    active: {
      visibility: "visible",
      opacity: 1,
    },
    inactive: {
      cursor: "default",
      opacity: 0,
      visibility: "hidden",
      transition: "0.4s",
    },
    error: {
      borderColor: theme.colors.red,
    },
    errorMsg: {
      textAlign: "end",
      fontSize: "12px",
      marginTop: "4px",
      color: theme.colors.red,
    },
    google: {
      width: "16%",
      display: "inline-block",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      padding: "0.3em 1em",
      borderRadius: "3em",
      position: "relative",
      zIndex: 10,
      backdropFilter: "blur(40px)",
      border: "solid 2px transparent",
      backgroundClip: "padding-box",
      textAlign: "center",
    },
  }),
  { name: "auth" }
);
