import React, { useEffect, useState } from "react";
import { CreateCSSProperties, makeStyles, useTheme } from "@material-ui/styles";
import { useAppSelector } from "../store/store";
import { isMobile, isTablet } from "react-device-detect";
import clsx from "clsx";



type CSSProps = {
  containerHeight: number;
};


const VideoSignature = () => {
  const theme = useTheme();
  const [containerHeight, setContainerHeight] = useState(0);
  const { VideoImage, youtube_video_url } = useAppSelector(state => state.signatureMode);

  useEffect(() => {
    const currContainerHeight = window.innerWidth * 0.28;
    setContainerHeight(currContainerHeight);
  });
  const classes = useStyles({ containerHeight });

  const buildYoutubeUrl = (url: string): string => {
    if (!url) {
      return "";
    }
    const URL_TEMPLATE = "https://www.youtube.com/embed/";

    if (!new URL(url).searchParams.get("v")) {
      return url;
    }
    return URL_TEMPLATE + new URL(url).searchParams.get("v");
  };


  return (
    window.innerWidth < 670
      ?
      <>
        <div id="youtube_video" className={classes.simplyTimelessMobile} >
          <iframe width='100%' height="100%" src={buildYoutubeUrl(youtube_video_url)} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        </div>
        <div className={classes.mobileImageUnderVideo}>
          <img className={classes.img} src={VideoImage?.url} alt="explore background" />
        </div>
      </>

      :
      <div className={classes.SimplyTimeless}>
        <div className={classes.SimplyTimelessContainer}>
          <div className={clsx(classes.leftContainer, classes.signatureImage)}>
            <img className={classes.img} src={VideoImage?.url} alt="explore background" />
          </div>

          <div className={clsx(classes.rightContainer)}>
            <iframe className={classes.iframe} width="100%" height="100%" src={buildYoutubeUrl(youtube_video_url)} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>

            {/* <div className={classes.iframeContainer}>
              <iframe className={classes.iframe} src={youtube_video_url} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </div> */}

          </div>
        </div>
      </div>

  );
}

const useStyles = makeStyles(theme => ({
  SimplyTimeless: {
    backgroundColor: "#a0998e",
    // paddingTop: "52px",
    fontFamily: "Montserrat, sans-serif",
    width: "100%",
    height: "100%",
  },

  SimplyTimelessContainer: ({ containerHeight }: CSSProps): CreateCSSProperties => ({
    display: "flex",
    maxWidth: "100%",
    height: containerHeight,

    [theme.device.tablet()]: {
      height: "300px",
      width: "100%"
    },

    // [theme.device.tablet()]: {
    //   height: "300px",
    // },

    // smallTablet
  }),

  signatureImage: {
    [theme.device.tablet()]: {
      display: "none"
    },
  },
  iframe: {
    [theme.device.tablet()]: {
      width: "100%!important",
      height: "100%!important"
    },
  },

  leftContainer: {
    display: "inline-block",
    width: "45%",
    height: "100%",
  },
  mobileImageUnderVideo: {

  },
  rightContainer: {
    textAlign: "center",
    display: "inline-block",
    width: "55%",
    height: "100%",
    margin: "0 auto",
  },
  title: {
    margin: "0 auto",
    display: "inline-block",
    fontSize: "2.5rem",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "4px",
    color: "rgba(0,0,0,0.7)",
    paddingBottom: "3px",
    borderBottom: "4px solid" + theme.colors.orange,
    marginBottom: "23px",
  },
  textContent: {
    fontSize: "1.25rem",
    textAlign: "center",
    margin: "0 auto",
    width: "55%",
    color: "black",
    fontWeight: 300,
    letterSpacing: "2px",
  },
  img: {
    width: "100%",
    height: "100%",
  },

  simplyTimelessMobile: {
    margin: "0 auto",
    width: "100%",

    [theme.device.mediumMobile()]: {
      // height: "calc(100vh - 420px)",
      // "&> iframe": {
      //   marginTop: "0px!important",
      //   height: "100%"
      // }
      margin: 0,
      height: '220px'
      //height: '320px'
    },

  },
}),
  { name: "simply-teimeless" }
);

export default VideoSignature;
