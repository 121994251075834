import { makeStyles } from "@material-ui/styles";
import { push } from "connected-react-router";
import React from "react";
import { config } from "../../config";
import type { Category, CCategory, ChidlCategory } from "../../services/categories/types";
import categoriesStore from "../../store/categories-store";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { Helmet } from "react-helmet";
const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: 20,
    },
    title: {
      cursor: "pointer",
      fontWeight: "bold",
      fontSize: 21,
      color: theme.colors.darkText,
      whiteSpace: "nowrap",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    item: {
      lineHeight: "120%",
      cursor: "pointer",
      fontSize: 19,
      color: theme.colors.darkText,
      whiteSpace: "nowrap",
    },
  }),
  { name: "subcategory-item" }
);

type TProps = {
  subcategory: any;
  setCurrentCategory: (id: number | null) => void;
};

const SubcategoryItem = ({ subcategory, setCurrentCategory }: TProps): JSX.Element => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const categoriesData = useAppSelector(state => state.categories.categoriesData.data);

  const onSubCategoryClick = () => {
    dispatch(
      push(
        `${config.routes.productsPage}/${subcategory?.parent?.id}` + `?sub=${subcategory?.id}`,
        true
      )
    );

    dispatch(categoriesStore.actions.setActiveSubCategory(subcategory?.id!));
    dispatch(categoriesStore.actions.setActiveSubSubCategory(null));

    setCurrentCategory(null);
  };

  const onSubSubCategoryClick =
    (subcategory: Category, subSubcategory: Category | undefined) => () => {
      dispatch(
        push(
          `${config.routes.productsPage}/${subcategory.parent?.id}` + `?sub_sub=${subSubcategory?.id}`,
          true
        )
      );

      dispatch(categoriesStore.actions.setActiveSubCategory(subSubcategory?.parent.id));
      dispatch(categoriesStore.actions.setActiveSubSubCategory(subSubcategory?.id!));

      setCurrentCategory(null);
    };

  const items = categoriesData
    .filter(cat => cat.parent?.id === subcategory?.id)
    ?.map((subSubCat: any) => {
      return (
        <div
          key={subSubCat?.id}
          className={classes.item}
          onClick={onSubSubCategoryClick(subcategory, subSubCat)}
        >
          {subSubCat?.name}
        </div>
      );
    });

  return (
    <>
      <div className={classes.root}>
        <div className={classes.title} onClick={onSubCategoryClick}>
          {subcategory?.name}
        </div>
        <div className={classes.container}>{items}</div>
      </div>
    </>
  );
};

export default SubcategoryItem;
