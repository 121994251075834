import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { getPhrase } from "../../utils/language";
import { useAppSelector, useAppDispatch } from "../../store/store";
import clsx from "clsx";
import slide from "../../static/images/middle_img.png";
import { push } from "connected-react-router";
import { config } from "../../config";
import Button from "./button";
import highChairs from "../../static/images/joie_productcat_3.png";
import cotsnCribs from "../../static/images/joie_productcat_4.png";
import soothers from "../../static/images/joie_productcat_5.png";
import pushChairs from "../../static/images/joie_productcat_1.png";
import carSeats from "../../static/images/joie_productcat_2.png";
import partnerBanner from "../../static/images/partner_banner.jpg";
import { useStyles } from '../pages/home-page';
import joinJoie from "../../static/images/join_joie_img.png";
import languagesStore from "../../store/languages-store";
import { UnderCrouselElements } from '../../services/site-config-service/types';
import LeftArrow from "../../static/icons/Path159.svg";
import RightArrow from "../../static/icons/Path160.svg";
import { useHistory } from "react-router";

export const UnderCarousel = () => {

  const classes = useStyles();
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const UnderCrouselElements = useAppSelector(state => state.siteConfig.UnderCrouselElements);
  const history = useHistory();

  const [currIndex, setCurrIndex] = useState(1);

  let isMobile = window.innerWidth < 730;

  useEffect(() => {
    isMobile = window.innerWidth < 730;
  }, [window.innerWidth]);

  const handleLeftArrowClick = (e: any) => {
    if (currIndex > 0) {
      setCurrIndex(oldState => oldState - 1)
    }
    if (currIndex === 0) {
      setCurrIndex(UnderCrouselElements.length -1)
    }
  }

  const handleRightArrowClick = (e: any) => {
    if (currIndex < UnderCrouselElements.length - 1) {
      setCurrIndex(oldState => oldState + 1);
    }
    if (currIndex === UnderCrouselElements.length -1) {
      setCurrIndex(0)
    }

  }
  // console.log(UnderCrouselElements.length -1 , 'car')
  // console.log(currIndex, 'cur')

  const handleImageClick = (data: any) => {
    data && (history.push(data));
  }

  return (
    <div className={classes.meetTheGear}>
      <div className={classes.meetTheGearContainer}>
        {isMobile ? (
          <>
            {/* <div><button onClick={handleArrowClick}>+</button></div> */}
            <div className={classes.meetTheGearArrow} ><img className={classes.meetTheGearArrowImg} onClick={handleLeftArrowClick} src={LeftArrow} /></div>
            <div onClick={() => handleImageClick(UnderCrouselElements[currIndex]?.link)}>
              <img
                className={classes.meetTheGearItemImg}
                src={UnderCrouselElements[currIndex]?.image.url}
                alt={UnderCrouselElements[currIndex]?.title}
              />
              <div className={classes.meetTheGearItemTitle}>
                {UnderCrouselElements[currIndex]?.title}
              </div>
            </div>
            <div className={classes.meetTheGearRightArrow} ><img className={classes.meetTheGearRightArrowImg} onClick={handleRightArrowClick} src={RightArrow} /></div>
            {/* <div><button onClick={handleArrowClick}></button></div> */}
          </>
        ) :
          (UnderCrouselElements && UnderCrouselElements.map((el: UnderCrouselElements, index: any) => {
            return (
              <div key={el.id} onClick={() => handleImageClick(UnderCrouselElements[index]?.link)}>
                <img
                  className={classes.meetTheGearItemImg}
                  src={el.image.url}
                  alt={el.title}
                />
                <div className={classes.meetTheGearItemTitle}>{el.title}</div>
              </div>
            )
          }))
        }

      </div>
    </div>
  )
}