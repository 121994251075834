import { buildCategory } from "../../utils/helper";
import strapiService from "../strapi";
import { Category } from "./types";

const getCategories = async (): Promise<Category[]> => {
  const fetchedCategories = await strapiService.fetchCategories();

  return fetchedCategories.map((dto: Category) => buildCategory(dto, fetchedCategories));
};

export { getCategories };
