import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Cookies from "js-cookie";
import clsx from "clsx";

import authFormStore from "../../store/auth-form-store";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { ReactComponent as FlagIcon } from "../../static/icons/flag.svg";
// import { ReactComponent as FlagBrownIcon } from "../../static/icons/flag-brow.svg";

import { ReactComponent as HeartIcon } from "../../static/icons/heart.svg";

type TProps = {
  className?: {
    root?: string;
    rootActive?: string;
    flagIcon?: string;
    flagIconActive?: string;
    heartIcon?: string;
    heartIconActive?: string;
  };
  isFavorite?: boolean;
  toggleFavorite?: () => void;
};

const Flag = ({ className, isFavorite, toggleFavorite }: TProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    signatureMode
  } = useAppSelector(state => ({
    signatureMode: state.signature.signatureMode,
  }));

  const [active, setActive] = useState<boolean>(false);

  const onFlagClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const isAuth = Cookies.get("jwt");

    if (isAuth) {
      setActive(!active);
      toggleFavorite && toggleFavorite();
    } else {
      event.stopPropagation();
      setActive(!active);
      toggleFavorite && toggleFavorite();
      dispatch(authFormStore.actions.toggleVisibility("authFormIsVisible", true));
    }
  };

  useEffect(() => {
    // Check should be explicit because isFavorite is not mandatory everywhere
    if (isFavorite !== undefined || isFavorite !== null) {
      setActive(isFavorite!);
    }
  }, [isFavorite]);

  return (
    <div
      className={clsx(classes.root, className?.root, active && className?.rootActive)}
      onClick={event => onFlagClick(event)}
    >
      {signatureMode ? <FlagIcon
        className={clsx(
          classes.flagIcon,
          classes.flagBrownIcon,
          className?.flagIcon,
          active && classes.flagBrownIconActive,
          active && className?.flagIconActive
        )}
      /> : <FlagIcon
        className={clsx(
          classes.flagIcon,
          className?.flagIcon,
          active && classes.flagIconActive,
          active && className?.flagIconActive
        )}
      />}

      {signatureMode ? <HeartIcon
        className={clsx(
          classes.heartIcon,
          classes.heartBrownIcon,
          className?.heartIcon,
          active && classes.heartBrownIconActive,
          active && className?.heartIconActive
        )}
      /> : <HeartIcon
        className={clsx(
          classes.heartIcon,
          className?.heartIcon,
          active && classes.heartIconActive,
          active && className?.heartIconActive
        )}
      />}

    </div>
  );
};

export default Flag;

const useStyles = makeStyles(
  theme => ({
    root: {
      cursor: "pointer",
      position: "absolute",
      alignSelf: "flex-end",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      top: -3,
      right: 8,
      width: 50,
      height: 60,
      [theme.device.mobile()]: {
        top: -4,
        height: 40,
        width: 25,
        right: 50
      },
    },
    flagIcon: {
      height: "100%",
      width: "100%",

    },
    flagBrownIcon: {
      ...theme.utils.svgStroke(theme.colors.brown),
    },
    heartIcon: {
      position: "absolute",
      marginBottom: 6,
      width: 25,
      height: 30,
      ...theme.utils.svgStroke(theme.colors.blue),
      [theme.device.mobile()]: {
        height: 20,
        width: 15,
      },
    },
    heartBrownIcon: {
      ...theme.utils.svgStroke(theme.colors.brown),
    },
    heartBrownIconActive: {
      ...theme.utils.svgChangeColor(theme.colors.orange),
    },
    flagBrownIconActive: {
      ...theme.utils.svgChangeColor(theme.colors.brown),
    },
    heartIconActive: {
      ...theme.utils.svgChangeColor(theme.colors.blue),
    },
    flagIconActive: {
      ...theme.utils.svgChangeColor(theme.colors.green),
    },
  }),
  { name: "flag" }
);
