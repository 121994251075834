import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";

import ApprovedSellersPanel from "./approved-sellers-panel";
import ApprovedSellerBox from "./approved-seller-box";

import approvedSellersStore, { ApprovedSeller } from "../../../store/approved-sellers-store";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import usersStore from "../../../store/users-store";

import languagesStore from '../../../store/languages-store'

interface SearchCriteria {
  searchTerm: string;
  currentDirection: string;
}

export const ApprovedSellers = (): JSX.Element => {
  const [filteredSellers, setFilteredSellers] = useState<ApprovedSeller[]>([]);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({
    searchTerm: "",
    currentDirection: "",
  });

  let approvedSellers = useAppSelector(state => state.approvedSellers.data);
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const classes = useStyles();

  const dispatch = useAppDispatch();

  const updateSearchCriteria = useCallback(
    (sC: SearchCriteria) =>
      setSearchCriteria({
        ...searchCriteria,
        searchTerm: sC.searchTerm.toLocaleLowerCase(),
        currentDirection: sC.currentDirection,
      }),
    [searchCriteria]
  );

  const displayApprovedSellers = () =>
    filteredSellers?.map(aS => (
      <ApprovedSellerBox
        key={aS.id}
        name={aS.name}
        address={aS.address}
        website={aS.website}
        workingHours={aS.workingHours}
        phoneNumber={aS.phoneNumber}
        mapsCoordinates={{
          lat: aS.googleMapsLatitude,
          lng: aS.googleMapsLongitude,
        }}
      />
    ));

  const noResultsFound = () => {
    if (filteredSellers.length < 1) {
      return (<p className={classes.noResultsLabel}>{activePhrases && activePhrases["no_results_found"]}</p>)
    }
  }

  useEffect(() => {
    dispatch(approvedSellersStore.actions.fetchApprovedSellers());
  }, [dispatch]);

  useEffect(() => {
    if (approvedSellers && approvedSellers.length > 0) {
      approvedSellers = approvedSellers.slice().sort((a, b) => a.name.localeCompare(b.name))
      approvedSellers && approvedSellers.length > 0 && setFilteredSellers(approvedSellers);
    }
  }, [approvedSellers]);

  useEffect(() => {
    let filteredResult: ApprovedSeller[] = [];
    console.log("search");
    if (approvedSellers && approvedSellers.length > 0) {
      filteredResult = approvedSellers
        .filter(filteredApprovedSeller => {
          if (searchCriteria.currentDirection === "All") {
            return filteredApprovedSeller;
          }

          return filteredApprovedSeller.locationDirection === searchCriteria.currentDirection;
        })
        .filter(approvedSeller => {
          if (searchCriteria.searchTerm.length > 0) {
            console.log(approvedSeller)
            return approvedSeller?.name?.toLocaleLowerCase().includes(searchCriteria.searchTerm) || approvedSeller?.address?.toLocaleLowerCase().includes(searchCriteria.searchTerm);
          } else {
            return approvedSeller;
          }
        });
    }
    if (filteredResult && filteredResult.length > 0) {
      filteredResult = filteredResult.slice().sort((a, b) => a.name.localeCompare(b.name))
      filteredResult && filteredResult.length > 0 && setFilteredSellers(filteredResult);
    }
    setFilteredSellers(filteredResult);
  }, [searchCriteria, approvedSellers]);

  return (
    <div className={classes.wrapper}>
      <ApprovedSellersPanel updateSearchCriteria={updateSearchCriteria} />

      <div className={classes.content}>{displayApprovedSellers()}</div>
      <div className={classes.content}>{noResultsFound()}</div>
    </div>
  );
};

const useStyles = makeStyles(
  {
    wrapper: {},
    noResultsLabel: {
      textAlign: 'center',
      fontSize: 24,
      fontWeight: 600,
    },

    content: {
      marginTop: 10,
      padding: 10,
    },
  },
  { name: "approved-sellers-page" }
);
