import { CreateCSSProperties, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useState } from "react";
import { TTypedStyles } from "../../theme";

type StyleProps = {
  isActive: boolean;
  borderRadius?: number;
  borderWidth?: number;
  textSize?: number;
  borderColor?: string;
  textColor?: string;
  arrowColor?: string;
};

const useStyles: TTypedStyles<StyleProps> = makeStyles(
  theme => ({
    root: {
      userSelect: "none",
      position: "relative",
      width: "100%",
    },
    container: ({ isActive }: StyleProps): CreateCSSProperties => ({
      opacity: isActive ? 1 : 0,
      transition: "0.4s",
      visibility: isActive ? "visible" : "hidden",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "absolute",
      width: "100%",
      top: "100%",
      left: 0,
      zIndex: 1,
      overflowX: "hidden",
    }),
    arrow: ({ isActive, arrowColor }: StyleProps): CreateCSSProperties => ({
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderTop: `${isActive ? 0 : 12}px solid ${arrowColor}`,
      borderBottom: `${isActive ? 12 : 0}px solid ${arrowColor}`,
    }),
    currentItem: ({ borderRadius, borderWidth, borderColor }: StyleProps): CreateCSSProperties => ({
      backgroundColor: theme.colors.text,
      borderRadius: borderRadius,
      border: `${borderWidth}px solid ${borderColor}`,
      transition: "0.2s",
      cursor: "pointer",
      boxSizing: "border-box",
      padding: "10px 12px",
      height: 50,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    item: ({ borderRadius, borderColor, borderWidth }: StyleProps): CreateCSSProperties => ({
      display: "flex",
      justifyContent: "flex-end",
      boxSizing: "border-box",
      cursor: "pointer",
      width: "100%",
      backgroundColor: theme.colors.text,
      border: `${borderWidth}px solid ${borderColor}`,
      borderBottom: "none",

      "&:last-child": {
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        borderRadius: `0 0 ${borderRadius}px ${borderRadius}px`,
      },
      "&:hover": {
        backgroundColor: theme.colors.blue,
      },
    }),
    text: ({ textSize, textColor }: StyleProps): CreateCSSProperties => ({
      boxSizing: "border-box",
      padding: "10px 13px",
      textAlign: "end",
      fontSize: textSize,
      fontWeight: 600,
      color: textColor,
      width: "100%",
      height: "100%",
    }), // eslint-disable-next-line
    currentText: ({}: StyleProps): CreateCSSProperties => ({
      // TS warns aboutempty {} but it's not working properly without it
      padding: "0 3px",
      height: "auto",
      width: "auto",
    }), // eslint-disable-next-line
    textHover: ({}: StyleProps): CreateCSSProperties => ({
      // TS warns aboutempty {} but it's not working properly without it
      color: theme.colors.text,
    }),
    currentItemActive: ({ borderRadius }: StyleProps): CreateCSSProperties => ({
      borderRadius: `${borderRadius}px ${borderRadius}px 0 0`,
      borderBottom: "none",
    }),
  }),
  { name: "drop-select" }
);

type TProps = {
  className?: {
    root?: string;
    currentItem?: string;
    text?: string;
    item?: string;
    container?: string;
  };
  borderRadius?: number;
  borderWidth?: number;
  textSize?: number;
  borderColor?: string;
  textColor?: string;
  arrowColor?: string;
  select: {
    text: string | undefined;
    key: string;
  }[];
  currentText: string | undefined;
  setCurrentText: ({ text, key }: { text: string | undefined; key: string }) => void;
};

const DropSelect = ({
  className,
  select,
  borderRadius,
  borderWidth,
  textSize,
  borderColor,
  textColor,
  arrowColor,
  currentText,
  setCurrentText,
}: TProps): JSX.Element => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [hoveredItem, setHoveredItem] = useState<number>(NaN);

  const classes = useStyles({ isActive, borderRadius, borderWidth, textSize, borderColor, textColor, arrowColor });

  const onCurrentClick = () => setIsActive(current => !current);

  const onItemClick = (text: string, key: string) => () => {
    setCurrentText({ text, key });
    setIsActive(false);
  };

  const onHoverStart = (index: number) => () => setHoveredItem(index);

  const onHoverEnd = () => setHoveredItem(NaN);

  const items = select
    .filter(s => s.text !== currentText)
    .map((dir, i) => (
      dir.text?
      <div
        key={i}
        className={clsx(classes.item, className?.item)}
        onMouseEnter={onHoverStart(i)}
        onMouseLeave={onHoverEnd}
        onClick={onItemClick(dir.text, dir.key)}
      >
        <div className={clsx(classes.text, i === hoveredItem && classes.textHover, className?.text)}>{dir.text}</div>
      </div>: null
    ));

  return (
    <div className={clsx(classes.root, className?.root)}>
      <div
        className={clsx(classes.currentItem, isActive && classes.currentItemActive, className?.currentItem)}
        onClick={onCurrentClick}
      >
        <div className={classes.arrow} />
        <div className={clsx(classes.text, classes.currentText)}>{currentText}</div>
      </div>
      <div className={clsx(classes.container, className?.container)}>{items}</div>
    </div>
  );
};

export default DropSelect;
