import React from "react";
import { useAppSelector } from "../store/store";
import { makeStyles } from "@material-ui/styles";
import isMobile from "react-device-detect";
import languagesStore from "../store/languages-store";

// const WhyToBuyContainer = (image: { id: number; url: string }) => {
//   const classes = useStyles();

//   return (
//     <div className={classes.WhyToBuySingleContainer}>
//       <div className={classes.signleImageContainer} style={{ background: `url(${image.url})` }}>
//         <div className={classes.rightContent}>
//           <h5 className={classes.title}>Opaaaaa</h5>
//           <p className={classes.textContent}>
//             Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur{" "}
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

const WhyToBuySignature = () => {
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const { WhyToChooseUsImages } = useAppSelector(state => state.signatureMode);
  const classes = useStyles();
  // console.log("WhyToChooseUsImages", WhyToChooseUsImages)
  return (
    <div className={classes.WhyToBuyContainer}>
      <div className={classes.WhyToBuySingleContainer}>
        <div className={classes.signleImageContainer} style={{ backgroundRepeat: "no-repeat", background: `url(${WhyToChooseUsImages[0]?.url})` }}>
          <div className={classes.rightContent}>
            <h5 className={classes.title}>{activePhrases && activePhrases["Section2_title"]}</h5>
            <p className={classes.textContent}>{activePhrases && activePhrases["Section2_text"]}</p>
          </div>
        </div>

        <div className={classes.signleImageContainerLeft} style={{ backgroundRepeat: "no-repeat", background: `url(${WhyToChooseUsImages[1]?.url})` }}>
          <div className={classes.leftContent}>
            <h5 className={classes.title}>{activePhrases && activePhrases["Section3_title"]}</h5>
            <p className={classes.textContent}>
              {activePhrases && activePhrases["Section3_text"]}
            </p>
          </div>
        </div>

        <div className={classes.signleImageContainer} style={{ backgroundRepeat: "no-repeat", background: `url(${WhyToChooseUsImages[2]?.url})` }}>
          <div className={classes.rightContent}>
            <h5 className={classes.title}>{activePhrases && activePhrases["Section4_title"]}</h5>
            <p className={classes.textContent}>
              {activePhrases && activePhrases["Section4_text"]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  WhyToBuyContainer: {
    width: "100%",
    height: "100%",
  },
  WhyToBuySingleContainer: {
    width: "100%",
    height: "100%",
    marginBottom: "13px",
  },
  signleImageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    backgroundSize: "cover!important",
    height: "280px",
    backgroundRepeat: "no-repeat!important",
    // smallTablet

    [theme.device.smallTablet()]: {
      height: "250px",
    },


    [theme.device.mediumMobile()]: {
      height: "260px",
      backgroundSize: "cover!important"
    },
    [theme.device.mobile()]: {
      minHeight: "400px",
      backgroundPosition: "center center!important"
    },

    // [theme.device.superSmall()]: {
    //   minHeight: "400px",
    // },
  },
  signleImageContainerLeft: {
    width: "100%",
    height: "280px",
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: "20px",
    marginBottom: "20px",
    marginTop: "20px",
    backgroundRepeat: "no-repeat!important",
    [theme.device.mediumMobile()]: {
      height: "260px",
      backgroundSize: "cover!important"
    },
    [theme.device.mobile()]: {
      minHeight: "400px",
      backgroundPosition: "center center!important"
    },
    // [theme.device.superSmall()]: {
    //   minHeight: "400px",
    // },
  },
  title: {
    paddingTop: "20px",
    margin: "0",
    display: "inline-block",
    fontSize: "2.5rem",
    textAlign: "center",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "4px",
    color: "rgba(255,255,255, 1)",
    paddingBottom: "3px",
    borderBottom: "4px solid" + theme.colors.orange,
    marginBottom: "10px",
    direction: 'rtl'
  },
  textContent: {
    display: "inline-block",
    fontSize: "1.25rem",
    color: "white",
    fontWeight: 300,
    letterSpacing: "2px",
    direction: 'rtl'
  },
  rightContent: {
    width: "40%",
    textAlign: "right",
    paddingRight: "40px",
    direction: 'rtl',

    [theme.device.tablet()]: {
      width: "100%",
      textAlign: "center",
      padding: "0 30px",
    },

    [theme.device.mediumMobile()]: {
      width: "100%",
      textAlign: "center",
      padding: "0 30px",
    },
  },
  leftContent: {
    width: "40%",
    //textAlign: "left",
    paddingLeft: "40px",
    justifyContent: "flex-start",
    direction: 'rtl',
    
    [theme.device.tablet()]: {
      width: "100%",
      textAlign: "center",
      padding: "0 30px",
    },

    [theme.device.mediumMobile()]: {
      width: "100%",
      textAlign: "center",
      padding: "0 30px",
    },
  },
  img: {
    width: "100%",
    height: "100%",
  },
}));

export default WhyToBuySignature;
