import { makeStyles, useTheme } from "@material-ui/styles";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import clsx from "clsx";

import Button from "./button";

import { useAppDispatch, useAppSelector } from "../../store/store";
import displayCurrencySign from "../../utils/currencies-signs";
import { TShippingMethod } from "../../services/orders/types";
import { countVat } from "../../utils/helper";
import { config } from "../../config";
import languagesStore from "../../store/languages-store";

type TProps = {
  shippingMethod?: TShippingMethod;
  totalPrice: number;
  couponDiscount: any;
  className?: {
    root?: string;
    shipping?: string;
    proceed?: string;
  };
};

const OrderSummary = ({ className, shippingMethod, totalPrice, couponDiscount }: TProps): JSX.Element => {
  const lang = useAppSelector(state => state.languages.language);

  const dispatch = useAppDispatch();

  const classes = useStyles();


  const [discountedPrice, setDiscountedPrice] = useState(0);


  useEffect(() => {
    setNewCouponPrice();
  }, [couponDiscount])

  const theme = useTheme();

  const { shipByPostPrice, courierPrice, vat, isAgent } = useAppSelector(state => ({
    shipByPostPrice: state.siteConfig.shipByPostPrice!,
    courierPrice: state.siteConfig.courierPrice!,
    isAgent: state.user.data?.approvedAgent,
    vat: state.siteConfig.vat!,
  }));

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const totalShippingPrice =
    shippingMethod === "ship_by_post"
      ? shipByPostPrice
      : shippingMethod === "delivery_by_courier"
        ? courierPrice
        : 0;


  const vatAmount = countVat(totalPrice, vat, isAgent);

  const totalOrderPrice = false
    ? totalPrice + totalShippingPrice + countVat(totalPrice, vat, isAgent)
    : totalPrice + totalShippingPrice;

  const onProceedBtnClick = () => dispatch(push(config.routes.checkoutPage, true));


  const setNewCouponPrice = () => {
    let discountedPrice = 0;
    if (couponDiscount !== 0) {
      if (couponDiscount?.total) {
        discountedPrice = totalPrice - couponDiscount.total;
      } else if (couponDiscount?.percent) {
        discountedPrice = totalPrice - (totalPrice * (couponDiscount.percent / 100));
      } else {
        console.log("Nothing to update !");
      }
    }
    //console.log('disc price', discountedPrice, (totalPrice * (+couponDiscount.total / 100)))
    setDiscountedPrice(discountedPrice);
  }

  return (
    <div className={clsx(classes.root, className?.root)}>
      <div className={classes.title}>{activePhrases && activePhrases["order_summary"]}</div>
      <div className={classes.subtotal}>
        <div className={classes.grayText}>
          {activePhrases && activePhrases["subtotal_before_delivery"]}
        </div>

        <div className={classes.blueText}>
          {displayCurrencySign(lang)}
          {totalPrice.toFixed(2)}
        </div>
      </div>

      <div className={clsx(classes.subtotal, className?.shipping)}>
        <div className={classes.grayText}>{activePhrases && activePhrases["shipping"]}</div>

        <div className={classes.blueText}>
          {displayCurrencySign(lang)}
          {totalShippingPrice}
        </div>
      </div>
      <div className={classes.subtotal}>
        <div className={classes.grayText}>{activePhrases && activePhrases["vat"]}</div>

        <div className={classes.blueText}>
          {displayCurrencySign(lang)}
          {vatAmount.toFixed(2)}&nbsp;({vat}%)
        </div>
      </div>
      <div className={classes.total}>
        <div className={classes.blueText}>{activePhrases && activePhrases["order_total"]}</div>

        <div className={classes.grayText}>({activePhrases && activePhrases["include_vat"]})</div>

        <div className={clsx(classes.blueText, classes.price)}>
          {/* <span className={discountedPrice !== 0 ? classes.lineThrough : '' }>Regular price</span> */}
          {/* {discountedPrice !== 0 && <span><hr/></span>} */}
          <span className={discountedPrice !== 0 ? classes.lineThrough : ''}>
            {displayCurrencySign(lang)}
            {totalOrderPrice.toFixed(2)}
          </span>
        </div>
        {discountedPrice !== 0 && <div className={clsx(classes.blueText, classes.price)}>
          <span className={classes.redColored}>

            {displayCurrencySign(lang)}
            {discountedPrice.toFixed(2)}
          </span>
        </div>}
      </div>
      <div className={clsx(classes.proceed, className?.proceed)}>
        <Button
          text={activePhrases && activePhrases["proceed_to_checkout"]}
          textColor={theme.colors.text}
          action={onProceedBtnClick}
          fill={theme.colors.blue}
          borderRadius={7}
          textSize={17}
          width="100%"
          height={36}
        />
      </div>
    </div>
  );
};

export default OrderSummary;

const useStyles = makeStyles(
  theme => ({
    root: {
      boxSizing: "border-box",
      padding: "5px 10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      width: "100%",
      maxHeight: 450,
      borderRadius: 5,
      border: `2px solid ${theme.colors.blue}`,
    },
    title: {
      fontWeight: 700,
      fontSize: 25,
      color: theme.colors.blue,
      cursor: "default",
      userSelect: "none",
    },
    subtotal: {
      paddingTop: 15,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      gridRowGap: 2,
    },
    blueText: {
      color: theme.colors.blue,
      fontSize: 16,
      fontWeight: 600,
    },
    grayText: {
      color: theme.colors.grayText,
      fontSize: 15,
      fontWeight: 600,
    },
    total: {
      marginTop: 15,
      borderTop: `1px solid ${theme.colors.border}`,
      padding: "15px 0 25px 0",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      gridRowGap: 2,
    },
    price: {
      fontWeight: 700,
      fontSize: 25,
    },
    proceed: {
      marginTop: "auto",
      boxSizing: "border-box",
      padding: "0 15px",
      width: "100%",
    },
    lineThrough: {
      textDecoration: "line-through",
    },
    redColored: {
      color: "red",
    }
  }),
  { name: "order-summary" }
);
