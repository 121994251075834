import React from "react";

import languagesStore from "../../store/languages-store";

import { makeStyles, useTheme } from "@material-ui/styles";

import Button from "../common/button";

import BuyPolicyPanel from "../pages/buy-policy/buy-policy-panel";

import { useAppDispatch, useAppSelector } from "../../store/store";

import theme from "../../theme";

import { isMobile } from "react-device-detect";

import fitGuideStore from "../../store/fit-guide-store";

import SelectFamily from "./select-family";

import CarsFit from "./cars-fit";

import SelectChildDimensions from "./select-child-dimensions";


const FitGuidePage = () => {

  const dispatch = useAppDispatch();

  const theme = useTheme();

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const classes = useStyles();



  const { case1, family } = useAppSelector(state => state.fitGuide);


  const handleButton = (e : any) => {

      if(e.target.innerHTML == 'Yes') {

            dispatch(fitGuideStore.actions.toggleState("case1", true));

    } else {

            dispatch(fitGuideStore.actions.toggleState("case1", false));

      }


  };


  return (

    <div className={classes.wrapper} style={{ textAlign: "center" }}>

        <BuyPolicyPanel />

        <div className={classes.FitContainer}>

        {case1 == null ? 

        <div>

                <h2>Do you know which car seat you want ?</h2>

        <button className={classes.buttons} onClick={handleButton}>Yes</button>

        <button className={classes.buttons} onClick={handleButton}>No</button>

        </div>

        : case1 == true ? family ? <CarsFit/> :<SelectFamily/> : <SelectChildDimensions/>

    }


        </div>

    </div>

  );

};


const useStyles = makeStyles(

    theme => ({

        wrapper: {

            "&>h1": {

              color: theme.colors.green,

              textAlign: "center",

              fontSize: 45,

      

              [theme.device.desktop()]: {

                fontSize: 65,

              },

            },

          },

          FitContainer: {

              margin: "0 auto",

          },

          buttons: {

              border: "1px solid " + theme.colors.blue,

              padding: "7px 52px",

              borderRadius: "24px",

              fontWeight: 700,

              margin: "12px",

              color: theme.colors.blue,

              "&:hover": {

              backgroundColor: theme.colors.blue,

              color: "white",

              },

          }

     })

)


export default FitGuidePage;