import { makeStyles } from "@material-ui/styles";
import React from "react";
import clsx from "clsx";
import { useAppSelector } from "../../store/store";


const useStyles = makeStyles(
  theme => ({
    panel: {
      boxSizing: "border-box",
      width: "100%",
      padding: "10px 15px",
      backgroundColor: theme.colors.blue,
      display: "flex",

      [theme.device.mobile()]: {
        flexDirection: "column",
      },
    },
    panelSignature: {
      boxSizing: "border-box",
      width: "100%",
      padding: "10px 15px",
      backgroundColor: theme.colors.brown,
      display: "flex",

      [theme.device.mobile()]: {
        flexDirection: "column",
      },
    },
  }),
  { name: "panel" }
);

type TProps = {
  children: JSX.Element[] | JSX.Element;
  className?: {
    panel?: string;
  };
};

const Panel = ({ children, className }: TProps): JSX.Element => {
  const classes = useStyles({});
  const signatureMode = useAppSelector(state => state.signature.signatureMode);


  return <div className={(signatureMode ? clsx(classes.panelSignature, className?.panel) : clsx(classes.panel, className?.panel))}>{children}</div>;
};

export default Panel;
