import React, { useEffect, useState } from "react";
import { CreateCSSProperties, makeStyles, useTheme } from "@material-ui/styles";
import { useAppSelector } from "../store/store";
import { isMobile } from "react-device-detect";
import languagesStore from "../store/languages-store";

type CSSProps = {
  containerHeight: number;
};

const SimplyTimeless = () => {
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const theme = useTheme();
  const [containerHeight, setContainerHeight] = useState(0);
  const { SimplyTimeless } = useAppSelector(state => state.signatureMode);

  useEffect(() => {
    const currContainerHeight = window.innerWidth * 0.28;
    setContainerHeight(currContainerHeight);
  });
  const classes = useStyles({ containerHeight });
  return (
    <div className={classes.SimplyTimelessContainer}>
      {window.innerWidth < 670 && <h3 className={classes.title}>{activePhrases && activePhrases["Section1_title"]}</h3>}
      <img className={classes.img} src={SimplyTimeless?.url} alt="explore background" />
      <div className={classes.rightContainer}>
        {window.innerWidth > 670 && <h3 className={classes.title}>{activePhrases && activePhrases["Section1_title"]}</h3>}
        <p className={classes.textContent}>{activePhrases && activePhrases["Section1_text"]}</p>
      </div>
    </div>
  );
};

const useStyles = makeStyles(
  theme => ({
    SimplyTimeless: {
      fontFamily: "Montserrat, sans-serif",
      width: "100%",
      height: "100%",
    },
    SimplyTimelessContainer: ({ containerHeight }: CSSProps): CreateCSSProperties => ({
      display: "flex",
      maxWidth: "100%",
      height: "auto",
      [theme.device.mediumMobile()]: {
        flexDirection: "column",
      },
      // height: containerHeight,
    }),
    leftContainer: {
      display: "inline-block",
      width: "45%",
      height: "100%",
    },
    rightContainer: {
      direction: 'rtl',
      paddingTop: "40px",
      display: "inline-block",
      width: "50%",
      height: "100%",
      margin: "0 auto",
      marginRight: '38px',
      [theme.device.mediumMobile()]: {
        position: "static",
        width: "100%",
      },
    },
    title: {
      margin: "0 auto",
      display: "inline-block",
      fontSize: "2.5rem",
      fontWeight: 600,
      textTransform: "uppercase",
      letterSpacing: "4px",
      color: theme.colors.brown,
      paddingBottom: "3px",
      borderBottom: "4px solid" + theme.colors.orange,
      marginBottom: "23px",
      direction: 'rtl',
      [theme.device.mediumMobile()]: {
        // marginLeft: "30px",
        fontSize: "2rem",
      },
    },
    textContent: {
      fontSize: "1.25rem",
      textAlign: "right",
      //margin: "0 auto",
      maxWidth: "90%",
      //marginLeft: "0",
      color: theme.colors.black,
      fontWeight: 300,
      letterSpacing: "2px",
      direction: 'rtl',
      // textShadow: " 0px 0px 6px rgba(150, 150, 150, 1)",
      [theme.device.mediumMobile()]: {
        maxWidth: "100%",
        padding: "0 30px",
        marginBottom: "30px",
        textAlign: 'center'
      },
    },
    img: {
      position: "relative",
      width: "360px",
      marginLeft: "auto",
      height: "100%",
      [theme.device.mediumMobile()]: {
        marginLeft: 'auto',
        marginRight: "auto"
      },

      [theme.device.mobile()]: {
        width: "250px",
      },
    },
  }),
  { name: "simply-teimeless" }
);

export default SimplyTimeless;
