import { useState, useEffect } from "react";

const useAuth = (userId?: number): { isAuth: boolean } => {
  const [isAuth, setIsAuth] = useState<boolean>(false);

  useEffect(() => {
    // For additional security check
    if (userId !== null && userId !== undefined && typeof userId === "number") {
      setIsAuth(true);
    }
  }, [userId]);

  return { isAuth };
};

export default useAuth;
