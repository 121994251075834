import { makeStyles } from "@material-ui/styles";
import React from "react";
import TermsAndConditionsPanel from "./terms-and-conditions-panel";
import { useAppSelector } from "../../../store/store";
import ReactMarkdown from "react-markdown";
import { parseRichText } from "../../../utils/helper";

export const TermsAndConditions = (): JSX.Element => {
  const classes = useStyles();

  const { termsAndConditions, language } = useAppSelector(state => ({
    termsAndConditions:
      state.languages.language === "he"
        ? state.siteConfig.TermsAndConditionsHebrew
        : state.siteConfig.TermsAndConditions,
    language: state.languages.language,
  }));

  const textAlign = language === "he" ? "right" : "left";

  return (
    <div className={classes.wrapper} style={{ textAlign: textAlign }}>
      <TermsAndConditionsPanel />

      <div className={classes.markdownWrapper}>
        {parseRichText(termsAndConditions)}
      </div>
    </div>
  );
};

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      "&>h1": {
        color: theme.colors.green,
        textAlign: "center",
        fontSize: 45,

        [theme.device.desktop()]: {
          fontSize: 65,
        },
      },
    },

    spinnerWrapper: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },

    markdownWrapper: {
      padding: "2rem",
      marginBottom: "55px",

      "& > h1, h2, h3, h4, h5, h6": {
        textAlign: "center",
        color: theme.colors.green,
        marginTop: 0,
      },

      "& > p": {
        fontWeight: 700,
        color: theme.colors.grayText,
        fontSize: 20,
      },
      "& > ul": {
        direction: "rtl",
      },
      "& > ul > li > ol": {
        direction: "rtl",
      },
    },
  }),
  { name: "terms-and-conditions" }
);
