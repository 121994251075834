import React, { useCallback, useEffect, useState } from "react";
import { TContentPage } from "../../../services/content-pages/types";
import { useAppSelector } from "../../../store/store";
import BuyPolicyPanel from "../buy-policy/buy-policy-panel";
import SingleContentPage from "./single-content-page";
import { makeStyles, useTheme } from "@material-ui/styles";
import ContentPanelPage from "./content-panel-page";
import { config } from "../../../config";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

const ContentPage = () => {
  const [pageContent, setPageContent]: any = useState([]);
  const [contentPageTitle, setContentPageTitle] = useState("Loading ...");
  const { data } = useAppSelector(state => state.contentPages.contentPagesData);
  const classes = useStyles();
  const dispatch = useDispatch();

  let pathName = window.location.pathname.split("/")[1];

  const setCurrPageContent = useCallback(() => {
    for (let i = 0; i < data.length; i++) {
      //TODO change the way you are loading the page - subUrl === pathname
      if (data[i].subUrl == pathName) {
        const pageTitle: any = data[i].pageTitle;
        //Change the name of the setter it is no more page title it should be subUrl
        setContentPageTitle(pageTitle);
        setPageContent(data[i]);
        return;
      } else {
        setContentPageTitle("Page not found :(");
        setTimeout(() => {
          dispatch(push(config.routes.homePage, true));
        }, 2000);
      }
    }
  }, [data]);

  useEffect(() => {
    //set current data for page
    setCurrPageContent();
  }, [data]);

  return (
    <section className={classes.contentPageContainer}>
      <ContentPanelPage title={contentPageTitle} />
      <ul className={classes.sectionsContainer}>
        {pageContent.Section1?.map((cP: any) => {
          return <SingleContentPage section={cP} key={cP.id} />;
        })}
      </ul>
    </section>
  );
};

const useStyles = makeStyles(theme => ({
  contentPageContainer: {
    margin: "10px auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    "& h2": {
      fontSize: 26,
    },
  },
  sectionsContainer: {
    // width: "86%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export default ContentPage;
