import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { getPhrase } from "../../utils/language";
import { useAppSelector, useAppDispatch } from "../../store/store";
import clsx from "clsx";
import slide from "../../static/images/middle_img.png";
import { push } from "connected-react-router";
import { config } from "../../config";
import Button from "../common/button";
import partnerBanner from "../../static/images/partner_banner.jpg";
import languagesStore from "../../store/languages-store";

export const Partner = () => {
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const partners = useAppSelector(state => state.siteConfig.partners);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  //console.log(partners)
  return (
    <div className={classes.partner}>
      <a
        href="https://www.instagram.com/accounts/login/?next=/joieisrael/"
        className={clsx(classes.title, classes.partnerText)}
      >
        {partners?.title}
      </a>
      <div className={clsx(classes.text, classes.partnerText)}>{partners?.subtitle}</div>
      <div
        onClick={() => {
          dispatch(push(partners?.link ? partners?.link : "/", true));
        }}
        className={classes.partnerContainer}
      >
        {window.innerWidth < 600 ? (
          <img
            className={classes.partnerImg}
            src={partners?.imageMobile?.url}
            alt="partners banner"
          />
        ) : (
          <img className={classes.partnerImg} src={partners?.image?.url} alt="partners banner" />
        )}

        {/* <Button
          width={130}
          height={40}
          borderRadius={25}
          text={partners?.button_text ? partners?.button_text : "CLICK"}
          fill={theme.colors.text}
          textColor={theme.colors.orange}
          action={() => {
            dispatch(push(partners?.link ? partners?.link : "/", true));
          }}
          className={classes.partnerBtn}
        /> */}
      </div>
    </div>
  );
};
const useStyles = makeStyles(theme => ({
  partner: {
    paddingTop: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  partnerText: {
    paddingBottom: 20,
    color: theme.colors.orange,
  },
  partnerContainer: {
    position: "relative",
    width: "100%",
    boxSizing: "border-box",
    cursor: "pointer",
    height: "16.66vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.device.mobile()]: {
      height: "53.33vw",
    },
  },
  partnerImg: {
    zIndex: -1,
    position: "absolute",
    width: "100%",
    objectFit: "cover",
    height: "100%",
  },
  partnerBtn: {
    marginTop: "auto",
    marginBottom: 10,
  },
  text: {
    fontSize: 13,
    fontWeight: 600,
  },
  title: {
    padding: 0,
    fontWeight: 700,
    fontSize: 30,
    textDecoration: "none",
  },
}));
