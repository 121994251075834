import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { getPhrase } from "../../utils/language";
import { useAppSelector, useAppDispatch } from "../../store/store";
import clsx from "clsx";
import slide from "../../static/images/middle_img.png";
import { push } from "connected-react-router";
import { config } from "../../config";
import Button from "./button";
import partnerBanner from "../../static/images/partner_banner.jpg";
import {useStyles} from '../pages/home-page';
import joinJoie from "../../static/images/join_joie_img.png";
import languagesStore from "../../store/languages-store";
import { isMobile } from "react-device-detect";

export const Join = () => {
        
    const classes = useStyles();
    const { HomePageJoinBanner, HomePageJoinBannerMobile } = useAppSelector(state => state.siteConfig);
    const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

    return (
        <div className={classes.joinJoie}>
          {isMobile ? <img className={classes.img} src={HomePageJoinBannerMobile?.url} alt="background" />
          : <img className={classes.img} src={HomePageJoinBanner?.url} alt="background" />}
        {/* <div className={classes.joinJoieContainer}>
          <div className={classes.bigTitle}>{activePhrases &&  activePhrases["join_joie"]}</div>
          <div className={classes.exploreInfoText}>{activePhrases &&  activePhrases["join_joie_desc"]}</div>
          <form className={classes.joinJoieForm} autoComplete="off">
            <input className={classes.joinJoieInput} placeholder={activePhrases &&  activePhrases["name"]} type="text" />
            <input className={classes.joinJoieInput} placeholder={activePhrases &&  activePhrases["email"]} type="text" />
            <input className={classes.joinJoieInput} placeholder={activePhrases &&  activePhrases["location"]} type="text" />
            <input className={classes.joinJoieInput} placeholder={activePhrases &&  activePhrases["dob"]} type="text" />
            <a className={classes.joinJoieSubmitBtn} href="#">submit</a>
          </form>
        </div> */}
      </div>
    )
}