import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import { en } from "./locales/en";

import { hebrew } from "./locales/hebrew";

const resources = {
  he: {
    translation: hebrew,
  },
};

const options = {
  order: ["localStorage", "navigator"],
  caches: ["localStorage"],
};

i18n.use(initReactI18next).init({
  detection: options,
  resources,
  ns: ["translation"],
  defaultNS: "translation",
  fallbackLng: "he",
  lng: "he",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
