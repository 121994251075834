import { createAsyncThunk, createReducer, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import ordersService from "../services/orders";
import { TOrder, TUpdateReq } from "../services/orders/types";
import { getDefaultResourceState, Resource } from "./resource-state";
import { castDraft } from "immer";
import { TThunk } from "./store";

type State = {
  ordersData: Resource<TOrder>;
  activeOrder: TOrder | null;
};

const initialState: State = {
  ordersData: getDefaultResourceState<TOrder>(),
  activeOrder: null,
};

const updateOrder: TThunk<void, TUpdateReq> = createAsyncThunk(
  "orders/update",
  async (body: TUpdateReq) => await ordersService.updateOrder(body)
);

const createOrder: TThunk<TOrder, TOrder> = createAsyncThunk(
  "orders/create",
  async (order: TOrder) => await ordersService.createOrder(order)
);

const fetchOrders: TThunk<TOrder[]> = createAsyncThunk("orders/fetch", async () => await ordersService.fetchOrders(), {
  condition: (_, { getState }) => {
    const state = getState();
    return !state.orders.ordersData.loaded;
  },
});

const reducer = createReducer(initialState, {
  [updateOrder.fulfilled.type]: state => {
    state.activeOrder = null;
    state.ordersData.loading = false;
    state.ordersData.loaded = true;
  },
  [updateOrder.pending.type]: state => {
    state.ordersData.loading = true;
  },
  [updateOrder.rejected.type]: (state, action: PayloadAction<null, string, unknown, SerializedError>) => {
    state.ordersData!.error = action.error.message || "General Error";
    state.ordersData!.loading = false;
  },
  [createOrder.fulfilled.type]: (state, action: PayloadAction<TOrder>) => {
    state.ordersData.data = castDraft([...state.ordersData.data, action.payload]);
    state.activeOrder = action.payload;
    state.ordersData.loading = false;
    state.ordersData.loaded = true;
  },
  [createOrder.pending.type]: state => {
    state.ordersData.loading = true;
  },
  [createOrder.rejected.type]: (state, action: PayloadAction<null, string, unknown, SerializedError>) => {
    state.ordersData!.error = action.error.message || "General Error";
    state.ordersData!.loading = false;
  },
  [fetchOrders.pending.type]: state => {
    state.ordersData.loading = true;
  },
  [fetchOrders.fulfilled.type]: (state, action: PayloadAction<TOrder[]>) => {
    state.ordersData.data = castDraft(action.payload);
    state.ordersData.loading = false;
    state.ordersData.loaded = true;
  },
  [fetchOrders.rejected.type]: (state, action: PayloadAction<null, string, unknown, SerializedError>) => {
    state.ordersData!.error = action.error?.message! || "General Error";
    state.ordersData!.loading = false;
  },
});

const actions = {
  createOrder,
  fetchOrders,
  updateOrder,
};

const selectors = {};

const ordersStore = {
  actions,
  selectors,
  reducer,
};

export default ordersStore;

export type { State };
