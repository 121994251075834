import { buildCategory, buildMediaUrl, buildProduct } from "../../utils/helper";
import strapiService from "../strapi";
import { MediaData, SignatureConfig } from "./types";

const getSignatureConfig =  async () : Promise<SignatureConfig> => {
    const fetchedData = await strapiService.fetchSignatureConfig();

    const signatureConfig: SignatureConfig = {
        ImageOverFooter: !!fetchedData.ImageOverFooter ?
            {
            id: fetchedData.ImageOverFooter.id,
            url: buildMediaUrl(fetchedData.ImageOverFooter.url),
        } : null,
        SimplyTimeless: !!fetchedData.SimplyTimeless ?
            {
            id: fetchedData.SimplyTimeless.id,
            url: buildMediaUrl(fetchedData.SimplyTimeless.url),
        } : null,
        VideoImage: !!fetchedData.VideoImage ?
            {
            id: fetchedData.VideoImage.id,
            url: buildMediaUrl(fetchedData.VideoImage.url),
        } : null,
        WhyToChooseUsImages: fetchedData?.WhyToChooseUsImages?.map((dto: MediaData) => ({
            id: dto.id,
            url: buildMediaUrl(dto.url),
          })), 
          youtube_video_url: fetchedData.youtube_video_url,
    }
    return signatureConfig;
}

export { getSignatureConfig } ;