import { createAsyncThunk, createReducer, createSelector, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { castDraft } from "immer";
import couponsService from "../services/coupons";
import { Coupon } from "../services/coupons/types";
import { getDefaultResourceState, Resource } from "./resource-state";
import { ApplicationState } from "./root-reducer";
import { TThunk } from "./store";

type State = Resource<Coupon>;

const initialState: State = getDefaultResourceState();

const fetchCoupons: TThunk<Coupon[]> = createAsyncThunk("coupons/fetch", async () => await couponsService.getCoupons(), {
  condition: (_, { getState }) => {
    const state = getState();
    return !state.coupons.loaded;
  },
});

const reducer = createReducer(initialState, {
  [fetchCoupons.pending.type]: state => {
    state.loading = true;
  },
  [fetchCoupons.fulfilled.type]: (state, action: PayloadAction<Coupon[]>) => {
    state.data = castDraft(action.payload);
    state.loading = false;
    state.loaded = true;
  },
  [fetchCoupons.rejected.type]: (state, action: PayloadAction<null, string, unknown, SerializedError>) => {
    state.error = action.error.message || "General Error";
    state.loading = false;
  },
});

// Selectors

// Returns all brands
const brandsSelector = () =>
  createSelector(
    (state: ApplicationState) => state.coupons.data,
    (coupons: Coupon[]) => coupons
  );

const selectors = {
  brandsSelector,
};

const actions = {
  fetchCoupons,
};

const couponsStore = {
  actions,
  reducer,
  selectors,
  initialState,
};

export default couponsStore;
export type { State };
