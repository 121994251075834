import { buildMediaUrl } from "../../utils/helper";
import strapiService from "../strapi";
import type { Coupon } from "./types";

const getCoupons = async (): Promise<Coupon[]> => {
  const fetchedCoupons = await strapiService.fetchCoupons();

  return fetchedCoupons.map((dto: Coupon) => {
    const coupon: Coupon = {
      id: dto.id,
      name: dto.name,
      code: dto.code,
      quantity: dto.quantity,
      discountAmount: dto.discountAmount,
      discountPercent: dto.discountPercent,
      validityPeriod: dto.validityPeriod,
      discountType: dto.discountType,
      couponsUsed: dto.couponsUsed,
      appliedTo: dto.appliedTo,
      appliedToEntireCard: dto.appliedToEntireCard,
    };
    return coupon;
  });
};

export { getCoupons };
