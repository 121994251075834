import { makeStyles } from "@material-ui/styles";
import React from "react";

import CarouselLinear from "../../carousel/carousel";

import { ApplicationState } from "../../../store/root-reducer";
import { useAppSelector } from "../../../store/store";
import { getPhrase } from "../../../utils/language";
import languagesStore from "../../../store/languages-store";


const ContactPanel = (): JSX.Element => {
  // The empty object is intentional because otherwise the app crashes
  const classes = useStyles({});
  
  const signatureMode = useAppSelector(state => state.signature.signatureMode);

  const { lang, slides, slidesMobile } = useAppSelector((state: ApplicationState) => ({
    slides: state.siteConfig.catalogCarousel,
    slidesMobile: state.siteConfig.catalogCarouselMobile,
    lang: state.languages.language,
  }));

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const isMobile = window.innerWidth < 600;

  return (
    <div className={classes.wrapper}>
      <CarouselLinear
        slides={isMobile ? slidesMobile : slides}
        className={{ root: classes.carousel, dots: classes.dots, dot: classes.dot }}
      />
      {
        <div className={signatureMode ? classes.titleBlack : classes.title}>
          <h1>{activePhrases && activePhrases["contact_us"]}</h1>
        </div>
      }
    </div>
  );
};

export default ContactPanel;

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      backgroundColor: theme.colors.blue,
      textAlign: "center",
      width: "100%",

      "&>h1": {
        display: "grid",
        placeItems: "center",
        fontWeight: "normal",
        color: "#fff",
        fontSize: 47,
        height: 65,
        padding: 0,
        margin: 0,

        [theme.device.mobile()]: {
          fontSize: 30,
        },
      },
    },
    titleBlack: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      backgroundColor: theme.colors.black,
      textAlign: "center",
      width: "100%",

      "&>h1": {
        display: "grid",
        placeItems: "center",
        fontWeight: "normal",
        color: "#fff",
        fontSize: 47,
        height: 65,
        padding: 0,
        margin: 0,

        [theme.device.mobile()]: {
          fontSize: 30,
        },
      },
    },
    carousel: {
      // minHeight: 200,
      [theme.device.mobile()]: {
        minHeight: 100,
      },
    },
    dots: {
      marginBottom: 20,
      [theme.device.mobile()]: {
        gridColumnGap: 15,
      },
    },
    dot: {
      height: 22,
      width: 22,
      [theme.device.mobile()]: {
        height: 17,
        width: 17,
      },
    },
  }),
  { name: "contact-panel" }
);
