import { createAction, createReducer } from "@reduxjs/toolkit";

type State = {
    signatureMode: boolean
}

const initialState: State = {
    signatureMode: false,
}

  const toggleMode = createAction("signatureMode", (newState: boolean) => ({
    payload: {
      newState,
    },
  }));

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(toggleMode, (state, action) => {
      const { newState } = action.payload;

        state.signatureMode = newState;

    })

});

const actions = { toggleMode };

const authFormStore = {
  reducer,
  actions,
};

export default authFormStore;

export type { State };