import { makeStyles } from "@material-ui/styles";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import clsx from "clsx";

import SocialMediaBar from "../social-media-bar/social-media-bar";
import Presentation from "../presentation/presentation";
import CarouselLinear from "../carousel/carousel";
import Catalog from "../catalog/catalog";
import { config } from "../../config";

import { TCatalogItem } from "../../services/site-config-service/types";

import { useAppSelector } from "../../store/store";
import { Explore } from "../common/explore";
import { Partner } from "../common/partner";
import { Join } from "../common/join";
import { MeetTheGear } from "../common/meet-the-gear";
import VideosSignature from "../VideoSignature";
import WhyToBuySignature from "../WhyToBuySignature";
import OverFooterContainer from "../OverFooterContainer";
import SimplyTimeless from "../simply-timeless";
import { SignatureProducts } from "../SignatureProducts";
import { UnderCarousel } from "../common/under-carousel";
import TestPage from "./test-page";
import SpinxlAutoplay from "./spinxl-autoplay";

const HomePage = (): JSX.Element => {
  const classes = useStyles();

  const {
    product,
    categories,
    carousel,
    carouselMobile,
    middleBanner,
    middleBannerMobile,
    pathname,
    siteConfig,
  } = useAppSelector(state => ({
    products: state.siteConfig.products?.map(p => p.product),
    product: state.siteConfig.product!,
    carousel: state.siteConfig.carousel,
    carouselMobile: state.siteConfig.carouselMobile,
    middleBanner: state.siteConfig.middleBanner!,
    middleBannerMobile: state.siteConfig.middleBannerMobile!,
    categories: state.siteConfig.categories,
    pathname: state.router.location.pathname,
    siteConfig: state.siteConfig,
  }));

  const catalogItems: TCatalogItem[] = categories.map(category => ({
    id: category.category.id,
    name: category.name,
    backgroundImg: category?.category?.backgroundImg?.url!,
    path: `${config.routes.productsPage}/${category.category.id}`,
  }));

  const presentationCatalogItem: TCatalogItem = {
    id: product?.id!,
    name: product?.name!,
    backgroundImg: product?.mainPicture?.url!,
    path: `${config.routes.product}/${product?.catalogNumber}`,
  };

  const isMobile = window.innerWidth < 600;
  const signatureMode = useAppSelector(state => state.signature.signatureMode);

  return (
    <>
      <Helmet>
        <title>{siteConfig.title}</title>
        <meta name="description" content={siteConfig.description} />
        <meta name="keywords" content={siteConfig.keywords} />
      </Helmet>
      <div className={classes.wrapper}>
        {signatureMode ? (
          <Fragment>
            {/* <VideosSignature />
          <SimplyTimeless />
          <WhyToBuySignature />
          <SignatureProducts />
          <OverFooterContainer /> */}
          </Fragment>
        ) : (
          <Fragment>
            <CarouselLinear
              slides={isMobile ? carouselMobile : carousel}
              className={{ root: classes.carousel, slides: classes.slides, dots: classes.dots }}
            />
            <UnderCarousel />
            <TestPage />
            {/* <Explore /> */}
            <SpinxlAutoplay />
            <Partner />
          </Fragment>
        )}
        <MeetTheGear />
        <Join />

        {/*//   <Catalog pathname={pathname} catalogItems={catalogItems} />

    //   {!!middleBanner && (
    //     <img
    //       className={clsx(classes.banner)}
    //       src={isMobile ? middleBannerMobile.url : middleBanner.url}
    //       alt="home middle page banner"
    //     />
    //   )}

    //   {!!product && <Presentation catalogItem={presentationCatalogItem} video={product?.video!} />}*/}
        <SocialMediaBar />
      </div>
    </>
  );
};

export default HomePage;

export const useStyles = makeStyles(
  theme => ({
    wrapper: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },

    post: {
      width: 500,
      minHeight: 600,
      border: `1px solid ${theme.colors.border}`,
      borderRadius: 15,
      boxShadow: theme.colors.shadow,
    },

    banner: {
      width: "100%",
      [theme.device.mobile()]: {
        height: "25vh",
      },
    },
    instagramHeading: {
      fontSize: 42,
      textAlign: "center",
      color: theme.colors.orange,
      marginBottom: 40,
      margin: "0 auto",
      [theme.device.mobile()]: {
        marginBottom: 0,
      },
    },
    carousel: {
      height: "100%",
    },

    slides: {
      [theme.device.mobile()]: {
        position: "relative",
      },
    },

    dots: {
      margin: "80px 0",
      [theme.device.tablet()]: {
        margin: "10px 0",
        // position: "relative",
      },
    },

    activeDesktop: {
      [theme.device.mobile()]: {
        display: "none",
      },
    },

    activeMobile: {
      [theme.device.desktop()]: {
        display: "none",
      },
    },
    joinJoie: {
      width: "100%",
      minHeight: 600,
      position: "relative",
      display: "flex",
      justifyContent: "flex-end",
      [theme.device.mobile()]: {
        minHeight: 0,
      },
    },
    // joinJoieContainer: {
    //   boxSizing: "border-box",
    //   padding: "20px 3px 15px 3px",
    //   marginRight: 60,
    //   marginTop: 40,
    //   width: 480,
    //   maxHeight: 380,
    //   backgroundColor: theme.colors.blueTransparent,
    //   borderTopLeftRadius: 50,
    //   borderTopRightRadius: 50,
    //   borderBottomRightRadius: 50,
    //   borderBottomLeftRadius: 50,
    //   display: "flex",
    //   flexDirection: "column",
    //   alignItems: "center",
    //   gridRowGap: 15,
    //   [theme.device.mobile()]: {
    //     marginRight: 0,
    //   },
    // },
    bigTitle: {
      padding: 0,
      fontWeight: 700,
      fontSize: 40,
      color: theme.colors.text,
    },
    img: {
      zIndex: -1,
      position: "absolute",
      width: "100%",
      height: "100%",
      objectFit: "cover"
    },
    joinJoieForm: {
      width: "100%",
      boxSizing: "border-box",
      padding: "0 30px",
      display: "flex",
      flexDirection: "column",
      gridRowGap: 8,
    },
    exploreInfoText: {
      boxSizing: "border-box",
      padding: "0 15px",
      textAlign: "center",
      width: "100%",
      fontWeight: 600,
      fontSize: 15,
      color: theme.colors.text,
    },
    meetTheGear: {
      padding: "20px 0 35px 0",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.device.mobile()]: {
        padding: "20px 0 20px 0",
      },
    },
    meetTheGearTitle: {
      color: theme.colors.yellow,
    },
    meetTheGearText: {
      boxSizing: "border-box",
      width: "50%",
      margin: "auto",
      padding: "10px 0",
      textAlign: "center",
      color: theme.colors.blue,
      [theme.device.mobile()]: {
        width: "80%",
      },
    },
    meetTheGearCategoriesText: {
      [theme.device.mobile()]: {
        paddingBottom: 0,
        marginBottom: 10
      },
    },
    meetTheGearContainer: {
      paddingTop: 20,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      gridColumnGap: "8%",
      height: 180,
      [theme.device.mobile()]: {
        paddingTop: 0,
        gridColumnGap: 0,
      }
    },
    meetTheGearCategoriesContainer: {
      [theme.device.tablet()]: {
        display: "block",
        textAlign: "center",
        marginBottom: 150,
      }
    },
    meetTheGearArrow: {
      height: "100%",
      position: "relative",
    },
    meetTheGearArrowImg: {
      position: "relative",
      top: "20%",
      bottom: 0,
      left: "100%",
      // margin: "auto",
      [theme.device.mobile()]: {
        top: "40%",
      },
    },
    meetTheGearRightArrow: {
      height: "100%",
      position: "relative",
    },
    meetTheGearRightArrowImg: {
      position: "relative",
      top: "20%",
      bottom: 0,
      margin: "auto",
      right: "100%",
      [theme.device.mobile()]: {
        top: "40%",
      },
    },
    meetTheGearItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    meetTheGearItemImg: {
      width: 250,
      height: "auto",
      display: "block",
      margin: "0 auto",
      marginTop: 10,
      [theme.device.tablet()]: {
        width: 120,
      },
    },
    meetTheGearItemImgUnder: {
      width: 170,
      height: "auto",
      display: "block",
      margin: "0 auto",
      [theme.device.mobile()]: {
        width: 170,
      },
    },
    meetTheGearCategoriesItemImg: {
      [theme.device.tablet()]: {
        width: 40,
      },
    },
    meetTheGrearItemText: {
      color: theme.colors.darkBlue,
      fontWeight: "bold"
    },
    text: {
      fontSize: 13,
      fontWeight: 600,
    },
    title: {
      padding: 0,
      fontWeight: 700,
      fontSize: 30,
    },
    meetTheGearItemTitle: {
      fontSize: 16,
      fontWeight: 700,
      color: theme.colors.blue,
      textAlign: "center",
    },
    meetTheGearItemPara: {
      fontSize: 16,
      fontWeight: 700,
      marginBottom: "12px",
      color: theme.colors.blue,
      textAlign: "center",
    },
    joinJoieInput: {
      border: "none",
      borderRadius: 5,
      textAlign: "end",
      padding: "12px 10px",
      boxSizing: "border-box",
      color: theme.colors.grayText,
      fontSize: 21,
      "&::placeholder": {
        color: theme.colors.gray,
      },
    },
    joinJoieSubmitBtn: {
      color: theme.colors.white,
      textDecoration: "underline",
      textAlign: "end",
      fontSize: 18,
    },
  }),
  { name: "home-page" }
);
