type Language = "en" | "he" | "ar";

// TODO: Change "₪" with the needed currency sign on every specific language.
// Currently the app support only "₪". It's a convinient helper function when
// multicurrency support is present.

const displayCurrencySign = (lang: Language): string => {
  switch (lang) {
    case "en":
      return "₪";

    case "he":
      return "₪";

    case "ar":
      return "₪";

    default:
      return "₪";
  }
};

export default displayCurrencySign;
