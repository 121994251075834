import { makeStyles, useTheme } from "@material-ui/styles";
import { display } from "@mui/system";
import clsx from "clsx";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { config } from "../../config";
import type { Product } from "../../services/products/types";
import { Favorites, LoggedUser } from "../../services/users/types";
import { ReactComponent as BinIcon } from "../../static/icons/bin.svg";
import languagesStore from "../../store/languages-store";
import { useAppDispatch, useAppSelector } from "../../store/store";
import displayCurrencySign from "../../utils/currencies-signs";
import {
  checkProductAvailability,
  countProductPrice,
  detectUserStatus,
  getColors,
  getProductUrl,
  countProductDiscountPrice,
} from "../../utils/helper";
import Button from "../common/button";
import Flag from "../common/flag";

type TProps = {
  product: Product;
  user: LoggedUser;
  isImporterDeals?: boolean;
  isFavorite?: boolean;
  favorites?: boolean;
  removeFromFavorites?: (p: Favorites) => () => void;
  addToCart?: (p: Product) => void;
  toggleFavorite?: (p: Favorites) => void;
};

const ProductItemGrid = ({
  product,
  user,
  isImporterDeals,
  isFavorite,
  favorites,
  toggleFavorite,
  removeFromFavorites,
  addToCart,
}: TProps): JSX.Element => {
  const theme = useTheme();



  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const classes = useStyles();

  const dispatch = useAppDispatch();

  const [isHover, setIsHover] = useState<boolean>(false);

  const lang = useAppSelector(state => state.languages.language);

  const { activeCategory, activeSubCategory, activeSubSubCategory, products } = useAppSelector(
    state => {
      const { activeCategory, activeSubCategory, activeSubSubCategory } = state.categories;
      const products = state.products.productsData.data;

      return {
        activeCategory,
        activeSubCategory,
        activeSubSubCategory,
        products,
      };
    }
  );

  const showPrice = true;

  const colors = getColors(products, product);

  const onColorItemClick = (color: Product) => () => {
    const url = `${isImporterDeals ? config.routes.importerDealsProduct : config.routes.product}/${color.catalogNumber}`;
    const isSignature = product.isSignature;
    let signature = "";
    if (isSignature) {
      signature = "signature";
    }
    dispatch(push(getProductUrl(url, activeCategory, activeSubCategory, activeSubSubCategory, signature), true));
  };

  const productName = `${product.title} ${product.name}`;
  const title: string = productName;

  const colorItems = colors
    ?.filter((_, i) => i < 3)
    .map(cP => {
      return (
        <img
          key={cP.id}
          className={classes.smallImg}
          src={cP?.mainPicture?.url!}
          onClick={onColorItemClick(cP!)}
          alt={`product color ${cP?.color}`}
        />
      );
    });

  const goToSpProduct = () => {
    const url = `${isImporterDeals ? config.routes.importerDealsProduct : config.routes.product}/${product.catalogNumber }`;
    dispatch(push(url));
  };

  const onHoverStart = () => setIsHover(true);

  const onHoverEnd = () => setIsHover(false);
  const updateFavorite = () => {
    toggleFavorite!({ productId: product.id, name: product.name });
  };

  const idLabel = `${product.catalogNumber} :${activePhrases && activePhrases["catalog_number"]}`;

  const { price } = countProductDiscountPrice(product);
  const signatureMode = useAppSelector(state => state.signature.signatureMode);

  return (
    <li className={clsx(signatureMode ? classes.signature : classes.root)} onMouseEnter={onHoverStart} onMouseLeave={onHoverEnd}>
      {/* {!favorites && <img className={classes.brand} src={product.brand?.image?.url!} alt="brand" />} */}

      <div className={classes.content}>
        <div>
          <div className={classes.imageWrapper}>
            <img
              className={clsx(signatureMode ? classes.signatureLargeImage : classes.largeImg)}
              src={product?.mainPicture?.url}
              alt="main"
              onClick={goToSpProduct}
            />
            <span className={classes.awardsWrapper}>
              {product.awards ?
                product.awards.slice(0, 3).map(a =>
                  <img className={classes.award} src={a.image?.url} alt='awards' key={a.id}/>)
                : null}
            </span>

            {product?.quantity === 0 && (
              <div className={classes.outOfStock} onClick={goToSpProduct}>
                {activePhrases && activePhrases["out_of_stock"]}
              </div>
            )}
          </div>

          <div dir="rtl" className={clsx(signatureMode ? classes.signatureTitle : classes.title)} onClick={goToSpProduct}>
            {title}
          </div>
        </div>

        <div className={classes.labelPrice}>
          <div className={classes.id} onClick={goToSpProduct} dir="ltr">
            {idLabel}
          </div>

          {!favorites && (
            <div
              className={clsx(
                classes.smallImgs,
                product.smallPictures.length <= 3 && classes.smallIgmsCount
              )}
            >
              {colors.length > 0 && colorItems}
            </div>
          )}

          <div className={clsx((signatureMode ? classes.priceBlack : classes.price), showPrice && classes.active)} onClick={goToSpProduct}>
            {displayCurrencySign(lang)}{" "}
            {(user?.approvedAgent!
              ? price
              : !!price ? price : 0
            )?.toFixed(2)}
          </div>

          {!favorites && (
            <Button
              width={200}
              height={40}
              borderRadius={10}
              className={clsx(classes.button, isHover && classes.active)}
              text={activePhrases && activePhrases["additional_info"]}
              fill={(signatureMode ? theme.colors.brown : theme.colors.orange)}
              fillHover={theme.colors.text}
              outline={(signatureMode ? theme.colors.brown : theme.colors.orange)}
              textColor={theme.colors.text}
              textColorHover={(signatureMode ? theme.colors.brown : theme.colors.orange)}
              action={goToSpProduct}
            />
          )}
          {favorites && (
            <>
              <Button
                className={classes.favoriteItemBtn}
                text={activePhrases && activePhrases["more_details"]}
                borderWidth={3}
                borderRadius={16}
                textColor={theme.colors.text}
                fill={(signatureMode ? theme.colors.black : theme.colors.orange)}
                outline={(signatureMode ? theme.colors.black : theme.colors.orange)}
                action={goToSpProduct}
              />

              <Button
                className={clsx(
                  classes.favoriteItemBtn,
                  classes.addToCart,
                  (!checkProductAvailability(product?.quantity!) ||
                    !detectUserStatus(user!, product!)) &&
                  classes.addToCartDisabled
                )}
                text={activePhrases && activePhrases["add_to_cart"]}
                borderWidth={3}
                borderRadius={16}
                textColor={(signatureMode ? theme.colors.black : theme.colors.orange)}
                fill={theme.colors.text}
                outline={(signatureMode ? theme.colors.black : theme.colors.orange)}
                action={() => addToCart!(product!)}
              />
            </>
          )}
        </div>


      </div>

      {
        favorites && (
          <BinIcon
            className={clsx(classes.bin, isHover && classes.active)}
            onClick={removeFromFavorites!({ productId: product?.id, name: product?.name })}
          />
        )
      }

      {
        !favorites && (
          <Flag
            className={{
              flagIcon: classes.flagIcon,
              heartIcon: clsx(classes.heartIcon, isHover && classes.active),
            }}
            isFavorite={isFavorite}
            toggleFavorite={updateFavorite}
          />
        )
      }
    </li >
  );
};

export default ProductItemGrid;

const useStyles = makeStyles(
  theme => ({
    root: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      boxSizing: "border-box",
      padding: "0 8px 8px 8px",
      width: "100%",
      borderRadius: 15,
    },
    signature: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      boxSizing: "border-box",
      padding: "0 8px 8px 8px",
      width: "100%",
      borderRadius: 15,
    },
    brand: {
      fontSize: 13,
      position: "absolute",
      alignSelf: "flex-end",
      marginTop: 12,
      maxHeight: 22,
      width: 50,
      left: 0,
      objectFit: "contain",
      [theme.device.mobile()]: {
        width: 30,
        height: 10,
        left: 50,
      },
    },
    labelPrice: {
      //backgroundColor: 'red',
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    awardsWrapper: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      marginRight: "65%",
      [theme.device.tablet()]: {
        marginRight: "35%",
      },
      [theme.device.mobile()]: {
        marginRight: "45%",
      },
    },
    award: {
      width: 50,
      height: 50,
      objectFit: "contain",
      [theme.device.mobile()]: {
        width: 40,
        height: 30,
      },
    },
    content: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      height: "100%",
    },
    imageWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
    },
    largeImg: {
      userSelect: "none",
      marginTop: 4,
      height: 150,
      maxWidth: "50%",
      objectFit: "contain",
      cursor: "pointer",
      [theme.device.mobile()]: {
        height: 200,
        width: 300
      },
    },
    signatureLargeImage: {
      userSelect: "none",
      marginTop: 4,
      height: 150,
      width: "50%",
      objectFit: "contain",
      cursor: "pointer",
      [theme.device.mobile()]: {
        height: 100,
      },
    },
    title: {
      wordBreak: "break-word",
      color: theme.colors.black,
      fontSize: 20,
      fontWeight: 600,
      cursor: "pointer",
      textAlign: "center",
      minHeight: 60,
      [theme.device.mobile()]: {
        fontSize: 16,
      },
    },
    signatureTitle: {
      wordBreak: "break-word",
      color: theme.colors.black,
      fontSize: 20,
      fontWeight: 600,
      cursor: "pointer",
      textAlign: "center",
      minHeight: 60,
      padding: "0px 10%",
      [theme.device.mobile()]: {
        fontSize: 16,
      },
    },
    id: {
      color: theme.colors.grayText,
      fontSize: 16,
      fontWeight: 600,
      cursor: "pointer",
      [theme.device.mobile()]: {
        fontSize: 13,
      },
    },
    price: {
      opacity: 0,
      visibility: "hidden",
      padding: "3px 0",
      color: theme.colors.blue,
      fontWeight: 600,
      fontSize: 23,
      cursor: "pointer",
      direction: "ltr",
      [theme.device.mobile()]: {
        fontSize: 19,
      },
    },
    priceBlack: {
      opacity: 0,
      visibility: "hidden",
      padding: "3px 0",
      color: theme.colors.black,
      fontWeight: 600,
      fontSize: 23,
      cursor: "pointer",

      [theme.device.mobile()]: {
        fontSize: 19,
      },
    },
    smallImgs: {
      userSelect: "none",
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
      direction: "rtl",
      padding: "3px 0",
      [theme.device.desktop()]: {
        padding: "3px 0",
      },
    },
    smallIgmsCount: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    smallImg: {
      width: 30,
      height: "100%",
      minHeight: 42,
      objectFit: "contain",
      cursor: "pointer",
      marginRight: 5,
      "&:last-child": {
        // marginRight: 0,
      },
    },
    button: {
      textAlign: "center",
      transition: "0.3s",
      opacity: 0,
      visibility: "hidden",
      //marginTop: "auto",
      [theme.device.mobile()]: {
        opacity: 1,
        visibility: "visible",
        width: "75%",
        height: 30,
        borderRadius: 25,
        fontSize: 12,
      },
      [theme.device.smallMobile()]: {
        fontSize: 10,
      },
    },
    favoriteItemBtn: {
      boxShadow: theme.utils.boxShadow(theme.colors.shadow),
      width: "100%",
      height: 45,
      fontSize: 20,
      [theme.device.mobile()]: {
        height: 40,
        fontSize: 17,
      },
      [theme.device.smallMobile()]: {
        height: 30,
      },
    },
    flagIcon: {
      opacity: 0,

    },
    heartIcon: {
      transition: "0.3s",
      width: 35,
      height: 35,
      [theme.device.desktop()]: {
        opacity: 0,
        visibility: "hidden",
      },
      [theme.device.mobile()]: {
        width: 30,
        height: 30,
      },
    },
    addToCartDisabled: {
      backgroundColor: theme.colors.grayText,
      cursor: "default",
      color: theme.colors.text,
      borderColor: theme.colors.grayText,
    },
    bin: {
      width: 30,
      position: "absolute",
      right: 10,
      top: -8,
      cursor: "pointer",
      [theme.device.mobile()]: {
        width: 25,
      },
      [theme.device.desktop()]: {
        opacity: 0,
        visibility: "hidden",
      },
    },
    addToCart: {
      marginTop: 20,
      [theme.device.mobile()]: {
        marginTop: 10,
      },
    },
    active: {
      transition: "0.3s",
      visibility: "visible",
      opacity: 1,
    },
    outOfStock: {
      cursor: "pointer",
      position: "absolute",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      textAlign: "center",
      borderRadius: 20,
      color: theme.colors.text,
      padding: 10,
    },
  }),
  { name: "product-item-grid" }
);
