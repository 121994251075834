import {
  createAction,
  createAsyncThunk,
  createReducer,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";

import { SendMessage, SendMessagePayload } from "../services/users/types";
import createCmsClient from "../services/common/cmsClient";
import { TThunk } from "./store";

type State = {
  data: SendMessage | {} | "";
  loading: boolean;
  loaded: boolean;
  error: Error | string | null;
};

const initialState: State = {
  data: {},
  loading: false,
  loaded: false,
  error: null,
};

const cmsClient = createCmsClient();

const sendMessage: TThunk<SendMessagePayload, SendMessage> = createAsyncThunk(
  "user/send-message",
  async (msgData: SendMessage) => {
    try {
      console.log("userData --->", msgData);
      const { data } = await cmsClient.post("/user-messages", msgData);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

const clearMessageStatus = createAction("user/clear-send-message");

const reducer = createReducer(initialState, {
  [sendMessage.pending.type]: state => {
    state.loading = true;
  },
  [sendMessage.fulfilled.type]: (state, action) => {
    state.loading = false;
    state.loaded = true;
    state.data = action.payload;
  },
  [sendMessage.rejected.type]: (
    state,
    action: PayloadAction<null, string, unknown, SerializedError>
  ) => {
    state.loading = false;
    state.loaded = true;
    state.error = action.error.message || "General Error";
  },

  [clearMessageStatus.toString()]: state => {
    state.data = {};
  },
});

const actions = {
  sendMessage,
  clearMessageStatus,
};

const sendMessageStore = {
  actions,
  reducer,
};

export default sendMessageStore;

export type { State };
