import { makeStyles } from "@material-ui/styles";
import React from "react";
import clsx from "clsx";
import { useAppSelector } from "../../store/store";

type ProviderAuthButton = {
  providerName: string;
  className?: string;
  logoUrl: string;
  text: string | undefined;
  imgSize: {
    width: number;
    height: number;
  };
};

const ProviderAuthButton = (props: ProviderAuthButton): JSX.Element => {
  const classes = useStyles();
  const cmsUrl = process.env.REACT_APP_STRAPI_CMS_URL;
  const { pathname, search } = useAppSelector(state => state.router.location);

  const handleClick = () => {
    const currentLocation = pathname + search;
    localStorage.setItem("lastLocation", currentLocation);
  };

  return (
    <a
      className={clsx(classes.provider, props.className)}
      href={`${cmsUrl}connect/${props.providerName}`}
      onClick={handleClick}
    >
      <img
        src={props.logoUrl}
        alt="auth provider logo"
        width={props.imgSize.width}
        height={props.imgSize.height}
      />{" "}
      {props.text}
    </a>
  );
};

export default ProviderAuthButton;

const useStyles = makeStyles(
  theme => ({
    provider: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 240,
      height: 30,
      padding: "4px 10px",
      fontWeight: 600,
      margin: "10px 0",
      fontSize: 16,
      borderRadius: "3em",
      textAlign: "center",
      textDecoration: "none",
      backgroundColor: "#fff",
      border: `1px solid ${theme.colors.blue}`,

      "&:hover": {
        color: theme.colors.blue,
      },

      "&:active": {
        color: theme.colors.blue,
      },

      "&:visited": {
        color: theme.colors.blue,
      },

      "&>img": {
        marginRight: 6,
      },
    },
  }),
  { name: "auth-provider-button" }
);
