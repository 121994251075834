import { makeStyles } from "@material-ui/styles";
import Loader from "react-loader-spinner";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

import { useAppDispatch, useAppSelector } from "../../../store/store";
import { UpdateUserInfo } from "../../../services/users/types";
import usersStore from "../../../store/users-store";
import languagesStore from "../../../store/languages-store";

type Props = {
  hideForm: () => void;
};

const PersonalDetailsForm = ({ hideForm }: Props): JSX.Element => {
  const classes = useStyles();

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const dispatch = useAppDispatch();

  const { lang, user } = useAppSelector(state => ({
    lang: state.languages.language,
    user: state.user,
  }));

  const initialValues: UpdateUserInfo = {
    userId: undefined,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
  };

  const validationSchema: any = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string().email(activePhrases && activePhrases["invalid_email"]),
    phoneNumber: Yup.string(),
    address: Yup.string(),
  });

  const onSubmit = (values: UpdateUserInfo) => {
    const fieldsWithUpdatedData: UpdateUserInfo = {
      userId: user.data?.id,
    };

    if (values.firstName?.length! > 0) fieldsWithUpdatedData.firstName = values.firstName;
    if (values.lastName?.length! > 0) fieldsWithUpdatedData.lastName = values.lastName;
    if (values.email?.length! > 0) fieldsWithUpdatedData.email = values.email;
    if (values.phoneNumber?.length! > 0) fieldsWithUpdatedData.phoneNumber = values.phoneNumber;
    if (values.address?.length! > 0) fieldsWithUpdatedData.address = values.address;

    dispatch(usersStore.actions.updateUserInfo(fieldsWithUpdatedData));

    formik.resetForm({});
  };

  const formik = useFormik<UpdateUserInfo>({ initialValues, validationSchema, onSubmit });

  useEffect(() => {
    if (user?.updated) {
      setTimeout(() => {
        dispatch(usersStore.actions.resetUserUpdateFlag());
        hideForm?.();
      }, 4000);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user?.updated, user, hideForm]);

  return (
    <div className={classes.wrapper}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <div className="form-row">
          <label htmlFor="firstName">{activePhrases && activePhrases["first_name"]}</label>
          <input
            id="firstName"
            className={clsx(formik.errors.firstName && formik.touched.firstName && "invalid-field")}
            name="firstName"
            type="text"
            onChange={formik.handleChange}
          />
          {formik.errors.firstName && formik.touched.firstName && (
            <span className="error">{formik.errors.firstName}</span>
          )}
        </div>

        <div className="form-row">
          <label htmlFor="lastName">{activePhrases && activePhrases["last_name"]}</label>
          <input
            id="lastName"
            className={clsx(formik.errors.lastName && formik.touched.lastName && "invalid-field")}
            name="lastName"
            type="text"
            onChange={formik.handleChange}
          />
          {formik.errors.lastName && formik.touched.lastName && (
            <span className="error">{formik.errors.lastName}</span>
          )}
        </div>

        <div className="form-row">
          <label htmlFor="email">{activePhrases && activePhrases["email"]}</label>
          <input
            id="email"
            className={clsx(formik.errors.email && formik.touched.email && "invalid-field")}
            name="email"
            type="email"
            onChange={formik.handleChange}
          />
          {formik.errors.email && formik.touched.email && (
            <span className="error">{formik.errors.email}</span>
          )}
        </div>

        <div className="form-row">
          <label htmlFor="phone">{activePhrases && activePhrases["phone_number"]}</label>
          <input
            id="phone"
            className={clsx(
              formik.errors.phoneNumber && formik.touched.phoneNumber && "invalid-field"
            )}
            name="phoneNumber"
            type="tel"
            onChange={formik.handleChange}
          />
          {formik.errors.phoneNumber && formik.touched.phoneNumber && (
            <span className="error">{formik.errors.phoneNumber}</span>
          )}
        </div>

        <div className="form-row">
          <label htmlFor="address">{activePhrases && activePhrases["address"]}</label>
          <input
            id="address"
            className={clsx(formik.errors.address && formik.touched.address && "invalid-field")}
            name="address"
            type="text"
            onChange={formik.handleChange}
          />
          {formik.errors.address && formik.touched.address && (
            <span className="error">{formik.errors.address}</span>
          )}
        </div>

        {user?.errors.authentication && (
          <div className="error">
            <span>{user.errors.authentication}</span>
          </div>
        )}

        {user?.updated && (
          <div className={classes.updateMessage}>
            <span>{activePhrases && activePhrases["update_message"]}</span>
          </div>
        )}

        <div className={classes.saveBtnWrapper}>
          <button className={classes.saveBtn} type="submit">
            {user?.loading ? (
              <Loader type="ThreeDots" color="#FFF" width={45} />
            ) : (
              activePhrases && activePhrases["save_changes"]
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PersonalDetailsForm;

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      marginTop: 50,

      "&.separate": {
        borderTop: `1px solid ${theme.colors.blue}`,
      },

      "&>form": {
        padding: "0 10px",

        "&>div.form-row": {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",

          marginBottom: 20,

          "&>label": {
            fontSize: 24,
            marginBottom: 6,
            textAlign: "end",
            fontWeight: "bold",
            color: `${theme.colors.grayBorder}`,

            "@media (min-width: 1200px)": {
              fontSize: 35,
              marginBottom: 16,
            },
          },

          "&>input": {
            boxSizing: "border-box",
            padding: "0 5px",
            textAlign: "end",
            height: 50,
            fontSize: 24,
            width: "100%",
            maxWidth: 500,
            paddingLeft: 10,
            borderRadius: 10,
            border: `1px solid ${theme.colors.blue}`,

            "&.invalid-field": {
              borderColor: theme.colors.red,
            },

            "@media (min-width: 1200px)": {
              height: 55,
            },
          },

          "&>input:-webkit-autofill": {
            "-webkit-box-shadow": "0 0 0px 1000px #fff inset",
          },

          "&>span": {
            "&.error": {
              fontSize: 20,
              color: theme.colors.red,

              "@media (min-width: 1200px)": {},
            },

            "&.pwd-hint": {
              fontSize: 18,
              color: theme.colors.grayText,

              "@media (min-width: 800px)": {
                fontSize: 20,
              },

              "@media (min-width: 1000px)": {
                fontSize: 25,
              },
            },
          },
        },

        "&>div": {
          "&.error": {
            fontSize: 20,
            textAlign: "end",
            color: theme.colors.red,
          },
        },
      },
    },

    tabTitle: {
      fontSize: 34,
      fontWeight: "bold",
      textAlign: "center",
      margin: "10px 0 30px 0",

      [theme.device.desktop()]: {
        textAlign: "end",
      },
      "@media (min-width: 800px)": {
        fontSize: 40,
      },
    },

    saveBtnWrapper: {
      display: "flex",
      justifyContent: "flex-end",

      "&>button": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 200,
        height: 50,
      },
    },
    saveBtn: {
      display: "grid",
      placeItems: "center",

      fontSize: 28,
      marginTop: 40,
      minWidth: 100,
      maxHeight: 80,
      outline: "none",
      color: "#fff",
      cursor: "pointer",
      borderRadius: 30,
      padding: "6px 10px",
      textAlign: "center",
      backgroundColor: theme.colors.blue,

      [theme.device.desktop()]: {
        fontSize: 24,
      },
      "@media (min-width: 700px)": {
        fontSize: 30,
      },
      "@media (min-width: 800px)": {
        textAlign: "end",
      },
      "@media (min-width: 1200px)": {
        fontSize: 35,
        maxHeight: 100,
        textAlign: "center",
      },
    },

    updateMessage: {
      color: theme.colors.blue,
      textAlign: "end",
      fontSize: 20,

      [theme.device.desktop()]: {
        fontSize: 30,
      },
    },
  }),
  { name: "my-account-overview-personal-details" }
);
