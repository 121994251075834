import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Layout } from "../../store/ui-sorting-store";
import SimplyTimeless from "../simply-timeless";
import VideosSignature from "../VideoSignature";
import WhyToBuySignature from "../WhyToBuySignature";
import { SignatureProducts } from "../SignatureProducts";
import OverFooterContainer from "../OverFooterContainer";

const SignaturePage = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>

      <>
        <VideosSignature />
        <SimplyTimeless />
        <WhyToBuySignature />
        <SignatureProducts />
        <OverFooterContainer />
      </>


    </div>
  );
};

export default SignaturePage;

export type { Layout };

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
  }),
  // { name: "products-page" }
);
