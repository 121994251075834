import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { push } from "connected-react-router";
import clsx from "clsx";

import CategoryPanel from "../category/category-panel";
import OrderSummary from "../common/order-summary";
import EmptyBag from "../common/empty-bag";

import { useAppSelector, useAppDispatch } from "../../store/store";
import { countProductDiscountPrice, countProductPrice } from "../../utils/helper";
import { UpdatedProduct } from "../header/cart/cart";
import { getPhrase } from "../../utils/language";
import CartItem from "../header/cart/cart-item";
import { config } from "../../config";
import languagesStore from "../../store/languages-store";

const ShoppingBagPage = (): JSX.Element => {
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const cart = useAppSelector(state => state.cart.items);

  const { isAgent, cartProducts, cartItems, lang, discount } = useAppSelector(state => {
    const cartItems = state.user.data?.cartItems;
    const isAgent = state.user.data?.approvedAgent;
    const discount = state.user.data?.discount;
    const cartProducts: UpdatedProduct[] = [];

    state.products.productsData.data?.forEach((product: UpdatedProduct) => {
      const updatedProduct = { ...product };
      if (
        cartItems?.find(cartItem => {
          let { price } = countProductDiscountPrice(cartItem);
          if (cartItem.productId === product.id) {
            updatedProduct.count = cartItem.productCount;
            updatedProduct.totalPrice = price * cartItem.productCount;
            updatedProduct.totalWeight = product.weight * cartItem.productCount;
            updatedProduct.price = price;
            return updatedProduct;
          }

          return;
        })
      ) {
        cartProducts.push(updatedProduct);
      } else if (
        cart?.find(cartItem => {
          let { price } = countProductDiscountPrice(cartItem.product);
          if (cartItem.product.id === product.id) {
            updatedProduct.count = cartItem.count;
            updatedProduct.totalPrice = price * cartItem.count;
            updatedProduct.totalWeight = product.weight * cartItem.count;
            updatedProduct.price = price;
            return updatedProduct;
          }

          return;
        })
      ) {
        cartProducts.push(updatedProduct);
      }

      return false;
    });

    return {
      // Do NOT remove cartItems! They are use in the useEffect as a source of truth.
      // Otherwise on hard-refresh it will always go in the first if() condition and
      // redirect to "/catalog"
      cartItems: state.user.data?.cartItems,
      lang: state.languages.language,
      cartProducts,
      isAgent,
      discount,
    };
  });

  const dispatch = useAppDispatch();
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const classes = useStyles();

  useEffect(() => {
    if (cartItems?.length === 0 && cart.length === 0) {
      dispatch(push(config.routes.catalogPage, true));
    }

    let totalCartCount = 0;
    let totalItem = 0;
    if (!cartItems?.length) {
      cart.forEach(cP => {
        let { price } = countProductDiscountPrice(cP.product);
        totalItem += price * cP.count!;
        totalCartCount += cP.count!;
      });
    } else {
      cartProducts.forEach(cP => {
        let { price } = countProductDiscountPrice(cP);
        totalItem += price * cP.count!;
        totalCartCount += cP.count!;
      });
    }
    setTotalCount(totalCartCount);
    setTotalPrice(totalItem);
  }, [dispatch, cartProducts, cartItems, cart]);
  return (
    <div className={classes.root}>
      <CategoryPanel />

      <div className={clsx(classes.title, classes.titleBig)}>
        {activePhrases && activePhrases["shopping_bag"]}
      </div>

      <EmptyBag className={{ root: classes.emptyBag }} />

      <div className={clsx(classes.column, classes.count, classes.activeMobile)}>
        {lang === "he" && (
          <span>
            {totalCount === 1
              ? activePhrases && activePhrases["item"]
              : activePhrases && activePhrases["items"]}
            &nbsp;
          </span>
        )}

        <div className={clsx(classes.blueText, classes.text)}>{totalCount}</div>

        {lang !== "he" && (
          <span>
            &nbsp;
            {totalCount === 1
              ? activePhrases && activePhrases["item"]
              : activePhrases && activePhrases["items"]}
          </span>
        )}
      </div>

      <div className={classes.shoppingBag}>
        <OrderSummary
          className={{ root: classes.activeDesktop, shipping: classes.shipping }}
          totalPrice={totalPrice}
          couponDiscount={0}
        />

        <div className={classes.orderProducts}>
          <div className={clsx(classes.columns, classes.activeDesktop)}>
            <div className={classes.column}>{activePhrases && activePhrases["subtotal"]}</div>

            <div className={classes.column}>{activePhrases && activePhrases["quantity"]}</div>

            <div className={clsx(classes.column, classes.count, classes.activeDesktop)}>
              {lang === "he" && (
                <span>
                  {totalCount === 1
                    ? activePhrases && activePhrases["item"]
                    : activePhrases && activePhrases["items"]}
                  &nbsp;
                </span>
              )}

              <div className={clsx(classes.blueText, classes.text)}>{totalCount}</div>

              {lang !== "he" && (
                <span>
                  &nbsp;
                  {totalCount === 1
                    ? activePhrases && activePhrases["item"]
                    : activePhrases && activePhrases["items"]}
                </span>
              )}
            </div>
          </div>

          <div className={classes.items}>
            {cartProducts.length
              ? cartProducts.map(item => <CartItem key={item.id} cartProduct={item} shoppingBag />)
              : cart.map(item => (
                  <CartItem key={item.product.id} cartProduct={item.product} shoppingBag />
                ))}
          </div>
        </div>
      </div>
      <div className={clsx(classes.border, classes.activeMobile)} />
      <div className={classes.summaryWr}>
        <OrderSummary
          className={{ root: classes.activeMobile, shipping: classes.shipping }}
          totalPrice={totalPrice}
          couponDiscount={0}
        />
      </div>
    </div>
  );
};

export default ShoppingBagPage;

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      [theme.device.mobile()]: {
        paddingBottom: 20,
      },
    },
    title: {
      fontWeight: 800,
      fontSize: 25,
      color: theme.colors.blue,
      cursor: "default",
      userSelect: "none",
    },
    titleBig: {
      padding: "25px 0",
      fontSize: 35,
    },
    shipping: {
      display: "none",
    },
    shoppingBag: {
      boxSizing: "border-box",
      padding: 15,
      width: "100%",
      display: "grid",
      gridTemplateColumns: "380px 2.1fr",
      gridColumnGap: 15,
      [theme.device.mobile()]: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: 0,
      },
    },
    blueText: {
      color: theme.colors.blue,
      fontSize: 16,
      fontWeight: 600,
    },
    grayText: {
      color: theme.colors.grayText,
      fontSize: 15,
      fontWeight: 600,
    },
    orderProducts: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    columns: {
      boxSizing: "border-box",
      paddingLeft: 35,
      paddingBottom: 10,
      display: "flex",
      alignItems: "flex-start",
      gridColumnGap: 72,
      borderBottom: `1px solid ${theme.colors.border}`,
    },
    column: {
      userSelect: "none",
      cursor: "pointer",
      color: theme.colors.grayText,
      fontSize: 18,
      display: "flex",
      alignItems: "flex-end",
    },
    count: {
      marginLeft: "auto",
      paddingRight: 50,
      [theme.device.mobile()]: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 0,
        paddingBottom: 5,
        borderBottom: `1px solid ${theme.colors.grayBorder}`,
        "& > span": {
          fontSize: 29,
        },
        "& > div": {
          fontSize: 30,
          paddingRight: 15,
        },
      },
    },
    text: {
      fontSize: 19,
    },
    items: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "10px 0 0",
      gridRowGap: 5,
    },
    summaryWr: {
      width: "100%",
      boxSizing: "border-box",
      padding: "0 15px",
    },
    emptyBag: {
      paddingRight: 15,
    },
    border: {
      margin: "20px 0",
      borderTop: `1px solid ${theme.colors.grayText}`,
      width: "100%",
    },
    activeDesktop: {
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    activeMobile: {
      [theme.device.desktop()]: {
        display: "none",
      },
    },
  }),
  { name: "shopping-bag-page" }
);
