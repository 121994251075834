import { makeStyles } from "@material-ui/styles";
import React from "react";
import languagesStore from "../../../store/languages-store";
import { useAppSelector } from "../../../store/store";
import SocialMediaIcons from "./social-media-icons";

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      paddingTop: 40,
      [theme.device.mobile()]: {
        alignItems: "center",
        width: "100%",
        paddingTop: 10,
        gridRow: 3,
      },
    },
    title: {
      fontWeight: "bold",
      fontSize: 20,
      marginBottom: 15,
      color: theme.colors.black,
      [theme.device.mobile()]: {
        fontSize: 22,
      },
    },
    titleBlack: {
      fontWeight: "bold",
      fontSize: 20,
      marginBottom: 15,
      color: theme.colors.brown,
      [theme.device.mobile()]: {
        fontSize: 22,
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      [theme.device.mobile()]: {
        alignItems: "center",
      },
    },
    item: {
      fontWeight: 600,
      color: theme.colors.black,
      fontSize: 20,
      lineHeight: 1.5,
      borderBottom: "2px solid transparent",
      [theme.device.mobile()]: {
        fontSize: 22,
      },
      [theme.device.mobile480()]: {
        fontSize: 18,
      },
    },
    itemBlack: {
      fontWeight: 600,
      color: theme.colors.brown,
      fontSize: 20,
      lineHeight: 1.5,
      borderBottom: "2px solid transparent",
      [theme.device.mobile()]: {
        fontSize: 22,
      },
      [theme.device.mobile480()]: {
        fontSize: 18,
      },
    },
  }),
  { name: "contact-us" }
);

const ContactUs = (): JSX.Element => {
  const classes = useStyles();
  const signatureMode = useAppSelector(state => state.signature.signatureMode);

  const { contactUs } = useAppSelector(state => state.siteConfig);
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  return (
    <div className={classes.root}>
      <div className={(signatureMode ? classes.titleBlack : classes.title)}>:{activePhrases && activePhrases["contact_us"]}</div>
      <div className={classes.container}>
        <div className={(signatureMode ? classes.itemBlack : classes.item)}>{activePhrases && activePhrases["main_address"]}: {contactUs?.address}</div>
        <div className={(signatureMode ? classes.itemBlack : classes.item)}></div>
        <div className={(signatureMode ? classes.itemBlack : classes.item)}>
          {contactUs?.phone} :{activePhrases && activePhrases["phone"]}
        </div>
        <div className={(signatureMode ? classes.itemBlack : classes.item)}>
          {contactUs?.fax} :{activePhrases && activePhrases["fax"]}
        </div>
        <div className={(signatureMode ? classes.itemBlack : classes.item)}>
          {contactUs?.email} :{activePhrases && activePhrases["email"]}
        </div>
        <SocialMediaIcons />
      </div>
    </div>
  );
};

export default ContactUs;
