import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(
  theme => ({
    root: {
      position: "absolute",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      minWidth: "100%",
      minHeight: "100%",
      zIndex: 5,
      backdropFilter: "blur(20px)",
      visibility: "hidden",
      opacity: 0,
      transition: "0.4s",
    },
    active: {
      visibility: "visible",
      opacity: 1,
    },
  }),
  { name: "popup" }
);

type TProps = {
  active?: boolean;
  className?: string;
  children?: JSX.Element | JSX.Element[];
};

const Popup = ({ active, children, className }: TProps) => {
  const classes = useStyles();

  return <div className={clsx(classes.root, className, active && classes.active)}>{children}</div>;
};

export default Popup;
