import { createAction, createReducer } from "@reduxjs/toolkit";

type State = {
  authFormIsVisible: boolean;
  cartIsVisible: boolean;
  langDropDownIsVisible: boolean;
  hamburgerMenuIsVisble: boolean;
  sortDropDownIsVisible: boolean;
  searchIsVisible: boolean;
  successPopIsVisible: boolean;
};

const initialState: State = {
  authFormIsVisible: false,
  cartIsVisible: false,
  langDropDownIsVisible: false,
  hamburgerMenuIsVisble: false,
  sortDropDownIsVisible: false,
  searchIsVisible: false,
  successPopIsVisible: false,
};

type MenuType =
  | "authFormIsVisible"
  | "cartIsVisible"
  | "langDropDownIsVisible"
  | "hamburgerMenuIsVisble"
  | "sortDropDownIsVisible"
  | "searchIsVisible"
  | "successPopIsVisible";

const toggleVisibility = createAction("auth-form/toggle", (type: MenuType, newState: boolean) => ({
  payload: {
    type,
    newState,
  },
}));

const closeAllDropDowns = createAction("dropdowns/close");

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(toggleVisibility, (state, action) => {
      const { type, newState } = action.payload;

      return (state = {
        authFormIsVisible: false,
        cartIsVisible: false,
        hamburgerMenuIsVisble: false,
        langDropDownIsVisible: false,
        sortDropDownIsVisible: false,
        searchIsVisible: false,
        successPopIsVisible: false,
        ...{ [type]: newState },
      });
    })

    .addCase(closeAllDropDowns, state => ({
      ...state,
      authFormIsVisible: false,
      // cartIsVisible: false,
      langDropDownIsVisible: false,
      hamburgerMenuIsVisble: false,
      sortDropDownIsVisible: false,
      searchIsVisible: false,
    }));
});

const actions = { toggleVisibility, closeAllDropDowns };

const authFormStore = {
  reducer,
  actions,
};

export default authFormStore;

export type { State };
