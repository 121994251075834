import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import React from "react";
import App from "./app";

import * as serviceWorker from "./serviceWorker";
import { history } from "./store/root-reducer";
import { createStore } from "./store/store";
import Theme from "./theme";

import "./assets/styles/payment.css";
import "./assets/i18n/i18n";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./index.css";

const store = createStore();

const render = (app: JSX.Element) => {
  const APP = (
    <React.StrictMode>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Theme>{app}</Theme>
        </ConnectedRouter>
      </Provider>
    </React.StrictMode>
  );

  const rootEl = document.getElementById("root");

  if (rootEl?.hasChildNodes()) {
    ReactDOM.hydrate(APP, rootEl);
    return;
  }

  ReactDOM.render(APP, rootEl);
};

render(<App />);

if (module.hot) {
  module.hot.accept("./app", () => {
    const NextApp = require("./app").default;
    render(<NextApp />);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
