import React, { useEffect, useState } from "react";

import fitGuideStore from "../../store/fit-guide-store";

import { useAppDispatch, useAppSelector } from "../../store/store";

import { getCarMakes, getCarModel, getCarYears } from "../../services/fit-guide-service";

import DisplayAllProducts from "./productsView";

import { makeStyles, useTheme } from "@material-ui/styles";

import { push } from "connected-react-router";


const CarsFit = () => {

  const isClicked = { one: false, two: false, three: false };


  const dispatch = useAppDispatch();

  const classes = useStyles();

  const { cars, totalCars } = useAppSelector(state => state.fitGuide);

  const [currCar, setCurrCar] = useState({});

  // for current car model

  const [currData, setCurrData] = useState([]);

  //for current car model data

  const [currModelData, setCurrModelData] = useState([]);


  // for current model years

  const [currYearsData, setCurrYearsData] = useState([]);

  // set values for every car

  const [currSelecetCarMake, setCurrSelecetCarMake] = useState("");

  const [currSelecetCarModel, setCurrSelecetCarModel] = useState("");

  const [currSelecetCarYears, setcurrSelecetCarYears] = useState("");


  let currentCars = cars.length;


  const [totalCar, setTotalCars] = useState(0);


  // this use effect fetches data for models / do not touch !

  useEffect(() => {

    getCarMakes().then(data => setCurrData(data));

    console.log(currData);

  }, [currSelecetCarMake]);


  // dont remove

  useEffect(() => {

    currentCars = cars.length;

  }, [cars]);


  // handles when you chose number of cars

  const handleCarsCountClick = (e: any) => {

    let currValue = e.target.innerHTML;

    if (currValue == 1) {

      e.target.classList.add(classes.blue);

      let pElements = e.target.parentNode.getElementsByTagName("p");

      pElements[1].classList.remove(classes.blue);

      pElements[2].classList.remove(classes.blue);

    } else if (currValue == 2) {

      e.target.classList.add(classes.blue);

      let pElements = e.target.parentNode.getElementsByTagName("p");

      pElements[0].classList.remove(classes.blue);

      pElements[2].classList.remove(classes.blue);

    } else if (currValue == 3) {

      e.target.classList.add(classes.blue);

      let pElements = e.target.parentNode.getElementsByTagName("p");

      pElements[0].classList.remove(classes.blue);

      pElements[1].classList.remove(classes.blue);

    }

    console.log(currValue);

    e.preventDefault();

    setTotalCars(+e.target.innerHTML);

  };


  // handle for submit button and setter for total client selected

  const handleSubmit = (e: any) => {

    e.preventDefault();

    dispatch(fitGuideStore.actions.setCarsCount("totalCars", totalCar));

  };


  //handles when you chose a car make and fetches models by this make/brand

  const handleOnCarsChange = (e: any) => {

    //set the selected value from car make selector

    setCurrSelecetCarMake(e.target.value);

    // let carSetter = { make: e.target.value };

    // setCurrCar({ ...carSetter });


    getCarModel({ make: e.target.value }).then(data => setCurrModelData(data));


    console.log(currModelData);

  };


  // handles when you select car model and shows yars of production for this model

  const handleOnModelsChange = (e: any) => {

    //set selected value from car_model selector

    setCurrSelecetCarModel(e.target.value);

    getCarYears({ model: e.target.value }).then(data => setCurrYearsData(data));

  };


  const handleOnYearssChange = (e: any) => {

    //set selected value from years selector

    let splitData = e.target.value.split(" - ");

    setcurrSelecetCarYears(splitData[0]);

  };


  useEffect(() => {}, [currModelData]);


  const handleCarSelect = (e: any) => {

    e.preventDefault();

    // console.log(currSelecetCarMake + currSelecetCarModel + currSelecetCarYears)

    let currDataCar = {

      make: currSelecetCarMake,

      model: currSelecetCarModel,

      start_year: currSelecetCarYears,

    };


    let dispatchCurrCar;

    if (!cars) {

      dispatchCurrCar = [currDataCar];

    } else {

      dispatchCurrCar = [...cars, currDataCar];

    }

    console.log(dispatchCurrCar);

    // dispatchCurrCar.push({...currCar});

    dispatch(fitGuideStore.actions.setCars("totalCars", dispatchCurrCar));


    setCurrSelecetCarModel("");

    setCurrSelecetCarMake("");

    setcurrSelecetCarYears("");

    setCurrData([]);

    setCurrModelData([]);

    setCurrYearsData([]);

    console.log(e.target.parentNode);

  };


  const handleBack = () => {

    dispatch(fitGuideStore.actions.setFamily("family", ""));

  };


  const handleNoVehicleClick = (e: any) => {

    e.preventDefault();

    dispatch(push("/vehicle-not-found", true));

  }


  return (

    <div>

      {totalCars ? <h3>Select Car {currentCars + 1}</h3> : null}

      {totalCars}

      {totalCars < 1 ? (

        <div>

          <h1>I'm in cars</h1>

          <h3>How many cars do you have</h3>

          <div className={classes.carsNumberContainer}>

            <p className={classes.numberElement} onClick={handleCarsCountClick}>

              1

            </p>

            <p className={classes.numberElement} onClick={handleCarsCountClick}>

              2

            </p>

            <p className={classes.numberElement} onClick={handleCarsCountClick}>

              3

            </p>

          </div>

          <button className={classes.buttons} onClick={handleSubmit}>

            Sumbit

          </button>

        </div>

      ) : currentCars < totalCars ? (

        <form id="my-form" className={classes.selectForm}>

          <select className={classes.selectElement} onChange={handleOnCarsChange} name="cars">

            <option disabled selected value="Select">

              Select Your Make

            </option>

            {currData.map((car: any) => {

              let asd = car.car_make;

              return <option value={asd}>{asd}</option>;

            })}

          </select>


          <select className={classes.selectElement} onChange={handleOnModelsChange} name="cars">

            <option disabled selected value="Select">

              Select Your Model

            </option>

            {currModelData.map((car: any) => {

              let asd = car.car_model;

              return <option value={asd}>{asd}</option>;

            })}

          </select>


          <select className={classes.selectElement} onChange={handleOnYearssChange} name="cars">

            <option disabled selected value="Select">

              Select Vehicle Years

            </option>

            {currYearsData.map((car: any) => {

              let asd = `${car.start_year} - ${car.end_year}`;

              return <option value={asd}>{asd}</option>;

            })}

          </select>

            

          <a className={classes.noVehicleLink} onClick={handleNoVehicleClick} >my vehicle is not listed</a>


          <button className={classes.buttons} onClick={handleBack}>

            Back

          </button>

          <button className={classes.buttons} onClick={handleCarSelect}>

            Submit

          </button>

        </form>

      ) : (

        <DisplayAllProducts />

      )}

    </div>

  );

};


const useStyles = makeStyles(theme => ({

  carsNumberContainer: {

    display: "flex",

    justifyContent: "center",

    alignItems: "center",

    marginBottom: "20px",

  },

  numberElement: {

    cursor: "pointer",

    padding: "8px 20px",

    border: "1px solid " + theme.colors.blue,

    borderRadius: "11px",

    margin: "0 20px",

    color: theme.colors.blue,

    fontWeight: 600,

    "&:hover": {

      backgroundColor: theme.colors.blue,

      color: "white",

    },

  },

  blue: {

    backgroundColor: theme.colors.blue,

    color: "white",

  },

  buttons: {

    border: "1px solid " + theme.colors.blue,

    padding: "7px 23px",

    borderRadius: "24px",

    fontWeight: 700,

    margin: "12px",

    color: theme.colors.blue,

    "&:hover": {

      backgroundColor: theme.colors.blue,

      color: "white",

    },

  },

  selectForm: {

    width: "50%",

    margin: "0 auto",

  },

  selectElement: {

    margin: "0 auto",

    border: "1px solid " + theme.colors.blue,

    display: "block",

    width: "40%",

    padding: "6px 14px",

    borderRadius: "11px",

    marginBottom: "8px",

    fontSize: "1.5rem",

    color: theme.colors.blue,

  },

  noVehicleLink: {

    color:theme.colors.orange,

    textDecoration:"underline",

    cursor:"pointer",

    fontSize:20,

    fontWeight:600,

    display:"block",

    marginTop: 15,

    marginBottom: 35,


    [theme.device.mobile()]: {

      fontSize: 15,

    },

  },

}));


export default CarsFit;