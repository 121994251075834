/* istanbul ignore file */

interface LoadedData {
  loading: boolean;
  error: Error | string | null;
  loaded: boolean;
  providerSignin?: boolean;
  totalPrice?: number;
}

interface Resource<TEntity> extends LoadedData {
  data: TEntity[];
}

const getDefaultResourceState = <TEntity>(): Resource<TEntity> => ({
  data: [],
  error: null,
  loaded: false,
  loading: true,
});

export { getDefaultResourceState };

export type { Resource, LoadedData };
