import React, { useState, useEffect, useCallback } from "react";
import { useTheme } from "@material-ui/styles";
import { push } from "connected-react-router";
import clsx from "clsx";

import useStyles from "./favorites-list-styles";

import Button from "../../common/button";

import { useAppDispatch, useAppSelector } from "../../../store/store";
import { CartItem, Favorites } from "../../../services/users/types";
import displayCurrencySign from "../../../utils/currencies-signs";
import { Product } from "../../../services/products/types";
import usersStore from "../../../store/users-store";
import { getPhrase } from "../../../utils/language";
import { config } from "../../../config";
import {
  checkProductAvailability,
  countProductPrice,
  detectUserStatus,
} from "../../../utils/helper";

import { ReactComponent as BinIcon } from "../../../static/icons/bin.svg";
import ProductItemGrid from "../../products-view/product-item-grid";
import languagesStore from "../../../store/languages-store";

type SelectedProduct = {
  cartProduct: CartItem | null;
  product: Product;
};

const FavoritesList = (): JSX.Element => {
  const [addingCartItem, setAddingCartItem] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<SelectedProduct | null>(null);

  const favoriteProducts = useAppSelector(usersStore.selectors.favoriteProductsSelector());
  const { lang, user } = useAppSelector(state => ({
    lang: state.languages.language,
    user: state.user.data,
  }));

  const dispatch = useAppDispatch();

  const classes = useStyles();

  const theme = useTheme();

  const favoriteCatalogItems = user?.favoriteCatalogItems! || [];

  const userId = user?.id?.toString()!;

  const removeFromFavorites = useCallback(
    (p: Favorites) => () => {
      const updatedFavoritesList = [...favoriteCatalogItems].filter(
        product => product.productId !== p.productId
      );

      dispatch(
        usersStore.actions.toggleFavoriteCatalogItem({
          userId,
          favoriteCatalogItems: updatedFavoritesList,
        })
      );
    },
    [dispatch, userId, favoriteCatalogItems]
  );
  const addToCart = (product: Product) => {
    let {price} = countProductPrice(user?.approvedAgent!, product!, Number(user?.discount!));
    setAddingCartItem(true);
    setSelectedProduct({
      cartProduct: {
        productId: product.id,
        productName: product.name,
        productCount: 1,
        price,
        discount: product.discount,
        discountDate: product.discountDate,
        retailPrice: product.retailPrice,
        salePrice: product.salePrice,
      },
      product,
    });
  };

  const addAllToCart = () => {
    const cartItems: CartItem[] = [];

    favoriteProducts.forEach(favoriteProduct => {
      if (!checkProductAvailability(favoriteProduct.quantity)) return;

      if (detectUserStatus(user!, favoriteProduct!)) {
        cartItems.push({
          productId: favoriteProduct.id,
          productName: favoriteProduct.name,
          productCount: 1,
          price: favoriteProduct.retailPrice,
          discount: favoriteProduct.discount,
          discountDate: favoriteProduct.discountDate,
          retailPrice: favoriteProduct.retailPrice,
          salePrice: favoriteProduct.salePrice,
        });
      }
    });

    dispatch(usersStore.actions.updateUserCart({ userId: user?.id!, cartItems }));
  };

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const goToProduct = useCallback(
    (catalogNumber: string) => () => {
      dispatch(push(`${config.routes.product}/${catalogNumber}`, true));
    },
    [dispatch]
  );

  const displayFavorites = () =>
    favoriteProducts
      ?.filter(fP => fP.isPublished)
      ?.map((fP: Product) => (
        <ProductItemGrid
          product={fP!}
          user={user!}
          favorites
          addToCart={addToCart}
          removeFromFavorites={removeFromFavorites}
        />
      ));

  const availableProduct = checkProductAvailability(1);

  useEffect(() => {
    if (addingCartItem) {
      if (!availableProduct) return;

      if (detectUserStatus(user!, selectedProduct?.product!)) {
        const count =
          1 - selectedProduct?.product?.quantity! > 0 ? selectedProduct?.product?.quantity : 1;
        const {price} = countProductPrice(
          user?.approvedAgent!,
          selectedProduct?.product!,
          Number(user?.discount!)
        );
        const itemToAdd = {
          productId: selectedProduct?.product.id!,
          productName: selectedProduct?.product.name!,
          productCount: count!,
          price: price,
          discount: selectedProduct?.product.discount,
          discountDate: selectedProduct?.product.discountDate,
          retailPrice: selectedProduct?.product.retailPrice,
          salePrice: selectedProduct?.product.salePrice,
        };

        const cartItems: CartItem[] = user?.cartItems!?.map(cI => cI);
        const updatedCartItems: CartItem[] = [];

        if (!cartItems.length) {
          updatedCartItems.push(itemToAdd);
          setAddingCartItem(false);
        } else if (cartItems.length) {
          cartItems.forEach(cartItem => {
            if (cartItem.productId !== itemToAdd.productId) {
              updatedCartItems.push(cartItem);
            }
          });

          updatedCartItems.push(itemToAdd);

          setAddingCartItem(false);
        }

        dispatch(
          usersStore.actions.updateUserCart({
            userId: user?.id!,
            cartItems: updatedCartItems,
          })
        );
      }
    }
  }, [dispatch, addingCartItem, user, selectedProduct, availableProduct]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <div>
          <span className={classes.favListTitle}>
            {activePhrases && activePhrases["favourite_list"]}
          </span>
        </div>

        <div className={classes.addAll}>
          {favoriteProducts?.length > 0 ? (
            <Button
              text={activePhrases && activePhrases["add_all_to_cart"]}
              textColor={theme.colors.blue}
              outline={theme.colors.blue}
              width={200}
              height={50}
              textSize={24}
              borderWidth={3}
              borderRadius={30}
              action={addAllToCart}
            />
          ) : (
            <span>{activePhrases && activePhrases["no_items_added_yet"]}</span>
          )}
        </div>
      </div>

      <div className={classes.itemsContainer}>{displayFavorites()}</div>
    </div>
  );
};

export default FavoritesList;
