import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";

import PersonalDetailsForm from "./personal-details-form";
import ChangePasswordForm from "./change-password-form";
import { useAppSelector } from "../../../store/store";
import { getPhrase } from "../../../utils/language";

import { ReactComponent as CloseIcon } from "../../../static/icons/close.svg";
import { ReactComponent as EditIcon } from "../../../static/icons/edit.svg";
import _ from "lodash";
import languagesStore from "../../../store/languages-store";

const MyAccountOverview = (): JSX.Element => {
  const [showPersonalDetailsForm, setShowPersonalDetailsForm] = useState<boolean>(false);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState<boolean>(false);
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const { lang, user } = useAppSelector(state => ({
    lang: state.languages.language,
    user: state.user.data,
  }));

  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.tabTitle}>
        <span
          className={classes.personalDetailsIcon}
          onClick={() => setShowPersonalDetailsForm(!showPersonalDetailsForm)}
        >
          {showPersonalDetailsForm ? <CloseIcon /> : <EditIcon />}
        </span>{" "}
        <h1>{activePhrases && activePhrases["personal_details"]}</h1>
      </div>

      {!_.isEqual(user, {}) && showPersonalDetailsForm ? (
        <PersonalDetailsForm hideForm={() => setShowPersonalDetailsForm(false)} />
      ) : (
        <>
          <div className={classes.infoRow}>
            <span>:{activePhrases && activePhrases["username"]}</span>
            <span>
              {user?.firstName} {user?.lastName}
            </span>
          </div>

          <div className={classes.infoRow}>
            <span>:{activePhrases && activePhrases["email"]}</span>
            <span>{user?.email}</span>
          </div>

          <div className={classes.infoRow}>
            <span>:{activePhrases && activePhrases["phone_number"]}</span>
            <span>{user?.phoneNumber}</span>
          </div>

          <div className={classes.infoRow}>
            <span>:{activePhrases && activePhrases["second_phone_number"]}</span>
            <span>{user?.additionalPhoneNumber}</span>
          </div>

          <div className={classes.infoRow}>
            <span>:{activePhrases && activePhrases["address"]}</span>
            <span>{user?.address}</span>
          </div>
        </>
      )}

      {user?.provider === "local" && (
        <>
          {!showChangePasswordForm && (
            <div className={classes.pwdChangeBtnWrapper}>
              <button
                type="button"
                className={classes.passwordChangeBtn}
                onClick={() => setShowChangePasswordForm(true)}
              >
                <span>{activePhrases && activePhrases["change_password"]}</span>
              </button>
            </div>
          )}

          {showChangePasswordForm && <ChangePasswordForm />}
        </>
      )}
    </div>
  );
};

export default MyAccountOverview;

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      padding: "0 10px 20px 10px",
    },

    tabTitle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      color: theme.colors.blue,
      margin: 0,
      fontSize: 40,
      fontWeight: "bold",
      textAlign: "end",

      "&>span": {
        "&:first-child": {
          marginRight: 10,
        },

        "&>svg": {
          width: 34,

          "@media (min-width: 800px)": {
            width: 40,
          },
        },
      },
      [theme.device.mobile()]: {
        fontSize: 30,
      },
      "@media (min-width: 800px)": {
        fontSize: 40,
      },

      "&>h1": {
        fontSize: 42,

        [theme.device.mobile()]: {
          fontSize: 26,
        },
      },
    },

    personalDetailsIcon: {
      cursor: "pointer",
    },

    infoRow: {
      display: "flex",
      flexDirection: "column",
      marginTop: 20,
      textAlign: "end",
      wordBreak: "break-word",

      "&>span": {
        fontSize: 28,
        [theme.device.mobile()]: {
          fontSize: 24,
        },

        "&:last-child": {
          fontWeight: "bold",
        },
      },
    },

    pwdChangeBtnWrapper: {
      display: "flex",
      justifyContent: "flex-end",

      [theme.device.mobile()]: {
        justifyContent: "center",
      },
    },
    passwordChangeBtn: {
      display: "grid",
      placeItems: "center",

      fontSize: 28,
      marginTop: 40,
      minWidth: 80,
      maxHeight: 80,
      maxWidth: 360,
      outline: "none",
      color: "#fff",
      cursor: "pointer",
      borderRadius: 30,
      padding: "6px 10px",
      textAlign: "center",
      backgroundColor: theme.colors.blue,

      [theme.device.desktop()]: {
        fontSize: 24,
      },
      "@media (min-width: 700px)": {
        minWidth: 100,
        fontSize: 30,
      },
      "@media (min-width: 800px)": {
        textAlign: "end",
      },
      "@media (min-width: 1200px)": {
        textAlign: "center",
      },
    },
  }),
  { name: "my-account-overview" }
);
