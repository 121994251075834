import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import Order from "./order";
import ordersStore from "../../../store/orders-store";
import { getPaymentResponse } from "../../../utils/helper";
import { TOrderStatus, TPaymentStatus } from "../../../services/orders/types";
import usersStore from "../../../store/users-store";
import languagesStore from "../../../store/languages-store";
import authFormStore from "../../../store/auth-form-store";


const MyAccountOrderHistory = (): JSX.Element => {
  const { paymentResponse, orders, user, successPopIsVisible } = useAppSelector(state => ({
    paymentResponse: state.router.location.search,
    orders: state.orders.ordersData.data,
    user: state.user.data,
    successPopIsVisible: state.authForm.successPopIsVisible,
  }));
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const dispatch = useAppDispatch();

  const classes = useStyles();

  const displayOrders = () => {
    if (orders && orders.length > 0) {
      return orders
        .slice()
        .sort((a, b) => b?.id! - a?.id!)
        .map(o => <Order key={o.id} order={o} />);
    }

    return (
      <div className={classes.noOrders}>{activePhrases && activePhrases["no_orders_yet"]}</div>
    );
  };

  useEffect(() => {
    const currentOrderId = Number(localStorage.getItem("currentOrderId"));

    if (!!currentOrderId) {
      const PAID_PAYMENT_STATUS: TPaymentStatus = "Paid";
      const PROCESSING_ORDER_STATUS: TOrderStatus = "Processing";

      const DECLINED_PAYMENT_STATUS: TPaymentStatus = "Declined";
      const CANCELED_ORDER_STATUS: TOrderStatus = "Canceled";

      const successfulResponse = getPaymentResponse(paymentResponse);

      dispatch(
        ordersStore.actions.updateOrder({
          id: currentOrderId,
          paymentStatus: successfulResponse ? PAID_PAYMENT_STATUS : DECLINED_PAYMENT_STATUS,
          orderStatus: successfulResponse ? PROCESSING_ORDER_STATUS : CANCELED_ORDER_STATUS,
        })
      );

      localStorage.removeItem("currentOrderId");
    }
  }, [dispatch, paymentResponse]);

  useEffect(() => {
    if (user && user.id && user.cartItems && user.cartItems.length !== 0) {
      if (getPaymentResponse(paymentResponse) !== null) {
        dispatch(usersStore.actions.updateUserCart({ userId: user.id, cartItems: [] }));
        window.location.reload(); //requires reloading of page to save current order status in UI
      }
    }
  }, [dispatch, user, paymentResponse]);

  const closePopUp = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    dispatch(authFormStore.actions.toggleVisibility("successPopIsVisible", false));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.tabTitle}>{activePhrases && activePhrases["order_history"]}</div>
      {successPopIsVisible && ( 
        <div className={classes.successPopup}>
          <button className={classes.policyCloseBtn} onClick={e => closePopUp(e)}>
            X
          </button>
          <p className={classes.successMessage}>
            {activePhrases && activePhrases["your_order_was_submitted"]}
          </p>
        </div>
      )}
      {displayOrders()}
    </div>
  );
};

export default MyAccountOrderHistory;

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      padding: "15px 10px 0 10px",

      [theme.device.desktop()]: {
        //textAlign: "end",
        padding: "0px 10px",
      },

      "@media (min-width: 1000px)": {
        paddingLeft: 30,
      },
    },

    tabTitle: {
      margin: 0,
      fontSize: 40,
      fontWeight: "bold",
      //textAlign: "end",
      color: theme.colors.blue,
      [theme.device.mobile()]: {
        fontSize: 30,
      },
    },
    noOrders: {
      fontWeight: 700,
      fontSize: 22,
      color: theme.colors.grayText,
      //textAlign: "end",
    },
    successPopup: {
      color: "white",
      width: "30%",
      position: "absolute",
      top: "9%",
      right: "30%",
      border: "1px solid #114649",
      backgroundColor: theme.colors.blue,
      // padding: "2% 3%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      borderRadius: "16px",
      overflowX: "hidden",
      // overflowY: "scroll",
      zIndex: 2,
      height: "150px",
      "&::-webkit-scrollbar": {
        width: 7,
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#114649",
        outline: `1px solid slategrey`,
      },
      [theme.device.mobile()]: {
        width: "80%",
        left: "10%",
      },
    },
    successMessage: {
      color: theme.colors.white,
      fontSize: 26,
      fontWeight: 600,
      width: "100%",
      padding: "15px",
      textAlign: "center",
      boxSizing: "border-box",
      margin: 0,
      [theme.device.mobile()]: {
        border: "none",
      },
    },
    policyCloseBtn: {
      width: "12px",
      height: "12px",
      position: "absolute",
      top: 12,
      right: 12,
      // padding: "4px",
      // border: "1px solid #114649",
      borderRadius: "18px",
    },
  }),
  { name: "my-account-order-history" }
);
