import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import message from "../../static/images/ooups.png";
import statusCode from "../../static/images/statusCode.png";
import block1 from "../../static/images/block1.png";
import block2 from "../../static/images/block2.png";
import block3 from "../../static/images/block3.png";
import block4 from "../../static/images/block4.png";
import { getPhrase } from "../../utils/language";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { isMobile } from "react-device-detect";
import homeButton from "../../static/images/homeButton.png";
import { push } from "connected-react-router";
import PuffLoader from "react-spinners/ClipLoader";
import { theme } from "../../theme";
import { config } from "../../config";
import languagesStore from "../../store/languages-store";
// import { relative } from "node:path";
import Button from "../common/button";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 70,
  },

  statusWrapper: {
    marginBottom: 20,

    [theme.device.mobile()]: {
      width: 250,
    },
  },

  errorMessage: {
    fontSize: 28,

    [theme.device.mobile()]: {
      fontSize: 18,
      textAlign: "center",
    },
  },

  buttonWrapper: {
    width: 180,
    height: 70,
    position: "relative",
    bottom: 100,
    cursor: "pointer",

    [theme.device.mobile()]: {
      bottom: 50,
      width: 160,
    },
  },

  ooupsWrapper: {
    textAlign: "center",
    [theme.device.mobile()]: {
      width: 150,
    },
  },

  ooupsMessage: {
    color: theme.colors.orange,
    fontSize: 100,
    fontWeight: 700,
    textAlign: "center",
    [theme.device.mobile()]: {
      fontSize: 40,
    },
  },

  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },

  imageError: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    filter:
      "invert(50%) sepia(20%) saturate(1321%) hue-rotate(173deg) brightness(89%) contrast(96%)",
  },

  block3Wrapper: {
    position: "absolute",
    left: -125,
    top: 180,

    [theme.device.mobile()]: {
      width: 120,
      top: -400,
      left: -190,
      position: "relative",
      zIndex: 500,
    },
  },

  block2Wrapper: {
    top: 220,
    right: -70,
    position: "absolute",
    zIndex: -1,

    [theme.device.mobile()]: {
      width: 120,
    },
  },

  block1Wrapper: {
    position: "relative",
    right: 400,
    bottom: 40,

    [theme.device.mobile()]: {
      width: 50,
      right: 140,
      bottom: -30,
      position: "relative",
      Zindex: 1000,
    },
  },

  block4Wrapper: {
    left: 400,
    bottom: 270,
    position: "relative",

    [theme.device.mobile()]: {
      width: 60,
      left: 180,
      bottom: 240,
    },
  },

  buttonError: {
    paddingBottom: 3,
    minWidth: 160,
  },
}));

type TProps = {
  isLoading?: boolean;
};

const ErrorPage = ({ isLoading }: TProps) => {
  const classes = useStyles();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const onButtonClick = () => {
    dispatch(push(config.routes.homePage, true));
  };

  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
      const timer = setTimeout(() => setShowSpinner(false), 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  return showSpinner ? (
    <div className={classes.root}>
      <PuffLoader color={theme.colors.blue} size={50} />
    </div>
  ) : (
    <div className={classes.root}>
      <div className={classes.statusWrapper}>
        <img src={statusCode} className={classes.imageError} alt="404" />
      </div>
      <div className={classes.ooupsWrapper}>
        <span className={classes.ooupsMessage}>{activePhrases && activePhrases["ooups"]}</span>
      </div>
      <div className={classes.errorMessage}>{activePhrases && activePhrases["notFound"]}</div>
      <div className={classes.block1Wrapper}>
        <img src={block1} className={classes.image} alt="ooups" />
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          text={activePhrases && activePhrases["go_home"]}
          outline={theme.colors.blue}
          fill={theme.colors.blue}
          textColor={theme.colors.text}
          textSize={24}
          borderWidth={2}
          borderRadius={20}
          width="100%"
          height={30}
          action={onButtonClick}
          className={classes.buttonError}
        />
      </div>
      <div className={classes.block2Wrapper}>
        <img src={block2} className={classes.image} alt="ooups" />
      </div>
      <div className={classes.block3Wrapper}>
        <img src={block3} className={classes.image} alt="ooups" />
      </div>
      <div className={classes.block4Wrapper}>
        <img src={block4} className={classes.image} alt="ooups" />
      </div>
    </div>
  );
};

export default ErrorPage;
