import { createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { TThunk } from "./store";
import { SeoSettings } from "../services/seo-settings/types";
import seoSettings from "../services/seo-settings";

type State = SeoSettings;

const initialState: State[] = [
  {
    id: 0,
    enteredURL: "",
    forwardToURL: "",
    timesClicked: 0,
  },
];

console.log(initialState);

const fetchSeoConfig: TThunk<SeoSettings[]> = createAsyncThunk(
  "seo-settings/fetch",
  async () => await seoSettings.getSeoSettings()
);

const reducer = createReducer(initialState, {
  [fetchSeoConfig.fulfilled.type]: (state, action: PayloadAction<SeoSettings[]>) => {
    state = action.payload;
    return state;
  },
});

const actions = {
  fetchSeoConfig,
};

const SeoSettingsStore = {
  reducer,
  actions,
};

export default SeoSettingsStore;

export type { State };
