import { createAsyncThunk, createReducer, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { TContentPage } from "../services/content-pages/types";
import { TThunk } from "./store";
import contentPageService from "../services/content-pages";
import { getDefaultResourceState } from "./resource-state";

type State = {
  contentPagesData: Resource<TContentPage>;
  error: string | null,
  loaded: boolean,
  loading: boolean,
};

interface LoadedData {
  loading: boolean;
  error: Error | string | null;
  loaded: boolean;
  providerSignin?: boolean;
}

interface Resource<TContentPage> extends LoadedData {
  data: TContentPage[];
}

const initialState: State = {
  contentPagesData: getDefaultResourceState(),
  error: null,
  loaded: false,
  loading: true,
};

const fetchContentPages: TThunk<TContentPage[]> = createAsyncThunk(
  "content-pages/fetch",
  async () => await contentPageService.getContentPages(),
  // {
  //   // condition: (_, { getState }) => {
  //   //   const state = getState();
  //   //   return !state.;
  //   // },
  // }
);


const reducer = createReducer(initialState, {
  [fetchContentPages.pending.type]: state => {
    state.contentPagesData.loading = true;
  },
  [fetchContentPages.fulfilled.type]: (state, action: PayloadAction<TContentPage[]>) => {
    state.contentPagesData.data = [...state.contentPagesData.data, ...action.payload];
    state.contentPagesData.loading = false;
    state.contentPagesData.loaded = true;
  },
  [fetchContentPages.rejected.type]: (
    state,
    action: PayloadAction<null, string, unknown, SerializedError>
  ) => {
    state.contentPagesData.error = action.error.message || "General Error";
    state.contentPagesData.loading = false;
  },

});

const actions = {
  fetchContentPages
};

const contentPagesStore = {
  actions,
  reducer,
  initialState,
};

export default contentPagesStore;
export type { State };
