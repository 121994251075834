import { makeStyles, useTheme } from "@material-ui/styles";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import React, { useState, useEffect } from "react";
import { push } from "connected-react-router";
import clsx from "clsx";

import Button from "../../common/button";

import SingleProductInfo from "./single-product-info/single-product-info";
import SingleProductView from "./single-product-view";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { CartItem, Favorites } from "../../../services/users/types";
import { Product } from "../../../services/products/types";
import usersStore from "../../../store/users-store";
import cartStore from "../../../store/cart-store";
import { config } from "../../../config";
import {
  checkProductAvailability,
  countProductDiscountPrice,
  countProductPrice,
  detectUserStatus,
  getCategoryParams,
  getColors,
  getProductUrl,
  removeHTML,
} from "../../../utils/helper";
import SingleProductPrice from "./single-product-info/single-product-price";
import languagesStore from "../../../store/languages-store";
import authFormStore from "../../../store/auth-form-store";
import Rating from "../../common/rating";
import SingleProductShareButton from "./single-product-info/single-product-share-button";
import PlusIcon from "../../common/icons/PlusIcon";
import MinusIcon from "../../common/icons/MinusIcon";

type TProps = {
  product: Product;
  isImporterDeals: boolean;
  open: boolean;
  toggleFavorite: (p: Favorites) => void;
  setOpen: (isOpen: boolean) => void;
  onReviewButtonClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type ProductToAdd = {
  product: Product;
  count: number;
};

const SingleProduct = ({
  product,
  isImporterDeals,
  open,
  toggleFavorite,
  setOpen,
  onReviewButtonClick,
}: TProps): JSX.Element => {
  const [addingCartItem, setAddingCartItem] = useState<boolean>(false);
  const [warningVisible, setWarningVisible] = useState(false);
  const [productCount, setProductCount] = useState<number>(1);

  const { isInCart, products, params, user, cart } = useAppSelector(state => ({
    isInCart: state.user.data?.cartItems?.find(cI => cI.productId === product?.id)! ? true : false,
    products: state.products.productsData.data,
    params: state.router.location.search,
    user: state.user.data,
    cart: state.cart.items,
  }));

  const dispatch = useAppDispatch();

  const classes = useStyles();

  const theme = useTheme();

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const activeCategory = getCategoryParams(params, "category");
  const activeSubCategory = getCategoryParams(params, "subcategory");
  const activeSubSubCategory = getCategoryParams(params, "sub_subcategory");

  const checkDiscountStatus = (
    startDiscountDate: any,
    endDiscountDate: any,
    importerDeal: boolean
  ) => {
    let [currYear, currMonth, currDay] = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, "-")
      .split("-");
    const currDate = currYear + "-" + currMonth + "-" + currDay;
    if (startDiscountDate <= currDate && currDate <= endDiscountDate && importerDeal) {
      return true;
    } else {
      return false;
    }
  };

  const awards = product?.awards.map(award => (
    <img
      key={award.id}
      className={classes.award}
      src={award.image?.url!}
      alt={`Award, id #${award.id}`}
    />
  ));

  const cartItems: CartItem[] = user?.cartItems!?.map(cI => cI);

  const onColorItemClick = (color: Product) => () => {
    const isSignature = product.isSignature;
    let signature = "";
    if (isSignature) {
      signature = "signature";
    }

    if (color.catalogNumber === product?.catalogNumber) return;

    const url = `${isImporterDeals ? config.routes.importerDealsProduct : config.routes.product}/${
      color.catalogNumber
    }`;
    dispatch(
      push(
        getProductUrl(url, activeCategory, activeSubCategory, activeSubSubCategory, signature),
        true
      )
    );
  };

  const colors = getColors(products, product);
  const isMobile = window.innerWidth < 600;

  const ratingFromUsers = product?.reviews.reduce((a, b) => (a += b.rating), 0) / product?.reviews.length;
  const round = (number: number) => (Math.round(number / 0.25) * 0.25).toFixed(2);
  const displayedRating = product.reviews.length < 5 ? product.rating.toFixed(2) : round(ratingFromUsers);
  const reviewsNumString = `(${activePhrases && activePhrases["reviews"]} ${product?.reviews ?.length!})`;

  colors.sort((a, b) => (a.id > b.id && 1) || -1);
  const colorItems = colors
    ?.sort((a, b) => b.quantity - a.quantity)
    .map(cP => {
      const productUnavailable = cP?.quantity <= 0;

      return (
        <>
          <Helmet>
            <title>{product.title + " " + product.name}</title>
            <meta name="description" content={removeHTML(product.description)} />
            <meta name="keywords" content={product?.keywords} />
          </Helmet>
          <div
            key={cP?.id}
            className={clsx(
              classes.availableColorsItem,
              cP.id === product?.id && classes.activeColor
            )}
            onClick={onColorItemClick(cP!)}
          >
            <img
              className={classes.availableColorsImg}
              src={cP?.mainPicture?.url!}
              alt={`product color ${cP?.color}`}
            />
            {productUnavailable && (
              <div className={classes.productOutOfStock}>
                {activePhrases && activePhrases["out_of_stock"]}
              </div>
            )}
          </div>
        </>
      );
    });

  const availableProduct = checkProductAvailability(product?.quantity);
  const { cartIsVisible } = useAppSelector(({ authForm: { cartIsVisible } }) => ({
    cartIsVisible,
  }));

  const onAddCartClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (cart.length || user) {
      dispatch(authFormStore.actions.toggleVisibility("cartIsVisible", true));
    }

    if (!user) {
      const count = productCount - product?.quantity > 0 ? product?.quantity : productCount;
      const productToAdd = {
        product,
        count,
      };

      const currentProducts = localStorage.getItem("cartProducts");
      let updatedProducts = [];

      if (currentProducts) {
        const parsedProducts: ProductToAdd[] = JSON.parse(currentProducts);

        const productIndex = parsedProducts.findIndex(
          obj => obj.product.id === productToAdd.product.id
        );

        if (productIndex !== -1) {
          updatedProducts = parsedProducts.map((p, i) => {
            if (i === productIndex) {
              p.count + productToAdd.count <= product.quantity
                ? (p.count += productToAdd.count)
                : (p.count = p.count);
            }

            return p;
          });
        } else {
          updatedProducts = [...parsedProducts, productToAdd];
        }
      } else {
        updatedProducts = [productToAdd];
      }
      dispatch(cartStore.actions.updateCart(updatedProducts));

      if (isMobile && !localStorage.getItem("cartProducts")?.length) {
        localStorage.setItem("cartProducts", JSON.stringify(updatedProducts));
        dispatch(push(config.routes.myAccount, true));
        return;
      }
      localStorage.setItem("cartProducts", JSON.stringify(updatedProducts));

      if (!cart.length) {
        dispatch(authFormStore.actions.toggleVisibility("authFormIsVisible", true));
      }
    } else {
      setAddingCartItem(true);
    }
  };

  const onSelectClick = (selectType: "add" | "reduce") => () => {
    const maxQuantityIsReached = productCount === product?.quantity;

    if (selectType === "add") {
      maxQuantityIsReached && setWarningVisible(true);
      setProductCount(maxQuantityIsReached ? productCount! : productCount! + 1);
    } else {
      setProductCount(productCount! > 1 ? productCount! - 1 : productCount!);
      setWarningVisible(false);
    }
  };

  useEffect(() => {
    if (!availableProduct || !addingCartItem) return;

    if (detectUserStatus(user!, product!)) {
      const count = productCount - product?.quantity > 0 ? product?.quantity : productCount;

      let productPrice = 0;
      if (user?.approvedAgent) {
        const { price } = countProductPrice(
          user?.approvedAgent!,
          product!,
          Number(user?.discount!)
        );
        productPrice = price * count;
      } else {
        let { price } = countProductDiscountPrice(product);
        productPrice = price;
      }

      const itemToAdd = {
        productId: product.id,
        productName: product.name,
        productCount: count,
        price: productPrice,
        discount: product.discount,
        discountDate: product.discountDate,
        retailPrice: product.retailPrice,
        salePrice: product.salePrice,
      };

      const updatedCartItems: CartItem[] = [];

      if (!cartItems?.length) {
        updatedCartItems.push(itemToAdd);
        setAddingCartItem(false);
      } else if (cartItems?.length) {
        cartItems.forEach(cartItem => {
          if (cartItem.productId !== itemToAdd.productId) {
            updatedCartItems.push(cartItem);
          }
        });

        updatedCartItems.push(itemToAdd);

        setAddingCartItem(false);
      }

      const isSameAmount = user?.cartItems?.find(item => {
        return item.productCount === productCount;
      })
        ? true
        : false;

      if (!isInCart || !isSameAmount) {
        dispatch(
          usersStore.actions.updateUserCart({
            userId: user?.id!,
            cartItems: updatedCartItems,
          })
        );
      }
    }
  }, [
    dispatch,
    product,
    user,
    productCount,
    addingCartItem,
    availableProduct,
    cartItems,
    isInCart,
  ]);

  const weightString = "- " + `${product?.weight} ${activePhrases && activePhrases["kg"]}`;
  const widthString = `width ${activePhrases && activePhrases["centimeter"]}`;
  const lengthString = `length ${activePhrases && activePhrases["centimeter"]}`;
  const heightString = `height ${activePhrases && activePhrases["centimeter"]}`;
  const ageString = `- ${activePhrases && activePhrases["from"]} from ${
    activePhrases && activePhrases["years"]
  } ${activePhrases && activePhrases["to"]} to ${activePhrases && activePhrases["years"]}`;
  const sizeString = `- ${
    product?.productWidth && product.productWidth !== null ? `${widthString}` : ""
  } 
                      ${
                        product?.productLength
                          ? product?.productWidth
                            ? ` x ${lengthString}`
                            : lengthString
                          : ""
                      }
                      ${
                        product?.productHeight
                          ? product?.productLength
                            ? ` x ${heightString}`
                            : heightString
                          : ""
                      }`;

  const signatureMode = useAppSelector(state => state.signature.signatureMode);

  // useEffect(() => {

  //   if (product.isSignature) {
  //     dispatch(signature.actions.toggleMode(true));
  //   }
  // }, [])

  const buildYoutubeUrl = (url: string): string => {
    if (!url) {
      return "";
    }
    const URL_TEMPLATE = "https://www.youtube.com/embed/";


    return URL_TEMPLATE + new URL(url).searchParams.get("v");
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.info)}>
        <SingleProductInfo
          product={product}
          toggleFavorite={toggleFavorite}
          open={open}
          setOpen={setOpen}
          onReviewButtonClick={onReviewButtonClick}
        />

        <SingleProductView product={product} className={{ root: classes.activeMobile }} />

        {availableProduct && detectUserStatus(user!, product!) && isMobile && (
          <div className={classes.price}>
            <SingleProductPrice user={user!} product={product!} />
            <div className={classes.buyNow}>
              <Button
                width="100%"
                height={40}
                textWeight={600}
                text={
                  activePhrases &&
                  activePhrases["buy_now"] +
                    ` ₪${
                      checkDiscountStatus(
                        product.startDiscountDate,
                        product.discountDate,
                        product.importerDeal
                      )
                        ? product.salePrice.toFixed(2)
                        : product.retailPrice.toFixed(2)
                    }`
                }
                fill={signatureMode ? theme.colors.brown : theme.colors.orange}
                textColor={theme.colors.text}
                action={event => onAddCartClick(event)}
              />
              <ShoppingCartOutlinedIcon className={classes.cartIcon} />
            </div>
          </div>
        )}

        <div className={classes.additionalInfo}>
          {availableProduct && detectUserStatus(user!, product!) && (
            <div className={classes.addToCart}>
              <div className={clsx(classes.addToCartEl, classes.activeDesktop)}>
                <Button
                  borderRadius={10}
                  width={140}
                  height={50}
                  textWeight={600}
                  textSize={19}
                  text={activePhrases && activePhrases["add_to_cart"]}
                  fill={signatureMode ? theme.colors.brown : theme.colors.orange}
                  textColor={theme.colors.text}
                  action={event => onAddCartClick(event)}
                  // className={clsx(
                  //   (!user || !user?.approvedAgent!) &&
                  //   !product?.importerDeal &&
                  //   classes.addToCartDisabled,
                  // )}
                />
              </div>

              <div className={classes.addToCartEl}>
                <div className={classes.availableColorsText}>
                  {activePhrases && activePhrases["quantity"]}
                </div>

                <div className={clsx(classes.counterWrap)}>
                  <div className={clsx(classes.content, classes.gridGap)}>
                    <div
                      className={clsx(
                        classes.select,
                        classes.selectBig,
                        product.isSignature ? classes.selectSignatureMode : "",
                        productCount <= 1 && classes.inactive
                      )}
                      onClick={onSelectClick("reduce")}
                    >
                      <div className={clsx(productCount <= 1 && classes.inactive)}>
                        <MinusIcon strokeColor={(productCount <= 1 ? theme.colors.darkText : theme.colors.darkBlue) ?? "black"} />
                        </div>
                    </div>
                    <div
                      className={clsx(
                        product.isSignature ? classes.countBlack : classes.count,
                        classes.countBig
                      )}
                    >
                      <div className={classes.countText}>{productCount}</div>
                    </div>
                    <div
                      className={clsx(
                        classes.select,
                        classes.selectBig,
                        product.isSignature ? classes.selectSignatureMode : ""
                      )}
                      onClick={onSelectClick("add")}
                    >
                      <div>
                        <PlusIcon strokeColor={theme.colors.darkBlue ?? 'black'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={clsx(classes.warning, warningVisible && classes.warningActive)}>
                {warningVisible
                  ? activePhrases &&
                    activePhrases["You_can_not_exceed_the_available_quantity_of_the_product"]
                  : ""}
              </div>
              <div className={clsx(classes.addToCartEl, classes.activeMobile)}>
                <Button
                  borderRadius={10}
                  width={140}
                  height={50}
                  textWeight={600}
                  textSize={19}
                  text={activePhrases && activePhrases["add_to_cart"]}
                  fill={signatureMode ? theme.colors.brown : theme.colors.orange}
                  textColor={theme.colors.text}
                  action={event => onAddCartClick(event)}
                  className={clsx(
                    (!user || !user?.approvedAgent!) &&
                      !product?.importerDeal &&
                      classes.addToCartDisabled
                  )}
                />
              </div>
            </div>
          )}
          {!availableProduct && (
            <div className={classes.outOfStock}>
              <div className={classes.outOfStockMessage}>
                {activePhrases && activePhrases["out_of_stock"]}
              </div>
              {product?.availabilityDate && (
                <div className={classes.outOfStockDate}>
                  {product?.availabilityDate} {activePhrases && activePhrases["date"]}
                </div>
              )}
            </div>
          )}

          <div className={clsx(classes.reviewShareBtns)}>
            <Button
              text={activePhrases && activePhrases["write_review"]}
              textSize={16}
              borderRadius={25}
              width={120}
              height={40}
              fill={(signatureMode ? theme.colors.brown : theme.colors.blue)}
              textColor={theme.colors.text}
              action={event => onReviewButtonClick(event)}
              className={clsx(classes.reviewButton)}
            />
            
            <SingleProductShareButton product={product} />
          </div>

          <div className={classes.rating}>
            <Rating rating={displayedRating} size={isMobile ? "medium" : "big"} isClickable={false} />
            <bdo className={classes.reviewCount}>{reviewsNumString}</bdo>
          </div>

          {colors?.length > 0 &&
            !(colors?.length === 1 && !!colors?.find(c => c.id === product?.id)) && (
              <div className={classes.availableColors}>
                <div className={product.isSignature ? classes.signatureTitle : classes.title}>
                  {activePhrases && activePhrases["available_colors"]}
                </div>

                <div className={classes.availableColorsContainer} dir="rtl">
                  {colorItems}
                </div>
              </div>
            )}

          <div className={classes.properties}>
            <div className={classes.propertiesContainer}>
              {!!product?.age && (
                <div className={signatureMode ? classes.propertyItemBlack : classes.propertyItem}>
                  <div className={classes.propertyText} dir="rtl">
                    {ageString
                      .replace("from", String(product?.age?.from))
                      .replace("to", String(product?.age?.to))}
                  </div>
                  &nbsp;{activePhrases && activePhrases["age"]}
                </div>
              )}

              {(!!product?.productHeight ||
                !!product?.productLength ||
                !!product?.productWidth) && (
                <div className={signatureMode ? classes.propertyItemBlack : classes.propertyItem}>
                  <bdo className={classes.propertyText} dir="rtl">
                    &nbsp;
                    {sizeString
                      .replace("width", String(product?.productWidth).split("").reverse().join(""))
                      .replace(
                        "height",
                        String(product?.productHeight).split("").reverse().join("")
                      )
                      .replace(
                        "length",
                        String(product?.productLength).split("").reverse().join("")
                      )}
                  </bdo>
                  <span>{activePhrases && activePhrases["size"]}</span>
                </div>
              )}

              {!!product?.weight && (
                <div className={signatureMode ? classes.propertyItemBlack : classes.propertyItem}>
                  <div className={classes.propertyText} dir="rtl">
                    {weightString}
                  </div>
                  <span>&nbsp;{activePhrases && activePhrases["weight"]}</span>
                </div>
              )}

              {!!product?.color && (
                <div className={signatureMode ? classes.propertyItemBlack : classes.propertyItem}>
                  <div className={classes.propertyText}>{product?.color} -</div>
                  <span>&nbsp;{activePhrases && activePhrases["color"]}</span>
                </div>
              )}
              {!!product?.deliveryTime && (
                <div className={signatureMode ? classes.propertyItemBlack : classes.propertyItem}>
                  <div className={classes.propertyText}>{activePhrases && activePhrases["up_to_business"].replace("?", '7')} -</div>
                  <span>&nbsp;{activePhrases && activePhrases["delivery_time"]}</span>
                </div>
              )}

              {!!product?.warranty && (
                <div
                  className={clsx(signatureMode ? classes.propertyItemBlack : classes.propertyItem)}
                >
                  <a
                    className={clsx(classes.warrantyLink)}
                    href={product?.warranty?.file?.url!}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {product?.warranty?.name}&nbsp;-&nbsp;
                  </a>
                  <span>{activePhrases && activePhrases["warranty"]}</span>
                </div>
              )}
              {!!product?.isofix && (
                <div className={signatureMode ? classes.propertyItemBlack : classes.propertyItem}>
                  <div className={classes.propertyText}>
                    {activePhrases && activePhrases[product.isofix]} -
                  </div>
                  <span>&nbsp;{activePhrases && activePhrases["isofix"]}</span>
                </div>
              )}
              {!!product?.productStandart && (
                <div className={signatureMode ? classes.propertyItemBlack : classes.propertyItem}>
                  <div className={classes.propertyText}>{product?.productStandart} -</div>
                  <span>&nbsp;{activePhrases && activePhrases["standart"]}</span>
                </div>
              )}
              {!!product?.foldTypes && (
                <div className={signatureMode ? classes.propertyItemBlack : classes.propertyItem}>
                  <div className={classes.propertyText}>
                    {activePhrases && activePhrases[product.foldTypes]} -
                  </div>
                  <span>&nbsp;{activePhrases && activePhrases["fold_types"]}</span>
                </div>
              )}

              {!!product?.barCode && (
                <div className={signatureMode ? classes.propertyItemBlack : classes.propertyItem}>
                  <div className={classes.propertyText}>{product.barCode} - </div>
                  <span>&nbsp;ברקוד</span>
                </div>
              )}
            </div>
          </div>

          <div className={classes.awards}>{awards}</div>
        </div>

        {!!product?.video && (
          <iframe
            className={classes.video}
            src={buildYoutubeUrl(product?.video)}
            title="product video"
          />
        )}
      </div>

      <SingleProductView product={product} className={{ root: classes.active }} />
    </div>
  );
};

export default SingleProduct;

const useStyles = makeStyles(
  theme => ({
    root: {
      width: "100%",
      padding: "0 50px 0 20%",
      paddingBottom: "80px",
      boxSizing: "border-box",
      display: "grid",
      gridTemplateColumns: "1.3fr 1.7fr",

      [theme.device.smallDesktop()]: {
        padding: "0 35px 0 10%",
        paddingBottom: "80px",
      },

      [theme.device.laptop()]: {
        padding: "0 35px 0 40px",
        paddingBottom: "0",
        gridTemplateColumns: "60% auto",
      },

      [theme.device.tablet()]: {
        gridTemplateColumns: "50% 50%",
      },

      //
      [theme.device.smallTablet()]: {
        padding: 50,
        gridTemplateColumns: "100%",
      },

      [theme.device.superSmall()]: {
        padding: 20,
        gridTemplateColumns: "100%",
      },
    },
    buyNow: {
      position: "fixed",
      left: 0,
      bottom: 0,
      zIndex: 10,
      width: "100%",
    },
    cartIcon: {
      position: "fixed",
      bottom: 9,
      left: 85,
      color: "white",
    },
    title: {
      userSelect: "none",
      cursor: "default",
      color: theme.colors.blue,
      fontWeight: 700,
      fontSize: 19,
      [theme.device.mobile()]: {
        fontSize: 17,
      },
    },
    rating: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      [theme.device.mobile()]: {
        marginTop: 10,
      }
    },
    reviewCount: {
      direction: "rtl",
      textTransform: "lowercase",
      userSelect: "none",
      marginTop: 2,
      fontWeight: 600,
      fontSize: 12,
      color: theme.colors.grayText,
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    signatureTitle: {
      userSelect: "none",
      cursor: "default",
      color: theme.colors.brown,
      fontWeight: 700,
      fontSize: 19,
      [theme.device.mobile()]: {
        fontSize: 17,
      },
    },
    info: {
      width: "100%",
      padding: "10px 0",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    infoSignature: {},
    availableColors: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      boxSizing: "border-box",
      padding: "10px 0",
      [theme.device.mobile()]: {
        paddingTop: 10,
        paddingBottom: 0,
      },
    },
    availableColorsContainer: {
      padding: "10px 0",
      maxWidth: "100%",
      display: "flex",
      //justifyContent: "flex-end",
      flexWrap: "wrap",
      gridColumnGap: 20,
      borderBottom: `1px solid ${theme.colors.border}`,
      [theme.device.mobile()]: {
        gridColumnGap: 10,
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        width: "100%",
      },
    },
    availableColorsItem: {
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxSizing: "border-box",
      width: 90,
      height: 90,
      padding: 5,
      [theme.device.mobile()]: {
        height: 70,
        width: "100%",
      },
    },
    availableColorsText: {
      userSelect: "none",
      marginTop: 2,
      color: theme.colors.grayText,
      fontWeight: 600,
      position: "absolute",
      bottom: 35,
    },
    availableColorsImg: {
      userSelect: "none",
      cursor: "pointer",
      objectFit: "contain",
      width: "100%",
      height: "100%",
    },
    activeColor: {
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.colors.black,
    },
    productOutOfStock: {
      cursor: "pointer",
      position: "absolute",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      textAlign: "center",
      borderRadius: 20,
      marginTop: 25,
      color: theme.colors.text,
      padding: 5,
      userSelect: "none",
    },
    properties: {
      width: "100%",
      boxSizing: "border-box",
      [theme.device.mobile()]: {
        padding: "0 0 15px 30px",
      },
    },
    propertiesContainer: {
      padding: "15px 0",
      width: "100%",
      borderBottom: `1px solid ${theme.colors.border}`,
      display: "flex",
      boxSizing: "border-box",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    propertyItem: {
      color: theme.colors.blue,
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
      fontSize: "16px",

      "&:nth-child(7) span": {
        marginBottom: 5,
      },
    },
    reviewButton: {
      minWidth: 120,
    },
    reviewShareBtns: {
      display: "flex",
      position: "relative",
      flexDirection: "row-reverse",
      gap: 8,
      marginBottom: 10,
      [theme.device.mobile()]: {
        marginTop: 20,
      }
    },
    propertyItemBlack: {
      color: theme.colors.brown,
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
    },
    propertyText: {
      color: theme.colors.grayText,
      fontWeight: 400,
    },
    warrantyLink: {
      color: theme.colors.grayText,
      fontWeight: 400,
      cursor: "pointer",
      textDecoration: "none",
      borderBottom: "2px solid transparent",
      "&:hover": {
        borderBottom: `2px solid ${theme.colors.grayText}`,
      },
    },
    awards: {
      //display: 'flex',
      textAlign: "end",
      //position: "relative",
      //left: "37%",
      // padding: "10px 0",
      // boxSizing: "border-box",
      // width: "100%",
      // display: "flex",
      // justifyContent: "space-between",
      // gridColumnGap: 40,
      [theme.device.mobile()]: {
        padding: "10px 0 10px 30px",
        left: "26%",
      },
    },
    award: {
      width: 90,
      height: 100,
      objectFit: "contain",
      [theme.device.mobile()]: {
        width: 70,
        height: 80,
      },
    },
    addToCart: {
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      alignItems: "center",

      [theme.device.desktop()]: {
        padding: "35px 0",
        flexWrap: "wrap",
        justifyContent: "space-between",
      },
      [theme.device.mobile()]: {
        flexDirection: "column",
        alignItems: "flex-end",
      },
    },
    price: {
      boxSizing: "border-box",
      padding: "0 15px",
      width: "100%",
    },
    additionalInfo: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      //alignItems: "center",
      [theme.device.mobile()]: {
        boxSizing: "border-box",
        padding: "20px 15px",
      },
    },
    addToCartEl: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      gridRowGap: 5,
      paddingLeft: 40,
    },
    addToCartDisabled: {
      backgroundColor: theme.colors.grayText,
      cursor: "default",
    },
    outOfStock: {
      borderBottom: `1px solid ${theme.colors.border}`,
      padding: "25px 0 15px 0",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    outOfStockMessage: {
      color: theme.colors.green,
      fontWeight: 700,
      fontSize: 21,
    },
    outOfStockDate: {
      color: theme.colors.grayText,
      fontSize: 15,
      fontWeight: 700,
    },
    video: {
      border: "none",
      width: "100%",
      height: 250,
      [theme.device.desktop()]: {
        display: "none",
      },
    },
    active: {
      display: "flex",
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    activeMobile: {
      display: "flex",
      [theme.device.desktop()]: {
        display: "none",
      },
    },

    warning: {
      cursor: "default",
      userSelect: "none",
      fontSize: 12,
      width: "100%",
      textAlign: "end",
      color: theme.colors.red,
      opacity: 0,
      transition: "0.2s",
      [theme.device.mobile()]: {
        marginBottom: 20,
      },
    },

    warningActive: {
      cursor: "text",
      userSelect: "text",
      opacity: 1,
      transition: "0.2s",
    },

    disabled: {
      opacity: 0,
      visibility: "hidden",
      cursor: "default",
    },

    counterWrap: {
      display: "flex",
      [theme.device.mobile()]: {
        gridRowStart: 1,
      },
    },
    content: {
      userSelect: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      position: "relative",
      gridColumnGap: 5,
    },
    gridGap: {
      width: 120,
    },
    select: {
      borderRadius: 4,
      cursor: "pointer",
      width: 15,
      height: 15,
      border: `1px solid ${theme.colors.blue}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.colors.blue,
      fontSize: 14,
      fontWeight: 700,
    },
    selectSignatureMode: {
      border: `1px solid #707070`,
      color: "#707070",
    },
    selectBig: {
      height: 25,
      width: 25,
      fontSize: 23,
    },
    inactive: {
      cursor: "default",
      borderColor: theme.colors.grayText,
      color: theme.colors.grayText,
    },
    count: {
      fontSize: 16,
      fontWeight: 600,
      color: theme.colors.blue,
      height: 20,
      width: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `2px solid ${theme.colors.blue}`,
      borderRadius: 5,
    },
    countBlack: {
      fontSize: 16,
      fontWeight: 600,
      color: theme.colors.black,
      height: 20,
      width: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `2px solid ${theme.colors.brown}`,
      borderRadius: 5,
    },
    countSignature: {
      fontSize: 16,
      fontWeight: 600,
      color: "#392F2C",
      height: 20,
      width: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `2px solid #392F2C`,
      borderRadius: 5,
    },
    countBig: {
      fontSize: 25,
      height: 35,
      width: 30,
      padding: "0 20px",
    },
    countText: {
      marginBottom: 3,
      textColor: theme.colors.blue,
    },
    activeDesktop: {
      [theme.device.mobile()]: {
        display: "none",
      },
    },
  }),
  { name: "single-product" }
);
