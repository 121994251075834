import React, { useRef, useState } from "react";
import clsx from "clsx";
import { ReactComponent as ShareIcon } from "../../../../static/icons/share.svg";
import { ReactComponent as CloseIcon } from "../../../../static/icons/close.svg";
import { ReactComponent as PinterestIcon } from "../../../../static/icons/pinterest.svg";
import { ReactComponent as LinkedinIcon } from "../../../../static/icons/linkedin.svg";
import { ReactComponent as TwitterIcon } from "../../../../static/icons/twitter.svg";
import { ReactComponent as FacebookIcon } from "../../../../static/icons/fb.svg";
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { useStyles } from "./single-product-styles";
import { useAppSelector } from "../../../../store/store";
import { buildClientUrl } from "../../../../utils/helper";
import { Product } from "../../../../services/products/types";
import { useTheme } from "@material-ui/styles";
import languagesStore from "../../../../store/languages-store";
import { getPhrase } from "../../../../utils/language";
import Button from "../../../common/button";

type TProps = {
  product?: Product
}

function SingleProductShareButton({ product }: TProps){
  const classes = useStyles();
  const theme = useTheme();

  const signatureMode = useAppSelector(state => state.signature.signatureMode);
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const lang = useAppSelector(({ languages }) => languages.language);
  const isMobile = theme.device.isMobile;

  const [sharePopup, setSharePopup] = useState<boolean>(false);
  const shareInputRef = useRef<HTMLInputElement>(null);

  const onShareBtnClick = () => setSharePopup(current => !current);
  const onCloseBtnClick = () => setSharePopup(false);

  const url = buildClientUrl(
    useAppSelector(state => {
      const { pathname, search } = state.router.location;

      return pathname + search;
    })
  );

  const onCopyClick = () => {
    shareInputRef.current!?.focus();
    shareInputRef.current!?.select();
    document.execCommand("copy");
  };

  return (
    <>
      <div className={clsx(classes.sharePopup, sharePopup && classes.popupActive)}>

        <CloseIcon className={classes.closeIcon} onClick={onCloseBtnClick} />
        <div className={signatureMode ? clsx(classes.sharePopupTitle, classes.sharePopupSignatureTitle) : classes.sharePopupTitle}>{activePhrases && activePhrases["share"]}</div>

        <div className={classes.sharePopupSocial}>
          <PinterestShareButton url={url} media={product?.mainPicture?.url!}>
            <PinterestIcon className={clsx(classes.shareIcon, classes.pinterestIcon)} />
          </PinterestShareButton>
          <LinkedinShareButton url={url}>
            <LinkedinIcon className={clsx(classes.shareIcon, classes.linkedInIcon)} />
          </LinkedinShareButton>
          <TwitterShareButton url={url} title={product?.name}>
            <TwitterIcon className={clsx(classes.shareIcon, classes.twitterIcon)} />
          </TwitterShareButton>
          <FacebookShareButton url={url}>
            <FacebookIcon className={clsx(classes.shareIcon, classes.facebookIcon)} />
          </FacebookShareButton>
          {isMobile && (
            <WhatsappShareButton url={url}>
              <WhatsappIcon className={clsx(classes.shareIcon)} />
            </WhatsappShareButton>
          )}
        </div>

        <div className={classes.copyLink}>
          <div className={signatureMode ? clsx(classes.sharePopupTitle, classes.sharePopupSignatureTitle, classes.copyLinkBtn) : clsx(classes.sharePopupTitle, classes.copyLinkBtn)} onClick={onCopyClick}>
            {activePhrases && activePhrases["copy_link"]}
          </div>
          <div className={classes.copyLinkText}>URL</div>
          <input
            className={classes.copyLinkInput}
            type="text"
            name="link"
            value={url}
            ref={shareInputRef}
            readOnly
          />
          <Button
            className={clsx(classes.activeMobile, classes.copyLinkBtn)}
            text={getPhrase("copy_link", lang)}
            textColor={theme.colors.text}
            width={120}
            height={40}
            fill={theme.colors.green}
            borderRadius={25}
            action={onCopyClick}
          />
        </div>

      </div>
    
      <Button
        icon={<ShareIcon className={classes.shareBtnIcon} />}
        text={activePhrases && activePhrases["share"]}
        textColor={theme.colors.text}
        className={classes.button}
        textSize={16}
        borderRadius={25}
        width={120}
        height={40}
        fill={(signatureMode ? theme.colors.brown : theme.colors.blue)}
        action={onShareBtnClick}
      />
    </>
  )
}

export default SingleProductShareButton