/* istanbul ignore file */
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AsyncThunk, configureStore } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import { throttle } from "lodash";

import { rootReducer, history, ApplicationState } from "./root-reducer";
import { State as LanguagesState } from "./languages-store";

type Language = {
  language: string;
};

const loadState = () => {
  const languagesState = localStorage.getItem("languages");

  if (languagesState === null) return undefined;
  return { languages: JSON.parse(languagesState) };
};

const saveState = (languages: Language) => {
  localStorage.setItem("languages", JSON.stringify(languages));
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createStore = () => {
  const persistedState = loadState();
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: { languages: persistedState?.languages },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(routerMiddleware(history)),
  });

  store.subscribe(
    throttle(() => {
      const { languages } = store.getState();
      saveState({ language: languages.language });
    }, 1000)
  );

  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module.hot.accept("./root-reducer", () => store.replaceReducer(rootReducer));
    }
  }

  return store;
};

const store = createStore();

type AppDispatch = typeof store.dispatch;

const useAppDispatch = (): AppDispatch => useDispatch();

const useAppSelector = <TReturn>(selector: (state: ApplicationState) => TReturn): TReturn =>
  useSelector<ApplicationState, TReturn>(selector, shallowEqual);

type ThunkOptions = {
  state: ApplicationState;
};

type TThunk<TPayload, TArg = void> = AsyncThunk<TPayload, TArg, ThunkOptions>;

export { createStore, useAppDispatch, useAppSelector };

export type { AppDispatch, TThunk };
