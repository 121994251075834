import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import { Product } from "../../../../services/products/types";
import { parseRichText } from "../../../../utils/helper";
import Rating from "../../../common/rating";
import { TMenuType } from "./single-product-menu";

const useStyles = makeStyles(
  theme => ({
    root: {
      marginTop: 25,
      borderRadius: 15,
      border: `1px solid ${theme.colors.blue}`,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      boxSizing: "border-box",
      padding: "8px 10px",
    },
    item: {
      transition: "0.5s",
    },
    description: {
      boxSizing: "border-box",
      paddingLeft: 10,
      textAlign: "end",

      "&>ul": {
        listStyleType: "disc",
        paddingRight: 25,
      },

      // "&>ul>li": {
      //   wordBreak: "break-all",
      // },
    },
    text: {
      color: theme.colors.grayText,
      fontSize: 17,
      direction: "rtl",
      textAlign: "right",
    },
    textBold: {
      fontWeight: 700,
      fontSize: 22,
      color: theme.colors.darkText,
      direction: "rtl",
    },

    date: {
      fontWeight: 600,
      fontSize: 18,
      color: theme.colors.darkText,
    },
    qnaItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      paddingBottom: 15,
      "&:last-child": {
        paddingBottom: 0,
      },
    },
    review: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      paddingBottom: 25,
      "&:last-child": {
        paddingBottom: 0,
      },
    },
    star: {
      height: 20,
      width: 20,
    },
  }),
  { name: "single-product-menu-content" }
);

type TProps = {
  type: TMenuType;
  product: Product;
  startIndex?: number;
  menuTopOffset: any;
};

const SingleProductMenuContent = ({ type, product, startIndex }: TProps) => {
  const classes = useStyles();

  const setContent = () => {
    switch (type) {
      case "description":
        return (
          <div className={clsx(classes.text, classes.description, classes.item)}>
            {parseRichText(product?.description)}
          </div>
        );
      case "specifications":
        return (
          <div className={clsx(classes.text, classes.description, classes.item)}>
            {parseRichText(product?.specifications)}
          </div>
        );
      case "includedInTheKit":
        return (
          <div className={clsx(classes.text, classes.description, classes.item)}>
            {parseRichText(product?.includedInTheKit)}
          </div>
        );
      case "qna":
        return product?.qna.map((qnaItem, i) => (
          <div key={i} className={clsx(classes.qnaItem, classes.item)}>
            <div className={classes.textBold}>{qnaItem.question}</div>
            <div className={classes.text}>{qnaItem.answer}</div>
          </div>
        ));
      case "reviews":
        let reviews = product?.reviews.slice(0);

        return reviews
          .reverse()
          .slice(startIndex! * 5, startIndex! * 5 + 5)
          .map((review, i) => {
            const dateObj = new Date(review.date);
            const date = dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate();
            const month =
              dateObj.getMonth() < 9 ? `0${dateObj.getMonth() + 1}` : dateObj.getMonth() + 1;
            const year = dateObj.getFullYear();
            const displayedDate = `${date}/${month}/${year}`;

            return (
              <div key={i} className={clsx(classes.review, classes.item)}>
                <div className={classes.textBold}>{review.title}</div>
                <div className={classes.date}>{displayedDate}</div>
                <Rating
                  staticCount={review.rating}
                  className={{ icon: classes.star }}
                  rating={`${review.rating}`}
                  size="small"
                  isClickable={false}
                />
                <div className={classes.text}>{review.text}</div>
              </div>
            );
          });
    }
  };

  return <>{setContent()}</>;
};

export default SingleProductMenuContent;
