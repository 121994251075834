import { DefaultTheme, CreateCSSProperties } from "@material-ui/styles";

const scrollStyles = (theme: DefaultTheme, visible?: boolean): CreateCSSProperties<Record<string, unknown>> => ({
  "&::-webkit-scrollbar": {
    width: visible ? 5 : 0,
    height: visible ? 5 : 0,
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: 10,
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.colors.scroll,
    borderRadius: 10,
  },
  "&::-webkit-scrollbar-thumb:hover": {
    opacity: 0.9,
  },
});

export { scrollStyles };
