import { buildProduct } from "../../utils/helper";
import strapiService from "../strapi";
import { TOrder, TUpdateCartItemsParams, TUpdateCartItemsPayload, TUpdateReq } from "./types";

const createOrder = async (order: TOrder): Promise<TOrder> => {
  const data = await strapiService.createOrder(order);

  return data;
};

const updateOrder = async (body: TUpdateReq): Promise<void> => {
  await strapiService.updateOrder(body);
};

const fetchOrders = async (): Promise<TOrder[]> => {
  const data = await strapiService.fetchOrders();

  return data;
};

const updateCartItems = async (props: TUpdateCartItemsParams): Promise<TUpdateCartItemsPayload> => {
  const cartProductsIds = props.cartItems.map(c => c.id);
  const fetchedCartProducts = await strapiService.fetchProductsById(cartProductsIds);

  return {
    products: fetchedCartProducts.map(p => buildProduct(p)),
    user: props.user,
  };
};

export { createOrder, fetchOrders, updateOrder, updateCartItems };
