import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";

type TProps = {
  label?: string;
  name: string;
  type?: string;
  inputValue: string;
  additional?: boolean;
  placeholder?: string;
  isAdditionalForm?: boolean;
  ref?: React.RefObject<HTMLInputElement>;
  disabled?: boolean;
  errorMsg?: string;
  className?: {
    root?: string;
    label?: string;
    circle?: string;
    circleText?: string;
    input?: string;
    errorMsg?: string;
  };
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCircleClick?: () => void;
};

const Input = ({
  label,
  type,
  name,
  disabled,
  inputValue,
  additional,
  className,
  errorMsg,
  placeholder,
  ref,
  isAdditionalForm,
  onInputChange,
  onCircleClick,
}: TProps) => {
  const classes = useStyles();
  const circleText = isAdditionalForm ? "-" : "+";

  return (
    <div className={clsx(classes.root, className?.root)}>
      {!!label && <div className={clsx(classes.label, className?.label)}>{label}</div>}
      <div className={classes.inputArea}>
        {additional && (
          <button
            className={clsx(classes.circle, className?.circle)}
            type="button"
            onClick={onCircleClick}
          >
            <div className={clsx(classes.circleText, className?.circleText)}>{circleText}</div>
          </button>
        )}
        <input
          className={clsx(classes.input, className?.input)}
          name={name}
          type={type || "text"}
          value={inputValue}
          onChange={onInputChange}
          disabled={disabled}
          ref={!!ref ? ref : null}
          placeholder={placeholder}
        />
      </div>
      <span className={clsx(classes.errorMsg, className?.errorMsg)}>{errorMsg}</span>
    </div>
  );
};

const useStyles = makeStyles(
  theme => ({
    root: {
      transition: "0.4s",
      width: 200,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginRight: '5%',
      marginLeft: '7%',
      position: "relative",
      [theme.device.mobile()]: {
        width: "100%",
        paddingLeft: 35,
        boxSizing: "border-box",
      },
    },
    label: {
      color: theme.colors.blue,
      fontSize: 15,
      fontWeight: 600,
      padding: "8px 0",
      [theme.device.mobile()]: {
        fontSize: 18,
      },
    },
    input: {
      boxSizing: "border-box",
      padding: "5px 10px",
      fontSize: 16,
      width: "100%",
      borderRadius: 25,
      backgroundColor: theme.colors.text,
      border: `1px solid ${theme.colors.orange}`,
      outline: "none",
      textAlign: "end",
      color: theme.colors.orange,
      [theme.device.mobile()]: {
        padding: "12px 15px",
        fontSize: 19,
      },
      "&.error": {
        border: `1px solid ${theme.colors.red}`,
        boxShadow: `0 0 2px 0 ${theme.colors.red}`,
      },
    },
    inputArea: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      gridColumnGap: 10,
    },
    circle: {
      transition: "0.3s",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      outline: "none",
      border: "none",
      cursor: "pointer",
      position: "absolute",
      left: -25,
      width: 18,
      height: 18,
      borderRadius: "100%",
      backgroundColor: theme.colors.blue,
      [theme.device.mobile()]: {
        left: 0,
        width: 25,
        height: 25,
      },
    },
    circleText: {
      userSelect: "none",
      marginBottom: 3,
      paddingBottom: 2,
      fontWeight: 700,
      fontSize: 18,
      color: "#fff",
      [theme.device.mobile()]: {
        fontSize: 21,
      },
    },
    additional: {
      transform: "translateY(-100%)",
      opacity: 0,
      visibility: "hidden",
      [theme.device.mobile()]: {
        height: 0,
      },
    },
    additionalActive: {
      transform: "translateY(0)",
      visibility: "visible",
      opacity: 1,
      height: "auto",
    },
    active: {
      visibility: "visible",
      opacity: 1,
    },
    inactive: {
      cursor: "default",
      opacity: 0,
      visibility: "hidden",
      transition: "0.4s",
    },
    error: {
      borderColor: theme.colors.red,
    },
    errorMsg: {
      textAlign: "end",
      fontSize: 12,
      marginTop: 4,
      color: theme.colors.red,
      whiteSpace: "nowrap",
    },
  }),
  { name: "input" }
);

export default Input;
