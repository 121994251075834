import { useCallback, useEffect, useState } from "react";

type SwipeDirection = "left" | "right" | null;

type TReturn = {
  onTouchStart: (event: React.TouchEvent<HTMLDivElement>) => void;
  onTouchEnd: (event: React.TouchEvent<HTMLDivElement>) => void;
};

const useSwipe = (swipeCallback: (direction: SwipeDirection) => void): TReturn => {
  const [initialPosition, setInitialPosition] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [swipeDirection, setSwipeDirection] = useState<SwipeDirection>(null);

  const onTouchStart = (event: React.TouchEvent<HTMLDivElement>): void => {
    setInitialPosition(event.touches[0].pageX);
    setIsDragging(true);
  };

  const onTouchDrag = useCallback(
    (event: TouchEvent) => {
      if (!isDragging) {
        return;
      }

      setSwipeDirection(event.touches[0].pageX < initialPosition ? "left" : "right");
    },
    [initialPosition, isDragging]
  );

  const onTouchEnd = (): void => {
    setIsDragging(false);
    document.removeEventListener("touchmove", onTouchDrag);

    if (!swipeDirection) {
      return;
    }

    swipeCallback(swipeDirection);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("touchmove", onTouchDrag);
    } else {
      document.removeEventListener("touchmove", onTouchDrag);
    }
  }, [isDragging, onTouchDrag]);

  return {
    onTouchStart,
    onTouchEnd,
  };
};

export default useSwipe;
