import React from "react";
import { TContentPage, Section } from "../../../services/content-pages/types";
import { makeStyles, useTheme } from "@material-ui/styles";
import { parseRichText } from "../../../utils/helper";
import { useHistory } from "react-router";

const regex = /<\/?[^>]+(>|$)/g;

const SingleSubSectionItem = (props: any) => {
  const { title, body, classes } = props;
  //const newBody = body?.replace(regex, "");

  return (
    <div className={classes.subSectionItem} dir="rtl">
      {title && <h2 className={classes.subSectionTitle}>{title}</h2>}
      {body && <p className={classes.subSectionBody}>{parseRichText(body)}</p>}
    </div>
  );
};

const buildYoutubeUrl = (url: string): string => {
  if (!url) {
    return "";
  }
  const URL_TEMPLATE = "https://www.youtube.com/embed/";

  if (!new URL(url).searchParams.get("v")) {
    return url;
  }
  return URL_TEMPLATE + new URL(url).searchParams.get("v");
};

const SingleContentPage = (section: any) => {
  const classes = useStyles();
  const {
    heading,
    heading_paragraph,
    video_url,
    image,
    backgroundColor,
    id,
    ImageFloat,
    Sections,
  } = section.section;

  const history = useHistory();

  const itemsCount = Sections.length;

  const handleImageClick = (data: any) => {
    data && (history.push(data));
  }

  return (
    <li className={classes.container}>
      <div className={classes.sectionTitle}>
        {heading && <h3>{heading}</h3>}
        {heading_paragraph && <p>{heading_paragraph}</p>}
      </div>
      <div className={classes.mediaContainer}>
        {video_url ? (
          <div className={classes.videoContainer}>
            <iframe
              className={classes.video}
              src={buildYoutubeUrl(video_url)}
              title="content video"
              frameBorder="0"
            />
          </div>
          // <iframe className={classes.video} src={video_url} title="content video" frameBorder="0" />
        ) : (
          image && (
            <div className={image?.caption === '' ? classes.imageContainer : classes.imageContainerCursor} onClick={() => handleImageClick(image.caption)}>
              <img src={image.url} alt={image.name} />
            </div>
          )
        )}
      </div>
      <div className={classes.subSectionsContainer}>
        {Sections?.length > 0
          ? Sections.map((el: any) => (
            <SingleSubSectionItem classes={classes} title={el.title} body={el.body} key={el.id} />
          ))
          : null}
      </div>
    </li>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 20,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "& :lastChild": {
      marginBottom: 0,
    },
  },
  sectionTitle: {
    textAlign: "center",

    "& h3": {
      fontSize: 26,
      color: theme.colors.blue,
    },

    "& p": {
      fontSize: 18,
      color: theme.colors.orange,
    },
  },
  mediaContainer: {
    width: "100%",
  },
  videoContainer: {
    position: "relative",
    paddingBottom: "56.25%" /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */,
    height: 0,
    overflow: "hidden",
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  //   video: {
  //     width: "100%",
  //     height: 400,

  //     [theme.device.mobile()]: {
  //       height: 300,
  //     },
  //   },
  imageContainer: {
    width: "100%",
    "& img": {
      cursor: 'default',
      width: "100%",
      // height: 350,
    },
  },
  imageContainerCursor: {
    width: "100%",
    "& img": {
      cursor: 'pointer',
      width: "100%",
      // height: 350,
    },
  },
  subSectionsContainer: {
    maxWidth: "86%",
    display: "flex",
    gap: 20,

    [theme.device.mobile()]: {
      flexDirection: "column",
    },

    [theme.device.tablet()]: {
      flexWrap: "wrap",
    },
  },
  subSectionItem: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 0px",

    [theme.device.tablet()]: {
      flex: "0 0 48%",
    },
  },
  subSectionTitle: {
    textAlign: "center",
    fontSize: 24,
    color: theme.colors.orange,
    maxWidth: "90%",

    [theme.device.smallTablet()]: {
      fontSize: 20,
    },
  },
  subSectionBody: {
    textAlign: "right",
    marginTop: 0,
    "&>ul": {
      listStyleType: "disc",
      paddingRight: 25,
    },

    "&>ul>li": {
      wordBreak: "break-all",
    },
    [theme.device.mobile()]: {
      fontSize: 16,
    },
  },
}));

export default SingleContentPage;
